import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {useRouteMatch} from "react-router-dom";
import Preloader from "../../util/Preloader";
import {Button, Col, Form, Row} from "react-bootstrap";

const getProductQuery = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      isHidden
      notForSale
      linksTo
      manufacturerId
      returnsCategory
      apiId
      manufacturerProductCode
      description
      excerpt
      stock
      minimalAmount
      restockDays
      weight
      country
      warrantyTime
      name
      slug
      price
      salePrice
      isRental
      isInstallation
      securityDeposit
      dailyRentalFee
      featuredSale
      featuredNews
      ean
      unit
      vat
      apiTimestamp
      createdAt
      updatedAt
      categoryId
      subCategoryId
      subSubCategoryId
      subSubSubCategoryId
      subSubSubSubCategoryId
      forbiddenShippingSlugs
      attributes {
        name
        value
        unit
      }
    }
  }
`;

const getProductConnections = gql`
  query getProductConnections{
    listProductCategorys {
      items {
        id
        name
        children{
          items{
            id
            name
            children{
              items{
                id
                name
                children{
                  items{
                    id
                    name
                    children{
                      items{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    listManufacturers{
      items{
        id
        name
      }
    }
  }
`;

const updateProductMutation = gql`
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

function ProductEditForm({initialProduct, saveChanges, categories, manufacturers}){
  const parseInitialProduct = () => JSON.parse(JSON.stringify(initialProduct), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [product, setProduct] = useState(parseInitialProduct());
  const [forbidShippingMethod, setForbidShippingMethod] = useState('');
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let floatValue = parseFloat(value);
    if (value == floatValue) value = floatValue;
    let newProduct = JSON.parse(JSON.stringify(product));
    let splitPath = path.split(".");
    for (let i = 0; i < splitPath.length; i++) {
      let intSplit = parseInt(splitPath[i]);
      if (intSplit == splitPath[i]) splitPath[i] = intSplit;
    }
    switch (splitPath.length) {
      case 1:
        newProduct[splitPath[0]] = value;
        break;
      case 2:
        newProduct[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newProduct[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setProduct(newProduct);
  };

  let selectedCategory = {
    category: null,
    subCategory: null,
    subSubCategory: null,
    subSubSubCategory: null,
    subSubSubSubCategory: null,
  };
  let levels = Object.keys(selectedCategory);
  let children = categories;
  for (let depth = 0; depth < levels.length; depth++) {
    const level = levels[depth];
    for (let i = 0; i < children.length; i++) {
      if (children[i].id === product[level + "Id"]){
        selectedCategory[level] = children[i];
        children = selectedCategory[level]?.children?.items || [];
        break;
      }
    }
  }

  return (
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={product.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="apiId">
            <Form.Label>Topas ID</Form.Label>
            <Form.Control type="text" placeholder="není" value={product.apiId} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="slug">
            <Form.Label>Název pro url</Form.Label>
            <Form.Control type="text" placeholder="není" value={product.slug} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="manufacturerId">
            <Form.Label>Výrobce</Form.Label>
            <Form.Control
              as={"select"}
              value={product.manufacturerId}
              onChange={changeEventHandler}
            >
              <option value={"NULL"}>Žádný</option>
              {manufacturers.map(manufacturer => <option value={manufacturer.id}>{manufacturer.name}</option>)}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="manufacturerProductCode">
            <Form.Label>ID výrobce</Form.Label>
            <Form.Control type="text" placeholder="není" value={product.manufacturerProductCode} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="ean">
            <Form.Label>EAN</Form.Label>
            <Form.Control type="text" placeholder="není" value={product.ean} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="price">
            <Form.Label>Cena</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.price} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="salePrice">
            <Form.Label>Prodejní cena</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.salePrice} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="vat">
            <Form.Label>DPH</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.vat} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="securityDeposit">
            <Form.Label>Kauce</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.securityDeposit} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="dailyRentalFee">
            <Form.Label>Cena za den</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.dailyRentalFee} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"isRental"}>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Check checked={product.isRental} type="checkbox" label="Produkt k výpůjčce" onClick={() => changeHandler("isRental", !product.isRental)}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"isInstallation"}>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Check checked={product.isInstallation} type="checkbox" label="Montáž" onClick={() => changeHandler("isInstallation", !product.isInstallation)}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="stock">
            <Form.Label>Skladové zásoby</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.stock} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="minimalAmount">
            <Form.Label>Minimální množství</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.minimalAmount} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="restockDays">
            <Form.Label>Naskladnění do dnů</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.restockDays} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="unit">
            <Form.Label>Prodejní jednotka</Form.Label>
            <Form.Control type="text" placeholder="není" value={product.unit} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="warrantyTime">
            <Form.Label>Záruční doba (měsíce)</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.warrantyTime} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="weight">
            <Form.Label>Váha pro dopravu</Form.Label>
            <Form.Control type="number" min={0} placeholder="není" value={product.weight} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"isHidden"}>
            <Form.Check checked={product.isHidden} type="checkbox" label="Skrytý produkt" onClick={() => changeHandler("isHidden", !product.isHidden)}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"featuredSale"}>
            <Form.Check checked={product.featuredSale} type="checkbox" label="Výprodej" onClick={() => changeHandler("featuredSale", !product.featuredSale)}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"featuredNews"}>
            <Form.Check checked={product.featuredNews} type="checkbox" label="Novinka" onClick={() => changeHandler("featuredNews", !product.featuredNews)}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"notForSale"}>
            <Form.Check checked={product.notForSale} type="checkbox" label="Není na prodej" onClick={() => changeHandler("notForSale", !product.notForSale)}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"linksTo"}>
            <Form.Label>Odkaz místo detailu:</Form.Label>
            <Form.Control type={"text"} value={product.linksTo} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={'forbidShippingMethod'}>
            <Form.Label>Zakázat dopravní metodu</Form.Label>
            <Form.Control type={"text"} value={forbidShippingMethod} onChange={({target}) => setForbidShippingMethod(target.value)} onKeyPress={({key}) => {
              if(key !== "Enter"){
                return;
              }
              let forbiddenShippingSlugs = [];
              if(product.forbiddenShippingSlugs){
                forbiddenShippingSlugs = product.forbiddenShippingSlugs;
              }
              forbiddenShippingSlugs.push(forbidShippingMethod);
              changeHandler('forbiddenShippingSlugs', forbiddenShippingSlugs)
              setForbidShippingMethod('');
            }}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Zakázané dopravní metody</Form.Label>
            <ul>
              {product.forbiddenShippingSlugs && product.forbiddenShippingSlugs.map(slug => (
                <li key={slug}>{slug} <a onClick={(event) => {
                  event.preventDefault();
                  changeHandler('forbiddenShippingSlugs', product.forbiddenShippingSlugs.filter(value => value !== slug))
                }} href={"#"}>x</a></li>
              ))}
            </ul>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"categoryId"}>
            <Form.Label>Kategorie</Form.Label>
            <Form.Control
              as={"select"}
              value={product.categoryId}
              onChange={changeEventHandler}
            >
              <option value={"NULL"}>Žádná</option>
              {categories.map(category => <option value={category.id}>{category.name}</option>)}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"subCategoryId"}>
            <Form.Label>1. podkategorie</Form.Label>
            <Form.Control
              as={"select"}
              value={product.subCategoryId}
              onChange={changeEventHandler}
            >
              <option value={"NULL"}>Žádná</option>
              {selectedCategory.category ? selectedCategory.category.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"subSubCategoryId"}>
            <Form.Label>2. podkategorie</Form.Label>
            <Form.Control
              as={"select"}
              value={product.subSubCategoryId}
              onChange={changeEventHandler}
            >
              <option value={"NULL"}>Žádná</option>
              {selectedCategory.subCategory ? selectedCategory.subCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"subSubSubCategoryId"}>
            <Form.Label>3. podkategorie</Form.Label>
            <Form.Control
              as={"select"}
              value={product.subSubSubCategoryId}
              onChange={changeEventHandler}
            >
              <option value={"NULL"}>Žádná</option>
              {selectedCategory.subSubCategory ? selectedCategory.subSubCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"subSubSubSubCategoryId"}>
            <Form.Label>4. podkategorie</Form.Label>
            <Form.Control
              as={"select"}
              value={product.subSubSubSubCategoryId}
              onChange={changeEventHandler}
            >
              <option value={"NULL"}>Žádná</option>
              {selectedCategory.subSubSubCategory ? selectedCategory.subSubSubCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"excerpt"}>
            <Form.Label>Perex</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={product.excerpt} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"description"}>
            <Form.Label>Popis</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={product.description} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2 pb-2"}>
        <h4>Atributy</h4>
        {product.attributes.map((attribute, index) => (
          <Col key={"attribute_" + index} md={12}>
            <Row>
              <Col>
                <Form.Group controlId={"attributes." + index + ".name"}>
                  <Form.Control type="text" placeholder="není" value={attribute.name} onChange={changeEventHandler} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"attributes." + index + ".value"}>
                  <Form.Control type="text" placeholder="není" value={attribute.value} onChange={changeEventHandler} />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Button className={"w-100"} onClick={() => {
                  product.attributes.splice(index, 1);
                  changeHandler("attributes", product.attributes);
                }} variant={"danger"}>Smazat</Button>
              </Col>
            </Row>
          </Col>
        ))}
        <Col md={12}>
          <Row>
            <Col md={{span: 2, offset: 10}}>
              <Button variant={"success"} className={"w-100"} onClick={() => {
                product.attributes.push({name: "", value: ""});
                changeHandler("attributes", product.attributes);
              }}>Přidat</Button>
            </Col>
          </Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 4, offset: 8}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <a href={"/admin/products/" + product.id + "/attachments"} className="btn btn-outline-primary">Upravit přílohy</a>
            <button onClick={() => setProduct(parseInitialProduct())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...product, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleProduct(){
  const match = useRouteMatch();
  const {loading: productLoading, data: productData, error: productError} = useQuery(getProductQuery, {
    variables: {
      id: match.params.productId,
    }
  });
  const {loading: connectonsLoading, data: connectionsData, error: connectionsError} = useQuery(getProductConnections);
  // updateProductResponse
  const [updateProduct, {
    loading: updateProductLoading,
    error: updateProductError,
    data: updateProductData,
  }] = useMutation(updateProductMutation, {
    onError: error => {
      console.log(error);
    }
  });
  if (productError || connectionsError) return (
    <article className={"singleProduct"}><div className={"danger"}>Chyba při načítání produktu</div></article>
  );

  if (productLoading || connectonsLoading || updateProductLoading) return (
    <article className={"singleProduct"}><Preloader/></article>
  );

  return (
    <article className={"singleProduct"}>
      <h3>Produkt: {match.params.productId}</h3>
      <hr/>
      <ProductEditForm initialProduct={productData.getProduct} manufacturers={connectionsData?.listManufacturers?.items} categories={connectionsData?.listProductCategorys?.items} saveChanges={(changedProduct) => {
        let input = JSON.parse(JSON.stringify(changedProduct));
        delete input.apiTimestamp;
        delete input.createdAt;
        delete input.updatedAt;
        if (!input.linksTo) input.linksTo = null;
        if(!input.restockDays) input.restockDays = null;
        if(!input.minimalAmount) input.minimalAmount = null;
        updateProduct({
          variables: {
            input
          },
          refetchQueries: [
            "GetProduct"
          ]
        })
      }}/>
    </article>
  );
}
/*
    attachments

    todo: later
    returnsCategory
    country
 */
export default SingleProduct;
