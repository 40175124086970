import React, {Component} from "react";
import { connect } from 'react-redux';
import {fetchCurrentUserInfo} from '../app/cognitoReducer';
import {Redirect} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Banners from "../widgets/Banners";
import {addNotification} from '../app/notificationsReducer';
import Advantages from "./Advantages";

import './resetPassword.scss'
import { Helmet } from "react-helmet-async";

class ResetPassword extends Component{
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      error: null,
      loading: false,
    }
  }


  componentDidMount(){
    this.props.dispatch(fetchCurrentUserInfo()); // Dispatch this on login status changes!!!
  }

  checkInputs(){
    if (!this.state.login){
      const error = {
        message: "Pole Login nesmí být prázdné",
        type: "error",
        code: "frontend.loginEmpty",
        field: "login"
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }
    return true;
  }

  doReset(){
    if (this.state.loading) return;
    if (!this.checkInputs()) return;
    this.setState(() => ({loading: true}), () => {
      Auth.forgotPassword(this.state.login)
        .then(() => {
           this.props.dispatch(fetchCurrentUserInfo());
           this.props.history.push("/verify-password/" + this.state.login);
        })
        .catch((error) => {
          switch (error.code){
            case "UserNotFoundException":
              error.message = "Uživatel s tímto loginem neexistuje";
              error.field = "login";
              break;
          }
          this.setState(() => ({
            error: error
          }))
          console.log(error);
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code
          }));
          this.setState(() => ({error: error}));
        })
        .finally(() => {
          this.setState(() => ({
              loading: false
          }))
        });

    });
  }



  render() {
    if (this.props.cognito.currentSession){
      return <Redirect to={"/"}/>
    }
    return (
      <div className={"resetPassword container mbt-25"}>
        <Helmet>
          <title>Zapomenuté heslo</title>
        </Helmet>
        <div className={"row gutter-20px m-0"}>
          <div className="col mbt-25 w-100p">
            <div className={"container mbt-25"}>
            <div className="pageHeader">
              <h1>Zapomenuté heslo</h1>
            </div>
            <div className="row pageForm">
              <div className="col w-66p">
                <div className="panel">
                  <div className="panelBody buttons">
                    <form onSubmit={event => {event.preventDefault(); this.doReset(); }}>
                      <label className={"mb-45 grid-80 mt-10"}>
                        <span>Login</span>
                        <input type={"text"} name={"login"} className={(this?.state?.error?.field === "login") ? "error" : ""} value={this.state.login} onChange={({target}) => this.setState(() => ({login: target.value}))}/>
                      </label>

                    </form>
                    <div className={"row m-0"}>
                      <div className={"button medium blue"} onClick={() => this.doReset()}><span>Resetovat heslo</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col w-33p">
                <Banners position={"login"}/>
              </div>
            </div>
            <div className="row">
              <div className="col w-100p">
                <h2 className={"mbt-25px"}>Výhody registrace</h2>
                <hr className={"mb-20px"}/>
                <Advantages/>
              </div>
            </div>
          </div>
          </div>
        </div>


      </div>


    );
  }
}

export default connect(state => ({cognito: state.cognito}))(ResetPassword);
