import React from "react";
import {useQuery, gql} from "@apollo/client";
import Preloader from "../util/Preloader";
import parse from "html-react-parser";
import PageNotFound from "../util/PageNotFound";
import domReplacementOptions from "./domReplacementOptions";
import {Helmet} from "react-helmet-async";

const GetPage = gql`
  query PageBySlug(
    $slug: String
    $limit: Int
  ) {
    pageBySlug(
      slug: $slug
      limit: $limit
    ) {
      items {
        id
        slug
        name
        content
        seoDescription
      }
    }
  }
`;

function DynamicPage(props){
  const { loading, error, data } = useQuery(GetPage, {
    variables:{
      slug: props.match.params.pageSlug,
      limit: 1,
    }
  });

  if(loading && !data){
    return (
      <article className={"dynamicPage container mbt-25"}>
        <Preloader/>
      </article>
    )
  }

  if(error || !data.pageBySlug || !data.pageBySlug.items){
    return (
      <article className={"dynamicPage container mbt-25"}>
        <strong className={"error"}>Nastala chyba při načítání kategorie novinek</strong>
      </article>
    )
  }

  if (!data.pageBySlug.items.length){
    return <PageNotFound/>
  }

  const page = data.pageBySlug.items[0];
  console.log(page);
  return (
    <article className={"dynamicPage container mbt-25"}>
      <Helmet>
        <title>{page.name}</title>
        {page.seoDescription ? <meta name={"description"} content={page.seoDescription}/> : null}
      </Helmet>
      <div className="pageHeader">
        <h1>{page.name}</h1>
      </div>
      <main className={"pageBody"}>
        {parse(page.content, domReplacementOptions)}
      </main>
    </article>
  );
}

export default DynamicPage;
