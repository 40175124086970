import React, {Component} from "react";
import { connect } from 'react-redux';
import {fetchCurrentSession, fetchCurrentUserInfo} from '../app/cognitoReducer';
import {Link, Redirect} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Banners from "../widgets/Banners";
import {addNotification} from '../app/notificationsReducer';


import "./login.scss";
import Advantages from "./Advantages";
import { Helmet } from "react-helmet-async";


class Login extends Component{
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      error: null,
      loading: false,
    }
  }


  componentDidMount(){
    this.props.dispatch(fetchCurrentUserInfo()); // Dispatch this on login status changes!!!
  }

  checkInputs(){
    if (!this.state.login){
      this.setState(() => ({error: {code: "frontend.loginEmpty", field: "login",}}));
      this.props.dispatch(addNotification({
        message: "Musíte zadat uživatelské jméno",
        type: "error",
        code: "frontend.loginEmpty",
      }));
      return false;
    }
    if (!this.state.password){
      this.setState(() => ({error: {code: "frontend.passwordEmpty", field: "password",}}));
      this.props.dispatch(addNotification({
        message: "Musíte zadat heslo",
        type: "error",
        code: "frontend.passwordEmpty",
      }));
      return false;
    }

    return true;
  }

  handleErrors(error) {
    this.setState(() => ({error: error}));
    switch (error.code){
      case "NotAuthorizedException":
      case "UserNotFoundException":
        error.message = "Neplatný uživatel nebo heslo";
        error.field = "login";
        break;
      case "UserNotConfirmedException":
        error.message = "Uživatel ještě nebyl potvrzen";
        error.field = "login";
        setTimeout(() => {
          this.props.history.push("/verify/" + this.state.login)
        }, 100);
        break;
    }
    console.log(error);
    this.props.dispatch(addNotification({
      message: error.message,
      type: "error",
      code: error.code
    }));
  }

  doLogin(){
    if (this.state.loading) return;
    if (!this.checkInputs()) return;
    this.setState(() => ({loading: true}), () => {
      Auth.signIn(this.state.login, this.state.password)
        .then(() => {this.props.dispatch(fetchCurrentUserInfo()); this.props.dispatch(fetchCurrentSession())})
        .catch((error) => this.handleErrors(error))
        .finally(() => this.setState(() => ({loading: false})));
    });
  }

  render() {
    if (this.props.cognito.currentSession){
      return <Redirect to={"/"}/>
    }
    return (
      <div className={"logIn container mbt-25"}>
        <Helmet>
          <title>Přihlášení</title>
        </Helmet>
        <div className={"row gutter-20px m-0"}>
          <div className="col mbt-25 w-100p">
            <div className="pageHeader">
              <h1>Přihlášení</h1>
            </div>

            <div className="row pageContent">
              <div className="col w-66p">
                <div className="panel">
                  <div className="panelBody buttons">
                    <form onSubmit={event => {event.preventDefault(); this.doLogin(); }}>
                      <label className={"mb-15 grid-80 mt-10"}>
                        <span>Login</span>
                        <input className={(this?.state?.error?.field === "login") ? "error" : ""} type={"text"} name={"login"} value={this.state.login} onChange={({target}) => this.setState(() => ({login: target.value}))}/>
                      </label>
                      <label className={"mb-45 grid-80"} >
                        <span>Heslo</span>
                        <input className={(this?.state?.error?.field === "password") ? "error" : ""} type={"password"} name={"password"} value={this.state.password} onChange={({target}) => this.setState(() => ({password: target.value}))}/>
                      </label>

                      <input type={"submit"} className={"hidden"}/>
                    </form>
                    <div className={"row m-0 buttonsWrapper"}>
                      <div className={"button medium blue"} onClick={() => this.doLogin()}><span>Přihlášení</span></div>
                      <Link className={"button medium light-gray border"} to={"/reset-password"}><span>Zapomenuté heslo?</span></Link>
                      <Link className={"button medium light-gray border"} to={"/register"}><span>Ještě nejsem zaregistrovaný</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col w-33p">
                <Banners position={"login"}/>
              </div>
            </div>
            <div className="row">
              <div className="col w-100p">
                <h2 className={"mbt-25px"}>Výhody registrace</h2>
                <hr className={"mb-20px"}/>
                <Advantages/>
              </div>
            </div>
          </div>
        </div>





      </div>
    );
  }
}

export default connect(state => ({
  cognito: state.cognito,
  notifications: state.notifications
}))(Login);
