import React, {useEffect, useState} from "react";
import {Form, Button, Row, Col, Table} from "react-bootstrap";
import {gql, useQuery} from "@apollo/client";
import API from "@aws-amplify/api";
import Preloader from "../util/Preloader";
import {useSelector} from "react-redux";
import Storage from "@aws-amplify/storage";

const getProductConnections = gql`
  query getProductConnections{
    listProductCategorys {
      items {
        id
        name
        children{
          items{
            id
            name
            children{
              items{
                id
                name
                children{
                  items{
                    id
                    name
                    children{
                      items{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    listManufacturers{
      items{
        id
        name
      }
    }
  }
`;

const searchProductsExports = gql`
  query SearchProductsExports(
    $filter: SearchableProductsExportFilterInput
    $sort: SearchableProductsExportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsExports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        fileName
        status
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

function ProductExport(props){
  const currentUserInfo = useSelector(state => state.cognito.currentUserInfo);

  const {loading: connectonsLoading, data: connectionsData, error: connectionsError} = useQuery(getProductConnections);

  const {loading: productsExportsLoading, error: productsExportsError, data: productsExportsData, startPolling, stopPolling} = useQuery(searchProductsExports, {
    variables: {
      sort: {field: "createdAt", direction: "desc"},
      limit: 9,
      nextFetchPolicy: "cache-first",
    },
  });
  useEffect(() => {
    startPolling(15000);
    return function cleanup() {
      stopPolling();
    }
  });
  const [productExportLoading, setProductExportLoading] = useState(false);
  const [productExportError, setProductExportError] = useState(null);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subSubCategoryId, setSubSubCategoryId] = useState(null);
  const [subSubSubCategoryId, setSubSubSubCategoryId] = useState(null);
  const [subSubSubSubCategoryId, setSubSubSubSubCategoryId] = useState(null);

  let exportProducts = (event) => {
    event.preventDefault();
    setProductExportLoading(true);
    setProductExportError(null);
    API.post('topasPlusRest', '/admin/exportProducts', {
      response: true,
      body: {
        categoryId: (categoryId === "NULL" ? null : categoryId),
        subCategoryId: (subCategoryId === "NULL" ? null : subCategoryId),
        subSubCategoryId: (subSubCategoryId === "NULL" ? null : subSubCategoryId),
        subSubSubCategoryId: (subSubSubCategoryId === "NULL" ? null : subSubSubCategoryId),
        subSubSubSubCategoryId: (subSubSubSubCategoryId === "NULL" ? null : subSubSubSubCategoryId),
        manufacturerId: (manufacturerId === "NULL" ? null : manufacturerId),
        userPath: currentUserInfo?.id,
      },
      headers: {
        InvocationType: 'Event',
      }
    }).then(response => {
      console.log("SUCCESS!", response);
    }).catch(error => {
      setProductExportError(error);
    }).finally(() => {
      setProductExportLoading(false);
    });
  };

  async function downloadExport(fileName){
    fileName = fileName.substr(fileName.indexOf("/") + 1);
    fileName = fileName.substr(fileName.indexOf("/") + 1);
    const downloadLink = await Storage.get(fileName, {
      level: "private",
      download: false,
    });
    window.open(downloadLink, '_blank');
  }

  const renderExports = () => {
    if (productExportError){
      return <Row>
        <Col>
          <h4 className={"text-danger"}>Chyba načítání</h4>
          <pre>{JSON.stringify(productExportError)}</pre>
        </Col>
      </Row>
    }
    if ((productsExportsLoading) && (!productsExportsData)) return(
      <Preloader/>
    );
    if (!productsExportsData.searchProductsExports.total){
      return <h4>Neproběhl žádný export</h4>;
    }
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>ID</th>
          <th>Soubor</th>
          <th>Stav</th>
          <th>Začátek</th>
          <th>Poslední změna</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        {productsExportsData.searchProductsExports.items.map(productsExport => (
          <tr key={"productExport_" + productsExport.id}>
            <td>{productsExport.id}</td>
            <td>{productsExport.fileName}</td>
            <td>{productsExport.status}</td>
            <td>{productsExport.createdAt}</td>
            <td>{productsExport.updatedAt}</td>
            <td>{productsExport.status === "OK" ? (
              <Button variant={"primary"} onClick={() => downloadExport(productsExport.fileName)} type={"button"}>
                <i className={"bi-download"}/>
              </Button>
            ) : null}</td>
          </tr>
        ))}
        </tbody>
      </Table>
    );
  };

  if (connectonsLoading && !connectionsData){
    return <div className={"productExport"}>
      <Preloader/>
    </div>
  }

  let categories = connectionsData?.listProductCategorys?.items;
  let manufacturers = connectionsData?.listManufacturers?.items;
  let product = {
    categoryId,
    subCategoryId,
    subSubCategoryId,
    subSubSubCategoryId,
    subSubSubSubCategoryId,
  }
  let selectedCategory = {
    category: null,
    subCategory: null,
    subSubCategory: null,
    subSubSubCategory: null,
    subSubSubSubCategory: null,
  };
  let levels = Object.keys(selectedCategory);
  let children = categories;
  for (let depth = 0; depth < levels.length; depth++) {
    const level = levels[depth];
    for (let i = 0; i < children.length; i++) {
      if (children[i].id === product[level + "Id"]){
        selectedCategory[level] = children[i];
        children = selectedCategory[level]?.children?.items || [];
        break;
      }
    }
  }

  return (
    <div className={"productExport"}>
      <h3>Export produktů</h3>
      <p>
        Pro vytvoření exportu zvolte filtry a klikněte na tlačítko vpravo, jakmile bude dokončen, lze stáhnout ve formuláři níže.
      </p>
      <hr/>
      <Form onSubmit={exportProducts}>
        <Form.Row>
          <Col>
            <Form.Group controlId={"categoryId"}>
              <Form.Label>Kategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={categoryId}
                onChange={({target}) => setCategoryId(target.value)}
              >
                <option value={"NULL"}>Žádná</option>
                {categories.map(category => <option value={category.id}>{category.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subCategoryId"}>
              <Form.Label>1. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subCategoryId}
                onChange={({target}) => setSubCategoryId(target.value)}
              >
                <option value={"NULL"}>Žádná</option>
                {selectedCategory.category ? selectedCategory.category.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subSubCategoryId"}>
              <Form.Label>2. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subSubCategoryId}
                onChange={({target}) => setSubSubCategoryId(target.value)}
              >
                <option value={"NULL"}>Žádná</option>
                {selectedCategory.subCategory ? selectedCategory.subCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subSubSubCategoryId"}>
              <Form.Label>3. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subSubSubCategoryId}
                onChange={({target}) => setSubSubSubCategoryId(target.value)}
              >
                <option value={"NULL"}>Žádná</option>
                {selectedCategory.subSubCategory ? selectedCategory.subSubCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subSubSubSubCategoryId"}>
              <Form.Label>4. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subSubSubSubCategoryId}
                onChange={({target}) => setSubSubSubSubCategoryId(target.value)}
              >
                <option value={"NULL"}>Žádná</option>
                {selectedCategory.subSubSubCategory ? selectedCategory.subSubSubCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId={"manufacturerId"}>
              <Form.Label>Výrobce</Form.Label>
              <Form.Control
                as={"select"}
                value={manufacturerId}
                onChange={({target}) => setManufacturerId(target.value)}
              >
                <option value={"NULL"}>Žádný</option>
                {manufacturers.map(manufacturer => <option value={manufacturer.id}>{manufacturer.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className={"text-right"}>
              <Form.Label className={"d-block"}>&nbsp;</Form.Label>
              <Button disabled={productExportLoading} variant="success" type="submit">
                Vytvořit nový export
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
      <hr/>
      <h3>Stav zadaných exportů</h3>
      <h6>(Aktualizace každých 15s)</h6>
      {renderExports()}
    </div>
  );
}

export default ProductExport;
