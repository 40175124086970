import React from "react";
import {Link} from "react-router-dom";
import {Nav} from "react-bootstrap";

function Header(props){
  return(
    <header className={"navbar position-sticky navbar-expand navbar-dark bg-dark"}>
      <Link to={"/admin"} className={"navbar-brand"}>
        <img style={({height: 50,})} src={"/staticImages/topasplus-logo.svg"} alt={"logo"}/>
      </Link>
      <Nav className="mr-auto">
        <Nav.Link href="/">Frontend</Nav.Link>
        {/*<Nav.Link href="#link">Link</Nav.Link>*/}
        {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
          {/*<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
          {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
          {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
          {/*<NavDropdown.Divider />*/}
          {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
        {/*</NavDropdown>*/}
      </Nav>
    </header>
  );
}

export default Header;
