import React, { Component } from 'react';
import { connect } from 'react-redux';
import {gql} from "@apollo/client";
import {graphql} from "@apollo/client/react/hoc";
import {Link} from "react-router-dom";
import Preloader from "../util/Preloader";

class Minicart extends Component{
  cartTotalCost(){
    if(!this.props.cart || !this.props.cart.length) return "0,0";
    let cost = 0;
    this.props.cart.forEach(item => {
      let product = this.props.products.find(product => product.id === item.productId);
      if(product) cost += item.amount * (product.salePrice ? product.salePrice : product.price)
    });

    return (Math.round(cost * 100) / 100).toFixed(2);
  }

  renderProductCountTag(){

    if(!this.props.cart || !this.props.cart.length) return null;
    let count = 0;
    this.props.cart.forEach(item => count += item.amount);
    return <div className={"cartSize"}>{count}</div>
  }

  render(){
    if (this.props.loading && !this.props.products){
      return (
        <div className="controlBox cart">
          <Preloader/>
        </div>
      );
    }
    if (this.props.error){
      return (
        <div className="controlBox cart">
          <strong className={"error"}>Nastala chyba při načítání košíku</strong>
        </div>
      );
    }
    return (
      <div className="controlBox cart">
        <Link to={"/cart"} className="button">
          <img src={"/staticImages/icons/cart.svg"} alt="košík" className="icon"/>
          {this.renderProductCountTag()}
        </Link>
        <div className="separator"></div>
        <div className="total">
          <span>{this.cartTotalCost()}</span>
          <span> Kč</span>
        </div>
      </div>
    );
  }
}

const getCartData = gql`
  query GetCartData(
    $filter: SearchableProductFilterInput
  ){
    searchProducts(
      filter: $filter
    ) {
      items {
        id
        price
        salePrice
        name
      }
    }
  }
`;

export default connect(state => ({cart: state.cart}))(graphql(getCartData, {

  props: props => {
    return {
      products: props.data.searchProducts && props.data.searchProducts.items,
      error: props.data.error,
      loading: props.data.loading,
    }
  },
  options: (props) => {
    let productsToSearch = [];
    props.cart.forEach(cartItem => productsToSearch.push({id: {eq: cartItem.productId}}));
    return {
      variables: {
        filter: {
          or: productsToSearch
        },
      },
    }
  },
})(Minicart))
