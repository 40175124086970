import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";

const getNewsCategory = gql`
    query GetNewsCategory($id: ID!) {
        getNewsCategory(id: $id) {
            id
            slug
            name
            order
            description
            createdAt
            updatedAt
        }
    }
`;

const updateNewsCategoryMutation = gql`
    mutation UpdateNewsCategory(
        $input: UpdateNewsCategoryInput!
        $condition: ModelNewsCategoryConditionInput
    ) {
        updateNewsCategory(input: $input, condition: $condition) {
            id
        }
    }
`;

function CategoryEditForm({initialCategory, saveChanges}){
  const parseInitialCategory = () => JSON.parse(JSON.stringify(initialCategory), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [category, setCategory] = useState(parseInitialCategory());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newCategory = JSON.parse(JSON.stringify(category));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newCategory[splitPath[0]] = value;
        break;
      case 2:
        newCategory[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newCategory[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setCategory(newCategory);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={category.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="slug">
            <Form.Label>Odkaz</Form.Label>
            <Form.Control type="text" placeholder="není" value={category.slug} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="order">
            <Form.Label>Pořadí</Form.Label>
            <Form.Control type="number" placeholder="není" value={category.order} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"description"}>
            <Form.Label>Popis</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={category.description} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s kategorií">
            <button onClick={() => setCategory(parseInitialCategory())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...category, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleCategory(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getNewsCategory, {
    variables: {
      id: match.params.categoryId,
    }
  });
  const [updateCategory, updateCategoryResponse] = useMutation(updateNewsCategoryMutation);
  if (error) return (
    <article className={"singleNewsCategory"}><div className={"danger"}>Chyba při načítání kategorie</div></article>
  ) ;

  if (loading || updateCategoryResponse.loading) return (
    <article className={"singleNewsCategory"}><Preloader/></article>
  ) ;

  const category = data.getNewsCategory;

  return (
    <article className={"singleNewsCategory"}>
      <h3>Kategorie novinek: {match.params.categoryId}</h3>
      <hr/>
      <CategoryEditForm initialCategory={category} saveChanges={(changedCategory) => updateCategory({
        variables:{
          input: changedCategory,
        }
      })}/>
    </article>
  );
}

export default SingleCategory;
