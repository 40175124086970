import React from 'react';
import { connect } from 'react-redux';
import Notification from "./Notification";

function Notifications(props) {
  if (!props)
    return null;


  return (
    <div className="notificationsWrapper">
      {props.notifications.map((item, index) => {
        return (
          <Notification
            key={index}
            index={index}
            type={item.type}
            message={item.message}/>
        );
        })
      }
    </div>
  )
}

export default connect(state => ({notifications: state.notifications})) (Notifications);
