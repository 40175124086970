import React, {useReducer, useState} from "react";
import {Table, Form, Col, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link, useRouteMatch} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listCategories = {
  null: gql`
      query ListProductCategorys(
          $filter: ModelProductCategoryFilterInput
          $limit: Int
          $nextToken: String
      ) {
          children: listProductCategorys(
              filter: $filter
              limit: $limit
              nextToken: $nextToken
          ) {
              items {
                  id
                  name
                  slug
                  description
                  order
                  createdAt
                  updatedAt
              }
              nextToken
          }
      }
  `,
  category: gql`
    query SubCategoryOfProductCategory(
        $parentId: ID
        $sortDirection: ModelSortDirection
        $filter: ModelProductSubCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        children: subCategoryOfProductCategory(
            parentId: $parentId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                slug
                description
                parentId
                order
                createdAt
                updatedAt
            }
            nextToken
        }
    }  
  `,
  subCategory: gql`
    query SubCategoryOfProductSubCategory(
        $parentId: ID
        $sortDirection: ModelSortDirection
        $filter: ModelProductSubSubCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        children: subCategoryOfProductSubCategory(
            parentId: $parentId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                slug
                description
                parentId
                order
                createdAt
                updatedAt
            }
            nextToken
        }
    }  
  `,
  subSubCategory: gql`
      query SubCategoryOfProductSubSubCategory(
          $parentId: ID
          $sortDirection: ModelSortDirection
          $filter: ModelProductSubSubSubCategoryFilterInput
          $limit: Int
          $nextToken: String
      ) {
          children: subCategoryOfProductSubSubCategory(
              parentId: $parentId
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
          ) {
              items {
                  id
                  name
                  slug
                  description
                  parentId
                  order
                  createdAt
                  updatedAt
              }
              nextToken
          }
      }
  `,
  subSubSubCategory: gql`
      query SubCategoryOfProductSubSubSubCategory(
          $parentId: ID
          $sortDirection: ModelSortDirection
          $filter: ModelProductSubSubSubSubCategoryFilterInput
          $limit: Int
          $nextToken: String
      ) {
          children: subCategoryOfProductSubSubSubCategory(
              parentId: $parentId
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
          ) {
              items {
                  id
                  name
                  slug
                  description
                  parentId
                  order
                  createdAt
                  updatedAt
              }
              nextToken
          }
      }
  `,
  subSubSubSubCategory: gql`
      query SubCategoryOfProductSubSubSubCategory(
          $parentId: ID
          $sortDirection: ModelSortDirection
          $filter: ModelProductSubSubSubSubCategoryFilterInput
          $limit: Int
          $nextToken: String
      ) {
          children: subCategoryOfProductSubSubSubCategory(
              parentId: $parentId
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
          ) {
              items {
                  id
                  name
                  slug
                  description
                  parentId
                  order
                  createdAt
                  updatedAt
              }
              nextToken
          }
      }
  `,
};

const getSelf = {
  null: gql`
    query GetProductCategory($id: ID!) {
        self: getProductCategory(id: $id) {
            id
            name
            slug
            description
            order
            createdAt
            updatedAt
        }
    }      
  `,
  category: gql`
      query GetProductCategory($id: ID!) {
          self: getProductCategory(id: $id) {
              id
              name
              slug
              description
              order
              createdAt
              updatedAt
          }
      }
  `,
  subCategory: gql`
    query GetProductSubCategory($id: ID!) {
        self: getProductSubCategory(id: $id) {
            id
            name
            slug
            description
            parentId
            order
            createdAt
            updatedAt
            parent {
                id
                name
            }
        }
    }
  `,
  subSubCategory: gql`
    query GetProductSubSubCategory($id: ID!) {
        self: getProductSubSubCategory(id: $id) {
            id
            name
            slug
            description
            parentId
            order
            createdAt
            updatedAt
            parent {
                id
                name
                parent{
                    id
                    name
                }
            }
        }
    }
  `,
  subSubSubCategory: gql`
    query GetProductSubSubSubCategory($id: ID!) {
        self: getProductSubSubSubCategory(id: $id) {
            id
            name
            slug
            description
            parentId
            order
            createdAt
            updatedAt
            parent {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
        }
    }
  `,
  subSubSubSubCategory: gql`
    query GetProductSubSubSubSubCategory($id: ID!){
        self: getProductSubSubSubSubCategory(id: $id){
            id
            name
            slug
            description
            parentId
            order
            createdAt
            updatedAt
            parent {
                id
                name
                parent {
                    id
                    name
                    parent {
                        id
                        name
                        parent{
                            id
                            name
                        }
                    }
                }
            }
        }
    }
  `,
};

const updateChild = {
  null: gql`
    mutation UpdateProductCategory(
        $input: UpdateProductCategoryInput!
        $condition: ModelProductCategoryConditionInput
    ) {
        updateProductCategory(input: $input, condition: $condition) {
            id
        }
    }  
  `,
  category: gql`
    mutation UpdateProductSubCategory(
        $input: UpdateProductSubCategoryInput!
        $condition: ModelProductSubCategoryConditionInput
    ) {
        updateProductSubCategory(input: $input, condition: $condition) {
            id
        }
    }  
  `,
  subCategory: gql`
    mutation UpdateProductSubSubCategory(
        $input: UpdateProductSubSubCategoryInput!
        $condition: ModelProductSubSubCategoryConditionInput
    ) {
        updateProductSubSubCategory(input: $input, condition: $condition) {
            id
        }
    }  
  `,
  subSubCategory: gql`
    mutation UpdateProductSubSubSubCategory(
        $input: UpdateProductSubSubSubCategoryInput!
        $condition: ModelProductSubSubSubCategoryConditionInput
    ) {
        updateProductSubSubSubCategory(input: $input, condition: $condition) {
            id
        }
    }  
  `,
  subSubSubCategory: gql`
    mutation UpdateProductSubSubSubSubCategory(
        $input: UpdateProductSubSubSubSubCategoryInput!
        $condition: ModelProductSubSubSubSubCategoryConditionInput
    ) {
        updateProductSubSubSubSubCategory(input: $input, condition: $condition) {
            id
        }
    }  
  `,
  subSubSubSubCategory: gql`
      mutation UpdateProductSubSubSubSubCategory(
          $input: UpdateProductSubSubSubSubCategoryInput!
          $condition: ModelProductSubSubSubSubCategoryConditionInput
      ) {
          updateProductSubSubSubSubCategory(input: $input, condition: $condition) {
              id
          }
      }
  `,
};

const linkPadding = {
  null: '',
  category: 'category/',
  subCategory: 'category/subCategory/',
  subSubCategory: 'category/subCategory/subSubCategory/',
  subSubSubCategory: 'category/subCategory/subSubCategory/subSubSubCategory/',
  subSubSubSubCategory: null,
}

const upALevel = {
  null: null,
  category: null,
  subCategory: 'category',
  subSubCategory: 'subCategory',
  subSubSubCategory: 'subSubCategory',
  subSubSubSubCategory: 'subSubSubCategory',
}

const refetchChildList = {
  null: ['ListProductCategorys'],
  category: ['SubCategoryOfProductCategory'],
  subCategory: ['SubCategoryOfProductSubCategory'],
  subSubCategory: ['SubCategoryOfProductSubSubCategory'],
  subSubSubCategory: ['SubCategoryOfProductSubSubSubCategory'],
  subSubSubSubCategory: ['SubCategoryOfProductSubSubSubCategory']
}

const refetchSelfList = {
  null: ['GetProductCategory'],
  category: ['GetProductCategory'],
  subCategory: ['GetProductSubCategory'],
  subSubCategory: ['GetProductSubSubCategory'],
  subSubSubCategory: ['GetProductSubSubSubCategory'],
  subSubSubSubCategory: ['GetProductSubSubSubSubCategory'],
}

const deleteChild = {
  null: gql`
    mutation DeleteProductCategory(
        $input: DeleteProductCategoryInput!
        $condition: ModelProductCategoryConditionInput
    ) {
        deleteProductCategory(input: $input, condition: $condition) {
            id
        }
    }
  `,
  category: gql`
    mutation DeleteProductSubCategory(
        $input: DeleteProductSubCategoryInput!
        $condition: ModelProductSubCategoryConditionInput
    ) {
        deleteProductSubCategory(input: $input, condition: $condition) {
            id
        }
    }
  `,
  subCategory: gql`
    mutation DeleteProductSubSubCategory(
        $input: DeleteProductSubSubCategoryInput!
        $condition: ModelProductSubSubCategoryConditionInput
    ) {
        deleteProductSubSubCategory(input: $input, condition: $condition) {
            id
        }
    }
  `,
  subSubCategory: gql`
    mutation DeleteProductSubSubSubCategory(
        $input: DeleteProductSubSubSubCategoryInput!
        $condition: ModelProductSubSubSubCategoryConditionInput
    ) {
        deleteProductSubSubSubCategory(input: $input, condition: $condition) {
            id
        }
    }
  `,
  subSubSubCategory: gql`
    mutation DeleteProductSubSubSubSubCategory(
        $input: DeleteProductSubSubSubSubCategoryInput!
        $condition: ModelProductSubSubSubSubCategoryConditionInput
    ) {
        deleteProductSubSubSubSubCategory(input: $input, condition: $condition) {
            id
        }
    }
  `,
  subSubSubSubCategory: gql`
      mutation DeleteProductSubSubSubSubCategory(
          $input: DeleteProductSubSubSubSubCategoryInput!
          $condition: ModelProductSubSubSubSubCategoryConditionInput
      ) {
          deleteProductSubSubSubSubCategory(input: $input, condition: $condition) {
              id
          }
      }
  `,
}

function CategoryRow({onSelectChange, selected, category, parentLevel}){
  const [updateCategory, updateCategoryResponse] = useMutation(updateChild[parentLevel], {
    refetchQueries: [
      ...refetchChildList[parentLevel]
    ]
  });
  const [deleteCategory, deleteCategoryResponse] = useMutation(deleteChild[parentLevel], {
    refetchQueries: [
      ...refetchChildList[parentLevel]
    ]
  });
  const [order, updateOrder] = useState(category.order);
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat výrobce"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{category.id}</td>
      <td>{category.name}</td>
      <td><Form.Control type={"number"} size={"sm"} value={order} onKeyDown={({key}) => key === "Enter" ? updateCategory({variables: {input: {id: category.id, order}}}) : null} onChange={({target}) => updateOrder(parseInt(target.value))}/></td>
      <td>{category.createdAt}</td>
      <td>
        {updateCategoryResponse.loading || deleteCategoryResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s objednávkou">
            <Link to={"/admin/categories/" + linkPadding[parentLevel] + category.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat kategorii: " + category.name + "?"} onClick={() => deleteCategory({variables: {input: {id: category.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function CategoryForm({loading, error, category: initialCategory, parentLevel}){
  const parseInitialCategory = () => JSON.parse(JSON.stringify(initialCategory || null), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [category, setCategory] = useState(parseInitialCategory());
  const [updateCategory, updateCategoryResponse] = useMutation(updateChild[upALevel[parentLevel]], {
    refetchQueries: [
      ...refetchSelfList[parentLevel]
    ]
  });

  if (((initialCategory && initialCategory.id) || null) !== ((category && category.id) || null)){
    setCategory(parseInitialCategory());
  }

  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newCategory = JSON.parse(JSON.stringify(category));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newCategory[splitPath[0]] = value;
        break;
      case 2:
        newCategory[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newCategory[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setCategory(newCategory);
  };

  if (parentLevel === null) return null;
  if (error) return(
    <div className="danger">Nastala chyba při načítání kategorie</div>
  );
  if (loading && !category) return(
    <Preloader/>
  )
  if (!category) return null;

  return(<>
    <hr/>
    <Form>
      <Form.Row className={"mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Jméno</Form.Label>
            <Form.Control type="text" placeholder="není" value={category.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="slug">
            <Form.Label>Jméno pro odkaz</Form.Label>
            <Form.Control type="text" placeholder="není" value={category.slug} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"description"}>
            <Form.Label>Popis</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={category.description} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 2, offset: 10}}>
          <div className="btn-group w-100" role="group" aria-label="Operace s kategorií">
            <Button onClick={() => updateCategory({variables: {input: {
                id: category.id,
                name: category.name,
                slug: category.slug,
                description: category.description,
              }}})} disabled={updateCategoryResponse.loading} type={"button"}>Upravit</Button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  </>)
}

function Categories({}){
  const match = useRouteMatch();
  let parentLevel = null;
  let parentId = null;
  if (match.params.categoryId){
    parentLevel = "category";
    parentId = match.params.categoryId;
    if (match.params.subCategoryId){
      parentLevel = "subCategory";
      parentId = match.params.subCategoryId;
      if (match.params.subSubCategoryId){
        parentLevel = "subSubCategory";
        parentId = match.params.subSubCategoryId;
        if (match.params.subSubSubCategoryId){
          parentLevel = "subSubSubCategory";
          parentId = match.params.subSubSubCategoryId;
          if (match.params.subSubSubSubCategoryId){
            parentLevel = "subSubSubSubCategory";
            parentId = match.params.subSubSubSubCategoryId;
          }
        }
      }
    }
  }
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("order");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listCategories[parentLevel], {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
      parentId,
    },
  });
  const {loading: loadingSelf, error: errorSelf, data: dataSelf} = useQuery(getSelf[parentLevel], {
    variables: {
      id: parentId || "doesNotExist",
    }
  });
  const [selectedCategories, dispatchSelectedCategories] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.children.items.length){
          return {}
        }
        data.children.items.forEach(category => newState[category.id] = category);
        return newState;
      case "toggle":
        if(newState[action.category.id]){
          delete newState[action.category.id];
        }
        else{
          newState[action.category.id] = action.category;
        }
        return newState;
      case "select":
        newState[action.category.id] = action.category;
        return newState;
      case "deselect":
        delete newState[action.category.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    if (parentLevel === "subSubSubSubCategory") return;

    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if (loading && !data) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání kategorií</div>
    );

    const categories = [...data.children.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedCategories).length === categories.length} onChange={() => dispatchSelectedCategories({type: "selectAll"})} aria-label={"Vybrat kategorii"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Pořadí {renderSortDirection("order")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {categories.map(category => (
            <CategoryRow key={"category_" + category.id} onSelectChange={() => dispatchSelectedCategories({type: "toggle", category})} selected={!!selectedCategories[category.id]} parentLevel={parentLevel} category={category}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  function renderParentPath(){
    if (loadingSelf && !dataSelf) return(
      <Preloader/>
    )
    if (errorSelf || !dataSelf.self || dataSelf.errors) return null;

    let toReturn = [];
    let self = dataSelf.self;
    let selfLevel = upALevel[parentLevel];
    toReturn.push(<Link to={"/admin/categories/" + linkPadding[selfLevel] + dataSelf.self.id}>{dataSelf.self.name}</Link>)
    toReturn.push(<span> / </span>);
    while (self.parent){
      selfLevel = upALevel[selfLevel];
      toReturn.push(<Link to={"/admin/categories/" + linkPadding[selfLevel] + self.parent.id}>{self.parent.name}</Link>)
      toReturn.push(<span> / </span>);
      self = self.parent;
    }

    return toReturn.reverse();
  }

  function renderSearchForm(){
    if (parentLevel === "subSubSubCategory") return;
    return (<>
      <h4 className={parentLevel === null ? "d-none" : ""}>Podkategorie</h4>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
      </Form>
    <hr/>
    </>);
  }

  return (
    <div className={"categories"}>
      <h3><Link to={"/admin/categories/"}>Kategorie</Link>{renderParentPath()}</h3>
      {<CategoryForm category={dataSelf && dataSelf.self} error={errorSelf || (dataSelf && dataSelf.errors)} loading={loadingSelf} parentLevel={parentLevel}/>}
      <hr/>
      {renderSearchForm()}
      {renderTable()}
    </div>
  );
}

export default Categories;
