import React, { Component } from 'react';
import {Link, Route} from 'react-router-dom';
import "./horizontalMenu.scss";
import Preloader from "../util/Preloader";
import PropTypes from 'prop-types';
import {gql} from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import EshopSidebar2 from "../eshop/EshopSidebar2";

class HorizontalMenu extends Component {
  elementIcon(){
    //TODO: If ever we create icons... For now only type is internal-link and external-link
  }

  renderSubMenu(type){
    switch (type){
      case "mobile-submenu-eshop":
        return <Route path={`/eshop/:filter*`}>
          <EshopSidebar2 className={"hidden-desktop mt-15"}/>
        </Route>;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open && (prevProps.open !== this.props.open)){
      document.querySelector("header .horizontalMenuWrapper").scroll(0, 0);
    }
  }

  render(){
    const internalTypes = ["internal-link", "mobile-submenu-eshop"];
    let toReturn = null;
    if (this.props.error){
      toReturn = (
        <div className={"menuElementWrapper error"}>
          <div className="menuElement">
            Chyba při načítání menu
          </div>
        </div>
      )
    }

    else if (this.props.loading && !this.props.menu){
      toReturn = (
        <div className={"menuElementWrapper"}>
          <div className="menuElement">
            <Preloader/>
          </div>
        </div>
      )
    }

    else{
      toReturn = this.props.menu.elements.items.map(element => (
        <div className={"menuElementWrapper"} key={element.id}>
          {internalTypes.indexOf(element.type) !== -1 ? (
            <Link className={"menuElement"} to={element.target}>
              {this.elementIcon(element.type)}
              <span>{element.title}</span>
            </Link>
          ) : (
            <a target={"_blank"} className={"menuElement"} href={element.target}>
              {this.elementIcon(element.type)}
              <span>{element.title}</span>
            </a>
          )}
          {this.renderSubMenu(element.type)}
        </div>
      ))
    }

    return (
      <div className={"horizontalMenuWrapper " + this.props.position + (this.props.open ? " open " : "") }>
        <nav className={"horizontalMenu"}>
          {toReturn}
        </nav>
      </div>
    )
  }
}

HorizontalMenu.propTypes = {
  position: PropTypes.string.isRequired,
};

const GetMainMenuWithElements = gql`
  query GetMainMenuWithElements($position: String) {
    menuByPosition(position: $position) {
      items {
        id
        name
        elements {
          items {
            id
            target
            title
            type
            order
          }
        }
      }
    }
  }
`;

export default graphql(GetMainMenuWithElements, {
  // options: {
  //   fetchPolicy: "network-only",
  // },
  props: props => ({
    menu: props.data.menuByPosition && props.data.menuByPosition.items && props.data.menuByPosition.items[0],
    error: props.data.error,
    loading: props.data.loading,
    //props.data fields: error; fetchMore(); loading; menuByPosition /*Or other inner fetched name*/; networkStatus; refetch(); startPolling(); stopPolling(); subscribeToMore(); updateQuery(); variables /* object in shape name: value */
  }),
})(HorizontalMenu)
