import React from "react";
import moment from "moment";

import Icons from "../util/Icons";

import "./thankYou.scss";
import {gql, useQuery} from "@apollo/client";
import {useRouteMatch} from "react-router-dom";
import Preloader from "../util/Preloader";
import {Helmet} from "react-helmet-async";

const GetSingleOrder = gql`
  query GetSingleOrder($id: ID!) {
    getSingleOrder(id: $id) {
      id
      userName
      fields {
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      shippingMethodId
      shippingMethod {
        id
        name
        description
        fee
        order
        createdAt
        updatedAt
      }
      shipmentTrackingId
      paymentMethodId
      paymentMethod {
        id
        name
        description
        fee
        order
        handler
        createdAt
        updatedAt
      }
      paymentTrackingId
      orderStatus
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
      note
      createdAt
      updatedAt
      redirectUrl
      orderNumber
    }
  }
`;

const ThankYou = (props) => {
  const match = useRouteMatch();
  const {
    data: getSingleOrderData,
    loading: getSingleOrderLoading,
    error: getSingleOrderError,
  } = useQuery(GetSingleOrder, {
    variables: {
      id: match.params.orderId,
    },
    onCompleted(data){
      const orderData = data.getSingleOrder;

      //Heureka Conversion:
      if(orderData.orderStatus !== 'cancelled'){
        window._hrq.setKey('10F94CD5D7E34EA67AD2662F37F46292');
        window._hrq.setOrderId(orderData.id);
        orderData.fields.forEach(field => {
          window._hrq.addProduct(field.name, field.priceIncludingTax, field.amount, field.productId);
        });
        window._hrq.trackOrder();
      }

    },
    onError(error) {
      console.log(error);
    },
  });

  if (getSingleOrderError){
    return (
      <div className={"container mbt-25"}>
        <strong className="error">Nastala chyba při načítání objednávky</strong>
      </div>
    );
  }

  if (getSingleOrderLoading){
    return (
      <div className={"container mbt-25"}>
        <Preloader/>
      </div>
    );
  }

  const orderData = getSingleOrderData.getSingleOrder;

  const invoiceAddress = orderData.invoiceAddress;
  const paymentMethod = orderData.paymentMethodId;
  const BANK_METHOD_ID = "84f19426-b335-48e0-a8bc-d7079f441656"; //TODO: This should not be hardcoded!
  let totalPrice = 0;

  const renderOrderContents = (props) => {
    return orderData.fields.map((field, index) => (
      <div
        className={
          (orderData.fields.length - 1 === index ? "last-item " : "") +
          "table-content-item"
        }
        key={"field_" + index}
      >
        <div className="name">{field.name}</div>
        <div className="quantity">
          <div className={" mr-10"}>{field.amount.toFixed(2)}</div>
        </div>
        <div className="itemprice">
          {field.price.toFixed(2)}
        </div>
        <div className="baseprice">
          {(field.price * field.amount).toFixed(2)}
        </div>
        <div className="dph">21%</div>
        <div className="totalprice">
          {(field.priceIncludingTax * field.amount).toFixed(2)}
        </div>
      </div>
    ));
  };
  const renderFooterContents = (props) => {
    let totalSansTax = 0;
    let totalTax = 0;
    let totalWithTax = 0;
    orderData.fields.forEach(field => {
      totalSansTax += field.price * field.amount;
      totalTax += (field.priceIncludingTax - field.price) * field.amount;
      totalWithTax += field.priceIncludingTax * field.amount;
    })
    totalPrice = totalWithTax;
    return (
      <div className="last-item table-content-item">
        <div className="infoDPH">DPH základní 21%</div>
        <div className="withoutDPH">{totalSansTax.toFixed(2)}</div>
        <div className="withtDPH">{totalTax.toFixed(2)}</div>
        <div className="totalprice">{totalWithTax.toFixed(2)}</div>
      </div>
    );
  };

  const pageHeader = {
    new: "Děkujeme, ",
    paid: "Děkujeme, ",
    complete: "Děkujeme, ",
    cancelled: "Litujeme, ",
  };

  const pageSubHeader = {
    new: "vaše objednávka byla v pořádku zavedena do systému",
    paid: "vaše objednávka byla v pořádku zaplacena",
    complete: "vaše objednávka byla v pořádku dokončena",
    cancelled: "vaše objednávka byla zrušena",
  };

  return (
    <div className={"container mbt-25"}>
      <Helmet>
        <title>Děkujeme za Váš nákup</title>
      </Helmet>
      <div className="pageHeader d-flex center">
        <h1>{pageHeader[orderData.orderStatus]}</h1>
        <span>{pageSubHeader[orderData.orderStatus]}</span>
      </div>
      <h2 className={"light-weight-header"}>
        <img
          className={"icon"}
          src={Icons.getIcon("pin")}
          alt={"Podrobnosti objednávky"}
        />
        <span>Podrobnosti objednávky</span>
      </h2>
      <section className="order">
        <div className="subscriber">
          <h2 className={"light-weight-header"}>
            <img
              className={"icon"}
              src={Icons.getIcon("user")}
              alt={"Odběratel"}
            />
            <span>Odběratel</span>
          </h2>
          <label>{invoiceAddress["companyName"]}</label>
          <label>{invoiceAddress["street"]}</label>
          <label>
            {invoiceAddress["zip"]} {invoiceAddress["city"]}
          </label>
          <label>IČO: {invoiceAddress["companyId"]}</label>
          <label>DIČ: {invoiceAddress["companyTaxId"]}</label>
        </div>
        <div className="deliveryAddress">
          <h2 className={"light-weight-header"}>
            <img
              className={"icon"}
              src={Icons.getIcon("truck")}
              alt={"Dodací adresa"}
            />
            <span>Dodací adresa</span>
          </h2>

          <label>{invoiceAddress["companyName"]}</label>
          <label>{invoiceAddress["name"]}</label>
          <label>{invoiceAddress["street"]}</label>
          <label>
            {invoiceAddress["zip"]} {invoiceAddress["city"]}
          </label>
        </div>
        <div className="orderInfo">
          <div className="createdAt">
            Datum vystavení: {moment().format("DD.MM.YYYY")}
          </div>
          <div className="paymentAt">
            Splatnost do: {moment().add(14, "days").format("DD.MM.YYYY")}
          </div>
          <div className="orderNumber">Č. objednávky: {orderData.orderNumber}</div>
        </div>
      </section>
      <section className={"order mt-20"}>
        <div className="subscriber">
          <h2 className={"light-weight-header"}>
            <img
              className={"icon"}
              src={Icons.getIcon("truck")}
              alt={"Podrobnosti dopravy"}
            />
            <span>Podrobnosti dopravy</span>
          </h2>
          <div>
            <strong>Metoda: </strong>
            <span>{orderData.shippingMethod.name}</span>
          </div>
          <div>
            <strong>Poplatek: </strong>
            <span>{orderData.shippingMethod.fee} Kč</span>
          </div>
          {orderData.shipmentTrackingId ? (
            <div>
              <strong>Sledovací číslo: </strong>
              <span>{orderData.shipmentTrackingId}</span>
            </div>
          ) : null}
        </div>

        <div className="orderInfo">
          <h2 className={"light-weight-header"}>
            <img
              className={"icon"}
              src={Icons.getIcon("bankTransfer")}
              alt={"Podrobnosti platby"}
            />
            <span>Podrobnosti platby</span>
          </h2>
          <div className="orderInfo">
            <div>
              <strong>Metoda: </strong>
              <span>{orderData.paymentMethod.name}</span>
            </div>
            <div>
              <strong>Poplatek: </strong>
              <span>{orderData.paymentMethod.fee} Kč</span>
            </div>
            {orderData.paymentTrackingId ? (
              <div>
                <strong>Sledovací číslo: </strong>
                <span>{orderData.paymentTrackingId}</span>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <section>
        <h2 className={"light-weight-header"}>
          <img className={"icon"} src={Icons.getIcon("cart")} alt={"košík"} />
          <span>Zboží</span>
        </h2>

        <div className="table-responsive thankyou">
          <div className="table-header">
            <div className="name">Označení dodávky</div>
            <div className="quantity">Počet MJ</div>
            <div className="itemprice">Cena za MJ</div>
            <div className="baseprice">Základ</div>
            <div className="dph">DPH</div>
            <div className="totalprice">Cena s DPH</div>
          </div>
          <div className="table-content">{renderOrderContents()}</div>
        </div>
        <div className="table-responsive footer">
          <div className="table-header">
            <div className="infoDPH">Sazba DPH</div>
            <div className="withoutDPH">Bez DPH</div>
            <div className="withDPH">DPH</div>
            <div className="totalprice">Celkem s DPH</div>
          </div>
          <div className="table-content">{renderFooterContents()}</div>
        </div>
      </section>
      {paymentMethod === BANK_METHOD_ID ? (
        <section>
          <div className="payment">
            <h2 className={"light-weight-header"}>
              <img
                className={"icon"}
                src={Icons.getIcon("bankTransfer")}
                alt={"Bankovní převod"}
              />
              <span>Informace pro platbu převodem</span>
            </h2>
            <div className="bankTransferWrapper">
              <div className="bankAccount">
                <label>Bankovní účet</label>
                <div className="accountInfo">
                  <span>298496327/0300</span>
                </div>
              </div>
              <div className="varSymbol">
                <label>Var. symbol</label>
                <div>{orderData.orderNumber}</div>
              </div>
              <div className="konstSymbol">
                <label>Konst. symbol</label>
                <div>0308</div>
              </div>
              <div className="totalPrice">
                <label>K úhradě</label>
                <div>{totalPrice.toFixed(2)}</div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};
export default ThankYou;
