import React, { Component } from 'react';
import Pills from "../widgets/Pills";
import Banners from "../widgets/Banners";
import ProductListing from "../widgets/ProductListing";
import ManufacturerListing from "../widgets/ManufacturerListing";

class HomePage extends  Component{
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return (
      <article className={"homePage container mb-50 mb-0-mobile"}>
        <section>
          <Banners position={"homepage-top"}/>
          <Pills className={"mt-20 homepage"} pillClassName={"bottom-right-arrow"}/>
        </section>
        <hr className={"offWhite mbt-20 hidden-mobile"}/>
        <ProductListing filter={({onSale: true, newest: false})} productClassName={"w-25p"} className={"mb-50 mb-0-mobile"} heading={"Produkty v akci"}/>
        <hr className={"hidden-desktop mbt-0 offWhite"}/>
        <ProductListing filter={({onSale: false, newest: true})} productClassName={"w-25p"} className={"mb-50 mb-25-mobile"} heading={"Nejnovější produkty"}/>
        <ManufacturerListing history={this.props.history} className={"mb-50 mb-0-mobile"} heading={"Vyberte si vaší značku"} subHeading={"Proklik na všechny produkty vybrané značky"}/>
        <section className={"hidden-mobile"}>
          <h3 className={"text-center"}>Naše výhody</h3>
          <Banners position={"homepage-bottom"}/>
        </section>
      </article>
    )
  }
}

export default HomePage;
