import React, { useState } from "react";
import { FormFile } from "react-bootstrap";
import { connect } from "react-redux";
import CalendarInput from "../util/CalendarInput";

import './rentFormular.scss'
import {from} from "@apollo/client";

const formFields = [
    {
        label: "Jmeno",
        name: 'rent_name',
        required: true,
        type: "text",
        defaultValue: "",
    },
    {
        label: "Příjmení",
        name: 'rent_lastname',
        required: true,
        type: "text",
        defaultValue: "",
    },
    {
        label: "Telefon",
        name: "rent_phone",
        required: true,
        type: "tel",
        defaultValue: "+420",
    },
    {
        label: "e-mail",
        name: "rent_email",
        required: true,
        type: "email",
        defaultValue: "@",
    },
    {
        label: "Od - Do *",
        name: "rent_calendar",
        required: true,
        type: "calendar",
    },
    {
        label: "Zpráva",
        name: "rent_textmessage",
        required: false,
        type: "textarea",
        defaultValue: "",
    }
]
function FormField(props) {
    const handleOnCHangeEvent = (event) => {
        let value = event.target.value
        props.setFormData(prevState => {
            const toReturn = {
                ...prevState,
                [field.name]: value
            };
            if(props.onChange) props.onChange(toReturn);
            return toReturn;
        });
    }

    let field = props.field
    let product = props.product;

    let dateSpanHeader = null;
    if (product.isRental){
        dateSpanHeader = "Půjčit";
    }
    if (product.isInstallation){
        dateSpanHeader = "Termín";
    }

    switch(field.type) {
        case "textarea": {
            return (
              <textarea
                name={field.name}
                required={field.required}
                placeholder={field.label}
                onChange={(event) => handleOnCHangeEvent(event)}
              ></textarea>
            )
          }
          case "calendar": {
              return (
                <CalendarInput onChange={(fromDate, toDate) => {
                    handleOnCHangeEvent({
                        target: {
                            value: `Od: ${fromDate?.toLocaleDateString()} Do: ${toDate?.toLocaleDateString()}`
                        }
                    })
                }} field={field} dateSpanHeader={dateSpanHeader}/>
              )
          }
          default: {
            let required_text = (field.required)? " *" : "";
            let placeholder_text = field.label + required_text

            return (
              <input
                type={field.type}
                name={field.name}
                placeholder={placeholder_text}
                required={field.required}
                onClick={event => {
                    if (event.target.value) return;
                    event.target.value = field.defaultValue;
                    handleOnCHangeEvent({target:{value: field.defaultValue}})
                }}
                onChange={(event) => handleOnCHangeEvent(event)}
              ></input>)
          }
    }
}



function RentFormular(props) {
    // console.log(props);
    let product = props.product
    const [formData, setFormData] = useState()
    const handleSubmit = (event, formData) => {
        event.preventDefault();
        props.handleSubmit(event, formData);
        console.log(formData);
    }
    return (
        <form className="rent_formular" onSubmit={event => handleSubmit(event, formData)}>
            {
                formFields.map((item, index) => {
                    return (
                        <label key={"formItem" + index} className={item.name}>
                            <FormField setFormData={setFormData} onChange={(newFormData) => props.onChange(newFormData)} field={item} product={product}/>
                        </label>)
                })
            }
        </form>
    )
}
export default connect((state) => ({
    cart: state.cart,
}))(RentFormular);
