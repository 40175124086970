import React, {useState} from "react";
import Preloader from "../../util/Preloader";
import {Button, Form, Table} from "react-bootstrap";
import Pagination from "../util/Pagination";
import {gql, useMutation, useQuery} from "@apollo/client";
import debounce from "lodash/debounce";
import {Link} from "react-router-dom";
import ConfirmButton from "../util/ConfirmButton";

const listSettings = gql`
    query ListSettingss(
        $name: String
        $filter: ModelSettingsFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSettingss(
            name: $name
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                name
                value
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

const createSettingMutation = gql`
    mutation CreateSettings(
        $input: CreateSettingsInput!
        $condition: ModelSettingsConditionInput
    ) {
        createSettings(input: $input, condition: $condition) {
            name
            value
            createdAt
            updatedAt
        }
    }
`;

const updateSettingMutation = gql`
    mutation UpdateSettings(
        $input: UpdateSettingsInput!
        $condition: ModelSettingsConditionInput
    ) {
        updateSettings(input: $input, condition: $condition) {
            name
            value
            createdAt
            updatedAt
        }
    }
`;

const deleteSettingMutation = gql`
    mutation DeleteSettings(
        $input: DeleteSettingsInput!
        $condition: ModelSettingsConditionInput
    ) {
        deleteSettings(input: $input, condition: $condition) {
            name
            value
            createdAt
            updatedAt
        }
    }
`;

function SettingRow({setting}){
  const [updateSetting, updateSettingResponse] = useMutation(updateSettingMutation, {
    refetchQueries: [
      'ListSettingss'
    ]
  });
  const [deleteSetting, deleteSettingResponse] = useMutation(deleteSettingMutation, {
    refetchQueries: [
      'ListSettingss'
    ]
  });
  const [value, updateValue] = useState(setting.value);
  return (
    <tr>
      <td>{setting.name}</td>
      <td>
        <Form.Control
          type={"number"}
          value={value}
          disabled={updateSettingResponse.loading}
          onKeyDown={({key}) => key === "Enter" ? updateSetting({variables: {input: {name: setting.name, value: parseFloat(value)}}}) : null}
          onChange={({target}) => updateValue(target.value)}/>
      </td>
      <td>{setting.updatedAt}</td>
      <td>
        <ConfirmButton confirmMessage={"Skutečně chcete smazat nastavení: " + setting.name + "?"} disabled={deleteSettingResponse.loading} onClick={() => deleteSetting({variables: {input: {name: setting.name}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
      </td>
    </tr>
  );
}

function Settings(){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("createdAt");
  const [sortDirection, changeSortDirection] = useState("desc");
  const { loading, error, data } = useQuery(listSettings, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createSetting, createSettingResponse] = useMutation(createSettingMutation, {
    refetchQueries: [
      'ListSettingss'
    ]
  });

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání nastavení</div>
    );

    const settings = [...data.listSettingss.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>Název {renderSortDirection("name")}</th>
            <th>Hodnota</th>
            <th>Datum {renderSortDirection("updatedAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {settings.map(setting => (
            <SettingRow key={"setting_" + setting.name} setting={setting}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"settings"}>
      <h3>Nastavení</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createSettingResponse.loading} onClick={() => createSetting({variables: {input: {
              name: window.prompt("Název", "Nové nastavení"),
              value: 0
            }}})}>Vytvořit nové</Button>
      </Form>
      {renderTable()}
    </div>
  );
}

export default Settings;
