import React, {Component} from "react";
import { connect } from 'react-redux';
import {fetchCurrentUserInfo} from '../app/cognitoReducer';
import {addNotification} from '../app/notificationsReducer';
import {Redirect} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Banners from "../widgets/Banners";
import Advantages from "./Advantages";

import './verifyPassword.scss'
import { Helmet } from "react-helmet-async";

class VerifyPassword extends Component{
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      code: "",
      password: "",
      error: null,
      loading: false,
    }
  }


  componentDidMount(){
    this.props.dispatch(fetchCurrentUserInfo()); // Dispatch this on login status changes!!!
    const login = this?.props?.match?.params?.name;
    const code = this?.props?.match?.params?.code;
    if (login || code){
      this.setState(() => ({
        login: login || "",
        code: code || "",
      }))
    }
  }

  checkInputs(){
    if (!this.state.login){
      const error = {
        message: "Pole Login nesmí být prázdné",
        type: "error",
        code: "frontend.loginEmpty",
        field: "login",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }

    if (!this.state.code){
      const error = {
        message: "Pole kód nesmí být prázdné",
        type: "error",
        code: "frontend.verificationcodeEmpty",
        field: "code",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }
    if (!this.state.password){
      const error = {
        message: "Pole heslo nesmí být prázdné",
        type: "error",
        code: "frontend.passwordEmpty",
        field: "password",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }
    return true;
  }

  doVerifyPassword(){
    if (this.state.loading) return;
    if (!this.checkInputs()) return;
    this.setState(() => ({loading: true}), () => {
      Auth.forgotPasswordSubmit(this.state.login, this.state.code, this.state.password)
        .then(() => {
          this.props.dispatch(fetchCurrentUserInfo());
          this.props.history.push("/login");
        })
        .catch(error => {
          switch (error.code){
            case "CodeMismatchException":
              error.message = "Kód nesedí nebo je zastaralý";
              error.field = "code";
              break;
            case "InvalidPasswordException":
              error.message = "Heslo nevyhovuje požadavkům: Alespoň 8 znaků, z čehož musí být alespoň jedno: Číslo, velké písmeno a malé písmeno";
              error.field = "password";
              break;
            default:
              console.log(error);
          }
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code,
          }));
          this.setState(() => ({error: error}));
        })
        .finally(() => {
          this.setState(() => ({
            loading: false
          }));
        });

    });
  }


  render() {
    if (this.props.cognito.currentSession){
      return <Redirect to={"/"}/>
    }
    return (
      <div className={"verify container mbt-25"}>
        <Helmet>
          <title>Nové heslo</title>
        </Helmet>
        <div className={"row gutter-20px m-0"}>
          <div className="col mbt-25 w-100p">
            <div className="pageHeader">
              <h1>Nové heslo</h1>
            </div>
            <div className="row pageForm">
              <div className="col w-66p">
                <div className="panel">
                  <div className="panelBody buttons">
                    <form onSubmit={event => {event.preventDefault(); this.doVerifyPassword(); }}>
                      <label className={"mb-15 grid-105 mt-10"}>
                        <span>Login</span>
                        <input type={"text"} name={"login"} className={(this?.state?.error?.field === "login") ? "error" : ""} value={this.state.login} onChange={({target}) => this.setState(() => ({login: target.value}))}/>
                      </label>
                      <label className={"mb-15 grid-105"}>
                        <span>Verifikační kód</span>
                        <input type={"text"} name={"code"} className={(this?.state?.error?.field === "code") ? "error" : ""} value={this.state.code} onChange={({target}) => this.setState(() => ({code: target.value}))}/>
                      </label>
                      <label className={"mb-45 grid-105"}>
                        <span>Nové heslo</span>
                        <input type={"password"} name={"password"} className={(this?.state?.error?.field === "password") ? "error" : ""} value={this.state.password} onChange={({target}) => this.setState(() => ({password: target.value}))}/>
                      </label>

                    </form>
                    <div className={"row m-0"}>
                      <div className={"button medium blue"} onClick={() => this.doVerifyPassword()}><span>Resetovat heslo</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col w-33p">
                <Banners position={"login"}/>
              </div>
            </div>
            <div className="row">
            <div className="col w-100p">
              <h2 className={"mbt-25px"}>Výhody registrace</h2>
              <hr className={"mb-20px"}/>
              <Advantages/>
            </div>
          </div>
          </div>
        </div>
      </div>


    );
  }
}

export default connect(state => ({cognito: state.cognito}))(VerifyPassword);
