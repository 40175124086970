import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate
} from 'redux-persist';
import cognitoReducer from './cognitoReducer';
import cartReducer from './cartReducer';
import userPreferencesReducer from "./userPreferencesReducer";
import notificationsReducer from "./notificationsReducer";
import menuReducer from './menuReducer';
import pageSettingsSlice, {defaultState as pageSettingsDefaultState} from "./pageSettingsReducer";
import popupsSlice, {defaultState as popupsDefaultState} from "./popupReducer";

const rootReducer = combineReducers({
  menuOpen: menuReducer,
  cognito: cognitoReducer,
  cart: cartReducer,
  userPreferences: userPreferencesReducer,
  notifications: notificationsReducer,
  pageSettings: pageSettingsSlice.reducer,
  popups: popupsSlice.reducer,
});

const migrations = {
  0: state => state,
  1: state => state,
  2: state => ({...state, userPreferences: {...state.userPreferences, "eshop.filter": {
        sort: { field: "createdAt", direction: "desc" },
        filter: {
          featuredSale: { eq: false },
          salePrice: { gte: 0, lte: 10000 },
        },
      }}}),
  3: state => ({...state, userPreferences: {...state.userPreferences, "eshop.filter": {
        sort: { field: "createdAt", direction: "asc" },
        filter: {
          featuredSale: { eq: false },
          salePrice: { gte: 0, lte: 100000 },
          categoryId: null,
          subCategoryId: null,
          subSubCategoryId: null,
          subSubSubCategoryId: null,
          or: [],
        },
        view: { isGridView: true },
      }}}),
  4: state => ({...state, userPreferences: {...state.userPreferences, "eshop.filter": {
        sort: { field: "createdAt", direction: "asc" },
        filter: {
          featuredSale: { eq: false },
          salePrice: { gte: 0, lte: 100000 },
          categoryId: null,
          subCategoryId: null,
          subSubCategoryId: null,
          subSubSubCategoryId: null,
          subSubSubSubCategoryId: null,
          or: [],
        },
        view: { isGridView: true },
      }}}),
  5: state => ({...state, userPreferences: {...state.userPreferences, cookiesAccepted: false}}),
  6: state => ({...state, userPreferences: {...state.userPreferences, eshop: {productDisplay: "col"}}}),
  7: state => ({...state, pageSettings: pageSettingsDefaultState}),
  8: state => ({...state, popups: popupsDefaultState}),
};
const persistConfig = {
  key: 'root',
  version: 8,
  blacklist: ['cognito'],
  storage,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "cognito/fetchCurrentSession/fulfilled"],
      ignoredPaths: ["cognito"],
    }
  }),
});
