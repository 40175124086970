import React, {useState, useRef} from 'react';
import {ButtonToolbar, ButtonGroup, Button, DropdownButton, Dropdown, Modal, ProgressBar} from "react-bootstrap";
import {DraftJsEditor, UndoButton, RedoButton, CharCounter, WordCounter, LineCounter, addDivider, basicBlocks, imagePlugin} from "./DraftJsEditor"
import {RichUtils, EditorState} from "draft-js";
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import Storage from "@aws-amplify/storage";

function EditorComponent({inputHtml, onChange}) {
  const editor = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(inputHtml || "")));

  const [uploadFile, setUploadFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSize, setUploadSize] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const selection = editorState.getSelection();
  const selectedBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const selectedInlineStyles = Array.from(editorState.getCurrentInlineStyle());
  const getBasicBlock = (code) => {
    for (let i = 0; i < basicBlocks.length; i++){
      if (basicBlocks[i].code === code){
        return basicBlocks[i];
      }
    }
    return {code, name: <div>{code}</div>, plainName: code, };
  };

  function cancelFileUpload(){
    if (uploading){
      return;
    }
    setUploadFile(null);
    setUploadSize(0);
    setUploadProgress(0);
    setShowUploadImage(false);
  }

  function handleFileChange({target}){
    if (target?.files?.length)
      setUploadFile(target.files[0]);
  }

  async function handleFileUpload(event){
    event.preventDefault();
    setUploading(true);
    setUploadProgress(0);
    setUploadSize(1);
    try {
      let putResponse = await Storage.put("cmsImages/" + uploadFile.name, uploadFile, {
        contentType: uploadFile.type,
        level: "public",
        progressCallback(progress) {
          setUploadProgress(progress.loaded);
          setUploadSize(progress.total);
        }
      });
      let getResponse = await Storage.get(putResponse.key, {
        level: "public",
      });
      let address = new URL(getResponse);
      address.search = "";
      editor.current.setEditorStateFocus(imagePlugin.addImage(editorState, address.href, {}));
      setShowUploadImage(false);
      setUploadSize(0);
      setUploadProgress(0);
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
    finally {
      setUploading(false);
    }
  }

  return (
    <div className={"editor"}>
      <Modal show={showUploadImage} onHide={cancelFileUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Nahrát obrázek</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>V poli níže vyberte obrázek a klikněte na tlačítko nahrát</p>
          <input accept="image/*" type="file" onChange={handleFileChange}/>
          {uploadSize ?
            <ProgressBar animated={uploadProgress !== uploadSize} now={uploadProgress} max={uploadSize} label={Math.round(uploadProgress/uploadSize * 100) + '%'}/>
            : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelFileUpload}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleFileUpload}>
            Nahrát
          </Button>
        </Modal.Footer>
      </Modal>
      <ButtonToolbar>
        <ButtonGroup className={"mr-1"}>
          <Button variant={selectedInlineStyles.indexOf("BOLD") !== -1 ? "primary" : "outline-primary"} className={"border-right"} onClick={() => editor.current?.setEditorStateFocus(RichUtils.toggleInlineStyle(editorState, 'BOLD'))}><b>B</b></Button>
          <Button variant={selectedInlineStyles.indexOf("ITALIC") !== -1 ? "primary" : "outline-primary"} className={"border-left border-right"} onClick={() => editor.current?.setEditorStateFocus(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))}><i>i</i></Button>
          <Button variant={selectedInlineStyles.indexOf("UNDERLINE") !== -1 ? "primary" : "outline-primary"} className={"border-left"} onClick={() => editor.current?.setEditorStateFocus(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))}><u>u</u></Button>
        </ButtonGroup>
        <DropdownButton as={ButtonGroup} title={"Styl: " + getBasicBlock(selectedBlockType).plainName} className={"ml-1 mr-1"}>
          {basicBlocks.map((basicBlock) => <Dropdown.Item key={"basicBlock_" + basicBlock.code} className={selectedBlockType === basicBlock.code ? "bg-info" : ""} onClick={() => editor.current?.setEditorStateFocus(RichUtils.toggleBlockType(editorState, basicBlock.code))}>{basicBlock.name}</Dropdown.Item>)}
        </DropdownButton>
        <ButtonGroup className={"ml-1 mr-1"}>
          <UndoButton/>
          <RedoButton/>
        </ButtonGroup>
        <ButtonGroup className={"ml-1 mr-1"}>
          <Button onClick={() => editor.current.setEditorStateFocus(addDivider(editorState))}><i className="bi bi-dash"/></Button>
        </ButtonGroup>
        <ButtonGroup className={"ml-1 mr-1"}>
          <Button onClick={() => {
            // console.log(convertToRaw(editorState.getCurrentContent()), JSON.stringify(convertToRaw(editorState.getCurrentContent())));
            console.log(stateToHTML(editorState.getCurrentContent()));
          }}>Export to console</Button>
        </ButtonGroup>
        <ButtonGroup className={"ml-1 mr-1"}>
          <Button className={"border-right"} onClick={() => editor.current.setEditorStateFocus(imagePlugin.addImage(editorState, window.prompt("Zadejte url obrázku"), {}))}><i className="bi bi-image"/> z url</Button>
          <Button className={"border-left"} onClick={() => setShowUploadImage(true)}><i className="bi bi-image"/> z disku</Button>
        </ButtonGroup>
      </ButtonToolbar>
      <div className="innerWrapper">
        <DraftJsEditor initialState={editorState} onChange={(newState) => {
          console.log("onChange", stateToHTML(editorState.getCurrentContent()));
          setEditorState(newState);
          if(onChange){
            onChange(stateToHTML(editorState.getCurrentContent()));
          }
        }} ref={editor} placeholder={"Obsah stránky..."}/>
        <div className="countWrapper">
          <span><CharCounter editorState={editorState} /> znaků, </span>
          <span><WordCounter editorState={editorState} /> slov, </span>
          <span><LineCounter editorState={editorState} /> řádků</span>
        </div>
      </div>
    </div>
  );
}

export default EditorComponent
