/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://384yqvm6ub.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "topasPlusRest",
            "endpoint": "https://te6bgxt0ka.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5mdrz3ggknde5bbucumbvl7u4q.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-24ytz5gt2je5fl7yj3c3bh7fyy",
    "aws_cognito_identity_pool_id": "eu-west-1:a1f989be-318d-4042-882c-5d0eda2df767",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_fFG7HUbhn",
    "aws_user_pools_web_client_id": "4an61lrupg4l0bqgut8ft93b29",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "topasplusfrontend-20201113131123-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://topasplusfrontend-20201113131123-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
    "aws_user_files_s3_bucket": "topasplusfrontend56811a89568e4711862065a850848b123017-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
