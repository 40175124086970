import React from "react";

import './stepsWithIcons.scss'

const howRentalsWorksSteps = [
    {
        icon: "/staticImages/processStepFirst.svg",
        text: "Vyberte si nářadí, které máme k dispozici."
    },
    {
        icon: "/staticImages/processStepSecond.svg",
        text: "Zarezervujte na telefonu +420 602 559 671."
    },
    {
        icon: "/staticImages/processStepThird.svg",
        text: "Vyzvedněte nářadí na pobočce nebo si nechte doručit rovnou na stavbu."
    }
]
const howItGoesSteps = [
    {
        icon: "/staticImages/processStepFirst.svg",
        text: "Vybírejte ze široké nabídky Produktů. Pohodlně, i z mobilu."
    },
    {
        icon: "/staticImages/processStepSecond.svg",
        text: " Objednejte výrobek online. Vždy znáte skladovou dostupnost."
    },
    {
        icon: "/staticImages/processStepThird.svg",
        text: " Nechte si dovézt zboží. Nově expres doprava na stavbu."
    },
    // {
    //   icon: "/staticImages/processStepFourth.svg",
    //   text: "Infografika jak u nás nakupovat - proces objednávky"
    // }
]


function Steps({type}) {
    let steps = []
    switch(type) {
        case "howItGoes": {
            steps = howItGoesSteps
        }
        case "howRentalsWorks": {
            steps = howRentalsWorksSteps
        }
    }

    return steps.map((item, index) => {
        return (
          <div key={"step" + index} className={"step" + ((index === howItGoesSteps.length - 1) ? " last" : "")}>
            <img src={item.icon} alt="step" />
            <span>{item.text}</span>
          </div>
        )
    })

}

const StepsWithIcons = ({type, title}) => {
    return (
        <div className="stepsWithIconsWrapper">
            <div className="stepsWithIconsHeader">
                <h3>{title}</h3>
            </div>
            <div className="stepsWithIconsBody">
                <Steps type={type} />
            </div>
        </div>
    )
}

export default StepsWithIcons;
