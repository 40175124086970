import React from "react";
import "./eshop.scss";
import { Route, Switch } from "react-router-dom";
import Product from "./Product";
import EshopCategory from "./EshopCategory";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { emptyPreferences } from "../app/userPreferencesReducer";
import EshopSidebar2 from "./EshopSidebar2";

function Eshop(props) {
  let { path } = props.match;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (props.history.location.pathname !== "/cart") {
        dispatch(
          emptyPreferences({
            name: "eshop.filter",
          })
        );
      }
    };
  }, []);
  return (
    <div className={"eshop container"}>
      <Helmet>
        <title>Eshop</title>
        <meta name="description" content="Vyberte si z kategorie zboží, které hledáte nebo jednoduše filtrujte zboží, které máme skladem."/>
      </Helmet>
      <div className={"row gutter-20px m-0"}>
        {/*<EshopSidebar {...props} />*/}
        <Route path={`${path}/:filter*`}>
          <EshopSidebar2 className={"hidden-mobile"}/>
        </Route>

        <div className="col w-75p eshopContents">
          <Switch>
            <Route path={`${path}/product/:productId`} render={(props) => (
              <Product
                {...props}
              />
            )}/>
            <Route path={`${path}/:filter*`}>
              <EshopCategory />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Eshop;
