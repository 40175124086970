import React, { useState } from "react";
// import { Form, Field } from "react-final-form";
import { Helmet } from "react-helmet-async";

import "./contacts.scss";
// import {useMutation, gql} from "@apollo/client";
// import { addNotification } from "../app/notificationsReducer";
// import {useDispatch} from "react-redux";
import Icons from "../util/Icons";
import ContactForm from "../widgets/ContactForm";

// const SubmitFormMutation = gql`
//     mutation SubmitForm($input: SubmitFormInput!) {
//         submitForm(input: $input) {
//             status
//             errorData
//         }
//     }
// `;
export function TitleWithIcon({title, icon}){
  return (
      <h3>
        <img className={"icon"} src={Icons.getIcon(icon)} alt={icon} />
        <span>{title}</span>
      </h3>
    )
}

export function ContactsMap({mapSrc}) {
  if (!mapSrc) mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2554.0012493470217!2d14.514592151677123!3d50.19850757934054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470be94cc694538f%3A0xd27f8a762f65675a!2sTopas%20Plus%20J.m.%20S.r.o.!5e0!3m2!1scs!2scz!4v1624499470795!5m2!1scs!2scz";
  return (
    <iframe
      src={mapSrc}
      width="100%"
      height="521"
      // allowfullscreen=""
      loading="lazy"
      title="map">
    </iframe>
  )
}
export function ContactsInfo({data, sellers}) {
  if(!data) data = [
    {name: "IČO:", value: "26471469", class: "ico"},
    {name: "DIČ:", value: "CZ26471469", class: "dic"},
    {name: "e-mail:", value: "info@topasplus.cz", class: "mail"},
    {name: "Č.Ú.:", value: "298496327/0300", class: "bankAccount"},
    {name: "Po-Pá:", value: "10:00 - 18:00", class: "hours"},
    {name: "Sídlo:", value: "Zlonín, Lísková 112", class: "hlavniSidlo"},
    {name: "Pobočka:", subName:"(místo pro vyzvednutí zboží)", value: "Měšice, Střední 81", class: "sidlo"},
  ]

  if(!sellers) sellers = [
    {img: "/staticImages/icons/helpdesk.svg", values: ["Heřtus Jiří", "+420 602 559 671", "hertus@topasplus.cz"], class: "seller1"},
    {img: "/staticImages/icons/helpdesk.svg", values: ["Heřtusová Hana", "+420 602 728 519", "hertusova@topasplus.cz"], class: "seller2"},
  ]

  let contentInfo = data.map((item, index) => {
    let values = (<span>{item.value}</span>)

    if (Array.isArray(item.value)) {
      values = item.value.map((value, i) => {
        return <span key={"value" + i}>{value}</span>
      })
    }

    return (
      <div key={"info" + index} className={"row item " + item.class}>
          <div className={"col name"}>
            <span>{item.name}</span>
            {item.subName ? <span className={"subName"}>{item.subName}</span> : null}
          </div>
          <div className={"col value"}>{values}</div>
      </div>
    )
  })


  let contentSeller = sellers.map((item, index) => {
    let values = item.values

    if (Array.isArray(item.values)) {
      values = item.values.map((value, i) => {
        return <span key={"seller_value" + i}>{value}</span>
      })
    }
    return (
      <div key={"seller" + index} className={"row item " + item.class}>
        {/* <div className={"col"}>{item.img}</div> */}
        <img className={"icon"} src={item.img} alt={'Prodejce'} />
        <div className={"col value"}>{values}</div>
      </div>
    )
  })
  return(
    <div className="contactsInfo">
      {contentInfo}
      <h3>Kontakty:</h3>
      {contentSeller}
    </div>
  )
}


// const contactFields = [
//   {
//     label: "Jméno",
//     name: "process_name",
//     required: true,
//     type: "text",
//   },
//   {
//     label: "Příjmení",
//     name: "process_lastname",
//     required: true,
//     type: "text",
//   },
//   {
//     label: "Telefon",
//     name: "process_phone",
//     required: true,
//     type: "tel",
//   },
//   {
//     label: "e-mail",
//     name: "process_email",
//     required: true,
//     type: "email",
//   },
//   {
//     label: "Zpráva",
//     name: "process_textarea",
//     required: false,
//     type: "textarea",
//   }
// ]

// function ContactFormField({field, setFormData}) {
//   // console.log(field);

//   const handleOnCHangeEvent = (event) => {
//     let value = event.target.value
//     setFormData(prevState => ({
//       ...prevState,
//       [field.name]: value
//     }));
//   }

//   switch(field.type) {
//     case "textarea": {
//       return (
//         <textarea
//           name={field.name}
//           required={field.required}
//           onChange={(event) => handleOnCHangeEvent(event)}
//         ></textarea>
//       )
//     }
//     default: {
//       return (
//         <input
//           type={field.type}
//           name={field.name}
//           required={field.required}
//           onChange={(event) => handleOnCHangeEvent(event)}
//         ></input>)
//     }
//   }

// }
// function ContactForm({handleSubmit}) {

//   const [formData, setFormData] = useState()
//   return (
//     <form onSubmit={event => handleSubmit(event, formData)}>
//       {contactFields.map((item, index) => {
//         // console.log(item);
//         return (
//           <label className={item.name}>
//             <span>
//               {item.label}
//               {(item.required) ? "*" : null}
//             </span>
//             <ContactFormField setFormData={setFormData} field={item}/>
//           </label>)
//       })}
//       <div className="buttonWrapper">
//         <button type="submit">
//           Odeslat
//           <img className={"icon"} src="staticImages/arrowRightDarkerBlue.svg" alt="odeslat" />
//         </button>
//       </div>

//     </form>
//   )
// }

const Contacts = (props) => {

  return (
    <div className="contacts container">
      <Helmet>
        <title>Kontakty</title>
        <meta name={"description"} content={"Sháníte produkty, nářadí nebo montáž z oblasti zdravotechniky a vytápění? Ozvěte se nám."}/>
      </Helmet>
      <div className={"row gutter-20px m-0"}>
        <div className="col mbt-25 w-100p">
          <div className="pageHeader">
            <h1>Kontakty</h1>
          </div>

          <div className="contactsWrapper">
            <div className="col contactsInfoWrapper">
              <TitleWithIcon title="TOPAS PLUS J.M. s.r.o." icon="company"/>
              <ContactsInfo/>
            </div>
            <div className="col contactsMapWrapper">
              <TitleWithIcon title="Kde nás najdete" icon="marker"/>
              <ContactsMap/>
            </div>
          </div>
          <div className="col mbt-40 w-100p">
            <ContactForm title="Kontaktní formulář"/>
          </div>
        </div>
      </div>
    </div>
  )

};

export default Contacts;
