import React, {useEffect} from "react";
import {Row, Col, Card, ListGroup} from "react-bootstrap";
import {gql, useQuery} from "@apollo/client";
import Preloader from "../util/Preloader";
import {Link, useHistory} from "react-router-dom";
import {orderStatuses} from "./util/globalMaps";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const searchOrders = gql`
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        orderStatus
        orderNumber
        createdAt
        fields{
          productId
          name
          amount
          price
          priceIncludingTax
          unit
        }
      }
      nextToken
      total
    }
  }
`;

const getOrderStatistics = gql`
  query GetOrderStatistics {
    getOrderHistogram {
      days {
        day
        count
      }
    }
    getOrderAggregation {
      today {
        count
        items
        price
        vatPrice
      }
      yesterday {
        count
        items
        price
        vatPrice
      }
      thisYear {
        count
        items
        price
        vatPrice
      }
      thisWeek {
        count
        items
        price
        vatPrice
      }
      thisMonth {
        count
        items
        price
        vatPrice
      }
      lastYear {
        count
        items
        price
        vatPrice
      }
      lastWeek {
        count
        items
        price
        vatPrice
      }
      lastMonth {
        count
        items
        price
        vatPrice
      }
    }
  }
`;

function Dashboard(){
  const history = useHistory();

  const { loading: lastOrdersLoading, error: lastOrdersError, data: lastOrdersData, startPolling: lastOrdersStartPolling, stopPolling: lastOrdersStopPolling } = useQuery(searchOrders, {
    variables: {
      limit: 5,
      from: 0,
      sort: {field: "createdAt", direction: "desc"},
    },
    nextFetchPolicy: "cache-and-network",
  });

  const { loading: orderStatsLoading, error: orderStatsError, data: orderStatsData} = useQuery(getOrderStatistics, {
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    lastOrdersStartPolling(10000);
    return function cleanup() {
      lastOrdersStopPolling();
    }
  });

  function renderLastOrders(){
    if (lastOrdersError){
      return <ListGroup.Item className={"text-danger bold"}>Chyba při načítání</ListGroup.Item>;
    }
    if (lastOrdersLoading && !lastOrdersData){
      return <ListGroup.Item><Preloader/></ListGroup.Item>;
    }
    return lastOrdersData.searchOrders.items.map(order => {
      let price = 0;
      order.fields.forEach(field => {
        price += field.amount * field.priceIncludingTax;
      })
      let date = new Date(order.createdAt);
      let midnight = new Date();
      midnight.setHours(0, 0, 0, 0);
      let dateText = "";
      if (date < midnight){
        dateText = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
      }
      else{
        dateText = "dnes " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      }
      return (
        <Link key={order.id} className={"text-left cursor-pointer list-group-item"} to={"/admin/orders/" + order.id}>
          <Row>
            <Col md={5}>Obj. č. {order.orderNumber}</Col>
            <Col className={"border-left"}>{orderStatuses[order.orderStatus]}</Col>
            <Col className={"border-left text-right"}>{price.toFixed(0)} Kč</Col>
            <Col className={"border-left text-right"}>{dateText}</Col>
          </Row>
        </Link>
      )
    })
  }

  function renderOrderStatisticsRow(heading, stats){
    if(!stats){
      stats = {
        count: 0,
        price: 0,
        items: 0,
      }
    }

    return(
      <ListGroup.Item>
        <Row className={"text-left"}>
          <Col>{heading}</Col>
          <Col className={"border-left text-right"}>{stats.count}</Col>
          <Col className={"border-left text-right"}>{stats.price.toFixed(2)}</Col>
          <Col className={"border-left text-right"}>{stats.items - stats.count}</Col>
        </Row>
      </ListGroup.Item>
    )
  }

  function renderOrderStatistics(){
    if (orderStatsError){
      return (
        <Row className={"mt-4"}>
          <Col md={6}>
            <Card>
              <Card.Title>Objem objednávek</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong className={"text-danger"}>Chyba při načítání statistik</strong>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      );
    }
    if (orderStatsLoading && !orderStatsData){
      return (
        <Row className={"mt-4"}>
          <Col md={6}>
            <Card>
              <Card.Title>Objem objednávek</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Preloader/>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      );
    }
    return (
      <Row className={"mt-4"}>
        <Col md={6}>
          <Card>
            <Card.Title>Objem objednávek</Card.Title>
            <ListGroup variant="flush" className={"hover-child-bg"}>
              <ListGroup.Item>
                <Row className={"bold text-left"}>
                  <Col>Období</Col>
                  <Col className={"border-left text-right"}>Objednávky</Col>
                  <Col className={"border-left text-right"}>Cena bez DPH</Col>
                  <Col className={"border-left text-right"}>Položky</Col>
                </Row>
              </ListGroup.Item>
              {renderOrderStatisticsRow("Dnes", orderStatsData.getOrderAggregation.today)}
              {renderOrderStatisticsRow("Tento týden", orderStatsData.getOrderAggregation.thisWeek)}
              {renderOrderStatisticsRow("Tento měsíc", orderStatsData.getOrderAggregation.thisMonth)}
              {renderOrderStatisticsRow("Tento rok", orderStatsData.getOrderAggregation.thisYear)}
            </ListGroup>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Title>Předchozí období</Card.Title>
            <ListGroup variant="flush" className={"hover-child-bg"}>
              <ListGroup.Item>
                <Row className={"bold text-left"}>
                  <Col>Období</Col>
                  <Col className={"border-left text-right"}>Objednávky</Col>
                  <Col className={"border-left text-right"}>Cena</Col>
                  <Col className={"border-left text-right"}>Položky</Col>
                </Row>
              </ListGroup.Item>
              {renderOrderStatisticsRow("Včera", orderStatsData.getOrderAggregation.yesterday)}
              {renderOrderStatisticsRow("Minulý týden", orderStatsData.getOrderAggregation.lastWeek)}
              {renderOrderStatisticsRow("Minulý měsíc", orderStatsData.getOrderAggregation.lastMonth)}
              {renderOrderStatisticsRow("Minulý rok", orderStatsData.getOrderAggregation.lastYear)}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    );
  }

  function renderGraph(){
    if (orderStatsError){
      return (
        <strong className={"text-danger"}>Chyba při načítání statistik</strong>
      );
    }
    if (orderStatsLoading && !orderStatsData){
      return (
        <Preloader/>
      );
    }

    function formatDate(day){
      const parsedDate = new Date(day.day);
      console.log(parsedDate);
      return parsedDate.getDate() + "." + (parsedDate.getMonth() + 1) + ".";
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={orderStatsData.getOrderHistogram.days}

        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={formatDate} />
          <YAxis />
          <Tooltip />
          <Line name={"Počet objednávek"} type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  return(
    <Row>
      <Col>
        <h2 className={"mb-4"}>Přehled aktivity</h2>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Title>Poslední objednávky</Card.Title>
              <ListGroup variant="flush" className={"hover-child-bg"}>
                {renderLastOrders()}
              </ListGroup>
            </Card>
          </Col>
          <Col md={6}>
            <Card className={"h-100"}>
              <Card.Title>Počet objednávek v posledních dnech</Card.Title>
              <Card.Body>
                {renderGraph()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {renderOrderStatistics()}
        <Row className={"mt-4"}>

        </Row>
      </Col>
    </Row>
  );
}

export default Dashboard;
