import React, {useEffect} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Row, Col, ButtonGroup, Button} from "react-bootstrap";
const getFtpServerStatus = gql`
  query AdminGetFtpStatus {
    adminGetFtpStatus
  }
`;
const setFtpServerStatus = gql`
  mutation AdminToggleFtp($online: Boolean) {
    adminToggleFtp(online: $online)
  }
`;
function FtpControl(){
  const {loading: serverStatusLoading, error: serverStatusError, data: serverStatus, startPolling, stopPolling} = useQuery(getFtpServerStatus);
  const [toggleServerStatus, toggleServerStatusResponse] = useMutation(setFtpServerStatus);
  useEffect(() => {
    startPolling(15000);
    return function cleanup() {
      stopPolling();
    }
  });

  const renderServerStatus = () =>{
    if (serverStatusError) return (<p>Chyba načítání</p>);
    if (!serverStatus && serverStatusLoading) return (<p>"Načítá se"</p>);
    const state = JSON.parse(serverStatus.adminGetFtpStatus).State;
    const stateClass = {
      'ONLINE': 'text-success',
      'OFFLINE': 'text-danger',
      'STARTING': 'text-warning',
      'STOPPING': 'text-warning',
    };
    return (
      <strong className={stateClass[state]}>
        {state}
      </strong>
    );
  };

  return(
    <div className={"ftpControl"}>
      <h3>Stav FTP serveru</h3>
      <h6>(Aktualizace každých 15s)</h6>
      <Row>
        <Col>
          {renderServerStatus()}
        </Col>
      </Row>
      <hr/>
      <h3>Ovládání FTP serveru</h3>
      <Row>
        <Col>
          <ButtonGroup>
            <Button disabled={toggleServerStatusResponse.loading} onClick={() => toggleServerStatus({variables: {online: true}})} variant={"success"}>Zapnout</Button>
            <Button disabled={toggleServerStatusResponse.loading} onClick={() => toggleServerStatus({variables: {online: false}})} variant={"danger"}>Vypnout</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}

export default FtpControl;
