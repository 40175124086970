import React, {useEffect} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {gql, useMutation} from "@apollo/client";
import Preloader from "../util/Preloader";

const intParams = ["resultCode", "paymentStatus"];

const csobVerifyMutation = gql`
  mutation CsobVerify($input: CsobVerifyInput!) {
    csobVerify(input: $input) {
      id
      userName
      fields {
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      shippingMethodId
      shipmentTrackingId
      paymentMethodId
      paymentTrackingId
      orderStatus
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
      note
      createdAt
      updatedAt
    }
  }
`;

function CsobVerify(){
  const [
    csobVerify,
    {
      data: csobVerifyData,
      loading: csobVerifyLoading,
      error: csobVerifyError,
    },
  ] = useMutation(csobVerifyMutation);
  const {search} = useLocation();

  useEffect(() => {
    const parsedSearch = new URLSearchParams(search);
    let params = {};
    for (let [paramName, paramValue] of parsedSearch){
      if (intParams.indexOf(paramName) !== -1){
        params[paramName] = parseInt(paramValue);
        continue;
      }
      params[paramName] = paramValue;
    }
    console.log(params);

    csobVerify({
      variables: {
        input: params,
      }
    });
  }, [search])

  if(csobVerifyLoading){
    return(
      <div className="container mbt-25">
        <Preloader />
      </div>
    );
  }

  if (csobVerifyError){
    return (
      <div className="container mbt-25">
        <strong className={"error"}>Nastala chyba při verifikaci platby</strong>
      </div>
    );
  }

  if (csobVerifyData){
    return (
      <Redirect
        to={{
          pathname: "/order/thankyou/" + csobVerifyData.csobVerify.id,
          state: { orderData: csobVerifyData.csobVerify, products: [] }, //data.searchProducts.items
        }}
      />
    );
  }

  return null;
}

export default CsobVerify;
