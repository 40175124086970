import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {listPopups} from "../graphql/queries";
import API, {GRAPHQL_AUTH_MODE} from "@aws-amplify/api";

export const getPopups = createAsyncThunk(
  'popups/getPopups',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state.cognito.currentSession){
      return await API.graphql({query: listPopups, authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS});
    }
    return await API.graphql({query: listPopups, authMode: GRAPHQL_AUTH_MODE.AWS_IAM});
  }
);

export const defaultState = {
  popups: [],
  dismissedPopups: [],
};

const popupsSlice = createSlice({
  name: 'popups',
  initialState: defaultState,
  reducers: {
    dismissPopup: (state, action) => {
      state.dismissedPopups.push(action.payload.id);
      return state;
    },
  },
  extraReducers: {
    [getPopups.fulfilled]: (state, action) => {
      state.popups = action.payload.data.listPopups.items;
    },
    [getPopups.rejected]: (state, action) => {
      console.log("popups failed", {state, action})
    }
  },
});

export const {dismissPopup} = popupsSlice.actions;
export default popupsSlice;
