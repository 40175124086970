import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";
import EditorComponent from "../editor/EditorComponent";

const listCategories = gql`
    query ListNewsCategorys(
        $filter: ModelNewsCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listNewsCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                order
            }
        }
    }
`;

const getNewsPost = gql`
    query GetNewsPost($id: ID!) {
        getNewsPost(id: $id) {
            id
            categoryId
            slug
            name
            content
            excerpt
            advancedEditor
            createdAt
            updatedAt
            thumbnail
            seoDescription
        }
    }
`;

const updateNewsPostMutation = gql`
    mutation UpdateNewsPost(
        $input: UpdateNewsPostInput!
        $condition: ModelNewsPostConditionInput
    ) {
        updateNewsPost(input: $input, condition: $condition) {
            id
        }
    }
`;

function NewsPostEditForm({initialNewsPost, saveChanges, categories}){
  const parseInitialNewsPost = () => JSON.parse(JSON.stringify(initialNewsPost), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [newsPost, setNewsPost] = useState(parseInitialNewsPost());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newNewsPost = JSON.parse(JSON.stringify(newsPost));
    if (path === "excerpt"){
      value = value.substr(0, 200);
    }
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newNewsPost[splitPath[0]] = value;
        break;
      case 2:
        newNewsPost[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newNewsPost[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setNewsPost(newNewsPost);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={newsPost.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="slug">
            <Form.Label>Odkaz</Form.Label>
            <Form.Control type="text" placeholder="není" value={newsPost.slug} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="thumbnail">
            <Form.Label>Náhledový obrázek</Form.Label>
            <Form.Control type="text" placeholder="není" value={newsPost.thumbnail} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="categoryId">
            <Form.Label>Kategorie</Form.Label>
            <Form.Control as="select" value={newsPost.categoryId} custom onChange={changeEventHandler}>
              {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"excerpt"}>
            <Form.Label>Perex (max. 200 znaků, zbývá {200 - newsPost.excerpt.length})</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={newsPost.excerpt} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"seoDescription"}>
            <Form.Label>Seo popisek</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={newsPost.seoDescription} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          {newsPost.advancedEditor ? (
            <Form.Group controlId={"content"}>
              <Form.Label className={"d-flex"}>
                <span className={"mbt-auto"}>Obsah</span>
              </Form.Label>
              <Form.Control as="textarea" rows={3} placeholder={"není"} value={newsPost.content} onChange={changeEventHandler}/>
            </Form.Group>
          ) : (
            <Form.Group controlId={"content"}>
              <Form.Label className={"d-flex"}>
                <span className={"mbt-auto"}>Obsah</span>
                <div className={"btn btn-primary ml-auto"} onClick={() => changeHandler("advancedEditor", true)}>Pokročilý editor</div>
              </Form.Label>
              <EditorComponent inputHtml={newsPost.content} onChange={(newValue) => {
                console.log("SinglePost change", newValue);
                changeHandler("content", newValue);
              }
              }/>
            </Form.Group>
          )}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setNewsPost(parseInitialNewsPost())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...newsPost, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SinglePost(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getNewsPost, {
    variables: {
      id: match.params.postId,
    }
  });
  const [updateNewsPost, updateNewsPostResponse] = useMutation(updateNewsPostMutation, {
    refetchQueries: [
      "GetNewsPost",
    ]
  });
  const {loading: categoryLoading, error: categoryError, data: categoryData} = useQuery(listCategories);
  if (error || categoryError) return (
    <article className={"singleNewsPost"}><div className={"danger"}>Chyba při načítání příspěvku</div></article>
  ) ;

  if (loading || categoryLoading || updateNewsPostResponse.loading) return (
    <article className={"singleNewsPost"}><Preloader/></article>
  ) ;

  const newsPost = data.getNewsPost;

  return (
    <article className={"singleNewsPost"}>
      <h3>Příspěvek: {match.params.postId}</h3>
      <hr/>
      <NewsPostEditForm categories={categoryData.listNewsCategorys?.items} initialNewsPost={newsPost} saveChanges={(changedNewsPost) => updateNewsPost({
        variables:{
          input: changedNewsPost,
        }
      })}/>
    </article>
  );
}

export default SinglePost;
