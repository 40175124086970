import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";
import {listShippingMethods} from "../shippingMethods/ShippingMethods";

const getPaymentMethod = gql`
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      name
      description
      sendEmail
      fee
      feePercent
      order
      createdAt
      updatedAt
      handler
      minAllowedCartPrice
      maxAllowedCartPrice
      allowedShipmentConnection{
        items {
          id
          paymentMethodId
          shippingMethodId
        }
      }
    }
  }
`;

const updatePaymentMethodMutation = gql`
  mutation UpdatePaymentMethod(
    $input: UpdatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    updatePaymentMethod(input: $input, condition: $condition) {
      id
      name
      description
      sendEmail
      fee
      feePercent
      order
      createdAt
      updatedAt
      handler
      minAllowedCartPrice
      maxAllowedCartPrice
    }
  }
`;

const createAllowedPaymentShipmentMutation = gql`
  mutation CreateAllowedPaymentShipment(
    $input: CreateAllowedPaymentShipmentInput!
  ) {
    createAllowedPaymentShipment(input: $input) {
      id
    }
  }
`;

const deleteAllowedPaymentShipmentMutation = gql`
  mutation DeleteAllowedPaymentShipment(
    $input: DeleteAllowedPaymentShipmentInput!
  ) {
    deleteAllowedPaymentShipment(input: $input) {
      id
    }
  }
`;

function PaymentMethodEditForm({initialPaymentMethod, saveChanges, shippingMethods}){
  const parseInitialPaymentMethod = () => JSON.parse(JSON.stringify(initialPaymentMethod), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [paymentMethod, setPaymentMethod] = useState(parseInitialPaymentMethod());
  const [connectionClass, setConnectionClass] = useState("");
  const [shippingIdsToRemove, setShippingIdsToRemove] = useState([]);
  const [shippingIdsToAdd, setShippingIdsToAdd] = useState([]);
  const [createConnection, createConnectionResponse] = useMutation(createAllowedPaymentShipmentMutation);
  const [deleteConnection, deleteConnectionResponse] = useMutation(deleteAllowedPaymentShipmentMutation);
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newPaymentMethod = JSON.parse(JSON.stringify(paymentMethod));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newPaymentMethod[splitPath[0]] = value;
        break;
      case 2:
        newPaymentMethod[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newPaymentMethod[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setPaymentMethod(newPaymentMethod);
  };

  const setDifference = (A, B) => {
    const setB = new Set(B);
    const _difference = new Set(A);
    for (const elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  const shippingConnectionChanged = (({target}) => {
    const oldAllowedShippingIds = paymentMethod.allowedShipmentConnection.items.map(connection => connection.shippingMethodId);
    let allowedShippingIds = [];
    for (let i = 0; i < target.selectedOptions.length; i++) {
      const selectedOption = target.selectedOptions[i];
      allowedShippingIds.push(selectedOption.value);
    }
    setShippingIdsToRemove(setDifference(oldAllowedShippingIds, allowedShippingIds));
    setShippingIdsToAdd(setDifference(allowedShippingIds, oldAllowedShippingIds));
  });

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={paymentMethod.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="order">
            <Form.Label>Pořadí</Form.Label>
            <Form.Control type="number" placeholder="není" value={paymentMethod.order} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"handler"}>
            <Form.Label>Zpracování platby</Form.Label>
            <Form.Control
              as={"select"}
              className={"mr-2 " + (paymentMethod.handler ? "" : " text-secondary ")}
              value={paymentMethod.handler}
              onChange={changeEventHandler}
            >
              <option value="manual">Ručně (dobírka, převod...)</option>
              <option value="csob">ČSOB platební brána</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"sendEmail"}>
            <Form.Label>Odesílání emailu a generování faktur</Form.Label>
            <Form.Control
              as={"select"}
              className={"mr-2 " + (paymentMethod.handler ? "" : " text-secondary ")}
              value={paymentMethod.sendEmail}
              onChange={changeEventHandler}
            >
              <option value="never">Nezasílat</option>
              <option value="onOrderCreated">Při vytvoření objednávky</option>
              <option value="onSuccess">Při úspěšném zaplacení</option>
              <option value="onOrderCompleted">Při dokončení objednávky</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="fee">
            <Form.Label>Poplatek</Form.Label>
            <Form.Control type="number" placeholder="není" step={0.01} value={paymentMethod.fee} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="feePercent">
            <Form.Label>Procentuelní poplatek</Form.Label>
            <Form.Control type="number" placeholder="není" step={0.01} value={paymentMethod.feePercent} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"minAllowedCartPrice"}>
            <Form.Label>Minimální povolená cena košíku</Form.Label>
            <Form.Control type={"number"} placeholder={"není"} step={0.01} value={paymentMethod.minAllowedCartPrice} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"maxAllowedCartPrice"}>
            <Form.Label>Maximální povolená cena košíku</Form.Label>
            <Form.Control type={"number"} placeholder={"není"} step={0.01} value={paymentMethod.maxAllowedCartPrice} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"description"}>
            <Form.Label>Popis</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={paymentMethod.description} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"allowedShipping"}>
            <Form.Label>Povolené dopravní metody</Form.Label>
            <Form.Control className={connectionClass} as={"select"} multiple={true} onInput={shippingConnectionChanged}>
              {shippingMethods.map(shippingMethod => {
                return <option value={shippingMethod.id} selected={!!paymentMethod.allowedShipmentConnection.items.find(connection => connection.shippingMethodId === shippingMethod.id)}>{shippingMethod.name}</option>
              })}
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setPaymentMethod(parseInitialPaymentMethod())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => {
              shippingIdsToRemove.forEach(shippingMethodId => {
                const idToDelete = paymentMethod.allowedShipmentConnection.items.find(connection => connection.shippingMethodId === shippingMethodId)?.id || null;
                deleteConnection({
                  variables: {
                    input: {
                      id: idToDelete,
                    }
                  },
                  onCompleted: () => {
                    setConnectionClass("border-success border-2");
                  },
                  onError: () => {
                    setConnectionClass("border-danger border-2");
                  }
                })
              });
              shippingIdsToAdd.forEach(shippingMethodId => createConnection({
                variables: {
                  input: {
                    paymentMethodId: paymentMethod.id,
                    shippingMethodId,
                  }
                },
                onCompleted: () => {
                  setConnectionClass("border-success border-2");
                },
                onError: () => {
                  setConnectionClass("border-danger border-2");
                }
              }));
              saveChanges({...paymentMethod, createdAt: undefined, updatedAt: undefined, allowedShipmentConnection: undefined, minAllowedCartPrice: paymentMethod.minAllowedCartPrice || null, maxAllowedCartPrice: paymentMethod.maxAllowedCartPrice || null});
            }} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SinglePaymentMethod(props){
  const match = useRouteMatch();
  const { loading: shippingLoading, error: shippingError, data: shippingData } = useQuery(listShippingMethods, {
    variables: {
      filter: null,
    },
  });
  const {loading, data, error} = useQuery(getPaymentMethod, {
    variables: {
      id: match.params.paymentMethodId,
    }
  });
  const [updatePaymentMethod, updatePaymentMethodResponse] = useMutation(updatePaymentMethodMutation);
  if (error || shippingError) return (
    <article className={"singlePaymentMethod"}><div className={"danger"}>Chyba při načítání metody</div></article>
  ) ;

  if (loading || shippingLoading || updatePaymentMethodResponse.loading) return (
    <article className={"singlePaymentMethod"}><Preloader/></article>
  ) ;


  const shippingMethods = shippingData.listShippingMethods.items;
  const paymentMethod = data.getPaymentMethod;

  return (
    <article className={"singlePaymentMethod"}>
      <h3>Platební metoda: {match.params.paymentMethodId}</h3>
      <hr/>
      <PaymentMethodEditForm shippingMethods={shippingMethods} initialPaymentMethod={paymentMethod} saveChanges={(changedPaymentMethod) => updatePaymentMethod({
        variables:{
          input: changedPaymentMethod,
        }
      })}/>
    </article>
  );
}

export default SinglePaymentMethod;
