import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Popup from './Popup';
import {getPopups} from "../app/popupReducer";

const Popups = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPopups());
  }, []);
  const popups = useSelector(state => state.popups.popups);
  return popups.map(popup => <Popup key={"popup_" + popup.id} popup={popup}/>);
}

export default Popups;
