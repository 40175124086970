import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from '@aws-amplify/auth';
import {fetchCurrentUserInfo} from "../app/cognitoReducer";
import {addNotification} from '../app/notificationsReducer';

import './settings.scss'

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      pricesInclVat: true,
      sendNews: true,

      oldPassword: "",
      password: "",
      passwordConfirm: ""
    };
  }

  autoFillFromCognito(){
    if(this.props.cognito && this.props.cognito.currentUserInfo){
      const attributes = this.props.cognito.currentUserInfo.attributes;

      this.setState(() => ({
        pricesInclVat: typeof attributes["custom:pricesInclVat"] === "string" ? attributes["custom:pricesInclVat"] === "1" : true,
        sendNews: typeof attributes["custom:sendNews"] === "string" ? attributes["custom:sendNews"] === "1" : true,
      }));
    }
  }

  componentDidMount(){
    this.autoFillFromCognito();
  }

  handleCheckboxChange(target) {
    this.setState((state) => ({
      [target] : !state[target]
    }));
  }

  checkPasswordInputs() {
    const notEmpty = [
      "oldPassword",
      "password",
      "passwordConfirm"
    ];

    let notEmptyCount = 0;

    for (var i = 0; i < notEmpty.length; i++) {
      if (this.state[notEmpty[i]]) {
        notEmptyCount++;
        //return false;
      }
    }
    if (notEmptyCount === 0) {
      return 0;
    }

    if (notEmptyCount === notEmpty.length ) {
        return 1;
    }

    // incomplete input
    return 2;
  }

  setError(error, loading) {
    this.setState(() => ({
      error: error,
      loading: loading,
    }));
  }

  saveSettings() {
    let self = this;
    if (self.state.loading) return;
    let changePassword = self.checkPasswordInputs();

    if (changePassword === 2) {
      this.setState(() => ({
        loading: false,
      }));
      this.props.dispatch(addNotification({
        message: `empty input`,
        type: "error",
        code: `frontend.empty`
      }));
      return;
    }

    //change password
    if (self.state.password !== self.state.passwordConfirm) {
      this.setState(() => ({
        loading: false,
      }));
      this.props.dispatch(addNotification({
        message: `mismatch password`,
        type: "error",
        code: `frontend.missmatchPassword`
      }));
      return;
    }

    //change attributes
    Auth.currentAuthenticatedUser()
    .then(user => {
      let promises = [];
      promises.push(Auth.updateUserAttributes(user, {
        "custom:pricesInclVat": (this.state.pricesInclVat) ? "1" : "0",
        "custom:sendNews": this.state.sendNews ? "1" : "0",
      })
      .then(() => {
        self.props.dispatch(fetchCurrentUserInfo());
      })
      .catch(error => {
        console.log(error);
        this.props.dispatch(addNotification({
          message: error.message,
          type: "error",
          code: error.code
        }));
      }));

      if (changePassword === 1) {
        promises.push(Auth.changePassword(user, this.state.oldPassword, this.state.password)
        .then((res) => {
          self.props.dispatch(fetchCurrentUserInfo())
        })
        .catch(error => {
          console.log(error);
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code
          }));
          //TODO: error notification
        }));
      }
      Promise.all(promises).finally(() => {
        this.setState(() => ({loading: false}));
      })
    })
    .catch(error => {
      //TODO: error notification
      console.log(error);
      this.props.dispatch(addNotification({
        message: error.message,
        type: "error",
        code: error.code
      }));
      this.setState(() => ({loading: false}));
    })
  }
  render() {
    return (
      <div className={"accountSettings container mbt-25"}>
        <div className="row gutter-20px">
          <div className="col w-66p">
            <form onSubmit={event => {event.preventDefault(); this.saveSettings(); }}>
              <div className="panel mb-20">
                <div className="panelHeader">
                  <h2>Nastavení účtu</h2>
                </div>
                <div className="panelBody">
                  <label className={"mb-15 flex"} htmlFor="pricesInclVat">
                    <span>Zobrazovat ceny vč. DPH</span>
                    <input
                      type="checkbox"
                      id="pricesInclVat"
                      name={"pricesInclVat"}
                      checked={this.state.pricesInclVat}
                      onChange={({target}) => this.handleCheckboxChange(target.name)}
                    />
                    <span className={((this.state.pricesInclVat) ? "checked " : "") + "checkbox ml-20"}></span>
                  </label>
                  <label className={"mb-15 flex"} htmlFor="sendNews">
                    <span>Zasílání novinek</span>

                    <input
                      type="checkbox"
                      id="sendNews"
                      name={"sendNews"}
                      checked={this.state.sendNews}
                      onChange={({target}) => this.handleCheckboxChange(target.name)}
                    />
                    <span className={((this.state.sendNews) ? "checked " : "") + "checkbox ml-20"}></span>
                  </label>
                </div>
                <div className="panelHeader borderTop">
                  <h2>Změna hesla</h2>
                </div>
                <div className="panelBody">
                  <label className={"mb-15 grid-135"}>
                    <span>Staré heslo</span>
                    <input type={"text"} name={"oldPassword"} value={this.state.oldPassword} onChange={({target}) => this.setState(() => ({oldPassword: target.value}))}/>
                  </label>
                  <label className={"mb-15 grid-135"}>
                    <span>Nové heslo</span>
                    <input type={"text"} name={"password"} value={this.state.password} onChange={({target}) => this.setState(() => ({password: target.value}))}/>
                  </label>
                  <label className={"mb-15 grid-135"}>
                    <span>Nové heslo znovu</span>
                    <input type={"text"} name={"passwordConfirm"} value={this.state.passwordConfirm} onChange={({target}) => this.setState(() => ({passwordConfirm: target.value}))}/>
                  </label>
                </div>

                <div className={"row buttonWrapper"}>
                  <div className={"button medium blue"} onClick={() => this.saveSettings()}><span>Uložit změny</span></div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({cognito: state.cognito}))(Settings);
