import React from 'react';
import {useQuery, gql} from "@apollo/client";
import Preloader from "../../util/Preloader";

const csobEchoTestQuery = gql`
  query adminCsobEchoTest{
    adminCsobEchoTest{
      getValid
      postValid
    }
  }
`;

function CsobEchoTest(){
  const {loading, data, error} = useQuery(csobEchoTestQuery);
  if (error){
    return (
      <div>
        <h3>Test spojení s ČSOB</h3>
        <p>
          Nastala chyba: {error.message}
        </p>
      </div>
    )
  }
  if (loading){
    return (
      <div>
        <h3>Test spojení s ČSOB</h3>
        <Preloader/>
      </div>
    );
  }
  return (
    <div>
      <h3>Test spojení s ČSOB</h3>
      <p>
        <strong>GET spojení: </strong> {data.adminCsobEchoTest.getValid ? "OK" : "Chyba!"}
      </p>
      <p>
        <strong>POST spojení: </strong> {data.adminCsobEchoTest.postValid ? "OK" : "Chyba!"}
      </p>
    </div>
  );
}

export default CsobEchoTest;
