import React from "react";
import { Form, Field } from "react-final-form";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import Preloader from "../util/Preloader";
import PostExcerpt from "./PostExcerpt";
import NewsCategory from "./NewsCategory";
import NewsPost from "./NewsPost";
import ProductListing from "../widgets/ProductListing";

import "./news.scss";

const GetLatestPostsAndCategories = gql`
  query SearchNewsPosts($sort: SearchableNewsPostSortInput, $limit: Int) {
    searchNewsPosts(sort: $sort, limit: $limit) {
      items {
        id
      }
      nextToken
      total
    }
    listNewsCategorys {
      items {
        id
        slug
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

const News = (props) => {
  let { path} = props.match;
  const { loading, error, data } = useQuery(GetLatestPostsAndCategories, {
    variables: {
      sort: { field: "createdAt", direction: "desc" },
      limit: 9,
    },
  });

  if (loading && !data) {
    return (
      <div className="news container">
        <Preloader />
      </div>
    );
  }
  if (error) {
    return (
      <div className="news container">
        <strong className={"error"}>Nastala chyba při načítání novinek</strong>
      </div>
    );
  }
  const handleSubmit = (e) => {
    console.log(e);
  };
  const newsPostIds = data.searchNewsPosts.items.map((item) => item.id);
  return (
    <div className={"news container"}>
      <Helmet>
        <title>Novinky</title>
        <meta name={"description"} content={"Sledujte novinky z oblasti zdravotechniky a vytápění Topas plus."}/>
      </Helmet>
      <div className={"row gutter-20px m-0"}>
        <div className="col mbt-25 w-100p">
          <Switch>
            <Route exact path={path}>
              <div className="pageHeader">
                <h1>Novinky</h1>
              </div>
              <div className="categoryExcerptWrapper">
                {newsPostIds.map((newsPostId, index) => (
                  <PostExcerpt
                    key={"excerpt_" + index}
                    newsPostId={newsPostId}
                  />
                ))}
              </div>
              <hr className={"offWhite mbt-20"} />
              <ProductListing
                filter={{ onSale: true, newest: false }}
                productClassName={"w-25p"}
                className={"mb-50"}
                heading={"Produkty v akci"}
              />
              <hr className={"offWhite mbt-20"} />
              <div className="sectionHeader">
                <h1>Kontaktní formulář</h1>
              </div>
              <Form onSubmit={handleSubmit}>
                {(props) => (
                  <form className="newsForm" onSubmit={props.handleSubmit}>
                    <div className="row formRow mbt-25 mt-0-mobile mb-15-mobile">
                      <div className="formField">
                        <label>Jméno:</label>
                        <Field name="news_name" component="input" />
                      </div>
                      <div className="formField">
                        <label>Příjmení:</label>

                        <Field name="news_lastname" component="input" />
                      </div>
                    </div>
                    <div className="row formRow mbt-25 mbt-15-mobile">
                      <div className="formField">
                        <label>Telefon:</label>
                        <Field name="news_phone" component="input" />
                      </div>
                      <div className="formField">
                        <label>Email:</label>
                        <Field name="news_email" component="input" />
                      </div>
                    </div>
                    <div className="formTextArea mbt-25 mbt-15-mobile">
                      <label>Zpráva</label>
                      <Field name="news_textarea" component="textarea" />
                    </div>
                    <div className="row formButton mbt-25 mt-15-mobile mb-0-mobile">
                      <button
                        className="button yellow arrow-right"
                        type="submit"
                      >
                        ODESLAT
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </Route>
            <Route
              path={`${path}/:categorySlug/:postSlug`}
              render={(props) => <NewsPost {...props} />}
            />
            <Route
              path={`${path}/:categorySlug`}
              render={(props) => <NewsCategory {...props} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default News;
