import React, {Component} from 'react';
import Immutable from 'immutable';
import Editor, {composeDecorators} from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/linkify/lib/plugin.css'
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import '@draft-js-plugins/alignment/lib/plugin.css';
import createFocusPlugin from '@draft-js-plugins/focus';
import '@draft-js-plugins/focus/lib/plugin.css';
import createCounterPlugin from '@draft-js-plugins/counter';
import createImagePlugin from '@draft-js-plugins/image';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import createMultilineListItemPlugin from 'draft-js-multiline-list-item-plugin';
import createListDepthPlugin from 'draft-js-list-depth-plugin';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import createUndoPlugin from '@draft-js-plugins/undo';
import createDividerPlugin from '@draft-js-plugins/divider';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createLinkPlugin from '@draft-js-plugins/anchor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
} from '@draft-js-plugins/buttons';
import {EditorState, RichUtils, convertToRaw, DefaultDraftBlockRenderMap, convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

const ColComponent = ({setParentReadOnly}) => {
  return <div onFocus={() => {
    console.log("Col focus");
    if (setParentReadOnly){
      setParentReadOnly(true);
    }
  }} onBlur={() => setParentReadOnly ? setParentReadOnly(false) : null} className={"colComponentWrapper"}>
    <DraftJsEditor/>
  </div>;
};

const createRowPlugin = ({rowClassName, colClassName, decorator, setParentReadOnly} = {}) => {
  let getEditorState = null;
  let setEditorState = null;

  const initialize = ({getEditorState: initGetEditorState, setEditorState: initSetEditorState}) => {
    getEditorState = initGetEditorState;
    setEditorState = initSetEditorState;
  };

  const blockStyleFn = (contentBlock) => {
    switch (contentBlock.getType()) {
      case 'row':
        return (rowClassName || "row") + " editorRow ";
      case 'col':
        return (colClassName || "col") + " editorCol ";
    }
  };

  const handleKeyCommand = (command) => {
    // let newState = TableUtils.handleKeyCommand(getEditorState(), command);
    // if (newState) {
    //   setEditorState(newState);
    //   return true;
    // }
    return false;
  };

  const blockRendererFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'col') {
      return {
        component: /*decorator ? decorator(ColComponent) : */ColComponent,
        editable: false,
        props: {
          setParentReadOnly,
        },
      };
    }
  };

  const COL_WRAP = React.createElement("div", {className: (rowClassName || "row") + " editorRow "});

  return {
    blockRenderMap: Immutable.Map({
      'col': {
        element: 'div',
        wrapper: COL_WRAP,
      }
    }),
    blockRendererFn,
    blockStyleFn,
    handleKeyCommand,
    initialize,
  };
};

const linkPlugin = createLinkPlugin({
  placeholder: "Zadejte adresu a enter",
  theme: {
    input: "form-control border-success",
    inputInvalid: "form-control border-danger",
    linkTarget: "_blank",
  }
});
const {LinkButton} = linkPlugin;
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const linkifyPlugin = createLinkifyPlugin({target: "_blank"});
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
const counterPlugin = createCounterPlugin();
const { CharCounter, WordCounter, LineCounter } = counterPlugin;
const blockDndPlugin = createBlockDndPlugin();
const resizeablePlugin = createResizeablePlugin({
  horizontal: 'relative',
  initialWidth: '50%',
});
const multiLineListItemPlugin = createMultilineListItemPlugin();
const listDepthPlugin = createListDepthPlugin();
const undoPlugin = createUndoPlugin({
  theme: {
    undo: "btn btn-primary",
    redo: "btn btn-primary",
  },
  undoContent: "",
  redoContent: "",
});
const { UndoButton, RedoButton } = undoPlugin;
const blockBreakoutPlugin = createBlockBreakoutPlugin({
  breakoutBlockType: 'unstyled',
  breakoutBlocks: ['header-one', 'header-two', 'header-three', 'header-four', 'header-five', 'header-six'],
  doubleBreakoutBlocks: ['blockquote', 'unordered-list-item', 'ordered-list-item', 'code-block'],
});

const decorator = composeDecorators(
  alignmentPlugin.decorator,
  resizeablePlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({decorator});

const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  handleUpload: () => alert("TODO"), //TODO
  addImage: imagePlugin.addImage,
});

const dividerPlugin = createDividerPlugin({ decorator });
const { addDivider } = dividerPlugin;

const basicBlocks = [
  {code: "unstyled", name: <div>Základní</div>, plainName: "Základní", },
  {code: "header-one", name: <h1>Nadpis 1</h1>, plainName: "Nadpis 1", },
  {code: "header-two", name: <h2>Nadpis 2</h2>, plainName: "Nadpis 2", },
  {code: "header-three", name: <h3>Nadpis 3</h3>, plainName: "Nadpis 3", },
  {code: "header-four", name: <h4>Nadpis 4</h4>, plainName: "Nadpis 4", },
  {code: "header-five", name: <h5>Nadpis 5</h5>, plainName: "Nadpis 5", },
  {code: "header-six", name: <h6>Nadpis 6</h6>, plainName: "Nadpis 6", },
  {code: "unordered-list-item", name: <ul><li>seznam</li></ul>, plainName: "- seznam", },
  {code: "ordered-list-item", name: <ol><li>seznam</li></ol>, plainName: "1. seznam", },
  {code: "blockquote", name: <blockquote>Citace</blockquote>, plainName: "Citace", },
  {code: "code-block", name: <pre>Kód</pre>, plainName: "Kód", },
  {code: "section", name: <section>Sekce</section>, plainName: "Sekce", },
  {code: "col", name: <section>Sloupec</section>, plainName: "Sloupec", },
];

const blockRenderMap = Immutable.Map({
  'section': {
    element: 'section'
  },
});
function myBlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  if (type === 'col') {
    return 'col';
  }
}

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

class DraftJsEditor extends Component{
  constructor(props) {
    super(props);

    const rowPlugin = createRowPlugin({decorator, setParentReadOnly: this.changeReadOnly});

    this.state = {
      editorState: this.props.initialState ? this.props.initialState : EditorState.createEmpty(),
      editor: React.createRef(),
      readOnly: false,
      plugins: [rowPlugin, linkPlugin, inlineToolbarPlugin, dividerPlugin, undoPlugin, counterPlugin, linkifyPlugin, focusPlugin, alignmentPlugin, imagePlugin, blockDndPlugin, resizeablePlugin, dragNDropFileUploadPlugin, multiLineListItemPlugin, listDepthPlugin, blockBreakoutPlugin],
    };

    this.setEditorState = this.setEditorState.bind(this);
    this.setEditorStateFocus = this.setEditorStateFocus.bind(this);
    this.changeReadOnly = this.changeReadOnly.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.removeLink = this.removeLink.bind(this);
    this.addLink = this.addLink.bind(this);
  }

  setEditorState(editorState){
    this.setState(() => ({editorState}));
    if(this.props.onChange) this.props.onChange(editorState);
  }

  setEditorStateFocus(state){
    this.setEditorState(state);
    setTimeout(() => {
      this.state.editor && this.state.editor.current && this.state.editor.current.focus();
    }, 0);
  }

  changeReadOnly(readOnly){
    this.setState(() => ({readOnly}));
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  removeLink() {
    const selection = this.state.editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setEditorState(RichUtils.toggleLink(this.state.editorState, selection, null));
    }
  };
  addLink(linkUri) {
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: linkUri}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(this.state.editorState, { currentContent: contentStateWithEntity });
    console.log(entityKey, newEditorState, newEditorState.getSelection());
    this.setEditorState(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    ));
  };
  render() {
    return (
      <div className="draftJsEditor">
        <Editor plugins={this.state.plugins} blockStyleFn={myBlockStyleFn} blockRenderMap={extendedBlockRenderMap} ref={this.state.editor} readOnly={this.state.readOnly} placeholder={this.props.placeholder || "Obsah..."} editorState={this.state.editorState} onChange={this.setEditorState} handleKeyCommand={this.handleKeyCommand} />
        <AlignmentTool />
        <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <React.Fragment>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <LinkButton {...externalProps} />
              </React.Fragment>
            )
          }
        </InlineToolbar>
      </div>
    );
  }
}

export {DraftJsEditor, UndoButton, RedoButton, CharCounter, WordCounter, LineCounter, addDivider, basicBlocks, imagePlugin, createRowPlugin};
