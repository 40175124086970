import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { removeNotification } from "../app/notificationsReducer";
import "./notification.scss";

function Notification(props) {
  const [index] = useState(props.index);

  useEffect(() => {
    setTimeout(() => {
      props.dispatch(
        removeNotification({
          index: index,
        })
      );
    }, 5000);
  }, [props,index]);

  return (
    <div className={"notificationWrapper"} key={props.key}>
      <div className={(props.type ? props.type : "") + " notification"}>
        <div className="notification_icon"/>
        <div className="notification_alert ml-15 mr-15">{props.message}</div>
        <div
          className="button notification_button"
          onClick={() => {
            props.dispatch(
              removeNotification({
                index: index,
              })
            );
          }}
        />
      </div>
    </div>
  );
}

export default connect((state) => ({
  notifications: state.notifications,
}))(Notification);
