import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listPages = gql`
    query ListPages(
        $filter: ModelPageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                slug
                name
                content
                createdAt
                updatedAt
            }
        }
    }
`;

const updatePageMutation = gql`
    mutation UpdatePage(
        $input: UpdatePageInput!
        $condition: ModelPageConditionInput
    ) {
        updatePage(input: $input, condition: $condition) {
            id
        }
    }
`;

const deletePageMutation = gql`
    mutation DeletePage(
        $input: DeletePageInput!
        $condition: ModelPageConditionInput
    ) {
        deletePage(input: $input, condition: $condition) {
            id
        }
    }
`;

const createPageMutation = gql`
    mutation CreatePage(
        $input: CreatePageInput!
        $condition: ModelPageConditionInput
    ) {
        createPage(input: $input, condition: $condition) {
            id
        }
    }
`;

function PageRow({page, selected, onSelectChange}){
  const [updatePage, updatePageResponse] = useMutation(updatePageMutation, {
    refetchQueries: [
      'ListPages'
    ]
  });
  const [deletePage, deletePageResponse] = useMutation(deletePageMutation, {
    refetchQueries: [
      'ListPages'
    ]
  });
  const [order, updateOrder] = useState(page.order);
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat dopravní metodu"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{page.id}</td>
      <td>{page.name}</td>
      <td>{page.slug}</td>
      <td>{page.createdAt}</td>
      <td>
        {updatePageResponse.loading || deletePageResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s dopravní metodou">
            <Link to={"/admin/pages/" + page.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat stránku: " + page.name + "?"} onClick={() => deletePage({variables: {input: {id: page.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Pages({}){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("name");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listPages, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createPage, createPageResponse] = useMutation(createPageMutation, {
    refetchQueries: [
      'ListPages'
    ]
  });
  const [selectedPages, dispatchSelectedPages] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listPages.items.length){
          return {}
        }
        data.listPages.items.forEach(page => newState[page.id] = page);
        return newState;
      case "toggle":
        if(newState[action.page.id]){
          delete newState[action.page.id];
        }
        else{
          newState[action.page.id] = action.page;
        }
        return newState;
      case "select":
        newState[action.page.id] = action.page;
        return newState;
      case "deselect":
        delete newState[action.page.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání stránek</div>
    );

    const pages = [...data.listPages.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedPages).length === pages.length} onChange={() => dispatchSelectedPages({type: "selectAll"})} aria-label={"Vybrat stránku"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Odkaz</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {pages.map(page => (
            <PageRow key={"page_" + page.id} onSelectChange={() => dispatchSelectedPages({type: "toggle", page})} selected={!!selectedPages[page.id]} page={page}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"pages"}>
      <h3>Příspěvky</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createPageResponse.loading} onClick={() => createPage({variables: {input: {
              name: "Nová stránka",
              slug: "nova-stranka",
              content: "",
            }}})}>Vytvořit novou</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Pages;
