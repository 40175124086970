import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listPopups = gql`
  query ListPopups(
    $filter: ModelPopupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPopups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        activeFrom
        activeTo
        createdAt
        updatedAt
      }
    }
  }
`;

const deletePopupMutation = gql`
    mutation DeletePopup(
        $input: DeletePopupInput!
        $condition: ModelPopupConditionInput
    ) {
        deletePopup(input: $input, condition: $condition) {
            id
        }
    }
`;

const createPopupMutation = gql`
    mutation CreatePopup(
        $input: CreatePopupInput!
        $condition: ModelPopupConditionInput
    ) {
        createPopup(input: $input, condition: $condition) {
            id
        }
    }
`;

function PopupRow({popup, selected, onSelectChange}){
  const [deletePopup, deletePopupResponse] = useMutation(deletePopupMutation, {
    refetchQueries: [
      'ListPopups'
    ]
  });

  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat popup"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{popup.id}</td>
      <td>{popup.title}</td>
      <td>{popup.activeFrom}</td>
      <td>{popup.activeTo}</td>
      <td>
        <div className="btn-group btn-group-sm" role="group" aria-label="Operace s dopravní metodou">
          <Link to={"/admin/popups/" + popup.id} type="button" className="btn btn-primary">Upravit</Link>
          <ConfirmButton confirmMessage={"Skutečně chcete smazat popup: " + popup.title + "?"} onClick={() => deletePopup({variables: {input: {id: popup.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
        </div>
      </td>
    </tr>
  );
}

function Popups({}){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("title");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listPopups, {
    variables: {
      filter: !fulltext ? null :{
        title: {
          contains: fulltext
        }
      },
    },
  });
  const [createPopup, createPopupResponse] = useMutation(createPopupMutation, {
    refetchQueries: [
      'ListPopups'
    ]
  });
  const [selectedPopups, dispatchSelectedPopups] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listPopups.items.length){
          return {}
        }
        data.listPopups.items.forEach(popup => newState[popup.id] = popup);
        return newState;
      case "toggle":
        if(newState[action.popup.id]){
          delete newState[action.popup.id];
        }
        else{
          newState[action.popup.id] = action.popup;
        }
        return newState;
      case "select":
        newState[action.popup.id] = action.popup;
        return newState;
      case "deselect":
        delete newState[action.popup.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání stránek</div>
    );

    const popups = [...data.listPopups.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedPopups).length === popups.length} onChange={() => dispatchSelectedPopups({type: "selectAll"})} aria-label={"Vybrat stránku"} />
            </th>
            <th>id</th>
            <th>Nadpis {renderSortDirection("title")}</th>
            <th>Zobrazovat od {renderSortDirection("activeFrom")}</th>
            <th>Zobrazovat do {renderSortDirection("activeTo")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {popups.map(popup => (
            <PopupRow key={"Popup_" + popup.id} onSelectChange={() => dispatchSelectedPopups({type: "toggle", popup})} selected={!!selectedPopups[popup.id]} popup={popup}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"popups"}>
      <h3>Popupy</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createPopupResponse.loading} onClick={() => createPopup({variables: {input: {
              title: "Nový popup",
              content: "",
            }}})}>Vytvořit nový</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Popups;
