import React, {useState} from "react";
import Preloader from "../util/Preloader";
import Icons from "../util/Icons";
import OrderDetail from "./OrderDetail";

import {connect} from 'react-redux';
import {addAllProducts} from "../app/cartReducer";
import {gql, useLazyQuery, useQuery} from "@apollo/client";

import "./orders.scss";
import Storage from "@aws-amplify/storage";
import axios from "axios";
import {addNotification} from "../app/notificationsReducer";

const GetOrdersForUser = gql`
  query GetOrdersForUser(
    $userName: String

  ) {
    listPaymentMethods{
      items {
        id
        name
        description
        fee
        order
      }
    }
    listShippingMethods{
        items{
            id
            name
            description
            fee
            order
        }
    }
    ordersForUser(
      userName: $userName
      sortDirection: DESC
    ) {
      items {
        id
        userName
        fields {
          productId
          name
          amount
          price
          priceIncludingTax
          unit
        }
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        orderNumber  
        note
        createdAt
        updatedAt
      }
    }
  }
  `;

const getOrderInvoiceQuery = gql`
    query GetOrderInvoice($orderId: ID!) {
        getOrderInvoice(orderId: $orderId) {
            invoice {
                bucket
                key
            }
        }
    }
`;

function Orders(props) {
  const [orderDetailId, setOrderDetailId] = useState(null);
  const includeVATInPrices = props.cognito.currentUserInfo.attributes["custom:pricesInclVat"] === "1";
  const {loading: loadingOrders, error: errorOrders, data: dataOrders} = useQuery(GetOrdersForUser, {
    variables: {
      userName: props.userName,
      // $createdAt: ModelStringKeyConditionInput
      // $sortDirection: ModelSortDirection
      // $filter: ModelOrderFilterInput
      // $limit: Int
    }
  });
  const [getOrderInvoice, {data: orderInvoiceS3Info}] = useLazyQuery(getOrderInvoiceQuery, {
    onCompleted: () => {
      openOrderInvoice();
    }
  });

  const openOrderInvoice = async (id = null) => {
    if (!id && orderInvoiceS3Info){
      const getOrderInvoice = await Storage.get(orderInvoiceS3Info.getOrderInvoice.invoice.key.substr(7), {
        level: "public",
        download: false,
      });
      window.open(getOrderInvoice, '_blank');
      return;
    }

    if( id )
      getOrderInvoice({variables:{orderId: id}});
  };

  if (loadingOrders && !dataOrders) {
    return (
      <div className="container mbt-25">
        <Preloader/>
      </div>
    )
  }
  if(errorOrders){
    return (
      <div className="container mbt-25">
        Chyba při načítání objednávek
      </div>
    )
  }

  return (
    <div id="orders" className={"container mbt-25"}>
      <h2 className={"light-weight-header"}>
        <span>Seznam objednávek</span>
      </h2>

      <div className="table-responsive orders">
        <div className="table-header">
          <div className="id">Číslo</div>
          <div className="date">Datum</div>
          <div className="paymentmethod">Platba</div>
          <div className="orderstate">Stav</div>
          <div className="addtocart">Vložit do košíku</div>
          <div className="pricewithtax">Cena vč. DPH</div>
          <div className="detailfa">Detail / Fa PDF</div>
        </div>
        <div className="table-content">
          {renderOrders()}
        </div>
      </div>

      {(orderDetailId) ?  <OrderDetail shippingMethods={dataOrders.listShippingMethods.items} paymentMethods={dataOrders.listPaymentMethods.items} orderId={orderDetailId} closeDetail={closeDetail}/> : ""}
    </div>
  );
  function closeDetail() {
    setOrderDetailId(null);
  }
  function renderOrders() {
    return dataOrders.ordersForUser.items.map((orderItem, index) => {
      return (
        <div className={((dataOrders.ordersForUser.items.length - 1 === index)? "last-item ": "") + "table-content-item"} key={orderItem.id}>
          <div className="id">{orderItem.orderNumber}</div>
          <div className="date">{renderCreatedAtDate(orderItem.createdAt)}</div>
          <div className="paymentmethod">{renderPaymentMethodName(orderItem.paymentMethodId)}</div>
          <div className="orderstate">{renderOrderStatus(orderItem.orderStatus)}</div>
          <div className="addtocart">{renderAddAllToCartButton(orderItem.fields)}</div>
          <div className="pricewithtax">{(includeVATInPrices ? renderPriceWithTax(orderItem.fields, orderItem.paymentMethodId, orderItem.shippingMethodId) : renderPriceSansTax(orderItem.fields, orderItem.paymentMethodId, orderItem.shippingMethodId)).toFixed(2)} Kč</div>
          <div className="detailfa">{renderDetailFaButton(orderItem.id)}</div>
        </div>
      );
    });
  }

  async function openOrderDeliveryNote(id){
    const getOrderDeliveryNote = await Storage.get('deliveryNotes/order_' + id + '_deliveryNote.pdf', {
      level: "public",
      download: false,
    });
    try{
      const orderDeliveryNoteStatus = await axios.get(getOrderDeliveryNote);
      console.log(orderDeliveryNoteStatus);
    }
    catch (error){
      if (error.response.status === 404){
        return props.dispatch(addNotification(
          {
            message: 'Dodací list ještě nebyl vytvořen',
            type: "warning",
            code: "frontend.deliveryNoteNotCreatedYet",
          }
        ));
      }
      console.log(error, error.response);
    }
    window.open(getOrderDeliveryNote, '_blank');
  }

  function renderDetailFaButton(id) {
    return (
      <div className={"row"}>
        <div className={"col"}>
          <div className={"button"} title={"detail"} onClick={() => setOrderDetailId(id)}>
            <img className={"icon"} src={Icons.getIcon("eye")} alt={"detail"}/>
          </div>
        </div>
        <div className={"col"}>
          <div className={"button"} title={"faktura"} onClick={() => openOrderInvoice(id)}>
            <img className={"icon"} src={Icons.getIcon("pdf")} alt={"faktura PDF"}/>
          </div>
        </div>
        <div className="col">
          <div className="button" title={"dodací list"} onClick={() => openOrderDeliveryNote(id)}>
            <img src={Icons.getIcon("pdf")} alt="dodací list pdf" className="icon"/>
          </div>
        </div>
      </div>
    );
  }


  function getMethodFees(paymentMethodId, shippingMethodId) {
    return 0; //Methods are now included as a product line in orders

    let methodFees = 0;
    dataOrders.listPaymentMethods.items.forEach(paymentMethod => {
      if (paymentMethod.id === paymentMethodId)
        methodFees += paymentMethod.fee;
    })
    dataOrders.listShippingMethods.items.forEach(shippingMethod => {
      if (shippingMethod.id === shippingMethodId)
        methodFees += shippingMethod.fee;
    });
    return methodFees;
  }

  function renderPriceWithTax(fields, paymentMethodId, shippingMethodId) {
    const methodFees = getMethodFees(paymentMethodId, shippingMethodId);
    let priceIncludingTax = fields.reduce((priceWithTax, productItem) => {
      return priceWithTax + productItem.priceIncludingTax * productItem.amount;
    }, methodFees);

    return priceIncludingTax;
  }
  function renderPriceSansTax(fields, paymentMethodId, shippingMethodId){
    const methodFees = getMethodFees(paymentMethodId, shippingMethodId) / 1.21;

    let price = fields.reduce((price, productItem) => {
      return price + productItem.price * productItem.amount;
    }, methodFees);

    return price;
  }
  function renderCreatedAtDate(createdAt) {
    let date = new Date(createdAt);
    return ("0" + date.getDate()).slice(-2) + "." + (" " + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
  }
  function renderPaymentMethodName(paymentMethodId) {
    return dataOrders.listPaymentMethods.items.map((paymentItem, index) => {
        if (paymentItem.id === paymentMethodId) {
          return paymentItem.name;
        }
        return null;
    });
  }
  function renderOrderStatus(orderStatus) {
    switch (orderStatus) {
      //new paid complete cancelled
      case "new":
        return "Nová";
      case "paid":
        return "Zaplacená";
      case "complete":
        return "Vyřízená";
      case "cancelled":
        return "Zrušená";
      default:
        break;
    }
    return orderStatus;
  }
  function renderAddAllToCartButton(fields) {

    return (
      <div className={"button gray small"} onClick={() => handleAddAllToCart(fields)}>
        <img className={"icon"} src={Icons.getIcon("addToCart")} alt={"Vložit do košíku"}/>
        <span>vložit</span>
      </div>
    );
  }
  function handleAddAllToCart(fields) {
    let products = fields
    .filter((productItem) => {
      if (productItem.productId == null) {
        return false
      }
      return true
    })
    .map((productItem) => {
      return ({productId: productItem.productId, amount: productItem.amount});
    });

    props.dispatch(addAllProducts({products: products}));
  }

}

export default connect(state => ({
  cognito: state.cognito,
  cart: state.cart,
}))(Orders);
