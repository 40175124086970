import React, {Component} from "react";
import { connect } from 'react-redux';
import {fetchCurrentUserInfo} from '../app/cognitoReducer';
import {Redirect} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Banners from "../widgets/Banners";
import {addNotification} from '../app/notificationsReducer';
import Advantages from "./Advantages";

import './verify.scss'
import { Helmet } from "react-helmet-async";

class Verify extends Component{
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      code: "",
      error: null,
      loading: false,
    }
  }

  componentDidMount(){
    this.props.dispatch(fetchCurrentUserInfo()); // Dispatch this on login status changes!!!
    const login = this?.props?.match?.params?.name;
    const code = this?.props?.match?.params?.code;
    if (login || code){
      this.setState(() => ({
        login: login || "",
        code: code || "",
      }))
    }
  }

  checkInputs(){
    if (!this.state.login){
      const error = {
        message: "Login nesmí být prázdný",
        type: "error",
        code: "frontend.loginEmpty",
        field: "login",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }
    if (!this.state.code){
      const error = {
        message: "Kód nesmí být prázdný",
        type: "error",
        code: "frontend.codeEmpty",
        field: "code",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }

    return true;
  }

  doVerify(){
    if (this.state.loading) return;
    if (!this.checkInputs()) return;
    this.setState(() => ({loading: true}), () => {
      Auth.confirmSignUp(this.state.login, this.state.code)
        .then(() => {
          this.props.dispatch(fetchCurrentUserInfo());
          this.props.history.push("/login");
          console.log("Done");
        })
        .catch((error) => {
          switch (error.code){
            case "UserNotFoundException":
            case "ExpiredCodeException":
            case "CodeMismatchException":
              error.message = "Uživatel neexistuje nebo je zadaný špatný / starý kód";
              error.field = "code";
              break;
            case "NotAuthorizedException":
              error.message = "Uživatel je již potvrzen";
              this.props.history.push("/login");
              error.field = "login";
              break;
            default:
              console.log(error);
              break;
          }
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code,
          }));
          this.setState(() => ({error: error}));
        })
        .finally(() => this.setState(() => ({loading: false})));
    });
  }

  doResend(){
    if (this.state.loading) return;
    this.setState(() => ({loading: true}), () => {
      Auth.resendSignUp(this.state.login)
        .then((response) => {
          this.props.dispatch(addNotification({
            message: "Kód byl znovuzaslán na email " + response?.CodeDeliveryDetails?.Destination,
            type: "info",
            code: "info",
          }));
        })
        .catch((error) =>{
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code,
          }));
          error.field = "login";
          this.setState(() => ({error: error}));
        })
        .finally(() => this.setState(() => ({loading: false})));
    });
  }


  render() {
    if (this.props.cognito.currentSession){
      return <Redirect to={"/"}/>
    }
    return (
      <div className={"verify container mbt-25"}>
        <Helmet>
          <title>Potvrzení registrace</title>
        </Helmet>
        <div className={"row gutter-20px m-0"}>
          <div className="col mbt-25 w-100p">
            <div className="pageHeader">
              <h1>Potvrzení registrace</h1>
            </div>
            <div className="row pageForm">
              <div className="col w-66p">
                <p>Po registraci nového zákazníka zasíláme verifikační email. Prosím zkontrolujte svůj email a zadejte do formuláře níže kód z něj.</p>
                <div className="panel fullWidthInput">
                  <div className="panelBody buttons">
                    <form onSubmit={event => {event.preventDefault(); this.doVerify(); }}>
                      <label className={"mb-15 grid-105 mt-10"}>
                        <span>Login</span>
                        <input type={"text"} name={"login"} className={(this?.state?.error?.field === "login") ? "error" : ""} value={this.state.login} onChange={({target}) => this.setState(() => ({login: target.value}))}/>
                      </label>
                      <label className={"mb-45 grid-105"}>
                        <span>Verifikační kód</span>
                        <input type={"text"} name={"code"} className={(this?.state?.error?.field === "code") ? "error" : ""} value={this.state.code} onChange={({target}) => this.setState(() => ({code: target.value}))}/>
                      </label>
                    </form>
                    <div className={"row m-0 buttonsWrapper"}>
                      <div className={"button small gray "} onClick={() => this.doResend()}><span>Znovu zaslat kód</span></div>
                      <div className={"button medium blue ml-auto"} onClick={() => this.doVerify()}><span>Potvrdit</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col w-33p">
                <Banners position={"login"}/>
              </div>
            </div>
            <div className="row">
              <div className="col w-100p">
                <h2 className={"mbt-25px"}>Výhody registrace</h2>
                <hr className={"mb-20px"}/>
                <Advantages/>
              </div>
            </div>
            </div>
          </div>
      </div>
    );
  }
}

export default connect(state => ({cognito: state.cognito}))(Verify);
