import { gql } from "@apollo/client";

export const getFullCategoryList = gql`
  query getFullCategoryList {
    listProductCategorys {
      items {
        id
        name
        description
        order
        slug
        children {
          items {
            id
            name
            description
            order
            slug
            children {
              items {
                id
                name
                description
                order
                slug
                children {
                  items {
                    id
                    name
                    description
                    order
                    slug
                    children{
                        items{
                            id
                            name
                            description
                            order
                            slug
                        }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCategoryBySlug = gql`
  query getCategoryBySlug($slug: String) {
    productCategorysBySlug(slug: $slug) {
      items {
        id
      }
    }
  }
`;

export const getSubCategoryBySlug = gql`
  query getSubCategoryBySlug($slug: String) {
    productSubCategorysBySlug(slug: $slug) {
      items {
        id
        parentId
      }
    }
  }
`;

export const getSubSubCategoryBySlug = gql`
  query getSubSubCategoryBySlug($slug: String) {
    productSubSubCategorysBySlug(slug: $slug) {
      items {
        id
        parentId
      }
    }
  }
`;

export const getSubSubSubCategoryBySlug = gql`
  query getSubSubSubCategoryBySlug($slug: String) {
    productSubSubSubCategorysBySlug(slug: $slug) {
      items {
        id
        parentId
      }
    }
  }
`;

export const getSubSubSubSubCategoryBySlug = gql`
  query getSubSubSubSubCategoryBySlug($slug: String){
    productSubSubSubSubCategorysBySlug(slug: $slug){
      items{
        id
        parentId
      }
    }
  }
`;
