import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";
import EditorComponent from "../editor/EditorComponent";
import {registerLocale} from "react-datepicker";
import DatePicker from "react-datepicker";
import cs from 'date-fns/locale/cs';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('cs', cs)

const getPopup = gql`
  query GetPopup($id: ID!) {
    getPopup(id: $id) {
      id
      title
      content
      activeFrom
      activeTo
      createdAt
      updatedAt
    }
  }
`;

const updatePopupMutation = gql`
  mutation UpdatePopup(
    $input: UpdatePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    updatePopup(input: $input, condition: $condition) {
      id
    }
  }
`;

function PopupEditForm({initialPopup, saveChanges}) {
  const parseInitialPopup = () => JSON.parse(JSON.stringify(initialPopup), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [popup, setPopup] = useState(parseInitialPopup());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    console.log({path, value, popup})
    let newPopup = JSON.parse(JSON.stringify(popup));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newPopup[splitPath[0]] = value;
        break;
      case 2:
        newPopup[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newPopup[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setPopup(newPopup);
  };

  return (
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="title">
            <Form.Label>Titulek</Form.Label>
            <Form.Control type="text" placeholder="není" value={popup.title} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"activeFrom"}>
            <Form.Label>Zobrazovat od</Form.Label>
            <div className={"date-picker"}>
              <DatePicker
                locale={'cs'}
                maxDate={popup.activeTo ? new Date(popup.activeTo) : undefined}
                selected={new Date(popup.activeFrom || Date.now())}
                onChange={(value) => changeHandler('activeFrom', new Date(value).toISOString())}
                showTimeSelect
                dateFormat="Pp"
              />
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"activeTo"}>
            <Form.Label>Zobrazovat do</Form.Label>
            <div className={"date-picker"}>
              <DatePicker
                locale={'cs'}
                minDate={popup.activeFrom ? new Date(popup.activeFrom) : undefined}
                selected={new Date(popup.activeTo || Date.now())}
                onChange={(value) => changeHandler('activeTo', new Date(value).toISOString())}
                showTimeSelect
                dateFormat="Pp"
              />
            </div>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          {popup.advancedEditor ? (
            <Form.Group controlId={"content"}>
              <Form.Label className={"d-flex"}>
                <span className={"mbt-auto"}>Obsah</span>
              </Form.Label>
              <Form.Control as="textarea" rows={3} placeholder={"není"} value={popup.content}
                            onChange={changeEventHandler}/>
            </Form.Group>
          ) : (
            <Form.Group controlId={"content"}>
              <Form.Label className={"d-flex"}>
                <span className={"mbt-auto"}>Obsah</span>
                <div className={"btn btn-primary ml-auto"}
                     onClick={() => changeHandler("advancedEditor", true)}>Pokročilý editor
                </div>
              </Form.Label>
              <EditorComponent inputHtml={popup.content} onChange={(newValue) => {
                console.log("SinglePopup change", newValue);
                changeHandler("content", newValue);
              }
              }/>
            </Form.Group>
          )}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}}>
          <div className="btn-group w-100" role="group" aria-label="Operace s popupem">
            <button onClick={() => setPopup(parseInitialPopup())} type="button" className="btn btn-secondary">Vrátit
              změny
            </button>
            <button onClick={() => saveChanges({...popup, createdAt: undefined, updatedAt: undefined})} type="button"
                    className="btn btn-primary">Uložit změny
            </button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SinglePopup(props) {
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getPopup, {
    variables: {
      id: match.params.popupId,
    }
  });
  const [updatePopup, updatePopupResponse] = useMutation(updatePopupMutation, {
    refetchQueries: [
      "GetPopup",
    ]
  });
  if (error) return (
    <article className={"singlePopup"}>
      <div className={"danger"}>Chyba při načítání popupu</div>
    </article>
  );

  if (loading || updatePopupResponse.loading) return (
    <article className={"singlePopup"}><Preloader/></article>
  );

  const popup = data.getPopup;

  return (
    <article className={"singlePopup"}>
      <h3>Popup: {match.params.popupId}</h3>
      <hr/>
      <PopupEditForm initialPopup={popup} saveChanges={(changedPopup) => updatePopup({
        variables: {
          input: changedPopup,
        }
      })}/>
    </article>
  );
}

export default SinglePopup;
