import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";

const getPriceLevel = gql`
  query GetPriceLevel($id: ID!) {
    getPriceLevel(id: $id) {
      id
      name
      discount {
        flat
        percent
      }
      default
      createdAt
      updatedAt
    }
  }
`;

const updatePriceLevelMutation = gql`
  mutation UpdatePriceLevel(
    $input: UpdatePriceLevelInput!
    $condition: ModelPriceLevelConditionInput
  ) {
    updatePriceLevel(input: $input, condition: $condition) {
      id
      name
      discount {
        flat
        percent
      }
      default
      createdAt
      updatedAt
      dynamoUserRecords {
        nextToken
      }
    }
  }
`;

function PriceLevelEditForm({initialPriceLevel, saveChanges}){
  const parseInitialPriceLevel = () => JSON.parse(JSON.stringify(initialPriceLevel), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [priceLevel, setPriceLevel] = useState(parseInitialPriceLevel());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newPriceLevel = JSON.parse(JSON.stringify(priceLevel));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newPriceLevel[splitPath[0]] = value;
        break;
      case 2:
        newPriceLevel[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newPriceLevel[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setPriceLevel(newPriceLevel);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={priceLevel.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="default" className={"pt-5"}>
            <Form.Switch
              id={"default"}
              label="Výchozí"
              onChange={({target}) => changeHandler(target.id, target.checked)}
              checked={priceLevel.default}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="discount.flat">
            <Form.Label>Sleva Kč</Form.Label>
            <Form.Control type="number" placeholder="není" step={0.01} value={priceLevel.discount.flat} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="discount.percent">
            <Form.Label>Sleva %</Form.Label>
            <Form.Control type="number" placeholder="není" step={0.01} value={priceLevel.discount.percent} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setPriceLevel(parseInitialPriceLevel())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...priceLevel, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SinglePriceLevel(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getPriceLevel, {
    variables: {
      id: match.params.priceLevelId,
    }
  });
  const [updatePriceLevel, updatePriceLevelResponse] = useMutation(updatePriceLevelMutation);
  if (error) return (
    <article className={"singlePriceLevel"}><div className={"danger"}>Chyba při načítání cenové úrovně</div></article>
  ) ;

  if (loading || updatePriceLevelResponse.loading) return (
    <article className={"singlePriceLevel"}><Preloader/></article>
  ) ;

  const priceLevel = data.getPriceLevel;

  return (
    <article className={"singlePriceLevel"}>
      <h3>Cenová úroveň: {match.params.priceLevelId}</h3>
      <hr/>
      <PriceLevelEditForm initialPriceLevel={priceLevel} saveChanges={(changedPriceLevel) => updatePriceLevel({
        variables:{
          input: changedPriceLevel,
        }
      })}/>
    </article>
  );
}

export default SinglePriceLevel;
