import React, {useState} from "react";
import {Table, Form, Button, Col} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {useRouteMatch} from "react-router-dom";
import {userAttributes, userStatuses} from "../util/globalMaps";

const adminGetUser = gql`
  query AdminGetUser($Username: String) {
    adminGetUser(Username: $Username) {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
      }
    }
  }
`;

const updateUserMutation = gql`
  mutation AdminUpdateUser($input: UpdateUserInput!) {
    adminUpdateUser(input: $input)
  }
`;

function AttributesUpdateForm({attributes: oldAttributes, userEnabled, userName, refetch}) {
  const [updateUser, updateUserResponse] = useMutation(updateUserMutation, {
    onCompleted: refetch
  });
  console.log(updateUserResponse);
  const parseAttributes = (userAttributes) => {
    let toReturn = {};
    userAttributes.forEach(userAttribute => {
      toReturn[userAttribute.Name] = userAttribute.Value;
    });
    return toReturn;
  };
  const [attributes, setAttributes] = useState(parseAttributes(oldAttributes));
  const changeEventHandler = ({target}) => {
    switch (target.type) {
      case "radio":
      case "checkbox":
        if (target.checked) changeHandler(target.name, target.value);
        break;
      default:
        changeHandler(target.id, target.value);
        break;
    }
  };
  const changeHandler = (path, value) => {
    let newAttributes = JSON.parse(JSON.stringify(attributes));
    newAttributes[path] = value;
    setAttributes(newAttributes);
  };
  const userAttributeKeys = Object.keys(userAttributes);
  const saveChanges = (attributes) => {
    let parsedOldAttributes = parseAttributes(oldAttributes);
    let attributeKeys = Object.keys(attributes);
    let newAttributes = [];
    attributeKeys.forEach(attributeKey => {
      if (attributes[attributeKey] !== parsedOldAttributes[attributeKey]){
        newAttributes.push({
          Name: attributeKey,
          Value: attributes[attributeKey],
        });
      }
    });
    if (newAttributes.length)
      updateUser({
        variables: {
          input: {
            Username: userName,
            Attributes: newAttributes,
          }
        }
      });
  };
  return (
    <Form>
      <Form.Row>
        {userAttributeKeys.map(userAttributeKey => (
          <Col md={6} key={userAttributeKey}>
            <Form.Group controlId={userAttributeKey}>
              <Form.Label>{userAttributes[userAttributeKey].name}</Form.Label>
              {userAttributes[userAttributeKey].type === "text" ?
                <Form.Control type="text" placeholder="není" value={attributes[userAttributeKey]}
                              onChange={changeEventHandler}/> :
                <div className={"form-control"}>
                  <Form.Check checked={attributes[userAttributeKey] === "1"} inline type={"radio"} label={"Ano"}
                              value={"1"} id={userAttributeKey + "1"} name={userAttributeKey}
                              onChange={changeEventHandler}/>
                  <Form.Check checked={attributes[userAttributeKey] === "0"} inline type={"radio"} label={"Ne"}
                              value={"0"} id={userAttributeKey + "0"} name={userAttributeKey}
                              onChange={changeEventHandler}/>
                </div>
              }
            </Form.Group>
          </Col>
        ))}
      </Form.Row>
      <Form.Row>
        <Col md={{span: 5, offset: 7}}>
          <div className="btn-group w-100" role="group" aria-label="Operace s uživatelem">
            {userEnabled ? <Button disabled={updateUserResponse.loading} variant={"danger"} onClick={() => updateUser({
                variables: {
                  input: {
                    Username: userName,
                    Enabled: false,
                  }
                }
              })}>Zakázat přístup</Button> :
              <Button disabled={updateUserResponse.loading} variant={"success"} onClick={() => updateUser({
                variables: {
                  input: {
                    Username: userName,
                    Enabled: true,
                  }
                }
              })}>Povolit přístup</Button>}
            <button disabled={updateUserResponse.loading} onClick={() => setAttributes(parseAttributes(oldAttributes))} type="button"
                    className="btn btn-secondary">Vrátit změny
            </button>
            <button disabled={updateUserResponse.loading} onClick={() => saveChanges(attributes)} type="button" className="btn btn-primary">Uložit změny
            </button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleUser(props) {
  const match = useRouteMatch();
  const {loading, data, error, refetch} = useQuery(adminGetUser, {
    variables: {
      Username: match.params.userId,
    }
  });

  if (error) return (
    <article className={"singleUser"}>
      <div className={"danger"}>Chyba při načítání uživatele</div>
    </article>
  );

  if (loading) return (
    <article className={"singleUser"}><Preloader/></article>
  );

  return (
    <div className={"singleUser"}>
      <h3>Uživatel: <span className={"text-transform-none"}>{match.params.userId}</span></h3>
      <hr/>
      <Table responsive bordered>
        <thead>
        <tr>
          <th>
            Datum registrace
          </th>
          <th>
            Datum posledních úprav
          </th>
          <th>
            Stav uživatele
          </th>
          <th>
            Přístup povolen
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            {data.adminGetUser.UserCreateDate}
          </td>
          <td>
            {data.adminGetUser.UserLastModifiedDate}
          </td>
          <td>
            {userStatuses[data.adminGetUser.UserStatus]}
          </td>
          <td>
            {data.adminGetUser.Enabled ? <span className={"text-success mr-2"}>Ano</span> :
              <span className={"text-danger mr-2"}>Ne</span>}
          </td>
        </tr>
        </tbody>
      </Table>
      <hr/>
      <AttributesUpdateForm attributes={data.adminGetUser.Attributes} userEnabled={data.adminGetUser.Enabled} userName={match.params.userId} refetch={refetch}/>
    </div>
  );
}

export default SingleUser;
