import { createSlice } from '@reduxjs/toolkit'

function checkAmount(amount){
  if (typeof amount !== 'number'){
    console.error(`The amount must be a number, current value: ${amount}`);
    return false;
  }
  if (amount < 1){
    console.error(`The amount must be >= 1, current value: ${amount}`);
    return false;
  }
  return true;
}

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],
  reducers: {
    addProduct: (state, action) => {
      let {productId, amount} = action.payload;
      if (!checkAmount(amount)) return state;
      const indexToChange = state.findIndex(value => value.productId === productId);
      if (indexToChange === -1){
        state.push({
          productId,
          amount,
        });
      }
      else{
        state[indexToChange].amount += amount;
      }

      return state;
    },
    addAllProducts: (state, action) => {
      let {products} = action.payload;
      products.forEach(product => {
        if (!checkAmount(product.amount)) return state;
        const indexToChange = state.findIndex(value => value.productId === product.productId);
        if (indexToChange === -1){
          state.push(product);
        }
        else{
          state[indexToChange].amount += product.amount;
        }
      });

      return state;
    },
    removeProduct: (state, action) => {
      const {productId} = action.payload;
      const indexToRemove = state.findIndex(value => value.productId === productId);
      if (indexToRemove === -1){
        console.error(`Product with id ${productId} is not in the cart!`);
        return state;
      }
      state.splice(indexToRemove, 1);
      return state;
    },
    setProductAmount: (state, action) => {
      const {productId, amount} = action.payload;
      if (!checkAmount(amount)) return state;
      const indexToChange = state.findIndex(value => value.productId === productId);
      if (indexToChange === -1){
        console.error(`Product with id ${productId} is not in the cart!`);
        return state;
      }
      state[indexToChange].amount = amount;
      return state;
    },
    changeProductAmount: (state, action) => {
      const {productId, amount} = action.payload;
      const indexToChange = state.findIndex(value => value.productId === productId);
      if (indexToChange === -1){
        console.error(`Product with id ${productId} is not in the cart!`);
        return state;
      }
      let finalAmount = state[indexToChange].amount + amount;
      if (!checkAmount(finalAmount)) return state;
      state[indexToChange].amount = finalAmount;
      return state;
    },
    emptyCart: () => [],
  }
});

export const { addProduct, addAllProducts, removeProduct, setProductAmount, changeProductAmount, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;
