import React, {useEffect, useState} from "react";
import {Form, ProgressBar, Button, Row, Col, Table} from "react-bootstrap";
import Storage from '@aws-amplify/storage';
import {gql, useQuery} from "@apollo/client";
import { useSelector } from 'react-redux';
import API from "@aws-amplify/api";
import Preloader from "../util/Preloader";

const searchProductsImports = gql`
  query SearchProductsImports(
    $filter: SearchableProductsImportFilterInput
    $sort: SearchableProductsImportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsImports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        fileName
        status
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

function ProductImport(props){
  const {loading: productsImportsLoading, error: productsImportsError, data: productsImportsData, startPolling, stopPolling} = useQuery(searchProductsImports, {
    variables: {
      sort: {field: "createdAt", direction: "desc"},
      limit: 9,
      nextFetchPolicy: "cache-first",
    },
  });
  useEffect(() => {
    startPolling(15000);
    return function cleanup() {
      stopPolling();
    }
  });
  const currentUserInfo = useSelector(state => state.cognito.currentUserInfo);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSize, setUploadSize] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [productImportLoading, setProductImportLoading] = useState(false);
  const [productImportError, setProductImportError] = useState(null);
  const [clearUnimportedProducts, setClearUnimportedProducts] = useState(false);
  const [clearEmptyCategories, setClearEmptyCategories] = useState(false);
  const [clearParentlessSubcategories, setClearParentlessSubcategories] = useState(false);
  const [clearCategorylessProducts, setClearCategorylessProducts] = useState(false);
  const [clearUnimportedProductsWithinCategory, setClearUnimportedProductsWithinCategory] = useState(false);
  const [clearUnimportedProductsWithinManufacturer, setClearUnimportedProductsWithinManufacturer] = useState(false);

  // const [importProducts, {data: productImportResponse, loading: productImportLoading, error: productImportError}] = useMutation(adminImportProducts, {
  //   onCompleted(data) {
  //     console.log(data);
  //   },
  //   onError(error) {
  //     console.log(error);
  //   }
  // });

  let importProducts = (s3ImportFile) => {
    setProductImportLoading(true);
    setProductImportError(null);
    API.post('topasPlusRest', '/admin/importProducts', {
      response: true,
      body: {
        s3ImportFile,
        clearUnimportedProducts,
        clearEmptyCategories,
        clearParentlessSubcategories,
        clearCategorylessProducts,
        clearUnimportedProductsWithinCategory,
        clearUnimportedProductsWithinManufacturer,
      },
      headers: {
        InvocationType: 'Event',
      }
    }).then(response => {
      console.log("SUCCESS!", response);
    }).catch(error => {
      setProductImportError(error);
    }).finally(() => {
      setProductImportLoading(false);
    });
  };

  let uploadHandler = async (event) => {
    event.preventDefault();
    setFileUploaded(false);
    setUploadProgress(0);
    setUploadSize(1);
    try {
      let putResponse = await Storage.put(selectedFile.name, selectedFile, {
        contentType: 'text/css', // contentType is optional
        level: "private",
        progressCallback(progress) {
          setUploadProgress(progress.loaded);
          setUploadSize(progress.total);
        }
      });
      importProducts("private/" + (currentUserInfo && currentUserInfo.id) + "/" + putResponse.key);
      setFileUploaded(true);
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  };

  const renderImports = () => {
    if (productImportError){
      return <Row>
        <Col>
          <h4 className={"text-danger"}>Chyba načítání</h4>
          <pre>{JSON.stringify(productImportError)}</pre>
        </Col>
      </Row>
    }
    if ((productsImportsLoading) && (!productsImportsData)) return(
      <Preloader/>
    );
    if (!productsImportsData.searchProductsImports.total){
      return <h4>Neproběhl žádný import</h4>;
    }
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>ID</th>
          <th>Soubor</th>
          <th>Stav</th>
          <th>Začátek</th>
          <th>Poslední změna</th>
        </tr>
        </thead>
        <tbody>
        {productsImportsData.searchProductsImports.items.map(productsImport => (
          <tr key={"productImport_" + productsImport.id}>
            <td>{productsImport.id}</td>
            <td>{productsImport.fileName}</td>
            <td>{productsImport.status}</td>
            <td>{productsImport.createdAt}</td>
            <td>{productsImport.updatedAt}</td>
          </tr>
        ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div className={"productImport"}>
      <h3>Import produktů</h3>
      <hr/>
      <Form onSubmit={uploadHandler}>
        <Form.Group>
          <Form.File id="importFile" isValid>
            <Form.File.Label>Prosím zvolte .csv soubor s importem produktů</Form.File.Label>
            <Form.File.Input onChange={({target}) => setSelectedFile(target.files[0])} accept={".csv"}/>
          </Form.File>
        </Form.Group>
        <hr/>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Check
                type={"checkbox"}
                id={`clearUnimportedProducts`}
                label={`Smazat produkty, které se nenacházejí v importu`}
                onClick={() => setClearUnimportedProducts(!clearUnimportedProducts)}
                checked={clearUnimportedProducts}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type={"checkbox"}
                id={`clearUnimportedProductsWithinCategory`}
                label={<span>V importovaných <strong>kategoriích</strong> smazat produkty, jež nejsou součástí importu</span>}
                onClick={() => setClearUnimportedProductsWithinCategory(!clearUnimportedProductsWithinCategory)}
                checked={clearUnimportedProductsWithinCategory}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type={"checkbox"}
                id={`clearUnimportedProductsWithinManufacturer`}
                label={<span>V importovaných <strong>výrobcích</strong> smazat produkty, jež nejsou součástí importu</span>}
                onClick={() => setClearUnimportedProductsWithinManufacturer(!clearUnimportedProductsWithinManufacturer)}
                checked={clearUnimportedProductsWithinManufacturer}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Check
                type={"checkbox"}
                id={`clearEmptyCategories`}
                label={`Smazat prázdné kategorie`}
                onClick={() => setClearEmptyCategories(!clearEmptyCategories)}
                checked={clearEmptyCategories}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type={"checkbox"}
                id={`clearParentlessSubcategories`}
                label={`Smazat podkategorie bez rodičů`}
                onClick={() => setClearParentlessSubcategories(!clearParentlessSubcategories)}
                checked={clearParentlessSubcategories}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type={"checkbox"}
                id={`clearCategorylessProducts`}
                label={`Smazat produkty bez kategorií`}
                onClick={() => setClearCategorylessProducts(!clearCategorylessProducts)}
                checked={clearCategorylessProducts}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <hr/>
        <Form.Group className={"text-right"}>
          <Button disabled={!selectedFile} variant="primary" type="submit">
            Odeslat
          </Button>
        </Form.Group>

        {uploadSize ? <Form.Group>
          <Form.Label>Postup nahrávání souboru: </Form.Label>
          <ProgressBar animated={uploadProgress !== uploadSize} now={uploadProgress} max={uploadSize} label={Math.round(uploadProgress/uploadSize * 100) + '%'}/>
        </Form.Group> : null}

        {fileUploaded ? <Form.Group>
          <Form.Label>Postup zpracování dat: </Form.Label>
          <ProgressBar animated={productImportLoading} now={1} max={1} variant={productImportError ? "danger" : "success"} label={productImportError ? "Chyba" : (productImportLoading ? "Zadává se ke zpracování" : "Zadáno")}/>
        </Form.Group> : null}
      </Form>
      <hr/>
      <h3>Stav zadaných importů</h3>
      <h6>(Aktualizace každých 15s)</h6>
      {renderImports()}
    </div>
  );
}

export default ProductImport;
