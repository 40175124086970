import React from "react";
import EshopCategoryDescription2 from "./EshopCategoryDescription2";
import "./eshopCategory.scss";
import EshopCategoryFilter2 from "./EshopCategoryFilter2";
import {useQuery} from "@apollo/client";
import {getFullCategoryList} from "./EshopSidebarAPI";
import Preloader from "../util/Preloader";
import EshopCategoryFilter3 from "./EshopCategoryFilter3";

function EshopCategory(props) {
  const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(getFullCategoryList);

  if (categoriesError){
    console.error(categoriesError);
    return (
      <div className="eshopCategoryWrapper">
        Nastala chyba při načítání kategorií
      </div>
    );
  }

  if (categoriesLoading && !categoriesData){
    return (
      <div className="eshopCategoryWrapper">
        <Preloader/>
      </div>
    );
  }

  return (
    <div className="eshopCategoryWrapper">
      <EshopCategoryDescription2 categories={categoriesData.listProductCategorys.items}/>
      {/*<EshopCategoryFilter2 categories={categoriesData.listProductCategorys.items}/>*/}
      <EshopCategoryFilter3 categories={categoriesData.listProductCategorys.items}/>
    </div>
  );
}

export default EshopCategory;
