import React, { Component } from "react";
import { Link } from "react-router-dom";
import {connect, useSelector} from "react-redux";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";

import { addProduct } from "../app/cartReducer";
import { addNotification } from "../app/notificationsReducer";

import Preloader from "../util/Preloader";
import Icons from "../util/Icons";

import "./productListing.scss";
import {ConvertImageUriFromS3ToCloudFront} from "../helpers";
class ProductListingInternalProductInternal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyAmount: this.props?.product?.minimalAmount || 1,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log({prev: prevProps?.product?.minimalAmount, curr: this.props?.product?.minimalAmount, state: this.state.buyAmount});
    if (prevProps?.product?.minimalAmount !== this.props?.product?.minimalAmount){
      this.setState(() => ({buyAmount: this.props?.product?.minimalAmount || 1}))
    }
  }

  shortenProductName = () => {
    const MAX_LENGTH = 70;
    const name = this.props?.product?.name;
    if (!name){
      return null;
    }
    if (name.length <= MAX_LENGTH){
      return name;
    }
    const subName = name.substring(0, MAX_LENGTH - 3)
    const separators = [".", ",", " "];
    let lastSeparator = -1;
    separators.forEach(separator => {
      const lastIndex = subName.lastIndexOf(separator);
      if (lastIndex > lastSeparator){
        lastSeparator = lastIndex;
      }
    });
    return <span title={name}>{name.substring(0, lastSeparator) + "..."}</span>;
  }

  renderMainImage() {
    const acceptedMainImageTypes = ["OV"];
    for (let i = 0; i < this.props.product.attachments.items.length; i++) {
      let attachment = this.props.product.attachments.items[i];

      if (acceptedMainImageTypes.indexOf(attachment.type) !== -1) {
        return <img src={ConvertImageUriFromS3ToCloudFront(attachment.file)} alt={attachment.description} />;
      }
    }

    return (
      <img
        src={"/staticImages/placeholders/product.png"}
        alt={this.props.product.name}
      />
    );
  }
  handleInputBuyAmount(amount){
    if (this.state.buyAmount + amount <= 0) return;
    this.setState((state) => ({buyAmount: state.buyAmount + amount}));
  }

  renderBuyRow(){
    const renderBuyAmount = () => {
      if (this.props.product.linksTo || this.props.product.isInstallation || this.props.product.isRental){
        return null;
      }
      return(
        <div className={"input_number buyAmount"}>
                <span>
                  {this.state.buyAmount} <span>{this.props?.product?.unit || "ks"}</span>
                </span>
          <div className={"spin_buttons"}>
                  <span
                    className="spin_top"
                    onClick={() => this.handleInputBuyAmount(this.props?.product?.minimalAmount || 1)}
                  ></span>
            <span
              className="spin_bottom"
              onClick={() => this.handleInputBuyAmount(-1 * (this.props?.product?.minimalAmount || 1))}
            ></span>
          </div>
        </div>
      );
    }

    const renderCartButton = () => {
      if (this.props.product.linksTo){
        return (
          <Link
            to={this.props.product.linksTo}
            className={"button medium gray m-10 full-width"}
          >
            <strong>Více</strong>
          </Link>
        );
      }
      if (this.props.product.isRental){
        return (
          <Link
            to={"/eshop/product/" + this.props.product.slug}
            className={"button medium gray m-10 full-width"}
          >
            <strong>Půjčit</strong>
          </Link>
        );
      }
      if (this.props.product.isInstallation){
        return (
          <Link
            to={"/eshop/product/" + this.props.product.slug}
            className={"button medium gray m-10 full-width"}
          >
            <strong>Poptat</strong>
          </Link>
        );
      }
      return (
        <div
          onClick={() => {
            // Check if we go over stock
            let inCart = null;
            for (let i = 0; i < this.props.cart.length; i++) {
              if (this.props.cart[i].productId === this.props.product.id){
                inCart = this.props.cart[i];
              }
            }
            if (inCart){
              const futureAmount = inCart.amount + this.state.buyAmount;
              if ((futureAmount > this.props.product.stock) && this.props.product.stock ){
                this.props.dispatch(
                  addNotification({
                    message: `V košíku máte více ${this.props.product.unit} produktu "${this.props.product.name}", než je okamžitě dostupných. Produkt dorazí do ${this.props.product.restockDays || this.props.restockDays} dnů`,
                    type: "warning",
                    code: "frontend.addCartProduct",
                  })
                );
              }
            }

            this.props.dispatch(
              addProduct({ productId: this.props.product.id, amount: this.state.buyAmount })
            );
            this.props.dispatch(
              addNotification({
                message: `${this.state.buyAmount} x Přídán produkt ${this.props.product.name}`,
                type: "info",
                code: "frontend.addCartProduct",
              })
            );
          }}
          className={"button medium gray m-10 " + (this.props.product.notForSale ? " hidden " : "")}
        >
          <img
            alt={"Košík"}
            className={"mr-5 icon cart"}
            src={Icons.getIcon("cart")}
          />
          <strong className={"ml-5"}>Koupit</strong>
        </div>
      );
    }

    return (
      <div className={"row amountBuy"}>
        {renderBuyAmount()}
        {renderCartButton()}
      </div>
    );
  }

  renderStockAmount() {
    if (this.props.product.linksTo || this.props.product.isInstallation){
      return null;
    }

    return (
      <div className={"row stockInfo"}>
        {this.props?.product?.stock > 0 ? (
          <span className="onStock">{`${this.props?.product?.stock} ${this.props?.product?.unit} skladem`}</span>
        ) : (
          <span className="offStock">Dodání do {this.props?.product?.restockDays || this.props.restockDays} dnů</span>
        )}
      </div>
    );
  }

  render() {
    if (this.props.loading && !this.props.product) {
      return (
        <div className="col product" style={this.props.style || {}}>
          <Preloader />
        </div>
      );
    }
    if (this.props.error || !this.props.product) {
      return (
        <div className="col product" style={this.props.style || {}}>
          <strong className={"error"}>
            Nastala chyba při načítání produktu
          </strong>
        </div>
      );
    }
    return (
      <article
        key={"product_" + this.props.product.id}
        className={"col product " + this.props.className}
        style={this.props.style || {}}
      >
        <Link
          to={"/eshop/product/" + this.props.product.slug}
          className={"image"}
        >
          {this.renderMainImage()}
        </Link>
        <div className="productDescription">
          <hr className={"transparentBlack mb-10"} />
          <Link to={"/eshop/product/" + this.props.product.slug} className="name">
            {this.shortenProductName()}
          </Link>
          <div className="excerpt">
            {this.props.product.excerpt || this.props.product.description}
          </div>
        </div>
        <div className="price mlr-auto-mobile mt-10-mobile">
          {this.props.product.salePrice ? (
            <div className="amount-pre-sale">
              {this.props.product.price.toFixed(2)} <span>Kč</span>
            </div>
          ) : null}
          <div className={"amount"}>
            {(this.props.product.salePrice > 1000
              ? (Math.round(this.props.product.salePrice * 100) / 100).toFixed(
                  2
                )
              : (Math.round(this.props.product.salePrice * 100) / 100).toFixed(
                  2
                )) || this.props.product.price}
            <span>Kč</span>
          </div>
        </div>
        {this.renderBuyRow()}
        {this.renderStockAmount()}
      </article>
    );
  }
}

ProductListingInternalProductInternal.propTypes = {
  productId: PropTypes.string,
  className: PropTypes.string,
  restockDays: PropTypes.number,
};

const GetProduct = gql`
  query GetProduct($productId: ID!) {
    getProduct(id: $productId) {
      id
      manufacturerId
      manufacturerProductCode
      name
      slug
      linksTo
      price
      salePrice
      isRental
      isInstallation
      securityDeposit
      dailyRentalFee
      featuredSale
      ean
      unit
      vat
      createdAt
      updatedAt
      minimalAmount
      stock
      restockDays
      manufacturer {
        id
        order
        name
        description
        image
        createdAt
        updatedAt
      }
      attachments {
        items {
          file
          id
          description
          type
          typeDesctiption
        }
      }
    }
  }
`;

const ProductListingInternalProduct = graphql(GetProduct, {
  // options: {
  //   fetchPolicy: "network-only",
  // },
  props: (props) => ({
    product: props.data.getProduct,
    error: props.data.error,
    loading: props.data.loading,
    //props.data fields: error; fetchMore(); loading; menuByPosition /*Or other inner fetched name*/; networkStatus; refetch(); startPolling(); stopPolling(); subscribeToMore(); updateQuery(); variables /* object in shape name: value */
  }),
})(connect()(ProductListingInternalProductInternal));

class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollState: 0
    }
  }
  renderProducts() {
    if (this.props.loading && !this.props.products) {
      return (
        <div className="col product mlr-auto">
          <Preloader />
        </div>
      );
    }
    if (this.props.error) {
      return (
        <div className="col product mlr-auto">
          <strong className={"error"}>
            Nastala chyba při načítání produktů
          </strong>
        </div>
      );
    }
    if (!this.props.products.length) {
      return (
        <div className="col product mlr-auto">
          <span>Žádný produkt nebyl nalezen</span>
        </div>
      );
    }

    return this.props.products.map((product, index) => {
      let style = {};
      if (index === 0){
        style.marginLeft = "calc(10px + " + this.state.scrollState * -25 + "%)";
      }
      return (
        <ProductListingInternalProduct
          className={this.props.productClassName}
          key={"product_" + product.id}
          productId={product.id}
          style={style}
          cart={this.props.cart}
          restockDays={this.props.restockDays}
        />
      )
    });
  }

  getMoreProductsHref(){
    if (this.props.filter.onSale){
      return "/eshop/sort:Akce";
    }
    if (this.props.filter.newest){

    }
    return "/eshop";
  }

  render() {
    return (
      <section className={"productListing " + this.props.className}>
        <div className="header">
          <h3>{this.props.heading}</h3>
          <Link
            to={this.getMoreProductsHref()}
            className="button ml-auto mbt-auto inline light-gray medium caret-right"
          >
            <span>Další produkty</span>
          </Link>
        </div>
        <div className="productsRowWrapper">
          <div aria-label={"Doleva"} style={{
            display: this.state.scrollState <= 0 ? "none" : "block"
          }} className="button gray round arrow-left hidden-mobile" onClick={() => this.setState(state => ({...state, scrollState: state.scrollState - 1}))} />
          <div className="row products wrap gutter-20px">
            {this.renderProducts()}
          </div>
          <div
            aria-label={"Doprava"}
            className="button gray round arrow-right hidden-mobile"
            onClick={() => this.setState(state => ({...state, scrollState: state.scrollState + 1}))}
            style={{
              display: (this.props.products?.length <= 4 + this.state.scrollState) ? "none" : "block"
            }}
          />
        </div>
      </section>
    );
  }
}

ProductListing.propTypes = {
  heading: PropTypes.string,
  filter: PropTypes.shape({
    onSale: PropTypes.bool,
    newest: PropTypes.bool,
    //TODO: More as needed
  }),
  className: PropTypes.string,
  productClassName: PropTypes.string,
};

const searchProducts = gql`
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
      }
      nextToken
      total
    }
  }
`;

export default connect((state) => ({
  cart: state.cart,
  restockDays: state.pageSettings.NaskladneniDo,
}))(graphql(searchProducts, {
  props: (props) => ({
    products: props.data.searchProducts && props.data.searchProducts.items,
    error: props.data.error,
    loading: props.data.loading,
    fetchMore: props.data.fetchMore,
    //props.data fields: error; fetchMore(); loading; menuByPosition /*Or other inner fetched name*/; networkStatus; refetch(); startPolling(); stopPolling(); subscribeToMore(); updateQuery(); variables /* object in shape name: value */
  }),
  options: (props) => {
    let filter = {};
    let sort = null;
    //TODO: Rework this.props.filter to this.props.filter and this.props.sort!!!
    if (props.filter.onSale) {
      sort = { field: "updatedAt", direction: "desc" };
      filter = { featuredSale: { eq: true } };
    }
    if (props.filter.newest) {
      sort = { field: "updatedAt", direction: "desc" };
      filter = {featuredNews: {eq: true}}
    }
    return {
      variables: {
        filter: {
          ...filter,
          isHidden: {ne: true}
        },
        sort,
        limit: 8,
      },
    };
  },
})(ProductListing));
