import React, { useEffect, useState } from "react";
import HorizontalMenu from "../menu/HorizontalMenu";
import "./header.scss";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Auth from "@aws-amplify/auth";

import {
  fetchCurrentSession,
  fetchCurrentUserInfo,
} from "../app/cognitoReducer";
import Minicart from "./Minicart";
import Preloader from "../util/Preloader";
import { setPreference } from "../app/userPreferencesReducer";
import { toggleMenu } from "../app/menuReducer";

import Search2 from "./Search2";

const Header = (props) => {
  // const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (!props.location.pathname.includes("eshop")) {
      // setMenuOpen(false)
      props.dispatch(toggleMenu(false))
    }
  }, [props.location]);

  const renderUserControls = () => {
    let toReturn = (
      <>
        <Link
          title={"Uživatelský účet"}
          to={"/user/account"}
          className="button account"
        >
          <img
            src={"/staticImages/icons/userAccount.svg"}
            alt="Uživatelský účet"
            className="icon"
          />
        </Link>
        <div
          title={"Odhlásit se"}
          onClick={() =>
            Auth.signOut().then(() => {
              props.dispatch(fetchCurrentUserInfo());
              props.dispatch(fetchCurrentSession());
            })
          }
          className="button signout"
        >
          <img
            src={"/staticImages/icons/logout.svg"}
            alt="Odhlásit se"
            className="icon"
          />
        </div>
      </>
    );

    if (props.cognito.currentSession === null) {
      toReturn = <Preloader />;
    }

    if (props.cognito.currentSession === false) {
      toReturn = (
        <Link title={"Přihlásit se"} to={"/login"} className="button signin">
          <span className={"hidden-mobile"}>Registrace / přihlášení</span>
          <img
            src={"/staticImages/icons/login.svg"}
            alt="Přihlásit se"
            className="icon"
          />
        </Link>
      );
    }

    let isAdmin = false;
    if (
      props.cognito.currentSession &&
      props.cognito.currentSession.idToken &&
      props.cognito.currentSession.idToken.payload &&
      props.cognito.currentSession.idToken.payload["cognito:groups"]
    ) {
      isAdmin =
        props.cognito.currentSession.idToken.payload["cognito:groups"].indexOf(
          "Admin"
        ) !== -1;
    }

    return (
      <div className="userControls controlBoxMobile ">
        {isAdmin ? (
          <Link title={"Administrace"} to={"/admin"} className={"button admin"}>
            <img
              src={"/staticImages/icons/admin.svg"}
              alt={"admin"}
              className={"icon"}
            />
          </Link>
        ) : null}
        {toReturn}
      </div>
    );
  };

  if (props.location.pathname.match(/^\/admin/)) return null;
  // const debouncedChangeFulltext = debounce(
  //   (value) => handleWhisperer(value),
  //   1000
  // );
  return (
    <header className={"pageHeader"}>
      <div className="headerControls">
        <Link to={"/"} className="logoContainer">
          <img
            src={"/staticImages/topasplus-logo.svg"}
            alt="Topas plus logo"
            className="logo"
          />
        </Link>

        <Search2/>

        {renderUserControls()}

        <div className={"controlBox burgerMenu hidden-desktop" + ((props.menuOpen) ? " blue" : "")}  onClick={() => {
          props.dispatch(toggleMenu(!props.menuOpen))
        }}>
          <div className="button" >
            <img src={(props.menuOpen) ? "/staticImages/xClose.svg" : "/staticImages/icons/menu.svg"} alt="menu" className={"icon menu"}/>  
          </div>
        </div>

        {<Minicart />}
      </div>

      <HorizontalMenu position={"main-menu"} open={props.menuOpen} />
    </header>
  );
};

export default withRouter(
  connect((state) => ({
    menuOpen: state.menuOpen,
    cognito: state.cognito,
  }))(Header)
);
