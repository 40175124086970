import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Preloader from "../util/Preloader";
import Icons from "../util/Icons";
import { Link } from "react-router-dom";
import "./Banners.scss";
import {gql, useQuery} from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const GetLatestPost = gql`
  query GetLatestPost {
    searchNewsPosts(sort: {field: createdAt, direction: desc}, limit: 1) {
      items {
        id
        slug
        name
        thumbnail
        category {
          id
          slug
          name
        }
      }
      nextToken
      total
    }
  }
`;

const Banner = ({banner}) => {
  let myBanner = JSON.parse(JSON.stringify(banner));
  const { loading: postLoading, error: postError, data: postData } = useQuery(GetLatestPost, {
    skip: myBanner?.specialFunction !== "lastPost",
  });

  if (myBanner.specialFunction === "lastPost"){
    if (postLoading && !postData){
      return (
        <div className={"col banner " + myBanner.bannerClass}>
          <Preloader/>
        </div>
      )
    }
    if (postError || !postData?.searchNewsPosts?.total){
      return (
        <div className={"col banner " + myBanner.bannerClass}>
          <p className={"error"}>Nastala chyba při načítání příspěvku</p>
        </div>
      )
    }
    const post = postData.searchNewsPosts.items[0];
    myBanner.target = "/novinky/" + post.category.slug + "/" + post.slug;
    if (post.thumbnail) myBanner.image = post.thumbnail;
    if (post.name) myBanner.text = post.name;
  }

  return (
    <div
      className={"col banner " + myBanner.bannerClass}
      style={{ backgroundImage: "url(" + myBanner.image + ")" }}
    >
      <Link to={myBanner.target} className={"inner-link"} />
      <div
        className={
          "row " + (!myBanner.pills.items.length ? "h-100p " : "")
        }
      >
        <div
          className={
            "col " +
            (!myBanner.pills.items.length ? "w-auto h-100p " : "w-40p bottom-right-arrow")
          }
        >
          {myBanner.title ? <h3>{myBanner.title}</h3> : null}

          {myBanner.text ? <p>{myBanner.text}</p> : null}
          {myBanner.buttonText ? (
            <div className={"button mt-auto " + myBanner.buttonClass}>
              <span>{myBanner.buttonText}</span>
            </div>
          ) : null}
        </div>
        {myBanner.pills.items.length ? (
          <div className={"col cards"}>
            {myBanner.pills.items.map((pill) => (
              <Link
                key={"pill_" + pill.id}
                className={"card " + pill.class}
                to={pill.target}
              >
                <h4 className={"header"}>{pill.text}</h4>
                <img
                  src={Icons.getIcon(pill.icon)}
                  alt={pill.text}
                  className={"icon " + pill.icon}
                />
              </Link>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

class Banners extends Component {
  static defaultProps = {
    isServicePage: false,
  };

  render() {
    // console.log(this.props);
    if (this.props.loading && !this.props.banners) {
      return (
        <div className="row banners gutter-20px mbt-10">
          <div className="col banner mlr-auto">
            <Preloader />
          </div>
        </div>
      );
    }
    if (this.props.error) {
      return (
        <div className="row banners gutter-20px mbt-10">
          <div className="col banner mlr-auto">
            <strong className={"error"}>
              Nastala chyba při načítání bannerů
            </strong>
          </div>
        </div>
      );
    }
    if (this.props.isServicePage === false) {

      // console.log(this.props.banners);
      return (
        <div
          className={"row banners gutter-20px mbt-10 " + this.props.className}
        >
          {this.props.banners
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((banner) => <Banner banner={banner} key={"banner_" + banner.id}/> )}
        </div>
      );
    }
    if (this.props.isServicePage === true) {
      return (
        <div className="bannerWrapeer ">
          <div
            className={
              "row bannersServices mbt-10 " + this.props.className
            }
          >
            <div
              key={"banner_" + this.props.banners[0].id}
              className={"col banner " + this.props.banners[0].bannerClass}
              style={{
                backgroundImage: "url(" + this.props.banners[0].image + ")",
              }}
            >
              <Link
                to={this.props.banners[0].target}
                className={"inner-link"}
              />
              <div
                className={
                  "row " +
                  (!this.props.banners[0].pills.items.length ? "h-100p " : "")
                }
              >
                <div
                  className={
                    "col " +
                    (!this.props.banners[0].pills.items.length
                      ? "w-auto h-100p "
                      : "w-40p")
                  }
                >
                  {this.props.banners[0].title ? (
                    <h3>{this.props.banners[0].title}</h3>
                  ) : null}
                  {this.props.banners[0].text ? (
                    <p>{this.props.banners[0].text}</p>
                  ) : null}
                  {this.props.banners[0].buttonText ? (
                    <div
                      className={
                        "button mt-auto " + this.props.banners[0].buttonClass
                      }
                    >
                      <span>{this.props.banners[0].buttonText}</span>
                    </div>
                  ) : null}
                </div>
                {this.props.banners[0].pills.items.length ? (
                  <div className={"col cards"}>
                    {this.props.banners[0].pills.items.map((pill) => (
                      <Link
                        key={"pill_" + pill.id}
                        className={"card " + pill.class}
                        to={pill.target}
                      >
                        <h4 className={"header"}>{pill.text}</h4>
                        <img
                          src={Icons.getIcon(pill.icon)}
                          alt={pill.text}
                          className={"icon " + pill.icon}
                        />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="bannerText right">
              Máme vlastní dodávková i nákladní vozidla, které lze využít pro
              expresní dopravu zboží. Mimo náš vozový park nabízíme i standardní
              dopravce, které spolehlivě doručí zboží domů nebo na stavbu.
            </div>
          </div>
          <div
            className={
              "row bannersServices mbt-10 " + this.props.className
            }
          >
            {" "}
            <div className="bannerText left">
              Vybírat můžete ze sortimentu profesionálního nářadí pro instalaci
              a montáž zdravotechniky a topení. Stačí si vybrat online a
              vyzvednout na naší prodejně nebo využít dopravy na stavbu.
            </div>
            <div
              key={"banner_" + this.props.banners[1].id}
              className={"col banner " + this.props.banners[1].bannerClass}
              style={{
                backgroundImage: "url(" + this.props.banners[1].image + ")",
              }}
            >
              <Link
                to={this.props.banners[1].target}
                className={"inner-link"}
              />
              <div
                className={
                  "row " +
                  (!this.props.banners[1].pills.items.length ? "h-100p " : "")
                }
              >
                <div
                  className={
                    "col " +
                    (!this.props.banners[1].pills.items.length
                      ? "w-auto h-100p "
                      : "w-40p")
                  }
                >
                  {this.props.banners[1].title ? (
                    <h3>{this.props.banners[1].title}</h3>
                  ) : null}
                  {this.props.banners[1].text ? (
                    <p>{this.props.banners[1].text}</p>
                  ) : null}
                  {this.props.banners[1].buttonText ? (
                    <div
                      className={
                        "button mt-auto " + this.props.banners[1].buttonClass
                      }
                    >
                      <span>{this.props.banners[1].buttonText}</span>
                    </div>
                  ) : null}
                </div>
                {this.props.banners[1].pills.items.length ? (
                  <div className={"col cards"}>
                    {this.props.banners[1].pills.items.map((pill) => (
                      <Link
                        key={"pill_" + pill.id}
                        className={"card " + pill.class}
                        to={pill.target}
                      >
                        <h4 className={"header"}>{pill.text}</h4>
                        <img
                          src={Icons.getIcon(pill.icon)}
                          alt={pill.text}
                          className={"icon " + pill.icon}
                        />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={
              "row bannersServices mbt-10 " + this.props.className
            }
          >
            <div
              key={"banner_" + this.props.banners[1].id}
              className={"col banner " + this.props.banners[1].bannerClass}
              style={{
                backgroundImage: "url(" + this.props.banners[1].image + ")",
              }}
            >
              <Link to="/montáže" className={"inner-link"} />
              <div
                className={
                  "row " +
                  (!this.props.banners[1].pills.items.length ? "h-100p " : "")
                }
              >
                <div
                  className={
                    "col " +
                    (!this.props.banners[1].pills.items.length
                      ? "w-auto h-100p "
                      : "w-40p")
                  }
                >
                  {this.props.banners[1].title ? <h3>Montáže</h3> : null}
                  {this.props.banners[1].text ? (
                    <p>{this.props.banners[1].text}</p>
                  ) : null}
                  {this.props.banners[1].buttonText ? (
                    <div
                      className={
                        "button mt-auto " + this.props.banners[1].buttonClass
                      }
                    >
                      <span>{this.props.banners[1].buttonText}</span>
                    </div>
                  ) : null}
                </div>
                {this.props.banners[1].pills.items.length ? (
                  <div className={"col cards"}>
                    {this.props.banners[1].pills.items.map((pill) => (
                      <Link
                        key={"pill_" + pill.id}
                        className={"card " + pill.class}
                        to={pill.target}
                      >
                        <h4 className={"header"}>{pill.text}</h4>
                        <img
                          src={Icons.getIcon(pill.icon)}
                          alt={pill.text}
                          className={"icon " + pill.icon}
                        />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="bannerTextLi right">
              <span>
                K vybranému zboží nabízíme profesionální montáž na stavbě nebo u
                vás doma. Pro více informací ohledně montáže nám zavolejte na +
                420 123 456 789 nebo napišete na info@topasplus.cz
              </span>
              <span>
                Kategorie, ke které nabízíme montáž:
                <ul>
                  <li>Ohřívače TV</li>
                  <li>Plynové kotle pro vytápění a ohčev TV</li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      );
    }
  }
}

Banners.propTypes = {
  position: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const BannersInPosition = gql`
  query bannersInPosition($position: String) {
    bannersInPosition(position: $position) {
      items {
        bannerClass
        buttonClass
        buttonText
        id
        image
        order
        target
        text
        title
        specialFunction
        pills {
          items {
            class
            icon
            id
            order
            target
            text
          }
        }
      }
    }
  }
`;

export default graphql(BannersInPosition, {
  // options: {
  //   fetchPolicy: "network-only",
  // },
  props: (props) => ({
    banners: props.data.bannersInPosition && props.data.bannersInPosition.items,
    error: props.data.error,
    loading: props.data.loading,
    //props.data fields: error; fetchMore(); loading; menuByPosition /*Or other inner fetched name*/; networkStatus; refetch(); startPolling(); stopPolling(); subscribeToMore(); updateQuery(); variables /* object in shape name: value */
  }),
})(Banners);
