import React, {useEffect, useReducer, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import InputRange from "react-input-range";
import {gql, useQuery} from "@apollo/client";

import { getListManufacturers } from "./EshopCategoryFilterAPI";
import {setEshopDisplay} from "../app/userPreferencesReducer";

import "./eshopCategoryFilter.scss";
import "react-input-range/lib/css/index.css";
import Preloader from "../util/Preloader";
import {useRouteMatch, useHistory} from "react-router-dom";
import {EshopProduct} from "./EshopCategoryProducts";
import {debounce} from "lodash";
import ReactPaginate from "react-paginate";

const searchProductsQuery = gql`
  query SearchFilteredProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
      }
      nextToken
      total
    }
  }
`;
const searchPriceAggregationsQuery = gql`
  query SearchFilteredPriceAggregations(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      aggregations{
        minPrice
        maxPrice
      }
    }
  }
`;
const searchManufacturerAggregationsQuery = gql`
  query SearchFilteredManufacturerAggregations(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      aggregations{
        uniqueManufacturers
      }
    }
  }
`;
const searchAttributeAggregationsQuery = gql`
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      aggregations{
        uniqueAttributes{
          name
          values
        }
      }
    }
  }
`;

const sortTypes = [
  {
    name: "Nejprodávanější",
    order: "desc",
    field: "stock",
  },
  {
    name: "Od nejdražšího",
    order: "desc",
    field: "salePrice",
  },
  {
    name: "Od nejlevnějšího",
    order: "asc",
    field: "salePrice",
  },
  {
    name: "Akce",
    order: "desc",
    field: "featuredSale",
  },
];

const baseFilter = {
  isHidden: {ne: true},
};

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const EshopCategoryFilter2 = ({categories}) => {
  const cart = useSelector((state) => state.cart);
  const forceUpdate = useForceUpdate();
  const match = useRouteMatch();
  const history = useHistory();
  const [showMinPrice, setShowMinPrice] = useState(0);
  const [showMaxPrice, setShowMaxPrice] = useState(1000000);
  const [debouncedChangePrice, setDebouncedChangePrice] = useState(() => debounce(
    function changePrice(value){
      console.log(value);
      if (!value) return;
      changeFilterFields([
        {
          field: "minPrice",
          value: value.min,
        },
        {
          field: "maxPrice",
          value: value.max,
        },
      ])
    }, 1000));
  let selectedManufacturers = [];
  let minPrice = 0;
  let maxPrice = 1000000;
  let sort = null;
  let pageItems = 9 + (window.innerWidth <= 768);
  let page = 0;
  const paramsFilter = match?.params?.filter;
  const splitFilter = paramsFilter ? paramsFilter.split("/") : [];
  let categorySlugs = [];
  splitFilter.forEach(filterParam => {
    if (filterParam.indexOf(":") === -1){
      categorySlugs.push(filterParam);
    }
    const [paramName, paramValue] = filterParam.split(":");
    let parsedParamValue;
    switch (paramName){
      case "manufacturers":
        selectedManufacturers = (paramValue.split(";"));
        break;
      case "minPrice":
        parsedParamValue = parseFloat(paramValue);
        minPrice = (parsedParamValue);
        //setShowMinPrice(parsedParamValue);
        break;
      case "maxPrice":
        parsedParamValue = parseFloat(paramValue);
        maxPrice = (parsedParamValue);
        //setShowMaxPrice(parsedParamValue);
        break;
      case "page":
        page = parseInt(paramValue);
        break;
      case "sort":
        sort = (paramValue);
        break;
    }
  });
  let categoryIds = {
    categoryId: undefined,
    subCategoryId: undefined,
    subSubCategoryId: undefined,
    subSubSubCategoryId: undefined,
    subSubSubSubCategoryId: undefined,
  }
  let actualCategory = null;
  let actualLevel = 0;
  const levels = Object.keys(categoryIds);
  let currentSelection = categories;
  for (let i = 0; i < categorySlugs.length; i++) {
    for (let j = 0; j < currentSelection.length; j++) {
      if (currentSelection[j].slug === categorySlugs[i]){
        actualCategory = currentSelection[j];
        categoryIds[levels[actualLevel]] = actualCategory.id;
        actualLevel++;
        currentSelection = actualCategory?.children?.items || [];
      }
    }
  }
  const [manufacturerFilterLimit, setManufacturerFilterLimit] = useState(5);
  useEffect(() => {
    setShowMinPrice(minPrice);
    setShowMaxPrice(maxPrice);
    function resizeListener(event){
      window.requestAnimationFrame(() => {
        forceUpdate();
      });
    }
    window.addEventListener("resize", resizeListener)
    return function cleanup(){
      window.removeEventListener("resize", resizeListener);
    }
  }, []);
  const dispatch = useDispatch();
  const productDisplay = useSelector(
    (state) => state.userPreferences.eshop.productDisplay
  );
  const {loading: manufacturersLoading, error: manufacturersError, data: manufacturersData} = useQuery(getListManufacturers);

  const currentSort = sortTypes.find((sortType) => sortType.name === sort) || {name: null, order: "asc", field: "apiId"};
  function changeFilterFields(fields){
    let url = history.location.pathname;
    if (url[url.length - 1] === "/"){
      url = url.substr(0, url.length - 1);
    }
    fields.forEach(({field, value}) => {
      url = changeFilterField(field, value, false, url);
    });
    history.push(url);
  }
  function changeFilterField(field, value, redirect = true, url = null){
    let splitUrl = null;
    if (url){
      splitUrl = url.split("/");
    }
    else{
      splitUrl = match.url.split("/");
    }
    let newUrl = [];
    let changed = false;
    splitUrl.forEach(urlFragment => {
      const [urlName, urlValue] = urlFragment.split(":");
      if (!urlValue){
        newUrl.push(urlFragment);
        return;
      }
      if (urlName === field){
        if (value) newUrl.push(field + ":" + value);
        changed = true;
        return;
      }
      newUrl.push(urlFragment);
    });
    if (!changed && value){
      newUrl.push(field + ":" + value);
    }
    if (redirect) history.push(newUrl.join("/"));
    return newUrl.join("/");
  }
  function dispatchSelectedManufacturers(action) {
    let newState = [...selectedManufacturers];
    const manufacturerIdIndex = newState.indexOf(action.manufacturerId);
    switch (action.type) {
      case "clear":
        newState = [];
        break;
      case "toggle":
        if (manufacturerIdIndex === -1){
          newState.push(action.manufacturerId);
        }
        else{
          newState.splice(manufacturerIdIndex, 1);
        }
        break;
      case "select":
        if (manufacturerIdIndex === -1){
          newState.push(action.manufacturerId);
        }
        break;
      case "deselect":
        if (manufacturerIdIndex !== -1){
          newState.splice(manufacturerIdIndex, 1);
        }
        break;
      default:
        throw new Error("Invalid action");
    }
    changeFilterField("manufacturers", newState.join(";"));
    selectedManufacturers = newState;
  }
  // const [selectedManufacturers, dispatchSelectedManufacturers] = useReducer((state, action) => {
  //   let newState = [...state];
  //   const manufacturerIdIndex = newState.indexOf(action.manufacturerId);
  //   switch (action.type) {
  //     case "clear":
  //       return [];
  //     case "toggle":
  //       if (manufacturerIdIndex === -1){
  //         newState.push(action.manufacturerId);
  //       }
  //       else{
  //         newState.splice(manufacturerIdIndex, 1);
  //       }
  //       return newState;
  //     case "select":
  //       if (manufacturerIdIndex === -1){
  //         newState.push(action.manufacturerId);
  //       }
  //       return newState;
  //     case "deselect":
  //       if (manufacturerIdIndex !== -1){
  //         newState.splice(manufacturerIdIndex, 1);
  //       }
  //       return newState;
  //     default:
  //       throw new Error("Invalid action");
  //   }
  // }, []);
  let filter = {
    salePrice: {range: [minPrice, maxPrice]},
    or: selectedManufacturers.length ? selectedManufacturers.map(manufacturerId => ({
      manufacturerId: {eq: manufacturerId},
    })) : undefined,
    categoryId: categoryIds.categoryId ? {eq: categoryIds.categoryId} : undefined,
    subCategoryId: categoryIds.subCategoryId ? {eq: categoryIds.subCategoryId} : undefined,
    subSubCategoryId: categoryIds.subSubCategoryId ? {eq: categoryIds.subSubCategoryId} : undefined,
    subSubSubCategoryId: categoryIds.subSubSubCategoryId ? {eq: categoryIds.subSubSubCategoryId} : undefined,
    subSubSubSubCategoryId: categoryIds.subSubSubSubCategoryId ? {eq: categoryIds.subSubSubSubCategoryId} : undefined,
  };
  const {loading: productsLoading, error: productsError, data: productsData} = useQuery(searchProductsQuery, {
    variables: {
      filter: {...filter, ...baseFilter},
      sort: currentSort ? {field: currentSort.field, direction: currentSort.order} : null,
      limit: pageItems,
      from: page * pageItems,
    },
    context: {
      debounceKey: 'eshopFilterProductSearch',
    },
  });
  const {loading: priceLimitLoading, error: priceLimitError, data: priceLimitData} = useQuery(searchPriceAggregationsQuery, {
    variables: {
      filter: {...filter, ...baseFilter, salePrice: undefined, aggregate: true},
      sort: currentSort ? {field: currentSort.field, direction: currentSort.order} : null,
      limit: 0,
    },
    context: {
      debounceKey: 'eshopFilterPriceAggregation',
    },
  });
  const {loading: manufacturerAggregationLoading, error: manufacturerAggregationError, data: manufacturerAggregationData} = useQuery(searchManufacturerAggregationsQuery, {
    variables: {
      filter: {...filter, ...baseFilter, or: undefined, aggregate: true},
      sort: currentSort ? {field: currentSort.field, direction: currentSort.order} : null,
      limit: 0,
    },
    context: {
      debounceKey: 'eshopFilterManufacturersAggregation',
    },
  });

  const loadingError = manufacturersError || priceLimitError || manufacturerAggregationError || productsError;
  if (loadingError){
    console.error(loadingError);
    return (
      <div className="container mbt-25 categoryFilter">
        Nastala chyba při načítání filtru
      </div>
    );
  }
  if (
    (manufacturersLoading && !manufacturersData) ||
    (priceLimitLoading && !priceLimitData) ||
    (manufacturerAggregationLoading && !manufacturerAggregationData) ||
    (productsLoading && !productsData)
  ){
    return (
      <div className="container mbt-25 categoryFilter">
        <Preloader/>
      </div>
    );
  }

  const [minPriceLimit, maxPriceLimit] = [parseFloat(priceLimitData?.searchProducts?.aggregations?.minPrice), parseFloat(priceLimitData?.searchProducts?.aggregations?.maxPrice)];
  if (minPrice < minPriceLimit){
    changeFilterFields([
      {
        field: "minPrice",
        value: minPriceLimit,
      },
      {
        field: "maxPrice",
        value: maxPrice,
      },
    ]);
  }
  if (maxPrice > maxPriceLimit){
    changeFilterFields([
      {
        field: "minPrice",
        value: minPrice,
      },
      {
        field: "maxPrice",
        value: maxPriceLimit,
      },
    ]);
  }
  const manufacturers = [...manufacturersData.listManufacturers.items]
    .filter((manufacturer) => manufacturerAggregationData.searchProducts.aggregations.uniqueManufacturers.indexOf(manufacturer.id) !== -1)
    .sort((a, b) => {
      const aSelected = selectedManufacturers.indexOf(a.id) !== -1;
      const bSelected = selectedManufacturers.indexOf(b.id) !== -1;
      if (aSelected && !bSelected) return -1;
      if (bSelected && !aSelected) return 1;
      return a.order - b.order;
    });
  return (<>
    <div className="container mbt-25 categoryFilter">
      <div className="row sortProductsWrapper hidden-mobile">
        <div className="col w-66p productOrder">
          <div className="row">
            <div className="filterTitle">Seřadit produkty</div>
          </div>
          <div className="row buttons">
            {sortTypes.map(sortType => (
              <button
                onClick={() => changeFilterField("sort", sortType.name)}
                key={"sortType_" + sortType.name}
                className={"button medium light-gray " + (sort === sortType.name ? "active" : "")}
              >
                {sortType.name}
              </button>
            ))}
          </div>
        </div>
        <div className="col w-33p productPriceRange">
          <div className="row priceIntervalSlider">
            <InputRange
              step={0.1}
              maxValue={maxPriceLimit}
              minValue={minPriceLimit}
              value={({min: showMinPrice, max: showMaxPrice})}
              onChange={(value) => {
                setShowMinPrice(value.min);
                setShowMaxPrice(value.max);
                debouncedChangePrice(value);
              }}
            />
            <div className="priceInterval">
              <div className="priceFrom">{`${showMinPrice.toFixed(2)}`}</div>
              <div className="priceTo">{`${showMaxPrice.toFixed(2)}`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row sortProductsWrapper hidden-desktop">
        <div className="col">
          <div className="row productOrder">
            <div className="col">
              <h4 className="filterTitle mbt-auto mr-25">Seřadit produkty</h4>
            </div>
            <div className="col flex-grow-1">
              <select className={"flex-grow-1"} value={sort} onChange={({target}) => changeFilterField("sort", target.value)} name="productOrder">
                <option value={"null"}>Výchozí</option>
                {sortTypes.map(sortType => (
                  <option
                    key={"sortType_" + sortType.name}
                  >
                    {sortType.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row priceInterval">
            Cena: od <strong>{`${showMinPrice.toFixed(2)}`} Kč</strong> do <strong>{`${showMaxPrice.toFixed(1)}`} Kč</strong>
          </div>
          <div className="row priceIntervalSlider">
            <InputRange
              step={0.1}
              maxValue={maxPriceLimit}
              minValue={minPriceLimit}
              value={({min: showMinPrice, max: showMaxPrice})}
              onChange={(value) => {
                setShowMinPrice(value.min);
                setShowMaxPrice(value.max);
                debouncedChangePrice(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row productDistributor">
        <h4 className={"filterTitle"}>Vyberte výrobce</h4>
        <div className="row factories">
          {manufacturers.slice(0, manufacturers.length >= manufacturerFilterLimit ? manufacturerFilterLimit - 1 + (window.innerWidth <= 768) : manufacturerFilterLimit).map(manufacturer => (
            <div key={manufacturer.id} className="factory"
            // onClick={() => {
            //   dispatchSelectedManufacturers({
            //     type: "toggle",
            //     manufacturerId: manufacturer.id,
            //   })}}
              >
              <input
                type="checkbox"
                checked={selectedManufacturers.indexOf(manufacturer.id) !== -1}
                onChange={() => dispatchSelectedManufacturers({
                  type: "toggle",
                  manufacturerId: manufacturer.id,
                })}
                className="logo"
              />
              <img src={manufacturer.image} alt={manufacturer.name} className="logo" />
            </div>
          ))}
          {manufacturers.length > manufacturerFilterLimit ? (
            <button
              className="factory factoryBtn arrow-right"
              onClick={() => setManufacturerFilterLimit(10000)}
            >
              Zobrazit další
            </button>
          ) : null}
          {manufacturerFilterLimit === 10000 ? (
            <button
              className="factory factoryBtn  arrow-left"
              onClick={() => setManufacturerFilterLimit(5)}
            >
              Skrýt
            </button>
          ) : null}
        </div>
      </div>
      {/*TODO: sortingTags class refactor*/}
      <div className=" row sortingTags">
        <div className="filterTitle filterView hidden-mobile">
          <span>Zobrazení</span>
          <button
            className={
              (productDisplay === "col")
                ? "active button small light-gray"
                : "button small light-gray"
            }
            onClick={() =>
              dispatch(setEshopDisplay('col'))
            }
          >
            <img src="/staticImages/gridView.svg" alt="Sloupcový výpis" />
          </button>
          <button
            className={
              (productDisplay === "row")
                ? "active button small light-gray"
                : "button small light-gray"
            }
            onClick={() =>
              dispatch(setEshopDisplay('row'))
            }
          >
            <img src="/staticImages/rowView.svg" alt="Řádkový výpis" />
          </button>
        </div>
        <div className="filterTitle">{`Výsledek filtrování - ${productsData.searchProducts.total} ks`}</div>
      </div>
    </div>
    <div className={(productDisplay === "col") ? "eshopProductGrid" : "eshopProductFlex"}> {/*If productDisplay is not col, it is row for now*/}
      {productsData?.searchProducts?.items?.map((product, i) => (
        <EshopProduct product={product} key={"product_" + product.id} cart={cart}/>
      ))}
    </div>
    {productsData?.searchProducts?.total ? (
      <>
        <div className="categoryPagination hidden-mobile">
          <ReactPaginate
            pageCount={productsData.searchProducts.total / pageItems}
            previousLabel="Předchozí"
            nextLabel="Další"
            pageRangeDisplayed={3}
            forcePage={page}
            onPageChange={(e) => {
              changeFilterField("page", e.selected);
            }}
          />
        </div>
        <div className="categoryPagination hidden-desktop">
          <ReactPaginate
            pageCount={productsData.searchProducts.total / pageItems}
            previousLabel="Předchozí"
            nextLabel="Další"
            pageRangeDisplayed={0}
            marginPagesDisplayed={0}
            forcePage={page}
            onPageChange={(e) => {
              changeFilterField("page", e.selected);
            }}
          />
        </div>
      </>
    ) : null}
  </>);
};

export default EshopCategoryFilter2;
