import { gql } from "@apollo/client";

export const GetProduct = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      isHidden
      notForSale
      apiId
      linksTo
      categoryId
      manufacturerId
      manufacturerProductCode
      name
      description
      excerpt
      price
      salePrice
      isRental
      isInstallation
      securityDeposit
      dailyRentalFee
      featuredSale
      ean
      unit
      vat
      createdAt
      updatedAt
      slug
      stock
      minimalAmount
      restockDays
      manufacturer {
        id
        order
        name
        description
        image
        createdAt
        updatedAt
      }
      attachments {
        items {
          file
          id
          description
          type
          typeDesctiption
        }
      }
      attributes {
        name
        value
        unit
      }
      category{
        id
        slug
        name
      }
      subCategory{
        id
        slug
        name
      }
      subSubCategory{
        id
        slug
        name
      }
      subSubSubCategory{
        id 
        slug 
        name 
      }
      subSubSubSubCategory{
        id 
        slug 
        name
      }
    }
  }
`;

export const getProductsInCategory = gql`
  query getProductsInCategory($categoryId: ID!) {
    productsInCategory(categoryId: $categoryId) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        stock
        salePrice
        price
        excerpt
        featuredSale
        unit
        vat
      }
    }
  }
`;
