import React from "react";
import {useRouteMatch, Switch, Route, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {useSelector} from "react-redux";
import "./admin.scss";
import Preloader from "../util/Preloader";
import Header from "./Header";
import {Container, Row} from "react-bootstrap";
import Sidebar from "./Sidebar";
import PageNotFound from "../util/PageNotFound";
import Orders from "./orders/Orders";
import SingleOrder from "./orders/SingleOrder";
import SingleUser from "./users/SingleUser";
import Users from "./users/Users";
import ProductImport from "./ProductImport";
import FtpControl from "./FtpControl";
import Manufacturers from "./manufacturers/Manufacturers";
import SingleManufacturer from "./manufacturers/SingleManufacturer";
import SinglePaymentMethod from "./paymentMethods/SinglePaymentMethod";
import PaymentMethods from "./paymentMethods/PaymentMethods";
import SingleShippingMethod from "./shippingMethods/SingleShippingMethod";
import ShippingMethods from "./shippingMethods/ShippingMethods";
import PriceLevels from "./priceLevels/PriceLevels";
import SinglePriceLevel from "./priceLevels/SinglePriceLevel";
import Categories from "./categories/Categories";
import Invoices from "./invoices/Invoices";
import Settings from "./settings/Settings";
import NewsCategories from "./news/Categories";
import SingleCategory from "./news/SingleCategory";
import NewsPosts from "./news/Posts";
import SingleNewsPost from "./news/SinglePost";
import EditorComponent from "./editor/EditorComponent";
import Products from "./products/Products";
import SingleProduct from "./products/SingleProduct";
import SingleProductAttachments from "./products/SingleProductAttachments";
import ProductExport from "./ProductExport";
import Pages from "./pages/Pages";
import SinglePage from "./pages/SinglePage";
import Banners from "./banners/Banners";
import SingleBanner from "./banners/SingleBanner";
import Menus from "./menu/Menus";
import SingleMenu from "./menu/SingleMenu";
import Dashboard from "./Dashboard";
import CsobEchoTest from "./util/CsobEchoTest";
import Sitemap from "./util/Sitemap";
import Popups from "./popups/Popups";
import SinglePopup from "./popups/SinglePopup";

function Admin(props) {
  const { path } = useRouteMatch();
  const currentSession = useSelector(state => state.cognito.currentSession);

  if (currentSession === null){
    return <div className={"adminWrapper container"}><Preloader/></div>;
  }

  if (currentSession === false){
    return <Redirect to={"/login"}/>;
  }

  let isAdmin = false;
  if(currentSession && currentSession.idToken && currentSession.idToken.payload && currentSession.idToken.payload["cognito:groups"]){
    isAdmin = currentSession.idToken.payload["cognito:groups"].indexOf("Admin") !== -1;
  }

  if (!isAdmin){
    return <Redirect to={"/"}/>;
  }

  return (
    <>
      <Helmet titleTemplate={"%s | Admin | Topas Plus"} defaultTitle={"Admin | Topas Plus"}>
        <html className={"admin"}/>
      </Helmet>
      <Header/>
      <Container fluid>
        <Row className={"flex-xl-nowrap"}>
          <Sidebar/>
          <main className={"py-3 col-xl-10 col-md-9"}>
            <Switch>
              {/*TODO: Inner workings*/}
              <Route exact path={path}>
                <Dashboard/>
              </Route>
              <Route path={`${path}/orders/:orderId`}>
                <SingleOrder/>
              </Route>
              <Route path={`${path}/orders`}>
                <Orders/>
              </Route>
              <Route path={`${path}/invoices`}>
                <Invoices/>
              </Route>
              <Route path={`${path}/users/:userId`}>
                <SingleUser/>
              </Route>
              <Route path={`${path}/users`}>
                <Users/>
              </Route>
              <Route path={`${path}/editor3`}>
                <EditorComponent/>
              </Route>
              {/*<Route path={`${path}/:topicId`}>*/}
                {/*<EditorTest/>*/}
              {/*</Route>*/}
              <Route path={`${path}/products/:productId/attachments`}>
                <SingleProductAttachments/>
              </Route>
              <Route path={`${path}/products/:productId`}>
                <SingleProduct/>
              </Route>
              <Route path={`${path}/products`}>
                <Products/>
              </Route>
              <Route path={`${path}/productImport`}>
                <ProductImport/>
              </Route>
              <Route path={`${path}/productExport`}>
                <ProductExport/>
              </Route>
              <Route path={`${path}/categories/:categoryId?/:subCategoryId?/:subSubCategoryId?/:subSubSubCategoryId?/:subSubSubSubCategoryId?`}>
                <Categories/>
              </Route>
              <Route path={`${path}/manufacturers/:manufacturerId`}>
                <SingleManufacturer/>
              </Route>
              <Route path={`${path}/manufacturers`}>
                <Manufacturers/>
              </Route>
              <Route path={`${path}/newsPosts/:postId`}>
                <SingleNewsPost/>
              </Route>
              <Route path={`${path}/newsPosts`}>
                <NewsPosts/>
              </Route>
              <Route path={`${path}/popups/:popupId`}>
                <SinglePopup/>
              </Route>
              <Route path={`${path}/popups`}>
                <Popups/>
              </Route>
              <Route path={`${path}/pages/:pageId`}>
                <SinglePage/>
              </Route>
              <Route path={`${path}/pages`}>
                <Pages/>
              </Route>
              <Route path={`${path}/menus/:menuId`}>
                <SingleMenu/>
              </Route>
              <Route path={`${path}/menus`}>
                <Menus/>
              </Route>
              <Route path={`${path}/banners/:bannerId`}>
                <SingleBanner/>
              </Route>
              <Route path={`${path}/banners`}>
                <Banners/>
              </Route>
              <Route path={`${path}/newsCategories/:categoryId`}>
                <SingleCategory/>
              </Route>
              <Route path={`${path}/newsCategories`}>
                <NewsCategories/>
              </Route>
              <Route path={`${path}/paymentMethods/:paymentMethodId`}>
                <SinglePaymentMethod/>
              </Route>
              <Route path={`${path}/paymentMethods`}>
                <PaymentMethods/>
              </Route>
              <Route path={`${path}/shippingMethods/:shippingMethodId`}>
                <SingleShippingMethod/>
              </Route>
              <Route path={`${path}/shippingMethods`}>
                <ShippingMethods/>
              </Route>
              <Route path={`${path}/priceLevels/:priceLevelId`}>
                <SinglePriceLevel/>
              </Route>
              <Route path={`${path}/priceLevels`}>
                <PriceLevels/>
              </Route>
              <Route path={`${path}/ftp`}>
                <FtpControl/>
              </Route>
              <Route path={`${path}/settings`}>
                <Settings/>
              </Route>
              <Route path={`${path}/csobEchoTest`}>
                <CsobEchoTest/>
              </Route>
              <Route path={`${path}/sitemap`}>
                <Sitemap/>
              </Route>
              <Route path={"*"}>
                <PageNotFound/>
              </Route>
            </Switch>
          </main>
        </Row>
      </Container>
    </>
  );
}

export default Admin;
