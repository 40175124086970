import React, { Component } from 'react';

class LoadingSplash extends Component{
  render(){
    //TODO
    return(
      <div>
        Loading
      </div>
    );
  }
}

export default LoadingSplash;
