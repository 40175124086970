import { createSlice } from '@reduxjs/toolkit'

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    addNotification: (state, action) => {
      let {message, type, code} = action.payload;
      if (state.length){
        const lastNotification = state[state.length - 1];
        if (lastNotification.message === message && lastNotification.type === type && lastNotification.code === code)
          return state;
      }
      state.push({
        message,
        type,
        code
      });

      return state;
    },
    removeNotification: (state, action) => {
      let {index} = action.payload;


      if (index === -1 || !index in state) {
        console.error(`Notification index ${index} is not in the state`);
        return state;
      }

      state.splice(index, 1);
      return state;
    },
  }
});

export const { addNotification, removeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
