import React from "react";
import ProductListing from "../widgets/ProductListing";
import ContactForm from "../widgets/ContactForm";
import StepsWithIcons from "../widgets/StepsWithIcons";
import Banners from "../widgets/Banners";
import {ContactsInfo, ContactsMap, TitleWithIcon} from "../contacts/Contacts";

const domReplacementOptions = {
  // domNode is an object with following attributes:
  // attribs: {key: value}
  // children: [domNode]
  // endIndex: null
  // name: "br"
  // next: Text {type: "text", parent: Element, prev: Element, next: Element, startIndex: null, …}
  // parent: Element {type: "tag", parent: null, prev: null, next: null, startIndex: null, …}
  // prev: Text {type: "text", parent: Element, prev: Element, next: Element, startIndex: null, …}
  // startIndex: null
  // type: "tag"
  replace: domNode => {
    if (domNode.type !== "tag") return;
    // For each attrib we split it by - and capitalize each word after the first, thus getting nice react params, whose values we parse from json
    let parsedAttribs = {};
    try{
      const attribParsed = (elementName, attribName) => {
        switch (elementName){
          case "productlisting":
            switch (attribName){
              case "filter":
                return true;
            }
            break;
        }
        return false;
      };
      Object.keys(domNode.attribs).forEach(name => parsedAttribs[name.split("-").map((word, index) => index ? word.charAt(0).toUpperCase() + word.slice(1) : word).join("")] = attribParsed(domNode.name, name) ? JSON.parse(domNode.attribs[name]) : domNode.attribs[name]);
    }
    catch (e) {
      console.log(e);
    }
    if (domNode.attribs.class){
      if (!parsedAttribs.className) parsedAttribs.className = domNode.attribs.class;
      else parsedAttribs.className += " " + domNode.attribs.class;
    }
    switch (domNode.name) {
      case "productlisting":
        return <ProductListing {...parsedAttribs}/>;
      case "contactform":
        return <ContactForm {...parsedAttribs}/>;
      case "stepswithicons":
        return <StepsWithIcons {...parsedAttribs}/>;
      case "banners":
        return <Banners {...parsedAttribs}/>;
      case "titlewithicon":
        return <TitleWithIcon {...parsedAttribs}/>;
      case "contactsmap":
        return <ContactsMap/>;
      case "contactsinfo":
        return <ContactsInfo {...parsedAttribs}/>;
      default:
        return;
    }
  },
};

export default domReplacementOptions;
