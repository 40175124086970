import React from "react";
import {Pagination as BootstrapPagination, Form, Row, Col} from "react-bootstrap";

function Pagination(props){
  let items = [];
  for (let i = 0; i < props.total / props.limit; i++){
    items.push(
      <BootstrapPagination.Item key={i} active={i === props.currentPage} onClick={() => props.changePage(i)}>
        {i + 1}
      </BootstrapPagination.Item>
    );
  }
  return (
    <Row>
      <Col md={11} className={"overflow-auto"}>
        <BootstrapPagination>
          {items}
        </BootstrapPagination>
      </Col>
      <Col md={1}>
        <Form.Group controlId="ordersPerPage">
          <Form.Control type="number" min={1} max={100} step={1} value={props.limit} onChange={({target}) => props.changePerPage(target.value)} />
        </Form.Group>
      </Col>
    </Row>
  );
}

export default Pagination;
