import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

export const listShippingMethods = gql`
  query ListShippingMethods(
    $filter: ModelShippingMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        fee
        feePercent
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const updateShippingMethodMutation = gql`
  mutation UpdateShippingMethod(
    $input: UpdateShippingMethodInput!
    $condition: ModelShippingMethodConditionInput
  ) {
    updateShippingMethod(input: $input, condition: $condition) {
      id
      name
      description
      fee
      feePercent
      order
      createdAt
      updatedAt
    }
  }
`;

const deleteShippingMethodMutation = gql`
  mutation DeleteShippingMethod(
    $input: DeleteShippingMethodInput!
    $condition: ModelShippingMethodConditionInput
  ) {
    deleteShippingMethod(input: $input, condition: $condition) {
      id
    }
  }
`;

const createShippingMethodMutation = gql`
  mutation CreateShippingMethod(
    $input: CreateShippingMethodInput!
    $condition: ModelShippingMethodConditionInput
  ) {
    createShippingMethod(input: $input, condition: $condition) {
      id
      name
      description
      fee
      feePercent
      order
    }
  }
`;

function ShippingMethodRow({shippingMethod, selected, onSelectChange}){
  const [updateShippingMethod, updateShippingMethodResponse] = useMutation(updateShippingMethodMutation, {
    refetchQueries: [
      'ListShippingMethods'
    ]
  });
  const [deleteShippingMethod, deleteShippingMethodResponse] = useMutation(deleteShippingMethodMutation, {
    refetchQueries: [
      'ListShippingMethods'
    ]
  });
  const [order, updateOrder] = useState(shippingMethod.order);
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat dopravní metodu"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{shippingMethod.id}</td>
      <td>{shippingMethod.name}</td>
      <td>{shippingMethod.fee} + {shippingMethod.feePercent}%</td>
      <td><Form.Control type={"number"} size={"sm"} value={order} onKeyDown={({key}) => key === "Enter" ? updateShippingMethod({variables: {input: {id: shippingMethod.id, order}}}) : null} onChange={({target}) => updateOrder(parseInt(target.value))}/></td>
      <td>{shippingMethod.createdAt}</td>
      <td>
        {updateShippingMethodResponse.loading || deleteShippingMethodResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s dopravní metodou">
            <Link to={"/admin/shippingMethods/" + shippingMethod.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete dopravní metodu: " + shippingMethod.name + "?"} onClick={() => deleteShippingMethod({variables: {input: {id: shippingMethod.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function ShippingMethods(props){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("order");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listShippingMethods, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createShippingMethod, createShippingMethodResponse] = useMutation(createShippingMethodMutation, {
    refetchQueries: [
      'ListShippingMethods'
    ]
  });
  const [selectedShippingMethods, dispatchSelectedShippingMethods] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listShippingMethods.items.length){
          return {}
        }
        data.listShippingMethods.items.forEach(shippingMethod => newState[shippingMethod.id] = shippingMethod);
        return newState;
      case "toggle":
        if(newState[action.shippingMethod.id]){
          delete newState[action.shippingMethod.id];
        }
        else{
          newState[action.shippingMethod.id] = action.shippingMethod;
        }
        return newState;
      case "select":
        newState[action.shippingMethod.id] = action.shippingMethod;
        return newState;
      case "deselect":
        delete newState[action.shippingMethod.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání dopravních metod</div>
    );

    const shippingMethods = [...data.listShippingMethods.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedShippingMethods).length === shippingMethods.length} onChange={() => dispatchSelectedShippingMethods({type: "selectAll"})} aria-label={"Vybrat dopravní metodu"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Poplatek {renderSortDirection("fee")}</th>
            <th>Pořadí {renderSortDirection("order")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {shippingMethods.map(shippingMethod => (
            <ShippingMethodRow key={"shippingMethod_" + shippingMethod.id} onSelectChange={() => dispatchSelectedShippingMethods({type: "toggle", shippingMethod})} selected={!!selectedShippingMethods[shippingMethod.id]} shippingMethod={shippingMethod}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"shippingMethods"}>
      <h3>Dopravní metody</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createShippingMethodResponse.loading} onClick={() => createShippingMethod({variables: {input: {
              name: "Nová metoda",
              fee: 0,
              feePercent: 0,
              order: 0,
              requiresShippingAddress: true,
            }}})}>Vytvořit novou</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default ShippingMethods;
