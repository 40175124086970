import React from 'react';
import "./postExcerpt.scss";
import { gql, useQuery } from '@apollo/client';
import Preloader from "../util/Preloader";
import parse from 'html-react-parser';
import {Link} from "react-router-dom";


const GetPost = gql`
  query GetNewsPost($newsPostId: ID!) {
    getNewsPost(id: $newsPostId) {
      id
      categoryId
      slug
      name
      content
      createdAt
      updatedAt
      category {
        id
        slug
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

function PostExcerpt(props){
  const { loading, error, data } = useQuery(GetPost, {
    variables:{
      newsPostId: props.newsPostId,
    }
  });
  if(loading && !data){
    return (
      <article className={"postExcerpt"}>
        <Preloader/>
      </article>
    )
  }

  if(error || !data.getNewsPost){
    return (
      <article className={"postExcerpt"}>
        <strong className={"error"}>Nastala chyba při načítání novinky</strong>
      </article>
    )
  }

  const newsPost = data.getNewsPost;

  return (
    <Link to={"/novinky/" + newsPost.category.slug + "/" + newsPost.slug}>
      <article className={"postExcerpt"}>

          <h3>{newsPost.name}</h3>
          <i className="createdAt">{newsPost.createdAt}</i>
          <div className="excerpt">{(newsPost.excerpt && parse(newsPost.excerpt)) || (newsPost.content && parse(newsPost.content))}</div>

      </article>
    </Link>
  )
}

export default PostExcerpt;
