import React from 'react';

import "./eshopCategoryDescription.scss";
import {useRouteMatch} from "react-router-dom";
import {Helmet} from "react-helmet-async";

function EshopCategoryDescription2({categories}) {
  const match = useRouteMatch();
  const filter = match?.params?.filter;
  const splitFilter = filter ? filter.split("/") : [];
  let categorySlugs = [];
  let fulltext;
  splitFilter.forEach(filterParam => {
    if (filterParam.indexOf(":") === -1){
      categorySlugs.push(filterParam);
    }
    const [paramName, paramValue] = filterParam.split(":");
    if (paramName === "fulltext"){
      fulltext = paramValue;
    }
  })

  let actualCategory = null;
  let currentSelection = categories;
  for (let i = 0; i < categorySlugs.length; i++) {
    for (let j = 0; j < currentSelection.length; j++) {
      if (currentSelection[j].slug === categorySlugs[i]){
        actualCategory = currentSelection[j];
        currentSelection = actualCategory?.children?.items || [];
      }
    }
  }

  if (!actualCategory){
    return (
      <div className="categoryDescription">
        <h1 className='eshopTitle'>Eshop {fulltext ? (
          <span>vyhledávání: <strong>{fulltext}</strong></span>
        ) : null }</h1>
        <div>
          {fulltext ? null : <span>Vyberte si z kategorie zboží, které hledáte nebo jednoduše filtrujte zboží, které máme skladem.</span>}
        </div>
      </div>
    )
  }

  return (
    <div className="categoryDescription">
      <Helmet>
        <title>{actualCategory.name}</title>
        {actualCategory.description ? <meta name="description" content={actualCategory.description}/> : null}
      </Helmet>
      <h1>{actualCategory.name}</h1>
      <div>
        {actualCategory.description}
      </div>
    </div>
  )
}

export default EshopCategoryDescription2;
