import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";

const getManufacturer = gql`
  query GetManufacturer($id: ID!) {
    getManufacturer(id: $id) {
      id
      order
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;

const updateManufacturerMutation = gql`
  mutation UpdateManufacturer(
    $input: UpdateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    updateManufacturer(input: $input, condition: $condition) {
      id
      order
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;

function ManufacturerEditForm({initialManufacturer, saveChanges}){
  const parseInitialManufacturer = () => JSON.parse(JSON.stringify(initialManufacturer), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [manufacturer, setManufacturer] = useState(parseInitialManufacturer());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newManufacturer = JSON.parse(JSON.stringify(manufacturer));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newManufacturer[splitPath[0]] = value;
        break;
      case 2:
        newManufacturer[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newManufacturer[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setManufacturer(newManufacturer);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={manufacturer.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="order">
            <Form.Label>Pořadí</Form.Label>
            <Form.Control type="number" placeholder="není" value={manufacturer.order} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"description"}>
            <Form.Label>Popis</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={manufacturer.description} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="image">
            <Form.Label>Obrázek</Form.Label>
            <Form.Control type="text" placeholder="není" value={manufacturer.image} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Náhled</Form.Label>
            <p>
              <img src={manufacturer.image} alt={manufacturer.name}/>
            </p>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setManufacturer(parseInitialManufacturer())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...manufacturer, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleManufacturer(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getManufacturer, {
    variables: {
      id: match.params.manufacturerId,
    }
  });
  const [updateManufacturer, updateManufacturerResponse] = useMutation(updateManufacturerMutation);
  if (error) return (
    <article className={"singleManufacturer"}><div className={"danger"}>Chyba při načítání výrobce</div></article>
  ) ;

  if (loading || updateManufacturerResponse.loading) return (
    <article className={"singleManufacturer"}><Preloader/></article>
  ) ;

  const manufacturer = data.getManufacturer;

  return (
    <article className={"singleManufacturer"}>
      <h3>Výrobce: {match.params.manufacturerId}</h3>
      <hr/>
      <ManufacturerEditForm initialManufacturer={manufacturer} saveChanges={(changedManufacturer) => updateManufacturer({
        variables:{
          input: changedManufacturer,
        }
      })}/>
    </article>
  );
}

export default SingleManufacturer;
