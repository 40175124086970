import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import chunk from "lodash/chunk";

import {
  GetEshopProduct,
  SearchEshopProducts,
} from "./EshopCategoryProductsAPI";
import { addProduct } from "../app/cartReducer";
import { addNotification } from "../app/notificationsReducer";
import { setFilterAmount, setPreference } from "../app/userPreferencesReducer";

import Icons from "../util/Icons";
import Preloader from "../util/Preloader";
import {ConvertImageUriFromS3ToCloudFront} from "../helpers";

export function EshopProduct(props) {
  const [buyAmount, setBuyAmount] = useState(1);
  const [showAttributes, setShowAttributes] = useState(false);

  const MAX_LENGTH = 70;
  const productDetailPath = "/eshop/product/:productId";

  const { data, loading, error } = useQuery(GetEshopProduct, {
    variables: {
      productId: props.product.id,
    },
    onCompleted(data) {
      console.log(data);
      if (!data?.getProduct){
        console.error("NULL DATA onCompleted");
      }
      //Peti, tohle tu má být. Je potřeba přidávat některé trubky i po 6 nebo kolika metrech, dle toho, jak se dodávají.
      setBuyAmount(data.getProduct?.minimalAmount || 1);
    },
    onError(error) {
      console.log(error);
    },
  });

  const dispatch = useDispatch();
  const isGridView = useSelector(
    (state) => state.userPreferences.eshop.productDisplay === "col"
  );
  const restockDays = useSelector(state => state.pageSettings.NaskladneniDo);

  useEffect(() => {
    if (isGridView) {
      setShowAttributes(false);
    }
  }, [isGridView]);

  if(error){
    return (
      <article className="col product">
        <span className="error">Nastala chyba při načítání produktu</span>
      </article>
    )
  }

  if (loading) {
    return (
      <article className="col product">
        <Preloader />
      </article>
    );
  }

  // console.log(data);

  const renderParametersTable = () => {
    const chunked = chunk(data?.getProduct?.attributes || [], 4);
    let toReturn = [];
    chunked.forEach((attributesChunk, index) => {
      let chunkShort = 4 - attributesChunk.length;
      for (let i = 0; i < chunkShort; i++) {
        attributesChunk.push({ name: null, value: null, unit: null });
      }
      toReturn.push(
        <div className="table-content-item">
          {attributesChunk.map((attribute) => (
            <div className="header">{attribute.name}</div>
          ))}
        </div>
      );
      toReturn.push(
        <div
          className={
            "table-content-item " +
            (index + 1 === chunked.length ? "last-item" : "")
          }
        >
          {attributesChunk.map((attribute) => (
            <div className="value">
              {attribute.value} {attribute.unit}
            </div>
          ))}
        </div>
      );
    });
    return (
      <div className="table-responsive product-parameters mb-25">
        {toReturn}
      </div>
    );
  };

  const handleInputBuyAmount = (amount) => {
    if (buyAmount + amount <= 0) return;
    setBuyAmount(buyAmount + amount);
  };

  const shortenProductName = (name) => {
    if (!name){
      return null;
    }
    if (name.length <= MAX_LENGTH){
      return name;
    }
    const subName = name.substring(0, MAX_LENGTH - 3)
    const separators = [".", ",", " "];
    let lastSeparator = -1;
    separators.forEach(separator => {
      const lastIndex = subName.lastIndexOf(separator);
      if (lastIndex > lastSeparator){
        lastSeparator = lastIndex;
      }
    });
    return <span title={name}>{name.substring(0, lastSeparator) + "..."}</span>;
  }

  if (!data?.getProduct){
    return <article className="col product">NULL PRODUCT DATA</article>
  }

  return (
    <>
      <article className="col product">
        <div className="productImage">
          <Link to={"/eshop/product/" + data.getProduct.slug} className={"image"}>
            {renderMainImage()}
          </Link>
        </div>
        <div className="productDescription">
          <hr className="transparentBlack mb-10" />
          <Link to={"/eshop/product/" + data.getProduct.slug} className="name">
            {shortenProductName(data.getProduct.name)}
          </Link>
          <div className="excerpt">
            {data.getProduct.excerpt || data.getProduct.description}
          </div>
          {!isGridView && props?.match?.path !== productDetailPath && (
            <div className="viewFlexBtn">
              <button
                className={"button small blue"}
                onClick={() => setShowAttributes(!showAttributes)}
              >
                Parametry
              </button>
              <Link
                className="button small blue"
                to={"/eshop/product/" + data.getProduct.slug}
              >
                Detail
              </Link>
            </div>
          )}
        </div>
        {((data?.getProduct?.isRental || data?.getProduct?.linksTo) ? (
            <div className="price">
              <div className="amount">
                {data?.getProduct?.dailyRentalFee}
                {data?.getProduct?.dailyRentalFee ? <span>Kč za den</span> : <span>&nbsp;</span>}
              </div>
            </div>
        ) : (
          <div className={"price"}>
            {data.getProduct.salePrice ? (
              <div className="amount-pre-sale">
                {data.getProduct.price.toFixed(2)} <span>Kč</span>
              </div>
            ) : null}
            <div className={"amount"}>
              {(data.getProduct.salePrice > 1000
                ? (Math.round(data.getProduct.salePrice * 100) / 100).toFixed(2)
                : (Math.round(data.getProduct.salePrice * 100) / 100).toFixed(
                  2
                )) || data.getProduct.price}
              <span>Kč</span>
            </div>
          </div>
        ))}
        <div className="productBuyStockInfo">
          {(data.getProduct.linksTo || data.getProduct.isInstallation) ? null : (
            <div className={"stockInfo " + ( isGridView || props?.match?.path === productDetailPath ? " last " : "")}>
              {data.getProduct.stock > 0 ? (
                <span className="onStock">{`${data.getProduct.stock} ${data.getProduct.unit} skladem`}</span>
              ) : (
                <span className="offStock">Dodání do {data.getProduct.restockDays || restockDays} dnů</span>
              )}
            </div>
          )}
          <div className="buyInfo">
            {/*!isGridView && props?.match?.path !== productDetailPath && */
              (data?.getProduct?.isRental || data?.getProduct?.isInstallation || data?.getProduct?.linksTo) ? null : (
              <div className={"input_number mr-10 buyAmount"}>
                <span>
                  {buyAmount} <span>{data?.getProduct?.unit || "ks"}</span>
                </span>
                <div className={"spin_buttons"}>
                  <span
                    className="spin_top"
                    onClick={() => handleInputBuyAmount(data?.getProduct?.minimalAmount || 1)}
                  ></span>
                  <span
                    className="spin_bottom"
                    onClick={() => handleInputBuyAmount(-1 * (data?.getProduct?.minimalAmount || 1))}
                  ></span>
                </div>
              </div>
            )}
            {data?.getProduct?.linksTo ? (
              <Link
                to={data?.getProduct?.linksTo}
                className={
                  ((isGridView || props?.match?.path === productDetailPath)
                    ? "button medium gray m-10 buyButton full-width"
                    : "button medium blue  buyButton")
                }
              >
                <strong>Více</strong>
              </Link>
            ) : (data?.getProduct?.isRental ? (
              <Link
                to={"/eshop/product/" + data.getProduct.slug}
                className={
                  ((isGridView || props?.match?.path === productDetailPath)
                    ? "button medium gray m-10 buyButton full-width"
                    : "button medium blue  buyButton")
                }
              >
                <strong>Půjčit</strong>
              </Link>
            ) : (data?.getProduct?.isInstallation) ? (
              <Link
                to={"/eshop/product/" + data.getProduct.slug}
                className={
                  ((isGridView || props?.match?.path === productDetailPath)
                    ? "button medium gray m-10 buyButton full-width"
                    : "button medium blue  buyButton")
                }
              >
                <strong>Poptat</strong>
              </Link>
            ) : (
              <div
                onClick={() => {
                  // Check if we go over stock
                  let inCart = null;
                  for (let i = 0; i < props.cart.length; i++) {
                    if (props.cart[i].productId === data?.getProduct?.id){
                      inCart = props.cart[i];
                    }
                  }
                  if (inCart){
                    const futureAmount = inCart.amount + buyAmount;
                    if ((futureAmount > data?.getProduct?.stock) && data?.getProduct?.stock ){
                      dispatch(
                        addNotification({
                          message: `V košíku máte více ${data?.getProduct?.unit} produktu "${data?.getProduct?.name}", než je okamžitě dostupných. Produkt dorazí do ${data?.getProduct?.restockDays || restockDays} dnů`,
                          type: "warning",
                          code: "frontend.addCartProduct",
                        })
                      );
                    }
                  }

                  dispatch(
                    addProduct({
                      productId: data.getProduct.id,
                      amount: buyAmount,
                    })
                  );
                  dispatch(
                    addNotification({
                      message: `${buyAmount}x Přídán produkt ${data.getProduct.name}`,
                      type: "info",
                      code: "frontend.addCartProduct",
                    })
                  );
                }}
                className={
                  ((isGridView || props?.match?.path === productDetailPath)
                    ? "button medium gray m-10 buyButton"
                    : "button medium blue  buyButton") +
                  (data.getProduct.notForSale ? " hidden " : "")
                }
              >
                <img
                  alt={"Košík"}
                  className={"mr-5 icon cart"}
                  src={Icons.getIcon(
                    isGridView || props?.match?.path === productDetailPath
                      ? "cart"
                      : "cartWhite"
                  )}
                />
                <strong className={"ml-5"}>Koupit</strong>
              </div>
            ))}
          </div>
        </div>
      </article>
      {showAttributes && renderParametersTable()}
    </>
  );

  function renderMainImage() {
    const acceptedMainImageTypes = ["OV"];
    for (let i = 0; i < data.getProduct.attachments.items.length; i++) {
      let attachment = data.getProduct.attachments.items[i];
      if (acceptedMainImageTypes.indexOf(attachment.type) !== -1) {
        return <img src={ConvertImageUriFromS3ToCloudFront(attachment.file)} alt={attachment.description} />;
      }
    }
    return (
      <img
        src={"/staticImages/placeholders/product.png"}
        alt={data.getProduct.name}
      />
    );
  }
}

const EshopCategoryProducts = (props) => {
  const dispatch = useDispatch();
  const sortState = useSelector(
    (state) => state.userPreferences["eshop.filter"].sort
  );
  const filterState = useSelector(
    (state) => state.userPreferences["eshop.filter"].filter
  );
  const userPreferencesState = useSelector(
    (state) => state.userPreferences["eshop.filter"]
  );
  const isGridView = useSelector(
    (state) => state.userPreferences["eshop.filter"].view
  );

  const [pagination, setPagination] = useState({
    pageCount: 0,
    pageRangeDisplayed: 3,
    productDisplayLimit: 9,
    selected: 0,
  });
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    setPagination((pagination) => ({
      ...pagination,
      selected: 0,
    }));
  }, [pagination.pageCount]);

  const filter = {};
  const productDetailPath = "/eshop/product/:productId";
  Object.keys(filterState).forEach((key) => {
    if (filterState[key] !== null) {
      filter[key] = filterState[key];
    }
  });

  const {
    loading: categoryProductsLoading,
    error: categoryProductsError,
    data: categoryProductsData,
  } = useQuery(SearchEshopProducts, {
    variables: {
      filter: {
        ...filter,
        isHidden: {ne: true}
      },
      from: pagination.selected * pagination.productDisplayLimit,
      limit: pagination.productDisplayLimit,
      sort: sortState,
    },
    onCompleted(data) {
      console.log(data);
      setPagination((pagination) => ({
        ...pagination,
        pageCount:
          categoryProductsData.searchProducts.total /
          pagination.productDisplayLimit,
      }));
      dispatch(
        setFilterAmount({
          name: "eshop.filterResultAmount",
          value: categoryProductsData.searchProducts.total,
        })
      );

      setCategoryProducts(categoryProductsData.searchProducts);
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    dispatch(
      setPreference({
        name: "eshop.filter",
        value: {
          ...userPreferencesState,
          filter: {
            ...userPreferencesState.filter,
            or: [],
          },
        },
      })
    );
  }, [
    userPreferencesState.filter.categoryId,
    userPreferencesState.filter.subCategoryId,
    userPreferencesState.filter.subSubcategoryId,
    userPreferencesState.filter.subSubSubcategoryId,
  ]);
  useEffect(() => {
    if (
      categoryProductsData?.searchProducts?.aggregations.uniqueManufacturers.includes(
        filter.or
      ) === false &&
      categoryProductsData?.searchProducts?.aggregations.uniqueManufacturers
        .length > filter.or.length
    )
      dispatch(
        setPreference({
          name: "eshop.filter",
          value: {
            ...userPreferencesState,
            uniqManufacturer:
              categoryProductsData?.searchProducts?.aggregations
                .uniqueManufacturers,
          },
        })
      );
  }, [categoryProductsData?.searchProducts?.aggregations.uniqueManufacturers]);

  if (categoryProductsLoading && categoryProducts.length === 0) {
    return <Preloader />;
  }
  return (
    <>
      <div className={(isGridView || props?.match?.path === productDetailPath) ? "eshopProductGrid" : "eshopProductFlex"}>
        {categoryProducts?.items?.map((product, i) => (
          <EshopProduct product={product} key={product.id} {...props} />
        ))}
      </div>
      {categoryProducts?.items?.length > 0 && (
        <div className="categoryPagination">
          <ReactPaginate
            pageCount={categoryProducts.total / pagination.productDisplayLimit}
            previousLabel="Předchozí"
            nextLabel="Další"
            pageRangeDisplayed={3}
            forcePage={pagination.selected}
            onPageChange={(e) => {
              setPagination((pagination) => ({
                ...pagination,
                selected: e.selected,
              }));
            }}
          />
        </div>
      )}
    </>
  );
};

export default EshopCategoryProducts;
