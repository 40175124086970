import React from "react";

function ConfirmButton(props){
  function confirmOnClick(e){
    const confirmMessage = props.confirmMessage || "Skutečně chcete provést tuto akci?";
    if (window.confirm(confirmMessage)){
      props.onClick(e);
    }
  }

  const copyProps = {...props, onClick: confirmOnClick};

  return(
    <button {...copyProps}/>
  )
}

export default ConfirmButton;
