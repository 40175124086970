import React, {useState} from "react";
import {Form, Button} from "react-bootstrap";
import {gql, useLazyQuery} from '@apollo/client';

const adminBatchDownloadInvoicesQuery = gql`
  query AdminBatchDownloadInvoices(
    $from: AWSDateTime
    $to: AWSDateTime
  ){
      adminBatchDownloadInvoices(
          from: $from
          to: $to
      )
  }
`;

function Invoices(){
  const [fromDate, setFromDate] = useState(new Date( new Date().setHours(0, 0, 0, 0)));
  const [toDate, setToDate] = useState(new Date(new Date().setHours(23, 59, 59, 999)));
  const [getInvoicesLink, {data: invoicesLink, error: invoicesLinkError, loading: invoicesLinkLoading}] = useLazyQuery(adminBatchDownloadInvoicesQuery);
  return (
    <div className={"invoices"}>
      <h3>Hromadné stahování faktur</h3>
      <hr/>
      <Form inline>
        <Form.Group className={"mb-3 w-100"}>
          <Form.Label className={"mr-3"}>Od</Form.Label>
          <Form.Control min={0} step={1} type={"number"} value={fromDate.getDate()} onChange={({target}) => {
            let newDate = new Date(fromDate);
            newDate.setDate(parseInt(target.value));
            setFromDate(newDate);
          }}/>
          .
          <Form.Control min={0} step={1} type={"number"} value={fromDate.getMonth() + 1} onChange={({target}) => {
            let newDate = new Date(fromDate);
            newDate.setMonth(parseInt(target.value) - 1);
            setFromDate(newDate);
          }}/>
          .
          <Form.Control min={1900} step={1} type={"number"} value={fromDate.getFullYear()} onChange={({target}) => {
            let newDate = new Date(fromDate);
            newDate.setFullYear(parseInt(target.value));
            setFromDate(newDate);
          }}/>
        </Form.Group>
        <Form.Group className={"mb-3 w-100"}>
          <Form.Label className={"mr-3"}>Do</Form.Label>
          <Form.Control min={0} step={1} type={"number"} value={toDate.getDate()} onChange={({target}) => {
            let newDate = new Date(toDate);
            newDate.setDate(parseInt(target.value));
            setToDate(newDate);
          }}/>
          .
          <Form.Control min={0} step={1} type={"number"} value={toDate.getMonth() + 1} onChange={({target}) => {
            let newDate = new Date(toDate);
            newDate.setMonth(parseInt(target.value) - 1);
            setToDate(newDate);
          }}/>
          .
          <Form.Control min={1900} step={1} type={"number"} value={toDate.getFullYear()} onChange={({target}) => {
            let newDate = new Date(toDate);
            newDate.setFullYear(parseInt(target.value));
            setToDate(newDate);
          }}/>
        </Form.Group>
        <Form.Group>
          <div className={"color-danger"}>
            {invoicesLinkError?.message}
          </div>
        </Form.Group>
        <Form.Group className={"ml-auto"}>
          <div className={"btn-group"}>
            <Button disabled={invoicesLink || invoicesLinkLoading} variant={"success"} onClick={() => getInvoicesLink({
              variables: {
                from: fromDate.toISOString(),
                to: toDate.toISOString(),
              }
            })}>Připravit</Button>
            <Button disabled={!invoicesLink || invoicesLinkError} onClick={() => window.open(invoicesLink.adminBatchDownloadInvoices, "_blank")} variant={"primary"}>Stáhnout</Button>
          </div>

        </Form.Group>
      </Form>
    </div>
  );
}

export default Invoices;
