
import React, { useState, useEffect } from "react";
import {connect, useSelector} from "react-redux";
import { gql, useMutation, useQuery } from "@apollo/client";
import Auth from "@aws-amplify/auth";
import { Link, Redirect } from "react-router-dom";

import {
  emptyCart,
  removeProduct,
  changeProductAmount,
} from "../app/cartReducer";
import { setPreference } from "../app/userPreferencesReducer";
import Preloader from "../util/Preloader";
import Icons from "../util/Icons";
import { addNotification } from "../app/notificationsReducer";

import * as Validator from "validatorjs";
import * as ValidatorErrors from "validatorjs/src/errors"

import "./cart.scss";
import {searchProducts} from "../graphql/queries";

const rules = {
  "fields": "required|array|min:1",
  "fields.*.productId": `required|uuid`,
  //"fields.*.name": "required|string",
  "fields.*.amount": "required|integer|min:1",
  //"fields.*.unit": "required|string",
  //"fields.*.price": "required|numeric",
  //"fields.*.priceIncludingTax": "required|numeric",
  "shippingMethodId": `required|uuid`,
  "paymentMethodId": `required|uuid`,
  "invoiceAddress": "required",
  "invoiceAddress.companyName": "string",
  "invoiceAddress.companyId": "string",
  "invoiceAddress.companyTaxId": "string",
  "invoiceAddress.name": "required|string",
  "shippingAddress": "required",
  "shippingAddress.companyName": "string",
  "shippingAddress.name": "required|string",
  "shippingAddress.telephone": "required|telephone",
  "shippingAddress.email": "required|email",
  "note": "string",
};

const rulesForShipping = {
  "shippingAddress.street": "required|string",
  "shippingAddress.city": "required|string",
  "shippingAddress.zip": "required|string",
  "invoiceAddress.street": "required|string",
  "invoiceAddress.city": "required|string",
  "invoiceAddress.zip": "required|string",
};

const GetCartData = gql`
  query GetCartData($filter: SearchableProductFilterInput) {
    listShippingMethods {
      items {
        id
        name
        description
        fee
        feePercent
        order
        requiresShippingAddress
        slug
        minAllowedCartPrice
        maxAllowedCartPrice
        allowedPaymentConnection{
          items{
            id
            paymentMethodId
          }
        }
      }
    }
    listPaymentMethods {
      items {
        id
        name
        description
        fee
        feePercent
        order
        handler
        minAllowedCartPrice
        maxAllowedCartPrice
        allowedShipmentConnection{
          items{
            id
            shippingMethodId
          }
        }
      }
    }
    searchProducts(filter: $filter) {
      items {
        id
        price
        vat
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        name
        manufacturerProductCode
        unit
        minimalAmount
        stock
        restockDays
        forbiddenShippingSlugs
      }
    }
  }
`;
const CreateNewOrder = gql`
  mutation CreateNewOrder($input: CreateNewOrderInput!) {
    createNewOrder(input: $input) {
      id
      userName
      redirectUrl
    }
  }
`;

function shippingCost(userPreferences, shippingMethods, productCost) {
  let shippingMethod = shippingMethods.find(
    (method) => method.id === userPreferences["cart.shippingMethod"]
  );
  if (!shippingMethod) return 0;
  return shippingMethod.fee + (shippingMethod.feePercent / 100) * productCost;
}
function paymentCost(userPreferences, paymentMethods, productCost) {
  let paymentMethod = paymentMethods.find(
    (method) => method.id === userPreferences["cart.paymentMethod"]
  );

  if (!paymentMethod) return 0;

  return paymentMethod.fee + (paymentMethod.feePercent / 100) * productCost;
}

function cartProductCost(props, products, includeVat = false){
  let cost = 0;
  if (!props.cart || !props.cart.length) return cost;
  props.cart.forEach((item) => {
    const product = products.find((product) => product.id === item.productId);
    if (product){
      const productPrice = product.salePrice ? product.salePrice : product.price;
      cost += item.amount * productPrice * (includeVat ? (1 + product.vat / 100) : 1);
    }
  });
  return cost;
}

function cartTotalCost(props, products, shippingMethods, paymentMethods, includeVat = false) {
  if (!props.cart || !props.cart.length) return "0,00";
  let productCost = cartProductCost(props, products, includeVat);
  let cost = productCost;
  cost += shippingCost(props.userPreferences, shippingMethods, productCost);
  cost += paymentCost(props.userPreferences, paymentMethods, productCost);
  return (Math.round(cost * 100) / 100).toFixed(2);
}
function handleInputNumber(props, productId, step) {
  // console.log({props, productId, step});
  props.dispatch(changeProductAmount({ productId: productId, amount: step }));
}

function Cart(props) {
  const [suppressEmptyRedirect, setSuppressEmptyRedirect] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [companyId, setCompanyId] = useState();
  const [companyTaxId, setCompanyTaxId] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [shippingCompanyName, setShippingCompanyName] = useState();
  const [shippingName, setShippingName] = useState();
  const [telephonePrefix, setTelephonePrefix] = useState("+420");
  const [telephone, setTelephone] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [zip, setZip] = useState();
  const [note, setNote] = useState();
  const [orderData, setOrderData] = useState({ cartList: [] });
  const [validationErrors, setValidationErrors] = useState(new ValidatorErrors());
  const [includeVatInPrices, setIncludeVatInPrices] = useState(true);
  const restockDays = useSelector(state => state.pageSettings.NaskladneniDo);
  let attributes = [];
  //console.log(validationErrors);
  useEffect(() => {
    if (props.cognito?.currentUserInfo) {
      autoFillFromCognito(props.cognito.currentUserInfo.attributes);
      setIncludeVatInPrices(props.cognito.currentUserInfo.attributes["custom:pricesInclVat"] === "1");
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          autoFillFromCognito(user.attributes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.cognito]);
  useEffect(() => {
    console.log("Validation errors changed: ", validationErrors);
    const errors = document.getElementsByClassName("error");
    if (!errors || errors.length < 1){
      return;
    }
    document.scrollingElement.scrollBy(0, errors[0].getBoundingClientRect().top - 150);
  }, [validationErrors])

  function autoFillFromCognito(attributes) {
    setCompanyName(attributes["custom:companyName"]);
    setCompanyId(attributes["custom:companyId"]);
    setCompanyTaxId(attributes["custom:companyTaxId"]);
    setEmail(attributes["email"]);
    setName(attributes["name"]);
    setShippingCompanyName(attributes["custom:companyName"]);
    setShippingName(attributes["name"]);
    setTelephone(attributes["phone_number"]?.substr(4));
    setTelephonePrefix(attributes["phone_number"]?.substr(0, 4) || "+420")
    setStreet(attributes["custom:street"]);
    setCity(attributes["custom:city"]);
    setZip(attributes["custom:zip"]);
  }

  function renderCartContentsMobile(props, products){
    return props.cart.map((cartItem, index) => {
      let product = products.find(
        (product) => product.id === cartItem.productId
      );
      if (!product) return null;
      return (
        <div className={"row mobileCartProduct gutter-10px"} key={cartItem.productId}>
          <div className="col w-50p">
            <div className="row name">
              {product.name}
            </div>
            <div className="row id">
              {product.manufacturerProductCode}
            </div>
          </div>
          <div className="col  w-50p">
            <div className="row quantity">
              <div className="col">
                <div className={"input_number mr-10"}>
              <span>
                {cartItem.amount} <span>{product.unit}</span>
              </span>
                  <div className={"spin_buttons"}>
              <span
                className="spin_top"
                onClick={() => handleInputNumber(props, product.id, product.minimalAmount || 1)}
              />
                    <span
                      className="spin_bottom"
                      onClick={() => handleInputNumber(props, product.id, -1 * (product.minimalAmount || 1))}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  onClick={() =>
                    props.dispatch(removeProduct({ productId: product.id }))
                  }
                  className="button small gray trashCan"
                />
              </div>
            </div>
            {(cartItem.amount > product.stock) ? (
              <div className="row quantityWarning">
                V tomto množství je produkt dostupný do {product.restockDays || restockDays} dnů
              </div>
            ) : null}
            <div className="row itemprice">
              <span>
                {product.salePrice
                  ? product.salePrice.toFixed(2)
                  : product.price.toFixed(2)}
              </span>{" "}
              <span> Kč / {product.unit}</span>
            </div>
            <div className="row totalprice">
              <span>
                {(
                  (product.salePrice ? product.salePrice : product.price) *
                  cartItem.amount
                ).toFixed(2)}
              </span>{" "}
              <span> Kč</span>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderCartContents(props, products) {

    return props.cart.map((cartItem, index) => {
      let product = products.find(
        (product) => product.id === cartItem.productId
      );

      // products that are not available in eshop anymore (old imports, deleted products)
      if (!product) return null;

      return (
        <div
          className={"table-content-item"}
          key={cartItem.productId}
        >
          <div className="id">{product.manufacturerProductCode}</div>
          <div className="name">{product.name}</div>
          <div className="quantityWrapper">
            <div className="quantity">
              <div className={"input_number mr-10"}>
              <span>
                {cartItem.amount} <span>{product.unit}</span>
              </span>
                <div className={"spin_buttons"}>
                <span
                  className="spin_top"
                  onClick={() => handleInputNumber(props, product.id, (product.minimalAmount || 1))}
                />
                  <span
                    className="spin_bottom"
                    onClick={() => handleInputNumber(props, product.id, -1 * (product.minimalAmount || 1))}
                  />
                </div>
              </div>
              <div
                onClick={() =>
                  props.dispatch(removeProduct({ productId: product.id }))
                }
                className="button small gray trashCan"
              />
            </div>
            {(cartItem.amount > product.stock) ? (
              <div className="quantityWarning">
                V tomto množství je produkt dostupný do {product.restockDays || restockDays} dnů
              </div>
            ) : null}
          </div>
          <div className="itemprice">
            <span>
              {product.salePrice
                ? product.salePrice.toFixed(2)
                : product.price.toFixed(2)}
            </span>{" "}
            <span> Kč</span>
          </div>
          <div className="totalprice">
            <span>
              {(
                (product.salePrice ? product.salePrice : product.price) *
                cartItem.amount
              ).toFixed(2)}
            </span>{" "}
            <span> Kč</span>
          </div>
        </div>
      );
    });
  }

  const [
    createNewOrder,
    {
      data: createNewOrderData,
      loading: createNewOrderLoading,
      error: createNewOrderError,
    },
  ] = useMutation(CreateNewOrder, {
    onCompleted(data) {
      setSuppressEmptyRedirect(true);
      props.dispatch(emptyCart());
      props.dispatch(setPreference({
        name: "cart.shippingMethod",
        value: null,
      }));
      props.dispatch(setPreference({
        name: "cart.paymentMethod",
        value: null,
      }));
    },
    onError(error) {
      props.dispatch(
        addNotification({
          message: "Vyplňte prosím všechny údaje!",
          type: "error",
          code: error.code,
        })
      );
      console.log(error);
    },
  });
  function submitOrder() {
    //if no agreedToTerms return error
    if (!props.userPreferences.agreedToTerms) {
      props.dispatch(
        addNotification({
          message: "Pro odeslání objednávky musíte souhlasit s obchodními podmínkami",
          type: "error",
          code: `frontend.agreedToTerms`,
        })
      );
      return;
    }

    const products = [];
    props.cart.forEach((cartItem, index) => {
      products.push(cartItem);
    });

    const invoiceAddress = {
      companyName: companyName || null,
      companyId: companyId || null,
      companyTaxId: companyTaxId || null,
      name: name,
      street: attributes["custom:street"] || street,
      city: attributes["custom:city"] || city,
      zip: attributes["custom:zip"] || zip,
    };

    let shippingAddress = {
      city: city,
      companyName: shippingCompanyName || null,
      name: shippingName,
      street: street,
      zip: zip,
      telephone: telephonePrefix + telephone,
      //+91 704-011-2342
      email: email,
    };

    if (methodDisabledByPrice(getCurrentPaymentMethod())){
      props.dispatch(
        addNotification({
          message: "Pro odeslání objednávky musíte zvolit povolenou platební metodu",
          type: "error",
          code: `frontend.paymentMethodInvalid`,
        })
      );
      return;
    }
    if (methodDisabledByPrice(getCurrentShippingMethod())){
      props.dispatch(
        addNotification({
          message: "Pro odeslání objednávky musíte zvolit povolenou metodu dopravy",
          type: "error",
          code: `frontend.shippingMethodInvalid`,
        })
      );
      return;
    }
    if (methodDisabledByProduct(getCurrentShippingMethod())){
      props.dispatch(
        addNotification({
          message: "Pro odeslání objednávky musíte zvolit povolenou metodu dopravy",
          type: "error",
          code: `frontend.shippingMethodInvalid`,
        })
      );
      return;
    }

    const requiresShippingAddress = getCurrentShippingMethod()?.requiresShippingAddress;

    if (!requiresShippingAddress){
      // shippingAddress = null; //TODO: change validation etc. so that we do not have those dashes here
      shippingAddress.name = name;
      shippingAddress.street = "";
      shippingAddress.city = "";
      shippingAddress.zip = "";

      invoiceAddress.street = invoiceAddress.street || "";
      invoiceAddress.city = invoiceAddress.city || "";
      invoiceAddress.zip = invoiceAddress.zip || "";
    }

    const variables = {
      input: {
        fields: products,
        paymentMethodId: props.userPreferences["cart.paymentMethod"],
        shippingMethodId: props.userPreferences["cart.shippingMethod"],
        invoiceAddress: invoiceAddress,
        shippingAddress: shippingAddress,
        note,
      },
    };

    let validation = new Validator(variables.input, rules);
    let shippingValidation = new Validator(variables.input, rulesForShipping);
    let fails = validation.fails();
    if (requiresShippingAddress){
      fails = shippingValidation.fails() || fails;
    }
    if (fails){
      let errors = validation.errors;
      let shippingErrors = shippingValidation.errors.all();
      let shippingErrorKeys = Object.keys(shippingErrors);
      shippingErrorKeys.forEach(shippingErrorKey => {
        shippingValidation.errors.get(shippingErrorKey).forEach(shippingErrorValue => {
          errors.add(shippingErrorKey, shippingErrorValue);
        })
      });
      setValidationErrors(errors);
      return;
    }
    else{
      setValidationErrors(new ValidatorErrors());
    }

    createNewOrder({ variables: variables });
    if (!createNewOrderError) {
      setOrderData(variables);
    }
  }

  let productsToSearch = [];
  props.cart.forEach((cartItem, i) => {
    productsToSearch.push({
      id: {
        eq: cartItem.productId,
      },
    });
  });
  const { loading, error, data } = useQuery(GetCartData, {
    variables: {
      filter: {
        or: productsToSearch,
      },
    },
  });

  if (!props.cart || !props.cart.length) {
    if (suppressEmptyRedirect){
      return null; //TODO: Check if this is reasonable. We do not want to go to eshop when we are waiting for payment redirect
    }
    props.dispatch(
      addNotification({
        message: "Košík je prázdný",
        type: "warning",
        code: 404,
      })
    );
    return <Redirect to={"/eshop"} />;
  }

  if (loading && !data) {
    return (
      <div className="container mbt-25">
        <Preloader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mbt-25">
        <strong className={"error"}>Nastala chyba při načítání košíku</strong>
      </div>
    );
  }

  if (createNewOrderLoading && !createNewOrderData && !createNewOrderError) {
    return (
      <div className="container mbt-25">
        <Preloader />
      </div>
    );
  }

  if (createNewOrderData) {
    if (createNewOrderData.createNewOrder.redirectUrl){
      window.location.href = createNewOrderData.createNewOrder.redirectUrl;
      return null;
    }
    return (
      <Redirect
        to={{
          pathname: "/order/thankyou/" + createNewOrderData.createNewOrder.id,
          state: { orderData: orderData.input, products: data.searchProducts.items },
        }}
      />
    );
  }

  function getCurrentPaymentMethod(){
    const methods = data?.listPaymentMethods?.items;
    if(!methods || !methods.length){
      return null;
    }
    const currentId = props.userPreferences["cart.paymentMethod"];
    for (let i = 0; i < methods.length; i++) {
      if (methods[i].id == currentId){
        return methods[i];
      }
    }
    return null;
  }

  function getCurrentShippingMethod(){
    const methods = data?.listShippingMethods?.items;
    if (!methods || !methods.length){
      return null;
    }
    const currentId = props.userPreferences["cart.shippingMethod"];
    for (let i = 0; i < methods.length; i++) {
      if (methods[i].id === currentId){
        return methods[i];
      }
    }
    return null;
  }
  let shippingAddressHidden = "";
  if (!getCurrentShippingMethod()?.requiresShippingAddress){
    shippingAddressHidden = "hidden";
  }

  const totalCost = cartTotalCost(
    props,
    data.searchProducts.items,
    data.listShippingMethods.items,
    data.listPaymentMethods.items,
    includeVatInPrices
  );
  const productCost = cartProductCost(props, data.searchProducts.items, includeVatInPrices);
  const billingShippingCost = totalCost - productCost;

  const methodDisabledByPrice = (shippingOrPaymentMethod) => {
    if (shippingOrPaymentMethod?.minAllowedCartPrice && productCost < shippingOrPaymentMethod.minAllowedCartPrice){
      return true;
    }
    if (shippingOrPaymentMethod?.maxAllowedCartPrice && productCost > shippingOrPaymentMethod.maxAllowedCartPrice){
      return true;
    }
    return false;
  };

  const methodDisabledByProduct = (shippingMethod) => {
    let disabled = false;
    props.cart.forEach((item) => {
      const cartProduct = data.searchProducts.items.find((product) => product.id === item.productId);
      if(!cartProduct || !cartProduct.forbiddenShippingSlugs){
        return;
      }
      cartProduct.forbiddenShippingSlugs.forEach(forbiddenSlug => {
        if (forbiddenSlug === shippingMethod.slug){
          disabled = true;
        }
      });
    })
    return disabled;
  };

  return (
    <div className={"container mbt-25 plr-10-mobile"}>
      <div className="pageHeader d-flex">
        <h1>Košík</h1>
        <Link
          className="ml-auto mbt-auto mt-10-mobile mlr-auto-mobile button medium blue arrow-right"
          to="/eshop"
        >
          <span>Pokračovat v nákupu</span>
        </Link>
      </div>

      <section>
        <h2 className={"light-weight-header"}>
          <img className={"icon"} src={Icons.getIcon("cart")} alt={"košík"} />
          <span>Obsah vašeho košíku</span>
        </h2>

        <div className="table-responsive cart hidden-mobile">
          <div className="table-header">
            <div className="id">ID</div>
            <div className="name">Název produktu</div>
            <div className="quantity">Množství</div>
            <div className="itemprice">Cena/ks</div>
            <div className="totalprice">Cena celkem</div>
          </div>
          <div className="table-content">
            {renderCartContents(props, data.searchProducts.items)}
            <div
              className={"last-item table-content-item"}
              key={"billingAndShipping"}
            >
              <div className="id"></div>
              <div className="name">Doprava a platba</div>
              <div className="quantityWrapper">
                <div className="quantity">
                  <div className={"input_number mr-10"}>
                      <span>
                        1 <span>x</span>
                      </span>
                  </div>
                </div>
              </div>
              <div className="itemprice">
                <span>
                  {billingShippingCost.toFixed(2)}
                </span>
                <span>&nbsp;Kč</span>
              </div>
              <div className="totalprice">
                <span>
                  {billingShippingCost.toFixed(2)}
                </span>
                <span>&nbsp;Kč</span>
              </div>
            </div>
          </div>
          <div className="table-footer mt-10">
            <div className="table-footer-item-1-2">
              <div
                onClick={() => props.dispatch(emptyCart())}
                className="button gray small trashCan_before"
              >
                <span>Vyprázdnit celý košík</span>
              </div>
            </div>
            <div className="table-footer-item-4-5 table_total_price table_total_price_label">
              <span>Cena celkem{includeVatInPrices ? " vč. DPH" : ""}: </span>
            </div>
            <div className="table-footer-item-5-6 table_total_price table_total_price_number">
              <span>
                {totalCost}{" "}
                Kč
              </span>
            </div>
          </div>
        </div>
        <div className="cart hidden-desktop">
          {renderCartContentsMobile(props, data.searchProducts.items)}
        </div>
      </section>

      <section>
        <div className="panel mb-25 mt-35">
          <div className="panelHeader">
            <h2>Způsob dopravy</h2>
          </div>
          <div className="panelBody flex-row flex-mobile-col">
            {[...data.listShippingMethods.items].sort((a, b) => a.order - b.order).map((shippingMethod) => (
              <label
                className={"flex mr-30 " + (validationErrors.has("shippingMethodId") ? "error" : "")}
                key={"shippingMethod_" + shippingMethod.id}
                title={shippingMethod.description}
              >
                <span
                  className={
                    (shippingMethod.id ===
                    props.userPreferences["cart.shippingMethod"]
                      ? "checked "
                      : "") + "radio"
                  }
                />
                <input
                  onChange={() =>
                    props.dispatch(
                      setPreference({
                        name: "cart.shippingMethod",
                        value: shippingMethod.id,
                      })
                    )
                  }
                  checked={
                    shippingMethod.id ===
                    props.userPreferences["cart.shippingMethod"]
                  }
                  type="radio"
                  value={shippingMethod.id}
                  disabled={methodDisabledByProduct(shippingMethod) || methodDisabledByPrice(shippingMethod) || (!!getCurrentPaymentMethod() && !getCurrentPaymentMethod()?.allowedShipmentConnection?.items?.find(connection => connection.shippingMethodId === shippingMethod.id))}
                  name="shippingMethod"
                />

                <span>{shippingMethod.name}</span>
                {shippingMethod.fee || shippingMethod.feePercent ? (
                  <span>
                    &nbsp;(
                    {shippingMethod.fee ? <span>{shippingMethod.fee} Kč</span> : null}
                    {(shippingMethod.fee && shippingMethod.feePercent) ? <span>&nbsp;+&nbsp;</span> : null}
                    {shippingMethod.feePercent ? <span>{shippingMethod.feePercent}%</span> : null}
                    ) &nbsp;
                  </span>
                ) : null}
              </label>
            ))}
          </div>
        </div>

        <div className="panel mb-25">
          <div className="panelHeader">
            <h2>Způsob platby</h2>
          </div>
          <div className="panelBody flex-row flex-mobile-col">
            {[...data.listPaymentMethods.items].sort((a, b) => a.order - b.order).map((paymentMethod) => (
              <label
                className={"flex mr-30 " + (validationErrors.has("paymentMethodId") ? "error" : "")}
                key={"paymentMethod_" + paymentMethod.id}
                title={paymentMethod.description}
              >
                <span
                  className={
                    (paymentMethod.id ===
                    props.userPreferences["cart.paymentMethod"]
                      ? "checked "
                      : "") + "radio"
                  }
                />
                <input
                  onChange={() =>
                    props.dispatch(
                      setPreference({
                        name: "cart.paymentMethod",
                        value: paymentMethod.id,
                      })
                    )
                  }
                  checked={
                    paymentMethod.id ===
                    props.userPreferences["cart.paymentMethod"]
                  }
                  type="radio"
                  value={paymentMethod.id}
                  disabled={methodDisabledByPrice(paymentMethod) || (!!getCurrentShippingMethod() && !getCurrentShippingMethod()?.allowedPaymentConnection?.items?.find(connection => connection.paymentMethodId === paymentMethod.id))}
                  name="paymentMethod"
                />
                <span>{paymentMethod.name}</span>
                {paymentMethod.fee || paymentMethod.feePercent ? (
                  <span>
                    &nbsp;(
                    {paymentMethod.fee ? <span>{paymentMethod.fee} Kč</span> : null}
                    {(paymentMethod.fee && paymentMethod.feePercent) ? <span>&nbsp;+&nbsp;</span> : null}
                    {paymentMethod.feePercent ? <span>{paymentMethod.feePercent}%</span> : null}
                    ) &nbsp;
                  </span>
                ) : null}
              </label>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="panel mb-25">
          <div className="panelHeader">
            <h2>Objednavatel</h2>
          </div>
          <div className="panelBody panel-grid-2 panel-grid-1-mobile">
            <label className={"mb-15 grid-80 grid-left " + (validationErrors.has("invoiceAddress.companyName") ? "error" : "")}>
              <span>Firma</span>
              <input
                type={"text"}
                name={"companyName"}
                value={companyName}
                onChange={({ target }) => setCompanyName(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-left " + (validationErrors.has("invoiceAddress.companyId") ? "error" : "")}>
              <span>IČO</span>
              <input
                type={"text"}
                name={"companyId"}
                value={companyId}
                onChange={({ target }) => setCompanyId(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-left " + (validationErrors.has("invoiceAddress.companyTaxId") ? "error" : "")}>
              <span>DIČ</span>
              <input
                type={"text"}
                name={"companyTaxId"}
                value={companyTaxId}
                onChange={({ target }) => setCompanyTaxId(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-right " + (validationErrors.has("invoiceAddress.name") ? "error" : "")}>
              <span>Jméno*</span>
              <input
                type={"text"}
                name={"name"}
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-right phoneNumber " + (validationErrors.has("shippingAddress.telephone") ? "error" : "")}>
              <span>Telefon*</span>
              <select value={telephonePrefix} onChange={({target}) => setTelephonePrefix(target.value)}>
                <option>+420</option>
                <option>+421</option>
              </select>
              <input
                type={"tel"}
                name={"telephone"}
                value={telephone}
                onChange={({ target }) => setTelephone(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-right " + (validationErrors.has("shippingAddress.email") ? "error" : "")}>
              <span>Email*</span>
              <input
                type={"email"}
                name={"email"}
                value={email}
                onChange={({ target }) => setEmail(target.value)}
              />
            </label>
          </div>
        </div>
        <div className={"panel mb-25 " + shippingAddressHidden}>
          <div className="panelHeader">
            <h2>Dodací adresa</h2>
          </div>
          <div className="panelBody panel-grid-2 panel-grid-1-mobile">
            <label className={"mb-15 grid-80 grid-left " + (validationErrors.has("shippingAddress.companyName") ? "error" : "")}>
              <span>Firma</span>
              <input
                type={"text"}
                name={"shippingCompanyName"}
                value={shippingCompanyName}
                onChange={({ target }) => setShippingCompanyName(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-left " + (validationErrors.has("shippingAddress.name") ? "error" : "")}>
              <span>Jméno*</span>
              <input
                type={"text"}
                name={"shippingName"}
                value={shippingName}
                onChange={({ target }) => setShippingName(target.value)}
              />
            </label>

            <label className={"mb-15 grid-80 grid-right " + (validationErrors.has("shippingAddress.street") ? "error" : "")}>
              <span>Ulice a č.p.*</span>
              <input
                type={"text"}
                name={"street"}
                value={street}
                onChange={({ target }) => setStreet(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-right " + (validationErrors.has("shippingAddress.city") ? "error" : "")}>
              <span>Město*</span>
              <input
                type={"text"}
                name={"city"}
                value={city}
                onChange={({ target }) => setCity(target.value)}
              />
            </label>
            <label className={"mb-15 grid-80 grid-right " + (validationErrors.has("shippingAddress.zip") ? "error" : "")}>
              <span>PSČ*</span>
              <input
                type={"text"}
                name={"zip"}
                value={zip}
                onChange={({ target }) => setZip(target.value)}
              />
            </label>
          </div>
        </div>
      </section>

      <section>
        <div className="panel mb-45">
          <div className="panelHeader">
            <h2>Poznámka k objednávce</h2>
          </div>
          <div className="panelBody">
            <label className={"grid-80 " + (validationErrors.has("note") ? "error" : "")}>
              <span>Text</span>
              <textarea
                onChange={({ target }) => setNote(target.value)}
                value={note}
              />
            </label>
          </div>
        </div>
      </section>

      <section>
        <div className={"terms mb-45"}>
          <label>
            <span
              className={
                (props.userPreferences["agreedToTerms"] ? "checked " : "") +
                "checkbox mr-12"
              }
            />
            <input
              type="checkbox"
              id="agreedToTerms"
              name={"agreedToTerms"}
              checked={props.userPreferences["agreedToTerms"]}
              onChange={({ target }) =>
                props.dispatch(
                  setPreference({
                    name: "agreedToTerms",
                    value: target.checked,
                  })
                )
              }
            />
            <strong>Souhlasím s obchodními podmínkami </strong> &nbsp;
            <span>
              {" "}
              - obchodní podmínky si můžete přečíst{" "}
              <a className={"blueLink underlinedLink"} target={"_blank"} href={"/staticDocs/cookies_TOPASPLUS_final_9_2021.docx.pdf"}>ZDE</a>
            </span>
          </label>
        </div>
        <div className={"submit"}>
          <div onClick={() => submitOrder()} className="button large blue">
            <span>Dokončit objednávku</span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default connect((state) => ({
  cognito: state.cognito,
  cart: state.cart,
  userPreferences: state.userPreferences,
}))(Cart);
