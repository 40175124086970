import { gql } from "@apollo/client";

export const GetEshopProduct = gql`
  query GetEshopProduct($productId: ID!) {
    getProduct(id: $productId) {
      id
      slug
      isHidden
      notForSale
      linksTo
      manufacturerId
      manufacturerProductCode
      name
      price
      salePrice
      isRental
      isInstallation
      securityDeposit
      dailyRentalFee
      featuredSale
      ean
      unit
      vat
      stock
      minimalAmount
      restockDays
      createdAt
      updatedAt
      manufacturer {
        id
        order
        name
        description
        image
        createdAt
        updatedAt
      }
      attachments {
        items {
          file
          id
          description
          type
          typeDesctiption
        }
      }
      attributes{
        name
        value
        unit
      }
    }
  }
`;
export const SearchEshopProducts = gql`
  query SearchEshopProducts(
    $filter: SearchableProductFilterInput
    $from: Int
    $limit: Int
    $sort: SearchableProductSortInput
    $nextToken: String
  ) {
    searchProducts(
      filter: $filter
      from: $from
      limit: $limit
      sort: $sort
      nextToken: $nextToken
    ) {
      aggregations {
        maxPrice
        minPrice
        uniqueAttributes {
          name
          values
        }
        uniqueManufacturers
      }
      items {
        id
        name
        slug
        manufacturerId
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
      }
      nextToken
      total
    }
  }
`;

export const GetProductAttributes = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      attributes {
        name
        value
        unit
      }
    }
  }
`;
