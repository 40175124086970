import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";

import {
  fetchCurrentSession,
  fetchCurrentUserInfo,
  getUserDynamoRecord,
} from "./app/cognitoReducer";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import HomePage from "./staticPages/HomePage";
import Eshop from "./eshop/Eshop";
import Admin from "./admin/Admin";
import Login from "./user/Login";
import Register from "./user/Register";
import Verify from "./user/Verify";
import ResetPassword from "./user/ResetPassword";
import VerifyPassword from "./user/VerifyPassword";
import User from "./user/User";
import Cart from "./eshop/Cart";
import ThankYou from "./eshop/ThankYou";
import News from "./news/News";
import AboutUs from "./aboutUs/AboutUs";
import TransportAndServices from "./transportAndServices/TransportAndServices";
import PageNotFound from "./util/PageNotFound";
import DynamicPage from "./dynamicPage/DynamicPage";
import Notifications from "./widgets/Notifications";
import Contacts from "./contacts/Contacts";

import "./App.css";
import "./style/universalElements.scss";
import ScrollToTop from "./util/ScrollToTop";
import CookiesBar from "./util/CookiesBar";
import CsobVerify from "./eshop/CsobVerify";
import Preloader from "./util/Preloader";
import {getPageSettings} from "./app/pageSettingsReducer";
import Popups from './popups/Popups';

class App extends Component {
  constructor() {
    super();
    this.state = {
      dispatchDone: false,
    }
  }

  componentDidMount() {
    const dispatches = [
      this.props.dispatch(fetchCurrentUserInfo()),
      this.props.dispatch(fetchCurrentSession()),
      this.props.dispatch(getUserDynamoRecord()),
      this.props.dispatch(getPageSettings()),
    ];
    Promise.all(dispatches).finally( () => {
      this.setState(() => ({dispatchDone: true}));
    });
  }

  render() {
    if (!this.state.dispatchDone){
      return (
        <div className={"app"}>
          <Helmet titleTemplate={"%s | Topas plus"} defaultTitle={"Topas plus"}>
            <html className={"frontEnd"} />
            <meta property="og:image" content="/logo512.png" />
            <meta name="description" content="Objednejte online ze široké nabídky produktů pro instalatérství, topení a sanity s možností expresního doručení na stavbu."/>
          </Helmet>
          <Preloader/>
        </div>
      );
    }
    return (
      <div className={"app"}>
        <Helmet titleTemplate={"%s | Topas plus"} defaultTitle={"Topas plus"}>
          <html className={"frontEnd"} />
          <meta property="og:image" content="/logo512.png" />
          <meta name="description" content="Objednejte online ze široké nabídky produktů pro instalatérství, topení a sanity s možností expresního doručení na stavbu."/>
        </Helmet>
        <Popups/>
        <Router>
          <ScrollToTop>
            <Header />
            <Notifications />
            {/*<Route path={"*"} render={(props) => <MainStyleSelector {...props}/>}/>*/}
            <div className="contentWrapper">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => <HomePage {...props}/>}
                />
                <Route
                  exact
                  path={"/login"}
                  render={(props) => <Login {...props}/>}
                />
                <Route
                  exact
                  path={"/register"}
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path={"/verify/:name?/:code?"}
                  render={(props) => <Verify {...props} />}
                />
                <Route
                  exact
                  path={"/reset-password"}
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path={"/verify-password/:name?/:code?"}
                  render={(props) => <VerifyPassword {...props} />}
                />
                <Route path={"/admin"} render={(props) => <Admin {...props} />} />
                <Route path={"/user"} render={(props) => <User {...props} />} />

                <Route
                  path={
                    "/eshop"
                  }
                  render={(props) => <Eshop {...props} />}
                />
                <Route
                  path={"/novinky"}
                  render={(props) => <News {...props} />}
                />

                <Route path={"/cart"}>
                  <Cart />
                </Route>
                <Route
                  path={"/order/thankyou/:orderId"}
                  render={(props) => <ThankYou {...props} />}
                />
                <Route
                  path={"/order/:orderId"}
                  render={() => (
                    <div className={"container mbt-25"}>
                      <div className="pageHeader">
                        <h1>Objednávka úspěšně dokončena</h1>
                      </div>
                    </div>
                  )}
                />
                <Route
                  path={"/csobVerify"}>
                  <CsobVerify/>
                </Route>
                <Route
                  path={"/:pageSlug"}
                  render={(props) => <DynamicPage {...props} />}
                />
                <Route path={"/admin"}>
                  <Admin />
                </Route>
                <Route path={"*"}>
                  <PageNotFound />
                </Route>
              </Switch>
            </div>
            <Footer />
          </ScrollToTop>
          <CookiesBar/>
        </Router>
      </div>
    );
  }
}

export default connect()(App);
