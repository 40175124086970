import React from "react";
import Moment from 'react-moment';
import { Form, Field } from "react-final-form";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import Preloader from "../util/Preloader";
import ProductListing from "../widgets/ProductListing";

import parse from "html-react-parser";

import "./newsPost.scss";

import domReplacementOptions from "../dynamicPage/domReplacementOptions";
import {Helmet} from "react-helmet-async";

const GetPost = gql`
  query NewsPostsBySlug($slug: String, $limit: Int) {
    newsPostsBySlug(slug: $slug, limit: $limit) {
      items {
        id
        categoryId
        slug
        name
        content
        createdAt
        updatedAt
        seoDescription
        category {
          id
          slug
          name
          order
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;

function NewsPostDesignContent(){
  return (<>
    <h2>Následuje vzor designu:</h2>
    <div className="content">
      Okay, you’ve decided you want to make money with Affiliate Marketing.
      So, you join some affiliate programs and start submitting free ads to
      newsletters and free advertising classifieds sites. You’re going to
      make BIG money now — right? Nope! Sorry! Just sending out a few ads is
      not going to do it. Not if you want to be a real success. You must
      first determine your passion. I mean other than making the money! What
      is your hobby? What do you know how to do REALLY well? What is your
      job? Everyone has something that is their own special talent. Find
      yours. You probably have more than one thing that you are very
      interested in and do well. See if you can find five (ten if you’re
      really ambitious). Write them down in a list. Remember, you are going
      to be spending a lot of time working with this subject. Make sure you
      enjoy it! Go down each item on your list. Start writing a rough
      outline of everything you know about the topic. It doesn’t have to be
      elaborate. This is just to give you an idea of how much information is
      available for a given subject. Try mind mapping. Write down the main
      subject of your idea. Draw a circle around it. Now, start thinking of
      sub-categories that are related to your main category. Draw a line
      from your main category and end it with a circle. Put the sub-category
      title inside this circle. Find as many sub-categories (and
      sub-sub-categories) as you can. Go through each of your interests with
      this procedure. Choose the one that you know the most about and that
      you can write about
    </div>
    <div className="images">
      <div className="image" />
      <div className="image" />
      <div className="image" />
    </div>
    <div className="content">
      Okay, you’ve decided you want to make money with Affiliate Marketing.
      So, you join some affiliate programs and start submitting free ads to
      newsletters and free advertising classifieds sites. You’re going to
      make BIG money now — right? Nope! Sorry! Just sending out a few ads is
      not going to do it. Not if you want to be a real success. You must
      first determine your passion. I mean other than making the money! What
      is your hobby? What do you know how to do REALLY well? What is your
      job? Everyone has something that is their own special talent. Find
      yours. You probably have more than one thing that you are very
      interested in and do well. See if you can find five (ten if you’re
      really ambitious). Write them down in a list. Remember, you are going
      to be spending a lot of time working with this subject. Make sure you
      enjoy it! Go down each item on your list. Start writing a rough
      outline of everything you know about the topic. It doesn’t have to be
      elaborate. This is just to give you an idea of how much information is
      available for a given subject. Try mind mapping. Write down the main
      subject of your idea. Draw a circle around it. Now, start thinking of
      sub-categories that are related to your main category. Draw a line
      from your main category and end it with a circle. Put the sub-category
      title inside this circle. Find as many sub-categories (and
      sub-sub-categories) as you can. Go through each of your interests with
      this procedure. Choose the one that you know the most about and that
      you can write about
    </div>
  </>);
}

function NewsPost(props) {
  const { loading, error, data } = useQuery(GetPost, {
    variables: {
      slug: props.match.params.postSlug,
      limit: 1,
    },
  });

  if (loading && !data) {
    return (
      <article className={"newsPost"}>
        <Preloader />
      </article>
    );
  }

  if (
    error ||
    !data.newsPostsBySlug ||
    !data.newsPostsBySlug.items ||
    !data.newsPostsBySlug.items.length
  ) {
    return (
      <article className={"newsPost"}>
        <strong className={"error"}>Nastala chyba při načítání novinky</strong>
      </article>
    );
  }

  //TODO: !data.newsPostsBySlug.items.length should throw 404 not just loading error

  const newsPost = data.newsPostsBySlug.items[0];
  const handleSubmit = (e) => {
  };
  return (
    <>
      <article className={"newsPost"}>
        <Helmet>
          <title>{newsPost.name}</title>
          {newsPost.seoDescription ? <meta name={"description"} content={newsPost.seoDescription}/> : null}
        </Helmet>
        <div className="pageHeader">
          <nav className={"breadcrumbs "}>
            <div className={"button medium light-gray arrow-left"}>
              <Link to={"/novinky/"}>
                <span>Zpět na výpis</span>
              </Link>
            </div>
          </nav>
        </div>
        <h1 className="title">{newsPost.name}</h1>
        <i className="createdAt"><Moment format="DD-MM-YYYY">{newsPost.createdAt}</Moment></i>
        <div className="content">
          {newsPost.content && parse(newsPost.content, domReplacementOptions)}
        </div>

      </article>
      <hr className={"offWhite mbt-20"} />
      <ProductListing
        filter={{ onSale: true, newest: false }}
        productClassName={"w-25p"}
        className={"mb-50"}
        heading={"Produkty v akci"}
      />
      <hr className={"offWhite mbt-20"} />
      <div className="sectionHeader">
        <h1>Kontaktní formulář</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        {(props) => (
          <form className="newsForm" onSubmit={props.handleSubmit}>
            <div className="row formRow mbt-25">
              <div className="formField">
                <label>Jméno:</label>
                <Field name="news_name" component="input" />
              </div>
              <div className="formField">
                <label>Příjmení:</label>

                <Field name="news_lastname" component="input" />
              </div>
            </div>
            <div className="row formRow mbt-25">
              <div className="formField">
                <label>Telefon:</label>
                <Field name="news_phone" component="input" />
              </div>
              <div className="formField">
                <label>Email:</label>
                <Field name="news_email" component="input" />
              </div>
            </div>
            <div className="formTextArea mbt-25">
              <label>Zpráva</label>
              <Field name="news_textarea" component="textarea" />
            </div>
            <div className="row formButton mbt-25">
              <button className="button yellow arrow-right" type="submit">
                ODESLAT
              </button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
}

export default NewsPost;
