import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listCategories = gql`
    query ListNewsCategorys(
        $filter: ModelNewsCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listNewsCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                slug
                name
                order
                description
                createdAt
                updatedAt
            }
        }
    }
`;

const listNewsPosts = gql`
    query ListNewsPosts(
        $filter: ModelNewsPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listNewsPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                categoryId
                slug
                name
                content
                excerpt
                advancedEditor
                createdAt
                updatedAt
                category{
                    id
                    name
                }
            }
        }
    }
`;

const updateNewsPostMutation = gql`
    mutation UpdateNewsPost(
        $input: UpdateNewsPostInput!
        $condition: ModelNewsPostConditionInput
    ) {
        updateNewsPost(input: $input, condition: $condition) {
            id
        }
    }
`;

const deleteNewsPostMutation = gql`
    mutation DeleteNewsPost(
        $input: DeleteNewsPostInput!
        $condition: ModelNewsPostConditionInput
    ) {
        deleteNewsPost(input: $input, condition: $condition) {
            id
        }
    }
`;

const createNewsPostMutation = gql`
    mutation CreateNewsPost(
        $input: CreateNewsPostInput!
        $condition: ModelNewsPostConditionInput
    ) {
        createNewsPost(input: $input, condition: $condition) {
            id
        }
    }
`;

function PostRow({newsPost, selected, onSelectChange}){
  const [updateNewsPost, updateNewsPostResponse] = useMutation(updateNewsPostMutation, {
    refetchQueries: [
      'ListNewsPosts'
    ]
  });
  const [deleteNewsPost, deleteNewsPostResponse] = useMutation(deleteNewsPostMutation, {
    refetchQueries: [
      'ListNewsPosts'
    ]
  });
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat dopravní metodu"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{newsPost.id}</td>
      <td>{newsPost.name}</td>
      {/*<td>{newsPost.slug}</td>*/}
      <td>{newsPost.category?.name}</td>
      <td>{newsPost.createdAt}</td>
      <td>
        {updateNewsPostResponse.loading || deleteNewsPostResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s dopravní metodou">
            <Link to={"/admin/newsPosts/" + newsPost.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat příspěvek: " + newsPost.name + "?"} onClick={() => deleteNewsPost({variables: {input: {id: newsPost.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Posts({}){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("name");
  const [sortDirection, changeSortDirection] = useState("asc");
  const {loading: categoryLoading, error: categoryError, data: categoryData} = useQuery(listCategories);
  const { loading, error, data } = useQuery(listNewsPosts, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createNewsPost, createNewsPostResponse] = useMutation(createNewsPostMutation, {
    refetchQueries: [
      'ListNewsPosts'
    ]
  });
  const [selectedNewsPosts, dispatchSelectedNewsPosts] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listNewsPosts.items.length){
          return {}
        }
        data.listNewsPosts.items.forEach(newsPost => newState[newsPost.id] = newsPost);
        return newState;
      case "toggle":
        if(newState[action.newsPost.id]){
          delete newState[action.newsPost.id];
        }
        else{
          newState[action.newsPost.id] = action.newsPost;
        }
        return newState;
      case "select":
        newState[action.newsPost.id] = action.newsPost;
        return newState;
      case "deselect":
        delete newState[action.newsPost.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading || categoryLoading) && (!data || !categoryData)) return(
      <Preloader/>
    );

    if (error || data.errors || categoryError) return(
      <div className="danger">Nastala chyba při načítání příspěvků</div>
    );

    const newsPosts = [...data.listNewsPosts.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedNewsPosts).length === newsPosts.length} onChange={() => dispatchSelectedNewsPosts({type: "selectAll"})} aria-label={"Vybrat příspěvek"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            {/*<th>Odkaz</th>*/}
            <th>Kategorie</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {newsPosts.map(newsPost => (
            <PostRow key={"newsPost_" + newsPost.id} onSelectChange={() => dispatchSelectedNewsPosts({type: "toggle", newsPost})} selected={!!selectedNewsPosts[newsPost.id]} newsPost={newsPost}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"newsPosts"}>
      <h3>Příspěvky</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createNewsPostResponse.loading} onClick={() => createNewsPost({variables: {input: {
              name: "Nový příspěvek",
              slug: "novy-prispevek",
              content: "",
              excerpt: "",
              categoryId: categoryData.listNewsCategorys.items[0].id,
            }}})}>Vytvořit nový</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Posts;
