import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listCategories = gql`
    query ListNewsCategorys(
        $filter: ModelNewsCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listNewsCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                slug
                name
                order
                description
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

const updateCategoryMutation = gql`
    mutation UpdateNewsCategory(
        $input: UpdateNewsCategoryInput!
        $condition: ModelNewsCategoryConditionInput
    ) {
        updateNewsCategory(input: $input, condition: $condition) {
            id
        }
    }
`;

const deleteCategoryMutation = gql`
    mutation DeleteNewsCategory(
        $input: DeleteNewsCategoryInput!
        $condition: ModelNewsCategoryConditionInput
    ) {
        deleteNewsCategory(input: $input, condition: $condition) {
            id
        }
    }
`;

const createCategoryMutation = gql`
    mutation CreateNewsCategory(
        $input: CreateNewsCategoryInput!
        $condition: ModelNewsCategoryConditionInput
    ) {
        createNewsCategory(input: $input, condition: $condition) {
            id
            slug
            name
            order
            description
            createdAt
            updatedAt
        }
    }
`;

function CategoryRow({category, selected, onSelectChange}){
  const [updateCategory, updateCategoryResponse] = useMutation(updateCategoryMutation, {
    refetchQueries: [
      'ListNewsCategorys'
    ]
  });
  const [deleteCategory, deleteCategoryResponse] = useMutation(deleteCategoryMutation, {
    refetchQueries: [
      'ListNewsCategorys'
    ]
  });
  const [order, updateOrder] = useState(category.order);
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat kategorii"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{category.id}</td>
      <td>{category.name}</td>
      <td>{category.slug}</td>
      <td><Form.Control type={"number"} size={"sm"} value={order} onKeyDown={({key}) => key === "Enter" ? updateCategory({variables: {input: {id: category.id, order}}}) : null} onChange={({target}) => updateOrder(parseInt(target.value))}/></td>
      <td>{category.createdAt}</td>
      <td>
        {updateCategoryResponse.loading || deleteCategoryResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s kategorií">
            <Link to={"/admin/newsCategories/" + category.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat kategorii: " + category.name + "?"} onClick={() => deleteCategory({variables: {input: {id: category.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Categories(props){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("order");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listCategories, {
    variables: {
      filter: !fulltext ? null : {
        or: [
          {name: {
            contains: fulltext
          }},
          {slug: {
            contains: fulltext
          }},
          {description: {
            contains: fulltext
          }},
        ],
      },
    },
  });
  const [createCategory, createCategoryResponse] = useMutation(createCategoryMutation, {
    refetchQueries: [
      'ListNewsCategorys'
    ]
  });
  const [selectedCategories, dispatchSelectedCategories] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listNewsCategorys.items.length){
          return {}
        }
        data.listNewsCategorys.items.forEach(category => newState[category.id] = category);
        return newState;
      case "toggle":
        if(newState[action.category.id]){
          delete newState[action.category.id];
        }
        else{
          newState[action.category.id] = action.category;
        }
        return newState;
      case "select":
        newState[action.category.id] = action.category;
        return newState;
      case "deselect":
        delete newState[action.category.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání kategorií</div>
    );

    const categories = [...data.listNewsCategorys.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedCategories).length === categories.length} onChange={() => dispatchSelectedCategories({type: "selectAll"})} aria-label={"Vybrat kategorie"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Odkaz {renderSortDirection("slug")}</th>
            <th>Pořadí {renderSortDirection("order")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {categories.map(category => (
            <CategoryRow key={"category_" + category.id} onSelectChange={() => dispatchSelectedCategories({type: "toggle", category})} selected={!!selectedCategories[category.id]} category={category}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"newsCategories"}>
      <h3>Kategorie</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createCategoryResponse.loading} onClick={() => createCategory({variables: {input: {
              name: "Nová kategorie",
              slug: "nova-kategorie",
              order: 0,
            }}})}>Vytvořit novou</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Categories;
