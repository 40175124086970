import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dismissPopup} from "../app/popupReducer";
import parse from "html-react-parser";
import domReplacementOptions from "../dynamicPage/domReplacementOptions";

const Popup = ({popup}) => {
  const [hide, setHide] = useState(false);
  const dismissedPopups = useSelector(state => state.popups.dismissedPopups);
  const dispatch = useDispatch();

  if(hide){
    return null;
  }
  if(dismissedPopups.indexOf(popup.id) !== -1){
    return null;
  }
  const now = Date.now();
  const displayFrom = +(new Date(popup.activeFrom));
  const displayTo = +(new Date(popup.activeTo));
  if(now < displayFrom || now > displayTo){
    return null;
  }
  return (
    <div className={'popupBackground'}>
      <div className={'popupContainer'}>
        <h2 className={'popupTitle'}>{popup.title}</h2>
        <div className={'popupContents'}>
          {parse(popup.content, domReplacementOptions)}
        </div>
        <div className={'popupButtons'}>
          <div onClick={() => setHide(true)} className={'popupButton button medium gray'}>
            Zavřít
          </div>
          <div onClick={() => dispatch(dismissPopup(popup))} className={'popupButton button medium blue'}>
            Již nezobrazovat
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
