import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";
import {orderStatuses} from "../util/globalMaps";

const getOrder = gql`
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      userName
      fields {
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      shippingMethodId
      shipmentTrackingId
      paymentMethodId
      paymentTrackingId
      orderStatus
      orderNumber  
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
      note
      createdAt
      updatedAt
    }
    listPaymentMethods{
      items {
        id
        name
        description
        fee
        feePercent
        order
        createdAt
        updatedAt
      }
    }
    listShippingMethods {
      items {
        id
        name
        description
        fee
        feePercent
        order
        createdAt
        updatedAt
      }
    }
  }
`;

const updateOrderMutation = gql`
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      userName
      shippingMethodId
      shipmentTrackingId
      paymentMethodId
      paymentTrackingId
      orderStatus
      orderNumber  
      note
      createdAt
      updatedAt
      fields{
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
    }
  }
`;

function OrderEditForm({initialOrder, paymentMethods, shippingMethods, saveChanges}){
  const parseInitialOrder = () => JSON.parse(JSON.stringify(initialOrder), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [order, setOrder] = useState(parseInitialOrder());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newOrder = JSON.parse(JSON.stringify(order));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newOrder[splitPath[0]] = value;
        break;
      case 2:
        newOrder[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newOrder[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    if (path === "shippingMethodId" || path === "paymentMethodId"){
      let paymentShipmentField = newOrder.fields.find(field => {
        return field.name === "Doprava a platba";
      });
      if (paymentShipmentField){
        let productsPrice = 0;
        const shippingMethod = shippingMethods.items.find(shippingMethod => shippingMethod.id === newOrder.shippingMethodId);
        const paymentMethod = paymentMethods.items.find(paymentMethod => paymentMethod.id === newOrder.paymentMethodId);
        newOrder.fields.forEach(field => {
          productsPrice += field.priceIncludingTax * field.amount;
        });
        console.log({shippingMethod, paymentMethod, productsPrice});
        paymentShipmentField.priceIncludingTax = shippingMethod.fee + paymentMethod.fee + ((shippingMethod.feePercent + paymentMethod.feePercent) / 100 * productsPrice);
        paymentShipmentField.price = paymentShipmentField.priceIncludingTax * (100 / 121);
        window.alert("Došlo k přepočtu ceny dopravy a platby");
      }
    }
    setOrder(newOrder);
  };
  const addOrderField = () => {
    let newOrder = JSON.parse(JSON.stringify(order));
    newOrder.fields.push({
      name: "",
      productId: "",
      price: 0,
      amount: 1,
      priceIncludingTax: 0,
      unit: "ks."
    });
    setOrder(newOrder);
  };
  const removeOrderField = (index) => {
    let newOrder = JSON.parse(JSON.stringify(order));
    newOrder.fields.splice(index, 1);
    setOrder(newOrder);
  };

  return (
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="userName">
            <Form.Label>Uživatel</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.userName} onChange={changeEventHandler} />
          </Form.Group>
          <Form.Group controlId="createdAt">
            <Form.Label>Datum vytvoření</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.createdAt} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="orderStatus">
            <Form.Label>Stav objednávky</Form.Label>
            <Form.Control as="select" value={order.orderStatus} custom onChange={changeEventHandler}>
              {Object.keys(orderStatuses).map(orderStatusKey => <option key={orderStatusKey} value={orderStatusKey}>{orderStatuses[orderStatusKey]}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="updatedAt">
            <Form.Label>Datum posledních úprav</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.updatedAt} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <h4>Doprava</h4>
          <Form.Group controlId="shippingMethodId">
            <Form.Label>Metoda</Form.Label>
            <Form.Control as="select" custom value={order.shippingMethodId} onChange={changeEventHandler}>
              {shippingMethods.items.map(shippingMethod => <option key={shippingMethod.id} value={shippingMethod.id}>{shippingMethod.name}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="shipmentTrackingId">
            <Form.Label>Sledování zásilky</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shipmentTrackingId} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <h4>Platba</h4>
          <Form.Group controlId="paymentMethodId">
            <Form.Label>Metoda</Form.Label>
            <Form.Control as="select" custom value={order.paymentMethodId} onChange={changeEventHandler}>
              {paymentMethods.items.map(paymentMethod => <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.name}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="paymentTrackingId">
            <Form.Label>Sledování platby</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.paymentTrackingId} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <h4>Fakturační údaje</h4>
          <Form.Group controlId="invoiceAddress.companyName">
            <Form.Label>Název společnosti</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.companyName} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="invoiceAddress.companyId">
            <Form.Label>IČO</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.companyId} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="invoiceAddress.companyTaxId">
            <Form.Label>DIČ</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.companyTaxId} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="invoiceAddress.name">
            <Form.Label>Jméno</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.name} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="invoiceAddress.street">
            <Form.Label>Ulice a č.p.</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.street} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="invoiceAddress.city">
            <Form.Label>Město</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.city} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="invoiceAddress.zip">
            <Form.Label>PSČ</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.invoiceAddress.zip} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <h4>Dodací údaje</h4>
          <Form.Group controlId="shippingAddress.companyName">
            <Form.Label>Název společnosti</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.companyName} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="shippingAddress.telephone">
            <Form.Label>Telefon</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.telephone} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="shippingAddress.email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.email} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="shippingAddress.name">
            <Form.Label>Jméno</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.name} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="shippingAddress.street">
            <Form.Label>Ulice a č.p.</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.street} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="shippingAddress.city">
            <Form.Label>Město</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.city} onChange={changeEventHandler}/>
          </Form.Group>
          <Form.Group controlId="shippingAddress.zip">
            <Form.Label>PSČ</Form.Label>
            <Form.Control type="text" placeholder="není" value={order.shippingAddress.zip} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"mb-2 border-bottom border-secondary"}>
        <Col>
          <h4>Položky objednávky</h4>
          {order.fields.map((orderField, orderFieldIndex) => (
            <Form.Row className={"mb-2 border-bottom "} key={"orderField_" + orderFieldIndex}>
              <Col>
                <Form.Group controlId={"fields." + orderFieldIndex + ".productId"}>
                  <Form.Label>ID produktu</Form.Label>
                  <Form.Control type="text" placeholder="není" value={orderField.productId} onChange={changeEventHandler}/>
                </Form.Group>
                <Form.Group controlId={"fields." + orderFieldIndex + ".price"}>
                  <Form.Label>Cena</Form.Label>
                  <Form.Control type="number" step={0.01} min={0} placeholder="není" value={orderField.price} onChange={changeEventHandler}/>
                </Form.Group>
                <Form.Group controlId={"fields." + orderFieldIndex + ".amount"}>
                  <Form.Label>Množství</Form.Label>
                  <Form.Control type="number" step={1} min={1} placeholder="není" value={orderField.amount} onChange={changeEventHandler}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"fields." + orderFieldIndex + ".name"}>
                  <Form.Label>Název</Form.Label>
                  <Form.Control type="text" placeholder="není" value={orderField.name} onChange={changeEventHandler}/>
                </Form.Group>
                <Form.Group controlId={"fields." + orderFieldIndex + ".priceIncludingTax"}>
                  <Form.Label>Cena s DPH</Form.Label>
                  <Form.Control type="number" step={0.01} min={0} placeholder="není" value={orderField.priceIncludingTax} onChange={changeEventHandler}/>
                </Form.Group>
                <Form.Group controlId={"fields." + orderFieldIndex + ".unit"}>
                  <Form.Label>Jednotka</Form.Label>
                  <Form.Control type="text" placeholder="není" value={orderField.unit} onChange={changeEventHandler}/>
                </Form.Group>
                <Form.Group>
                  <button onClick={() => removeOrderField(orderFieldIndex)} type="button" className="btn btn-danger float-right">Odebrat položku</button>
                </Form.Group>
              </Col>
            </Form.Row>
          ))}
          <Form.Row className={"mb-2"}>
            <Col md={{span: 3, offset: 9}} >
              <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
                <button onClick={() => addOrderField()} type="button" className="btn btn-primary">Přidat položku</button>
              </div>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"note"}>
            <Form.Label>Poznámka</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={order.note} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setOrder(parseInitialOrder())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges(order)} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleOrder(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getOrder, {
    variables: {
      id: match.params.orderId,
    }
  });
  const [updateOrder, updateOrderResponse] = useMutation(updateOrderMutation);

  if (error) return (
    <article className={"singleOrder"}><div className={"danger"}>Chyba při načítání objednávky</div></article>
  ) ;

  if (loading || updateOrderResponse.loading) return (
    <article className={"singleOrder"}><Preloader/></article>
  ) ;

  const {getOrder: order, listPaymentMethods: paymentMethods, listShippingMethods: shippingMethods } = data;

  return (
    <article className={"singleOrder"}>
      <h3>Objednávka: {order.orderNumber} ({match.params.orderId})</h3>
      <hr/>
      <OrderEditForm initialOrder={order} paymentMethods={paymentMethods} shippingMethods={shippingMethods} saveChanges={(changedOrder) => updateOrder({
        variables:{
          input: {...changedOrder, paymentTrackingId: changedOrder.paymentTrackingId || undefined, userName: changedOrder.userName || undefined},
        }
      })}/>
    </article>
  );
}

export default SingleOrder;
