import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
    name: 'menuOpen',
    initialState: false,
    reducers: {
        toggleMenu: (state, action) => {
            state = action.payload
            return state
        }
    }
})

export const { toggleMenu } = menuSlice.actions
export default menuSlice.reducer;