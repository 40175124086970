import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./manufacturerListing.scss"
import Preloader from "../util/Preloader";
import {Link} from 'react-router-dom';
import {gql} from "@apollo/client";
import {graphql} from "@apollo/client/react/hoc";

class ManufacturerListing extends Component {
  renderManufacturers(){
    if (this.props.loading && !this.props.manufacturers){
      return (
        <div className="mlr-auto">
          <Preloader/>
        </div>
      );
    }
    if (this.props.error){
      return (
        <div className="mlr-auto">
          <strong className={"error"}>Nastala chyba při načítání výrobců</strong>
        </div>
      );
    }
    return this.props.manufacturers.map(manufacturer => (
      <Link key={"manufacturer_" + manufacturer.id} to={"/eshop/selectedManufacturers:" + manufacturer.id} className="manufacturer">
        <img src={manufacturer.image} alt={manufacturer.name}/>
      </Link>
    ))
  }

  render(){
    return (
      <section className={"manufacturerListing text-center " + this.props.className}>
        <div className="header text-center">
          <h3 className={"full-width"}>{this.props.heading}</h3>
          <h4 className={"full-width"}>{this.props.subHeading}</h4>
        </div>
        <div className="box box-shadow">
          <div className="manufacturers">
            {this.renderManufacturers()}
          </div>
        </div>
      </section>
    );
  }
}

ManufacturerListing.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  className: PropTypes.string,
};

const ListManufacturers = gql`
  query ListManufacturers {
    listManufacturers{
      items {
        id
        order
        name
        description
        image
      }
    }
  }
`;

export default graphql(ListManufacturers, {
  // options: {
  //   fetchPolicy: "network-only",
  // },
  props: props => ({
    manufacturers: props.data.listManufacturers && props.data.listManufacturers.items && [...props.data.listManufacturers.items].sort((a, b) => a.order - b.order),
    error: props.data.error,
    loading: props.data.loading,
    //props.data fields: error; fetchMore(); loading; menuByPosition /*Or other inner fetched name*/; networkStatus; refetch(); startPolling(); stopPolling(); subscribeToMore(); updateQuery(); variables /* object in shape name: value */
  }),
})(ManufacturerListing)
