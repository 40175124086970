import React, {useEffect, useReducer, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import InputRange from "react-input-range";
import {gql, useQuery} from "@apollo/client";

import { getListManufacturers } from "./EshopCategoryFilterAPI";
import {setEshopDisplay} from "../app/userPreferencesReducer";

import "./eshopCategoryFilter.scss";
import "react-input-range/lib/css/index.css";
import Preloader from "../util/Preloader";
import {useRouteMatch, useHistory} from "react-router-dom";
import {EshopProduct} from "./EshopCategoryProducts";
import {debounce} from "lodash";
import ReactPaginate from "react-paginate";

const searchProductsQuery = gql`
  query SearchFilteredProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
      }
      nextToken
      total
    }
  }
`;
const searchPriceAggregationsQuery = gql`
  query SearchFilteredPriceAggregations(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      aggregations{
        minPrice
        maxPrice
      }
    }
  }
`;
const searchManufacturerAggregationsQuery = gql`
  query SearchFilteredManufacturerAggregations(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      aggregations{
        uniqueManufacturers
      }
    }
  }
`;
const searchAttributeAggregationsQuery = gql`
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      aggregations{
        uniqueAttributes{
          name
          values
        }
      }
    }
  }
`;

const sortTypes = [
  {
    name: "Nejprodávanější",
    order: "desc",
    field: "stock",
  },
  {
    name: "Od nejdražšího",
    order: "desc",
    field: "salePrice",
  },
  {
    name: "Od nejlevnějšího",
    order: "asc",
    field: "salePrice",
  },
  {
    name: "Akce",
    order: "desc",
    field: "featuredSale",
  },
];

const baseFilter = {
  isHidden: {ne: true},
};

const selectedManufacturersReducer = (state, action) => {
  const position = state.indexOf(action.value);

  switch (action.type){
    case "add":
      if (state.indexOf(action.value) === -1){
        return [...state, action.value];
      }
      return state;
    case "clear":
      return [];
    case "set":
      return [...action.value];
    case "remove":
      if (position !== -1){
        let newState = [...state];
        newState.splice(position, 1);
        return newState;
      }
      return state;
    case "toggle":
      if (position === -1){
        return selectedManufacturersReducer(state, {type: "add", value: action.value});
      }
      else{
        return selectedManufacturersReducer(state, {type: "remove", value: action.value});
      }
  }
  return state;
};

const defaultSort = {name: null, order: "asc", field: "apiId"};

const EshopCategoryFilter3 = ({categories}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const productDisplay = useSelector(
    (state) => state.userPreferences.eshop.productDisplay
  );

  const [initialised, setInitialised] = useState(false);
  const [showMinPrice, setShowMinPrice] = useState(false);
  const [showMaxPrice, setShowMaxPrice] = useState(false);

  //Responsive changes
  const [pageItems, setPageItems] = useState(12);
  const [manufacturerFilterLimit, setManufacturerFilterLimit] = useState(window.innerWidth <= 768 ? 5 : 4);
  //Computed values
  const [categoryIds, setCategoryIds] = useState({});
  const [currentSort, setCurrentSort] = useState(defaultSort);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [selectedManufacturers, dispatchSelectedManufacturers] = useReducer(selectedManufacturersReducer, []);
  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(0);
  const [fulltext, setFulltext] = useState(null);

  const computeFilter = () => {
    let toReturn = {
      salePrice: {range: [minPrice, maxPrice]},
      or: selectedManufacturers.length ? selectedManufacturers.map(manufacturerId => ({
        manufacturerId: {eq: manufacturerId},
      })) : undefined,
      categoryId: categoryIds.categoryId ? {eq: categoryIds.categoryId} : undefined,
      subCategoryId: categoryIds.subCategoryId ? {eq: categoryIds.subCategoryId} : undefined,
      subSubCategoryId: categoryIds.subSubCategoryId ? {eq: categoryIds.subSubCategoryId} : undefined,
      subSubSubCategoryId: categoryIds.subSubSubCategoryId ? {eq: categoryIds.subSubSubCategoryId} : undefined,
      subSubSubSubCategoryId: categoryIds.subSubSubSubCategoryId ? {eq: categoryIds.subSubSubSubCategoryId} : undefined,
    };
    if (fulltext){
      if (!toReturn.or) toReturn.or = [];
      const splitWhisperer = fulltext.split(/\s/).filter(word => !!word);

      toReturn.or.push({
        and: splitWhisperer.map(word => ({
          id: {matchPhrasePrefix: word},
        }))
      });
      toReturn.or.push({
        and: splitWhisperer.map(word => ({
          name: {matchPhrasePrefix: word},
        }))
      });
      toReturn.or.push({
        and: splitWhisperer.map(word => ({
          manufacturerProductCode: {matchPhrasePrefix: word},
        }))
      });
      toReturn.or.push({
        and: splitWhisperer.map(word => ({
          apiId: {matchPhrasePrefix: word},
        }))
      });
    }
    return toReturn;
  }
  const [filter, setFilter] = useState(computeFilter());
  const [minPriceLimit, setMinPriceLimit] = useState(NaN);
  const [maxPriceLimit, setMaxPriceLimit] = useState(NaN);
  const [manufacturers, setManufacturers] = useState([]);

  const {loading: manufacturersLoading, error: manufacturersError, data: manufacturersData} = useQuery(getListManufacturers);

  const loadStateFromUrl = () => {
    console.log("Loading state from url", match);
    const paramsFilter = match?.params?.filter;
    const splitFilter = paramsFilter ? paramsFilter.split("/") : [];
    let setToDefault = {
      minPrice: () => {setMinPrice(null); setShowMinPrice(null)},
      maxPrice: () => {setMaxPrice(null); setShowMaxPrice(null)},
      manufacturers: () => dispatchSelectedManufacturers({
        type: "clear",
      }),
      page: () => setPage(0),
      sort: () => setSort(null),
      fulltext: () => setFulltext(null),
    };
    let categorySlugs = [];
    splitFilter.forEach(filterParam => {
      if (filterParam.indexOf(":") === -1){
        categorySlugs.push(filterParam);
      }
      const [paramName, paramValue] = filterParam.split(":");
      let parsedParamValue;
      switch (paramName){
        case "selectedManufacturers":
          dispatchSelectedManufacturers({
            type: "set",
            value: paramValue.split(","),
          })
          delete setToDefault.manufacturers;
          break;
        case "minPrice":
          parsedParamValue = Math.floor(parseFloat(paramValue));
          setMinPrice(parsedParamValue);
          setShowMinPrice(parsedParamValue);
          delete setToDefault.minPrice;
          break;
        case "maxPrice":
          parsedParamValue = Math.ceil(parseFloat(paramValue));
          setShowMaxPrice(parsedParamValue);
          setMaxPrice(parsedParamValue);
          delete setToDefault.maxPrice;
          break;
        case "page":
          setPage(parseInt(paramValue));
          delete setToDefault.page;
          break;
        case "sort":
          setSort(paramValue);
          delete setToDefault.sort;
          break;
        case "fulltext":
          setFulltext(paramValue);
          delete setToDefault.fulltext;
          break;
      }
    });
    Object.keys(setToDefault).forEach(key => setToDefault[key]());

    let parsedCategoryIds = {
      categoryId: undefined,
      subCategoryId: undefined,
      subSubCategoryId: undefined,
      subSubSubCategoryId: undefined,
      subSubSubSubCategoryId: undefined,
    }
    let actualCategory = null;
    let actualLevel = 0;
    const levels = Object.keys(parsedCategoryIds);
    let currentSelection = categories;
    for (let i = 0; i < categorySlugs.length; i++) {
      for (let j = 0; j < currentSelection.length; j++) {
        if (currentSelection[j].slug === categorySlugs[i]){
          actualCategory = currentSelection[j];
          parsedCategoryIds[levels[actualLevel]] = actualCategory.id;
          actualLevel++;
          currentSelection = actualCategory?.children?.items || [];
        }
      }
    }
    setCategoryIds(parsedCategoryIds);
  };

  const afterFirstRender = () => {
    function resizeListener(event){
      window.requestAnimationFrame(() => {
        if (manufacturerFilterLimit < 6){
          setManufacturerFilterLimit(window.innerWidth <= 768 ? 5 : 4);
        }
      });
    }
    window.addEventListener("resize", resizeListener)

    setInitialised(true);

    return function cleanup(){
      window.removeEventListener("resize", resizeListener);
    }
  };
  useEffect(afterFirstRender, []);

  useEffect(loadStateFromUrl, [match.url]);

  //Computed value for sort
  const getCurrentSort = () => {
    setCurrentSort(sortTypes.find((sortType) => sortType.name === sort) || defaultSort);
  };
  useEffect(getCurrentSort, [sort]);

  const onFilterChanged = () => {
    setFilter(computeFilter);

    //TODO: This can not be done here as it creates a cycle
    // changeFilterFields([
    //   {
    //     field: "minPrice",
    //     value: minPrice,
    //   },
    //   {
    //     field: "maxPrice",
    //     value: maxPrice,
    //   },
    //   {
    //     field: "selectedManufacturers",
    //     value: selectedManufacturers,
    //   },
    //   {
    //     field: "sort",
    //     value: sort,
    //   },
    //   {
    //     field: "page",
    //     value: page,
    //   }
    // ]);
  }
  useEffect(onFilterChanged, [minPrice, maxPrice, selectedManufacturers, categoryIds, sort, page, fulltext]);

  function changeFilterFields(fields){
    let url = history.location.pathname;
    if (url[url.length - 1] === "/"){
      url = url.substr(0, url.length - 1);
    }
    fields.forEach(({field, value}) => {
      url = changeFilterField(field, value, false, url);
    });
    history.push(url);
  }
  function changeFilterField(field, value, redirect = true, url = null){
    console.log("changeFilterField", {field, value, redirect, url});
    let splitUrl = null;
    if (url){
      splitUrl = url.split("/");
    }
    else{
      splitUrl = match.url.split("/");
    }
    let newUrl = [];
    let changed = false;
    splitUrl.forEach(urlFragment => {
      const [urlName, urlValue] = urlFragment.split(":");
      if (typeof urlValue === "undefined"){
        newUrl.push(urlFragment);
        return;
      }
      if (urlName === field){
        if (Array.isArray(value) && value.length < 1) return;
        if (value) newUrl.push(field + ":" + value);
        changed = true;
        return;
      }
      newUrl.push(urlFragment);
    });
    if (!changed && value){
      if (!Array.isArray(value) || value.length)
        newUrl.push(field + ":" + value);
    }
    if (redirect) history.push(newUrl.join("/"));
    return newUrl.join("/");
  }

  const {loading: productsLoading, error: productsError, data: productsData} = useQuery(searchProductsQuery, {
    variables: {
      filter: {...filter, ...baseFilter},
      sort: currentSort ? {field: currentSort.field, direction: currentSort.order} : null,
      limit: pageItems,
      from: page * pageItems,
    },
    context: {
      debounceKey: 'eshopFilterProductSearch',
    },
  });
  const {loading: priceLimitLoading, error: priceLimitError, data: priceLimitData} = useQuery(searchPriceAggregationsQuery, {
    variables: {
      filter: {...filter, ...baseFilter, salePrice: undefined, aggregate: true},
      sort: currentSort ? {field: currentSort.field, direction: currentSort.order} : null,
      limit: 0,
    },
    context: {
      debounceKey: 'eshopFilterPriceAggregation',
    },
  });
  const {loading: manufacturerAggregationLoading, error: manufacturerAggregationError, data: manufacturerAggregationData} = useQuery(searchManufacturerAggregationsQuery, {
    variables: {
      filter: {...filter, ...baseFilter, or: undefined, aggregate: true},
      sort: currentSort ? {field: currentSort.field, direction: currentSort.order} : null,
      limit: 0,
    },
    context: {
      debounceKey: 'eshopFilterManufacturersAggregation',
    },
  });

  const watchPriceLimits = () => {
    const [newMinPriceLimit, newMaxPriceLimit] = [
      Math.floor(parseFloat(priceLimitData?.searchProducts?.aggregations?.minPrice)),
      Math.ceil(parseFloat(priceLimitData?.searchProducts?.aggregations?.maxPrice))
    ];
    setMinPriceLimit(newMinPriceLimit);
    setMaxPriceLimit(newMaxPriceLimit);
    if (isNaN(newMinPriceLimit) || isNaN(newMaxPriceLimit)){
      return;
    }
    if (minPrice && (minPrice < newMinPriceLimit)){
      setMinPrice(null);
    }
    if (maxPrice && (maxPrice > newMaxPriceLimit)){
      setMaxPrice(null);
    }
  }
  useEffect(watchPriceLimits, [minPrice, maxPrice, priceLimitData]);

  const watchManufacturers = () => {
    if (!manufacturersData?.listManufacturers?.items || !manufacturerAggregationData?.searchProducts?.aggregations?.uniqueManufacturers){
      return;
    }
    setManufacturers([...manufacturersData.listManufacturers.items]
      .filter((manufacturer) => manufacturerAggregationData.searchProducts.aggregations.uniqueManufacturers.indexOf(manufacturer.id) !== -1)
      .sort((a, b) => {
        const aSelected = selectedManufacturers.indexOf(a.id) !== -1;
        const bSelected = selectedManufacturers.indexOf(b.id) !== -1;
        if (aSelected && !bSelected) return -1;
        if (bSelected && !aSelected) return 1;
        return a.order - b.order;
      }));
  }
  useEffect(watchManufacturers, [manufacturersData, manufacturerAggregationData, selectedManufacturers]);

  const [minPriceTimeout, setMinPriceTimeout] = useState(null);
  const debouncedChangeMinPrice = (value) => {
    if (minPriceTimeout) clearTimeout(minPriceTimeout);
    setMinPriceTimeout(setTimeout(() => {
      changeFilterField("minPrice", value);
      setMinPriceTimeout(null);
    }, 500));
  }

  const [maxPriceTimeout, setMaxPriceTimeout] = useState(null);
  const debouncedChangeMaxPrice = (value) => {
    if (maxPriceTimeout) clearTimeout(maxPriceTimeout);
    setMaxPriceTimeout(setTimeout(() => {
      changeFilterField("maxPrice", value);
      setMaxPriceTimeout(null);
    }, 500));
  }

  const priceRangeChanged = (value) => {
    let min = Math.floor(value.min);
    if (min <= minPriceLimit) min = null;
    let max = Math.ceil(value.max);
    if (max >= maxPriceLimit) max = null;

    if (min !== minPrice){
      setShowMinPrice(min);
      debouncedChangeMinPrice(min);
      return;
    }
    if (max !== maxPrice){
      setShowMaxPrice(max);
      debouncedChangeMaxPrice(max);
    }
  }

  const loadingError = manufacturersError || priceLimitError || manufacturerAggregationError || productsError;
  if (loadingError){
    console.error(loadingError);
    return (
      <div className="container mbt-25 categoryFilter">
        Nastala chyba při načítání filtru
      </div>
    );
  }
  if (
    (manufacturersLoading && !manufacturersData) ||
    (priceLimitLoading && !priceLimitData) ||
    (manufacturerAggregationLoading && !manufacturerAggregationData) ||
    (productsLoading && !productsData) || !initialised
  ){
    return (
      <div className="container mbt-25 categoryFilter">
        <Preloader/>
      </div>
    );
  }

  return (<>
    <div className="container mbt-25 categoryFilter">
      <div className="row sortProductsWrapper hidden-mobile">
        <div className="col w-66p productOrder">
          <div className="row">
            <div className="filterTitle">Seřadit produkty</div>
          </div>
          <div className="row buttons">
            {sortTypes.map(sortType => (
              <button
                onClick={() => changeFilterField("sort", sortType.name)}
                // onClick={() => setSort(sortType.name)}
                key={"sortType_" + sortType.name}
                className={"button medium light-gray " + (sort === sortType.name ? "active" : "")}
              >
                {sortType.name}
              </button>
            ))}
          </div>
        </div>
        <div className="col w-33p productPriceRange">
          <div className="row">
            <div className="filterTitle">Cenové rozpětí</div>
          </div>
          <div className="row priceIntervalSlider ">
            <InputRange
              step={0.1}
              maxValue={maxPriceLimit}
              minValue={minPriceLimit}
              value={({min: (showMinPrice || minPriceLimit), max: (showMaxPrice || maxPriceLimit)})}
              onChange={priceRangeChanged}
            />
            <div className="priceInterval">
              <div className="priceFrom">{`${(showMinPrice || minPriceLimit).toFixed(0)}`} ,-</div>
              <div className="priceTo">{`${(showMaxPrice || maxPriceLimit).toFixed(0)}`} ,-</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row sortProductsWrapper hidden-desktop">
        <div className="col">
          <div className="row productOrder">
            <div className="col">
              <h4 className="filterTitle mbt-auto mr-25">Seřadit produkty</h4>
            </div>
            <div className="col flex-grow-1">
              <select className={"flex-grow-1"} value={sort === null ? "null" : sort}
                      onChange={({target}) => changeFilterField("sort", target.value)}
                      // onChange={({target}) => setSort(target.value)}
                      name="productOrder">
                <option value={"null"}>Výchozí</option>
                {sortTypes.map(sortType => (
                  <option
                    key={"sortType_" + sortType.name}
                  >
                    {sortType.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row priceInterval">
            Cena: od <strong>{`${(showMinPrice || minPriceLimit).toFixed(2)}`} Kč</strong> do <strong>{`${(showMaxPrice || maxPriceLimit).toFixed(1)}`} Kč</strong>
          </div>
          <div className="row priceIntervalSlider">
            <InputRange
              step={0.1}
              maxValue={maxPriceLimit}
              minValue={minPriceLimit}
              value={({min: showMinPrice, max: showMaxPrice})}
              onChange={priceRangeChanged}
            />
          </div>
        </div>
      </div>

      <div className="row productDistributor">
        <h4 className={"filterTitle"}>Vyberte výrobce</h4>
        <div className="row factories">
          {manufacturers.slice(0, manufacturers.length >= manufacturerFilterLimit ? manufacturerFilterLimit - 1 + (window.innerWidth <= 768) : manufacturerFilterLimit).map(manufacturer => (
            <div key={manufacturer.id} className="factory"
              onClick={() => {
                changeFilterField("selectedManufacturers", selectedManufacturersReducer(selectedManufacturers, {
                  type: "toggle",
                  value: manufacturer.id,
                }))
                // dispatchSelectedManufacturers({
                //   type: "toggle",
                //   value: manufacturer.id,
                // })
              }}
            >
              <input
                type="checkbox"
                checked={selectedManufacturers.indexOf(manufacturer.id) !== -1}
                onChange={() => {}}
                // onChange={() => dispatchSelectedManufacturers({
                //   type: "toggle",
                //   value: manufacturer.id,
                // })}
                className="logo"
              />
              <img src={manufacturer.image} alt={manufacturer.name} className="logo" />
            </div>
          ))}
          {manufacturers.length > manufacturerFilterLimit ? (
            <button
              className="factory factoryBtn arrow-right"
              onClick={() => setManufacturerFilterLimit(10000)}
            >
              Zobrazit další
            </button>
          ) : null}
          {manufacturerFilterLimit === 10000 ? (
            <button
              className="factory factoryBtn  arrow-left"
              onClick={() => setManufacturerFilterLimit(5)}
            >
              Skrýt
            </button>
          ) : null}
        </div>
      </div>
      {/*TODO: sortingTags class refactor*/}
      <div className=" row sortingTags">
        <div className="filterTitle filterView hidden-mobile">
          <span>Zobrazení</span>
          <button
            className={
              (productDisplay === "col")
                ? "active button small light-gray"
                : "button small light-gray"
            }
            onClick={() =>
              dispatch(setEshopDisplay('col'))
            }
          >
            <img src="/staticImages/gridView.svg" alt="Sloupcový výpis" />
          </button>
          <button
            className={
              (productDisplay === "row")
                ? "active button small light-gray"
                : "button small light-gray"
            }
            onClick={() =>
              dispatch(setEshopDisplay('row'))
            }
          >
            <img src="/staticImages/rowView.svg" alt="Řádkový výpis" />
          </button>
        </div>
        <div className="filterTitle">{`Výsledek filtrování - ${productsData.searchProducts.total} ks`}</div>
      </div>
    </div>
    <div className={(productDisplay === "col") ? "eshopProductGrid" : "eshopProductFlex"}> {/*If productDisplay is not col, it is row for now*/}
      {productsData?.searchProducts?.items?.map((product, i) => (
        <EshopProduct product={product} key={"product_" + product.id} cart={cart}/>
      ))}
    </div>
    {productsData?.searchProducts?.total ? (
      <>
        <div className="categoryPagination hidden-mobile">
          <ReactPaginate
            pageCount={Math.ceil(productsData.searchProducts.total / pageItems)}
            previousLabel="Předchozí"
            nextLabel="Další"
            pageRangeDisplayed={3}
            forcePage={page}
            onPageChange={(e) => {
              // setPage(e.selected);
              changeFilterField("page", e.selected);
            }}
          />
        </div>
        <div className="categoryPagination hidden-desktop">
          <ReactPaginate
            pageCount={Math.ceil(productsData.searchProducts.total / pageItems)}
            previousLabel="Předchozí"
            nextLabel="Další"
            pageRangeDisplayed={0}
            marginPagesDisplayed={0}
            forcePage={page}
            onPageChange={(e) => {
              // setPage(e.selected);
              changeFilterField("page", e.selected);
            }}
          />
        </div>
      </>
    ) : null}
  </>);
}

export default EshopCategoryFilter3;
