import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listBanners = gql`
  query ListBanners(
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        order
        target
        title
        text
        buttonText
        buttonClass
        bannerClass
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const updateBannerMutation = gql`
  mutation UpdateBanner(
    $input: UpdateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    updateBanner(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteBannerMutation = gql`
  mutation DeleteBanner(
    $input: DeleteBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    deleteBanner(input: $input, condition: $condition) {
      id
    }
  }
`;

const createBannerMutation = gql`
  mutation CreateBanner(
    $input: CreateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    createBanner(input: $input, condition: $condition) {
      id
    }
  }
`;

export const bannerPositions = {
  'homepage-bottom': "Na spodu úvodní stránky",
  'homepage-top': "Nahoře úvodní stránky",
};

function BannerRow({banner, selected, onSelectChange}){
  const [updateBanner, updateBannerResponse] = useMutation(updateBannerMutation, {
    refetchQueries: [
      'ListBanners'
    ]
  });
  const [deleteBanner, deleteBannerResponse] = useMutation(deleteBannerMutation, {
    refetchQueries: [
      'ListBanners'
    ]
  });
  const [order, updateOrder] = useState(banner.order);
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat banner"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{banner.id}</td>
      <td>{banner.title}</td>
      <td>{bannerPositions[banner.position] || banner.position}</td>
      <td><Form.Control type={"number"} size={"sm"} value={order} onKeyDown={({key}) => key === "Enter" ? updateBanner({variables: {input: {id: banner.id, order}}}) : null} onChange={({target}) => updateOrder(parseInt(target.value))}/></td>
      <td>{banner.createdAt}</td>
      <td>
        {updateBannerResponse.loading || deleteBannerResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s bannerem">
            <Link to={"/admin/banners/" + banner.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat banner: " + banner.title + "?"} onClick={() => deleteBanner({variables: {input: {id: banner.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Banners(props){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("position|order");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listBanners, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createBanner, createBannerResponse] = useMutation(createBannerMutation, {
    refetchQueries: [
      'ListBanners'
    ]
  });
  const [selectedBanners, dispatchSelectedBanners] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listBanners.items.length){
          return {}
        }
        data.listBanners.items.forEach(banner => newState[banner.id] = banner);
        return newState;
      case "toggle":
        if(newState[action.banner.id]){
          delete newState[action.banner.id];
        }
        else{
          newState[action.banner.id] = action.banner;
        }
        return newState;
      case "select":
        newState[action.banner.id] = action.banner;
        return newState;
      case "deselect":
        delete newState[action.banner.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání bannerů</div>
    );

    const banners = [...data.listBanners.items].sort((a, b) => {
      let sortFields = sortField.split('|');
      let sortResult = 0;
      sortFields.forEach(sortField => {
        if (sortResult !== 0) return;
        if (a[sortField] > b[sortField]) sortResult = 1;
        if (a[sortField] < b[sortField]) sortResult = -1;
      })
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedBanners).length === banners.length} onChange={() => dispatchSelectedBanners({type: "selectAll"})} aria-label={"Vybrat banner"} />
            </th>
            <th>id</th>
            <th>Titulek {renderSortDirection("title")}</th>
            <th>Pozice {renderSortDirection("position")}</th>
            <th>Pořadí {renderSortDirection("order")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {banners.map(banner => (
            <BannerRow key={"banner_" + banner.id} onSelectChange={() => dispatchSelectedBanners({type: "toggle", banner})} selected={!!selectedBanners[banner.id]} banner={banner}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"banners"}>
      <h3>Bannery</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createBannerResponse.loading} onClick={() => createBanner({variables: {input: {
              title: "Nový banner",
              position: "none",
              order: 0,
            }}})}>Vytvořit nový</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Banners;
