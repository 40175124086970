export const userStatuses = {
  UNCONFIRMED: "Nepotvrzený",
  CONFIRMED: "Potvrzený",
  ARCHIVED: "Archivovaný",
  COMPROMISED: "Nabouraný",
  UNKNOWN: "Neznámý",
  RESET_REQUIRED: "Vyžádaná změna hesla",
  FORCE_CHANGE_PASSWORD: "Vynucená změna hesla"
};

export const orderStatuses = {
  "new": "Nová",
  "paid": "Zaplacená",
  "complete": "Vyřízená",
  "cancelled": "Zrušená",
};

export const userAttributes = {
  "custom:companyName": {
    name: "Název společnosti",
    type: "text",
  },
  "custom:companyId": {
    name: "IČO",
    type: "text",
  },
  "custom:companyTaxId": {
    name: "DIČ",
    type: "text",
  },
  "custom:pricesInclVat": {
    name: "Zobrazovat ceny vč. DPH",
    type: "toggle",
  },
  "name": {
    name: "Jméno",
    type: "text",
  },
  "custom:street": {
    name: "Ulice a č.p.",
    type: "text",
  },
  "custom:city": {
    name: "Město",
    type: "text",
  },
  "custom:zip": {
    name: "PSČ",
    type: "text",
  },
  "phone_number": {
    name: "Telefon",
    type: "text",
  },
  "email": {
    name: "Email",
    type: "text",
  },
  "custom:sendNews": {
    name: "Odebírá novinky",
    type: "toggle",
  },
};
