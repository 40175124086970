/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrderHistogram = /* GraphQL */ `
  query GetOrderHistogram {
    getOrderHistogram {
      days {
        day
        count
      }
    }
  }
`;
export const getOrderAggregation = /* GraphQL */ `
  query GetOrderAggregation {
    getOrderAggregation {
      today {
        count
        items
        price
        vatPrice
      }
      thisWeek {
        count
        items
        price
        vatPrice
      }
      thisMonth {
        count
        items
        price
        vatPrice
      }
      thisYear {
        count
        items
        price
        vatPrice
      }
      yesterday {
        count
        items
        price
        vatPrice
      }
      lastWeek {
        count
        items
        price
        vatPrice
      }
      lastMonth {
        count
        items
        price
        vatPrice
      }
      lastYear {
        count
        items
        price
        vatPrice
      }
    }
  }
`;
export const getProductsExport = /* GraphQL */ `
  query GetProductsExport($id: ID!) {
    getProductsExport(id: $id) {
      id
      fileName
      status
      createdAt
      updatedAt
    }
  }
`;
export const listProductsExports = /* GraphQL */ `
  query ListProductsExports(
    $filter: ModelProductsExportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileName
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProductsExports = /* GraphQL */ `
  query SearchProductsExports(
    $filter: SearchableProductsExportFilterInput
    $sort: SearchableProductsExportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsExports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        fileName
        status
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getProductsImport = /* GraphQL */ `
  query GetProductsImport($id: ID!) {
    getProductsImport(id: $id) {
      id
      fileName
      status
      createdAt
      updatedAt
    }
  }
`;
export const listProductsImports = /* GraphQL */ `
  query ListProductsImports(
    $filter: ModelProductsImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileName
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProductsImports = /* GraphQL */ `
  query SearchProductsImports(
    $filter: SearchableProductsImportFilterInput
    $sort: SearchableProductsImportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsImports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        fileName
        status
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      name
      position
      createdAt
      updatedAt
      elements {
        nextToken
      }
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        position
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuByPosition = /* GraphQL */ `
  query MenuByPosition(
    $position: String
    $sortDirection: ModelSortDirection
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuByPosition(
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        position
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuElement = /* GraphQL */ `
  query GetMenuElement($id: ID!) {
    getMenuElement(id: $id) {
      id
      title
      type
      target
      menuID
      order
      createdAt
      updatedAt
      menu {
        id
        name
        position
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMenuElements = /* GraphQL */ `
  query ListMenuElements(
    $filter: ModelMenuElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuElements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        target
        menuID
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const elementsInMenu = /* GraphQL */ `
  query ElementsInMenu(
    $menuID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    elementsInMenu(
      menuID: $menuID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        target
        menuID
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerPill = /* GraphQL */ `
  query GetBannerPill($id: ID!) {
    getBannerPill(id: $id) {
      id
      bannerID
      order
      text
      icon
      target
      class
      createdAt
      updatedAt
      banner {
        id
        position
        order
        target
        title
        text
        buttonText
        buttonClass
        bannerClass
        image
        specialFunction
        createdAt
        updatedAt
      }
    }
  }
`;
export const listBannerPills = /* GraphQL */ `
  query ListBannerPills(
    $filter: ModelBannerPillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerPills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bannerID
        order
        text
        icon
        target
        class
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pillsInHeader = /* GraphQL */ `
  query PillsInHeader(
    $bannerID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBannerPillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pillsInHeader(
      bannerID: $bannerID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bannerID
        order
        text
        icon
        target
        class
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBanner = /* GraphQL */ `
  query GetBanner($id: ID!) {
    getBanner(id: $id) {
      id
      position
      order
      target
      title
      text
      buttonText
      buttonClass
      bannerClass
      image
      specialFunction
      createdAt
      updatedAt
      pills {
        nextToken
      }
    }
  }
`;
export const listBanners = /* GraphQL */ `
  query ListBanners(
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        order
        target
        title
        text
        buttonText
        buttonClass
        bannerClass
        image
        specialFunction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bannersInPosition = /* GraphQL */ `
  query BannersInPosition(
    $position: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bannersInPosition(
      position: $position
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        order
        target
        title
        text
        buttonText
        buttonClass
        bannerClass
        image
        specialFunction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getManufacturer = /* GraphQL */ `
  query GetManufacturer($id: ID!) {
    getManufacturer(id: $id) {
      id
      order
      name
      description
      image
      createdAt
      updatedAt
      products {
        nextToken
      }
    }
  }
`;
export const listManufacturers = /* GraphQL */ `
  query ListManufacturers(
    $filter: ModelManufacturerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManufacturers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductSubSubSubSubCategory = /* GraphQL */ `
  query GetProductSubSubSubSubCategory($id: ID!) {
    getProductSubSubSubSubCategory(id: $id) {
      id
      name
      slug
      description
      parentId
      order
      createdAt
      updatedAt
      parent {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
    }
  }
`;
export const listProductSubSubSubSubCategorys = /* GraphQL */ `
  query ListProductSubSubSubSubCategorys(
    $filter: ModelProductSubSubSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductSubSubSubSubCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCategoryOfProductSubSubSubCategory = /* GraphQL */ `
  query SubCategoryOfProductSubSubSubCategory(
    $parentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubSubSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoryOfProductSubSubSubCategory(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productSubSubSubSubCategorysBySlug = /* GraphQL */ `
  query ProductSubSubSubSubCategorysBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubSubSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productSubSubSubSubCategorysBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductSubSubSubCategory = /* GraphQL */ `
  query GetProductSubSubSubCategory($id: ID!) {
    getProductSubSubSubCategory(id: $id) {
      id
      name
      slug
      description
      parentId
      order
      createdAt
      updatedAt
      children {
        nextToken
      }
      parent {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
    }
  }
`;
export const listProductSubSubSubCategorys = /* GraphQL */ `
  query ListProductSubSubSubCategorys(
    $filter: ModelProductSubSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductSubSubSubCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCategoryOfProductSubSubCategory = /* GraphQL */ `
  query SubCategoryOfProductSubSubCategory(
    $parentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoryOfProductSubSubCategory(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productSubSubSubCategorysBySlug = /* GraphQL */ `
  query ProductSubSubSubCategorysBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productSubSubSubCategorysBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductSubSubCategory = /* GraphQL */ `
  query GetProductSubSubCategory($id: ID!) {
    getProductSubSubCategory(id: $id) {
      id
      name
      slug
      description
      parentId
      order
      createdAt
      updatedAt
      children {
        nextToken
      }
      parent {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
    }
  }
`;
export const listProductSubSubCategorys = /* GraphQL */ `
  query ListProductSubSubCategorys(
    $filter: ModelProductSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductSubSubCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCategoryOfProductSubCategory = /* GraphQL */ `
  query SubCategoryOfProductSubCategory(
    $parentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoryOfProductSubCategory(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productSubSubCategorysBySlug = /* GraphQL */ `
  query ProductSubSubCategorysBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productSubSubCategorysBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductSubCategory = /* GraphQL */ `
  query GetProductSubCategory($id: ID!) {
    getProductSubCategory(id: $id) {
      id
      name
      slug
      description
      parentId
      order
      createdAt
      updatedAt
      children {
        nextToken
      }
      parent {
        id
        name
        slug
        description
        order
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
    }
  }
`;
export const listProductSubCategorys = /* GraphQL */ `
  query ListProductSubCategorys(
    $filter: ModelProductSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductSubCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCategoryOfProductCategory = /* GraphQL */ `
  query SubCategoryOfProductCategory(
    $parentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoryOfProductCategory(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productSubCategorysBySlug = /* GraphQL */ `
  query ProductSubCategorysBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productSubCategorysBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($id: ID!) {
    getProductCategory(id: $id) {
      id
      name
      slug
      description
      order
      createdAt
      updatedAt
      children {
        nextToken
      }
      products {
        nextToken
      }
    }
  }
`;
export const listProductCategorys = /* GraphQL */ `
  query ListProductCategorys(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productCategorysBySlug = /* GraphQL */ `
  query ProductCategorysBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productCategorysBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductRelation = /* GraphQL */ `
  query GetProductRelation($id: ID!) {
    getProductRelation(id: $id) {
      id
      detailId
      relatedId
      createdAt
      updatedAt
      detailProduct {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      relatedProduct {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
    }
  }
`;
export const listProductRelations = /* GraphQL */ `
  query ListProductRelations(
    $filter: ModelProductRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        detailId
        relatedId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductsRelatedTo = /* GraphQL */ `
  query GetProductsRelatedTo(
    $detailId: ID
    $relatedId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsRelatedTo(
      detailId: $detailId
      relatedId: $relatedId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        detailId
        relatedId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductsInverseRelations = /* GraphQL */ `
  query GetProductsInverseRelations(
    $relatedId: ID
    $detailId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsInverseRelations(
      relatedId: $relatedId
      detailId: $detailId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        detailId
        relatedId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      isHidden
      notForSale
      linksTo
      manufacturerId
      returnsCategory
      apiId
      manufacturerProductCode
      description
      excerpt
      stock
      minimalAmount
      restockDays
      weight
      country
      warrantyTime
      name
      slug
      price
      salePrice
      isRental
      isInstallation
      securityDeposit
      dailyRentalFee
      featuredSale
      featuredNews
      ean
      unit
      vat
      apiTimestamp
      createdAt
      updatedAt
      categoryId
      subCategoryId
      subSubCategoryId
      subSubSubCategoryId
      subSubSubSubCategoryId
      attributes {
        name
        value
        unit
      }
      forbiddenShippingSlugs
      manufacturer {
        id
        order
        name
        description
        image
        createdAt
        updatedAt
      }
      subSubSubSubCategory {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      subSubSubCategory {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      subSubCategory {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      subCategory {
        id
        name
        slug
        description
        parentId
        order
        createdAt
        updatedAt
      }
      category {
        id
        name
        slug
        description
        order
        createdAt
        updatedAt
      }
      attachments {
        nextToken
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const manufacturerProducts = /* GraphQL */ `
  query ManufacturerProducts(
    $manufacturerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manufacturerProducts(
      manufacturerId: $manufacturerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsInCategory = /* GraphQL */ `
  query ProductsInCategory(
    $categoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsInCategory(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsInSubCategory = /* GraphQL */ `
  query ProductsInSubCategory(
    $subCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsInSubCategory(
      subCategoryId: $subCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsInSubSubCategory = /* GraphQL */ `
  query ProductsInSubSubCategory(
    $subSubCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsInSubSubCategory(
      subSubCategoryId: $subSubCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsInSubSubSubCategory = /* GraphQL */ `
  query ProductsInSubSubSubCategory(
    $subSubSubCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsInSubSubSubCategory(
      subSubSubCategoryId: $subSubSubCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsInSubSubSubSubCategory = /* GraphQL */ `
  query ProductsInSubSubSubSubCategory(
    $subSubSubSubCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsInSubSubSubSubCategory(
      subSubSubSubCategoryId: $subSubSubSubCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsByApiId = /* GraphQL */ `
  query ProductsByApiId(
    $apiId: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByApiId(
      apiId: $apiId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const productsBySlug = /* GraphQL */ `
  query ProductsBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
      nextToken
      total
      aggregations {
        minPrice
        maxPrice
        uniqueManufacturers
      }
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      productId
      apiId
      file
      type
      typeDesctiption
      description
      apiTimestamp
      createdAt
      updatedAt
      product {
        id
        isHidden
        notForSale
        linksTo
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        minimalAmount
        restockDays
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
        forbiddenShippingSlugs
      }
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        apiId
        file
        type
        typeDesctiption
        description
        apiTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productAttachments = /* GraphQL */ `
  query ProductAttachments(
    $productId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productAttachments(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        apiId
        file
        type
        typeDesctiption
        description
        apiTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAllowedPaymentShipment = /* GraphQL */ `
  query GetAllowedPaymentShipment($id: ID!) {
    getAllowedPaymentShipment(id: $id) {
      id
      paymentMethodId
      shippingMethodId
      createdAt
      updatedAt
      shippingMethod {
        id
        name
        description
        fee
        feePercent
        order
        requiresShippingAddress
        heurekaId
        minAllowedCartPrice
        maxAllowedCartPrice
        slug
        createdAt
        updatedAt
      }
      paymentMethod {
        id
        name
        description
        fee
        feePercent
        order
        handler
        sendEmail
        minAllowedCartPrice
        maxAllowedCartPrice
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAllowedPaymentShipments = /* GraphQL */ `
  query ListAllowedPaymentShipments(
    $filter: ModelAllowedPaymentShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllowedPaymentShipments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentMethodId
        shippingMethodId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allowedPayment = /* GraphQL */ `
  query AllowedPayment(
    $paymentMethodId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAllowedPaymentShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allowedPayment(
      paymentMethodId: $paymentMethodId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentMethodId
        shippingMethodId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allowedShipment = /* GraphQL */ `
  query AllowedShipment(
    $shippingMethodId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAllowedPaymentShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allowedShipment(
      shippingMethodId: $shippingMethodId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentMethodId
        shippingMethodId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShippingMethod = /* GraphQL */ `
  query GetShippingMethod($id: ID!) {
    getShippingMethod(id: $id) {
      id
      name
      description
      fee
      feePercent
      order
      requiresShippingAddress
      heurekaId
      minAllowedCartPrice
      maxAllowedCartPrice
      slug
      createdAt
      updatedAt
      allowedPaymentConnection {
        nextToken
      }
    }
  }
`;
export const listShippingMethods = /* GraphQL */ `
  query ListShippingMethods(
    $filter: ModelShippingMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        fee
        feePercent
        order
        requiresShippingAddress
        heurekaId
        minAllowedCartPrice
        maxAllowedCartPrice
        slug
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      name
      description
      fee
      feePercent
      order
      handler
      sendEmail
      minAllowedCartPrice
      maxAllowedCartPrice
      createdAt
      updatedAt
      allowedShipmentConnection {
        nextToken
      }
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        fee
        feePercent
        order
        handler
        sendEmail
        minAllowedCartPrice
        maxAllowedCartPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($name: String!) {
    getSettings(name: $name) {
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSettingss = /* GraphQL */ `
  query ListSettingss(
    $name: String
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettingss(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      orderNumber
      invoiceNumber
      userName
      fields {
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      shippingMethodId
      shipmentTrackingId
      paymentMethodId
      paymentTrackingId
      orderStatus
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
      note
      createdAt
      updatedAt
      shippingMethod {
        id
        name
        description
        fee
        feePercent
        order
        requiresShippingAddress
        heurekaId
        minAllowedCartPrice
        maxAllowedCartPrice
        slug
        createdAt
        updatedAt
      }
      paymentMethod {
        id
        name
        description
        fee
        feePercent
        order
        handler
        sendEmail
        minAllowedCartPrice
        maxAllowedCartPrice
        createdAt
        updatedAt
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNumber
        invoiceNumber
        userName
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersForUser = /* GraphQL */ `
  query OrdersForUser(
    $userName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersForUser(
      userName: $userName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderNumber
        invoiceNumber
        userName
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersInStatus = /* GraphQL */ `
  query OrdersInStatus(
    $orderStatus: OrderStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersInStatus(
      orderStatus: $orderStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderNumber
        invoiceNumber
        userName
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByPaymentTrackingId = /* GraphQL */ `
  query OrderByPaymentTrackingId(
    $paymentTrackingId: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByPaymentTrackingId(
      paymentTrackingId: $paymentTrackingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderNumber
        invoiceNumber
        userName
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        orderNumber
        invoiceNumber
        userName
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        note
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getNewsCategory = /* GraphQL */ `
  query GetNewsCategory($id: ID!) {
    getNewsCategory(id: $id) {
      id
      slug
      name
      order
      description
      createdAt
      updatedAt
      posts {
        nextToken
      }
    }
  }
`;
export const listNewsCategorys = /* GraphQL */ `
  query ListNewsCategorys(
    $filter: ModelNewsCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        name
        order
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const newsCategoriesBySlug = /* GraphQL */ `
  query NewsCategoriesBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelNewsCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsCategoriesBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        order
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewsPost = /* GraphQL */ `
  query GetNewsPost($id: ID!) {
    getNewsPost(id: $id) {
      id
      categoryId
      slug
      name
      content
      excerpt
      thumbnail
      advancedEditor
      createdAt
      updatedAt
      seoDescription
      category {
        id
        slug
        name
        order
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const listNewsPosts = /* GraphQL */ `
  query ListNewsPosts(
    $filter: ModelNewsPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        slug
        name
        content
        excerpt
        thumbnail
        advancedEditor
        createdAt
        updatedAt
        seoDescription
      }
      nextToken
    }
  }
`;
export const newsPostsInCategory = /* GraphQL */ `
  query NewsPostsInCategory(
    $categoryId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewsPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsPostsInCategory(
      categoryId: $categoryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        slug
        name
        content
        excerpt
        thumbnail
        advancedEditor
        createdAt
        updatedAt
        seoDescription
      }
      nextToken
    }
  }
`;
export const newsPostsBySlug = /* GraphQL */ `
  query NewsPostsBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelNewsPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsPostsBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        slug
        name
        content
        excerpt
        thumbnail
        advancedEditor
        createdAt
        updatedAt
        seoDescription
      }
      nextToken
    }
  }
`;
export const searchNewsPosts = /* GraphQL */ `
  query SearchNewsPosts(
    $filter: SearchableNewsPostFilterInput
    $sort: SearchableNewsPostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNewsPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        categoryId
        slug
        name
        content
        excerpt
        thumbnail
        advancedEditor
        createdAt
        updatedAt
        seoDescription
      }
      nextToken
      total
    }
  }
`;
export const getPopup = /* GraphQL */ `
  query GetPopup($id: ID!) {
    getPopup(id: $id) {
      id
      title
      content
      activeFrom
      activeTo
      createdAt
      updatedAt
    }
  }
`;
export const listPopups = /* GraphQL */ `
  query ListPopups(
    $filter: ModelPopupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPopups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        activeFrom
        activeTo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      slug
      name
      content
      advancedEditor
      seoDescription
      createdAt
      updatedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        name
        content
        advancedEditor
        seoDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pageBySlug = /* GraphQL */ `
  query PageBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pageBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        content
        advancedEditor
        seoDescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchPages = /* GraphQL */ `
  query SearchPages(
    $filter: SearchablePageFilterInput
    $sort: SearchablePageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        slug
        name
        content
        advancedEditor
        seoDescription
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userName
      variant
      contents
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        variant
        contents
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsForUser = /* GraphQL */ `
  query NotificationsForUser(
    $userName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsForUser(
      userName: $userName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        variant
        contents
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPriceLevel = /* GraphQL */ `
  query GetPriceLevel($id: ID!) {
    getPriceLevel(id: $id) {
      id
      name
      discount {
        flat
        percent
      }
      default
      createdAt
      updatedAt
      dynamoUserRecords {
        nextToken
      }
    }
  }
`;
export const listPriceLevels = /* GraphQL */ `
  query ListPriceLevels(
    $filter: ModelPriceLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        default
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDynamoUserRecord = /* GraphQL */ `
  query GetDynamoUserRecord($userName: String!) {
    getDynamoUserRecord(userName: $userName) {
      userName
      sub
      priceLevelId
      overrideDiscount {
        flat
        percent
      }
      createdAt
      updatedAt
      priceLevel {
        id
        name
        default
        createdAt
        updatedAt
      }
    }
  }
`;
export const listDynamoUserRecords = /* GraphQL */ `
  query ListDynamoUserRecords(
    $userName: String
    $filter: ModelDynamoUserRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDynamoUserRecords(
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userName
        sub
        priceLevelId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dynamoUserRecordsByPriceLevel = /* GraphQL */ `
  query DynamoUserRecordsByPriceLevel(
    $priceLevelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDynamoUserRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dynamoUserRecordsByPriceLevel(
      priceLevelId: $priceLevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userName
        sub
        priceLevelId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dynamoUserRecordsBySub = /* GraphQL */ `
  query DynamoUserRecordsBySub(
    $sub: String
    $sortDirection: ModelSortDirection
    $filter: ModelDynamoUserRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dynamoUserRecordsBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userName
        sub
        priceLevelId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSingleOrder = /* GraphQL */ `
  query GetSingleOrder($id: ID!) {
    getSingleOrder(id: $id) {
      id
      userName
      fields {
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      shippingMethodId
      shippingMethod {
        id
        name
        description
        fee
        feePercent
        order
        requiresShippingAddress
        heurekaId
        minAllowedCartPrice
        maxAllowedCartPrice
        slug
        createdAt
        updatedAt
      }
      shipmentTrackingId
      paymentMethodId
      paymentMethod {
        id
        name
        description
        fee
        feePercent
        order
        handler
        sendEmail
        minAllowedCartPrice
        maxAllowedCartPrice
        createdAt
        updatedAt
      }
      paymentTrackingId
      orderStatus
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
      note
      createdAt
      updatedAt
      redirectUrl
      orderNumber
    }
  }
`;
export const getOrderInvoice = /* GraphQL */ `
  query GetOrderInvoice($orderId: ID!) {
    getOrderInvoice(orderId: $orderId) {
      invoice {
        bucket
        key
      }
    }
  }
`;
export const getOrderDeliveryNote = /* GraphQL */ `
  query GetOrderDeliveryNote($orderId: ID!) {
    getOrderDeliveryNote(orderId: $orderId) {
      deliveryNote {
        bucket
        key
      }
    }
  }
`;
export const adminGetFtpStatus = /* GraphQL */ `
  query AdminGetFtpStatus {
    adminGetFtpStatus
  }
`;
export const adminListUsers = /* GraphQL */ `
  query AdminListUsers(
    $Limit: Int
    $PaginationToken: String
    $Filter: CognitoListUsersFilter
  ) {
    adminListUsers(
      Limit: $Limit
      PaginationToken: $PaginationToken
      Filter: $Filter
    ) {
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
      }
      PaginationToken
    }
  }
`;
export const adminGetUser = /* GraphQL */ `
  query AdminGetUser($Username: String) {
    adminGetUser(Username: $Username) {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
      }
    }
  }
`;
export const adminBatchDownloadInvoices = /* GraphQL */ `
  query AdminBatchDownloadInvoices($from: AWSDateTime, $to: AWSDateTime) {
    adminBatchDownloadInvoices(from: $from, to: $to)
  }
`;
export const adminCsobEchoTest = /* GraphQL */ `
  query AdminCsobEchoTest {
    adminCsobEchoTest {
      getValid
      postValid
    }
  }
`;
