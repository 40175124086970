import { gql } from "@apollo/client";

export const getListManufacturers = gql`
  query getListManufacturers(
    $limit: Int
  ){
    listManufacturers(
      limit: $limit
    ){
      items {
        name
        image
        id
        description
        order
        createdAt
      }
    }
  }
`;
