import React from 'react';

import './advantages.scss'

function Advantages(){
  return(
    <div className="row advantages">
      <div className="col w-100p">
        <h3>1. Slevy za odběr zboží</h3>
        <p>
          Čím více objednáte, tím větší máte slevu! Registrujte se a získejte informace o partnerském programu.
        </p>
      </div>
      <div className="col w-100p">
        <h3>2. Sledování stavu objednávky</h3>
        <p>
          Ve svém uživatelském profilu můžete sledovat stav objednávky a tak víte, kdy materiál dorazí.
        </p>
      </div>
      <div className="col w-100p">
        <h3>3. Historie objednávek</h3>
        <p>
          Nemusíte držet faktury u sebe, ve svém profilu si jednoduše dohledáte všechny vaše objednávky.
        </p>
      </div>
    </div>
  );
}

export default Advantages;
