import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import Preloader from "../util/Preloader";
import Icons from "../util/Icons";
import {ConvertImageUriFromS3ToCloudFront} from "../helpers";

const SearchEshopProducts = gql`
  query SearchEshopProducts($filter: SearchableProductFilterInput, $categoryFilter: String) {
    searchProducts(filter: $filter) {
      items {
        id
        name
        slug
        manufacturerId
        category {
          name
          id
          slug
        }
        subCategory {
          name
          id
          slug
        }
        subSubCategory {
          name
          id
          slug
        }
        subSubSubCategory {
          name
          id
          slug
        }
        subSubSubSubCategory {
          name
          id
          slug
        }
        attachments {
          items {
            file
            type
          }
        }
      }
      nextToken
      total
    }
    listManufacturers(filter: {name: {contains: $categoryFilter}}){
      items{
        id
        name
      }
    }
    listProductCategorys(filter: {name: {contains: $categoryFilter}}){
      items{
        id
        name
        slug
      }
    }
    listProductSubCategorys(filter: {name: {contains: $categoryFilter}}){
      items{
        id
        name
        slug
        parent{
          id
          name
          slug
        }
      }
    }
    listProductSubSubCategorys(filter: {name: {contains: $categoryFilter}}){
      items{
        id
        name
        slug
        parent{
          id
          name
          slug
          parent{
            id
            name
            slug
          }
        }
      }
    }
    listProductSubSubSubCategorys(filter: {name: {contains: $categoryFilter}}){
      items{
        id
        name
        slug
        parent{
          id
          name
          slug
          parent{
            id
            name
            slug
            parent{
              id
              name
              slug
            }
          }
        }
      }
    }
    listProductSubSubSubSubCategorys(filter: {name: {contains: $categoryFilter}}){
      items{
        id
        name
        slug
        parent{
          id
          name
          slug
          parent{
            id
            name
            slug
            parent{
              id
              name
              slug
              parent{
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;


const Search2 = () => {
  const history = useHistory();
  const [searchWhisperer, setSearchWhisperer] = useState("");
  const [searchListHidden, setSearchListHidden] = useState(true);
  const [showMoreCategories, setShowMoreCategories] = useState(false);

  const splitWhisperer = searchWhisperer.split(/\s/).filter(word => !!word);

  const {data, error, loading} = useQuery(SearchEshopProducts, {
    context: {
      // Requests get debounced together if they share the same debounceKey.
      // Requests without a debounce key are passed to the next link unchanged.
      debounceKey: 'header.fulltext',
      debounceTimeout: 1000,
    },
    variables: {
      filter: {
        or: [
          {
            and: splitWhisperer.map(word => ({
              id: {matchPhrasePrefix: word},
            }))
          },
          {
            and: splitWhisperer.map(word => ({
              name: {matchPhrasePrefix: word},
            }))
          },
          {
            and: splitWhisperer.map(word => ({
              manufacturerProductCode: {matchPhrasePrefix: word},
            }))
          },
          {
            and: splitWhisperer.map(word => ({
              apiId: {matchPhrasePrefix: word},
            }))
          },
        ],
        isHidden: {ne: true},
      },
      categoryFilter: searchWhisperer.toUpperCase(),
    },
    onCompleted: (data) => {
      setSearchListHidden(data?.searchProducts?.total <= 0);
    },
    skip: !searchWhisperer || searchWhisperer.length < 3
  });

  const handleSearch = () => {
    history.push("/eshop/fulltext:" + searchWhisperer);
    setSearchWhisperer("");
    setSearchListHidden(true);
  };

  const renderMainImage = (data) => {
    const acceptedMainImageTypes = ["OV"];
    for (let i = 0; i < data.attachments.items.length; i++) {
      let attachment = data.attachments.items[i];
      if (acceptedMainImageTypes.indexOf(attachment.type) !== -1) {
        return <img src={ConvertImageUriFromS3ToCloudFront(attachment.file)} alt={attachment.description} />;
      }
    }
    return <img src={"/staticImages/topasplus-logo.svg"} />;
  };

  function renderManufacturers(){
    if (!data?.listManufacturers?.items?.length){
      return null;
    }
    return (<>
      <h3>Výrobci</h3>
      <div className="searchCategoryCol">
        {data.listManufacturers.items.map(item => (
          <Link
            key={"manufacturer_" + item.id}
            to={"/eshop/selectedManufacturers:" + item.id}
            className="searchCategoryItem"
            onClick={() => setSearchListHidden(true)}
          >
            <span className="caret-right" />
            <span className="linkName">
            {item.name}
          </span>
          </Link>
        ))}
      </div>
    </>);
  }

  function fullCategorySlug(category){
    if (!category) return "";

    return fullCategorySlug(category.parent) + "/" + category.slug;
  }

  function fullCategoryName(category){
    if (!category) return null;

    return (
      <span>
        {fullCategoryName(category.parent)}
        <span className="caret-right" />
        <span className="linkName">{category.name}</span>
      </span>
    );
  }

  function renderCategories(){
    let categories = [];
    let trash;

    trash = data?.listProductSubSubSubSubCategorys?.items?.forEach(item => {
      if (categories.find(existingItem => existingItem.id === item.id)){
        return;
      }
      categories.push({...item, level: "subSubSubSubCategory"});
    });
    trash = data?.listProductSubSubSubCategorys?.items?.forEach(item => {
      if (categories.find(existingItem => existingItem.id === item.id)){
        return;
      }
      categories.push({...item, level: "subSubSubCategory"});
    });
    trash = data?.listProductSubSubCategorys?.items?.forEach(item => {
      if (categories.find(existingItem => existingItem.id === item.id)){
        return;
      }
      categories.push({...item, level: "subSubCategory"});
    });
    trash = data?.listProductSubCategorys?.items?.forEach(item => {
      if (categories.find(existingItem => existingItem.id === item.id)){
        return;
      }
      categories.push({...item, level: "subCategory"});
    });
    trash = data?.listProductCategorys?.items?.forEach(item => {
      if (categories.find(existingItem => existingItem.id === item.id)){
        return;
      }
      categories.push({...item, level: "category"});
    });

    if (!categories.length){
      trash = data?.searchProducts?.items?.forEach(item => {
        const categoryLevels = ["subSubSubSubCategory", "subSubSubCategory", "subSubCategory", "subCategory", "category"];
        for (let i = 0; i < categoryLevels.length; i++) {
          if (!item[categoryLevels[i]]) continue;
          categories.push({...item[categoryLevels[i]], level: categoryLevels[i]});
          break;
        }
      });
    }

    return (<>
      <h3>Kategorie</h3>
      <div className={"searchCategoryCol " + (showMoreCategories ? "open " : "")}>
        {categories.map(item => (
          <Link
            key={"category_" + item.id}
            to={"/eshop" + fullCategorySlug(item)}
            className="searchCategoryItem"
            onClick={() => setSearchListHidden(true)}
          >
            {fullCategoryName(item)}
          </Link>
        ))}
        <div className="showMore" onClick={() => setShowMoreCategories(!showMoreCategories)}>
          <img className={"icon"} src={Icons.getIcon("caret")} alt={"caret"} />
          <span>Více</span>
          <img className={"icon"} src={Icons.getIcon("caret")} alt={"caret"} />
        </div>
      </div>
    </>);
  }
  function renderProducts(){
    return(<>
      <h3>Produkty</h3>
      {data?.searchProducts.items.map((item, index) => {
        if (index > 4) {
          return null;
        }
        return (
          <Link
            key={"product_" + item.id}
            to={"/eshop/product/" + item.slug}
            className="searchItem"
            onClick={() => setSearchListHidden(true)}
          >
            <div className="imgWrapper">{renderMainImage(item)}</div>
            <span>{item.name}</span>
          </Link>
        );
      })}
    </>);
  }

  return(<>
    {(searchListHidden || !searchWhisperer || searchWhisperer.length < 3) ? null : <div className="searchListBackground" onClick={(event) => {
      setSearchListHidden(true);
      // This can click elements under the overlay, but we are not doing that anymore
      // const elements = document.elementsFromPoint(event.clientX, event.clientY);
      // for (let i = 0; i < elements.length; i++){
      //   if (elements[i].className !== "searchListBackground"){
      //     elements[i].click();
      //     break;
      //   }
      // }
    }}/>}

    <div className="controlBox search">
      <input
        type="text"
        placeholder="Napište název, nebo ID produktu, který hledáte…"
        className="searchInput"
        value={searchWhisperer}
        onChange={({target}) => {
          setSearchWhisperer(target.value);
        }}
        onKeyPress={(e) => e.key === "Enter" && handleSearch()}
      />
      {loading ? <Preloader/> : null}
      <div className="separator" />
      <div className="button" onClick={() => handleSearch()}>
        <img
          className="icon"
          src={"/staticImages/icons/searchBlue.svg"}
          alt="Vyhledat"
        />
      </div>
      {(!searchListHidden && (data?.searchProducts?.items?.length > 0)) ? (
        <>
          <div className="searchList">
            <img className={"closeButton"} onClick={() => setSearchListHidden(true)} src={Icons.getIcon("close")} alt="close"/>
            <div className="content">
              {renderManufacturers()}
              {renderCategories()}
              {renderProducts()}
            </div>
            <Link
              to={"/eshop/fulltext:" + searchWhisperer}
              onClick={() => {
                setSearchWhisperer("");
                setSearchListHidden(true);
              }}
              className="button ml-auto mbt-auto inline light-gray medium caret-right"
            >
              Další produkty
            </Link>
          </div>
        </>
      ) : null}
    </div>
  </>);
};

export default Search2;
