import React, { useEffect, useState } from "react"

import "./calendarInput.scss"
const month_names = ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec']

function renderCalendarHeader(year, SetYear, month, SetMonth) {

    return (
        <>
            <div className="selected_month">
                <span
                    onClick={() => {SetMonth(month - 1)}}
                    className="left_month caret_left"></span>
                <span>
                    {month_names[month]}
                </span>
                <span
                    onClick={() => {SetMonth(month + 1)}}
                    className="right_month caret_right"></span>

            </div>
            <div className="selected_year">
                <span
                    onClick={() => {SetYear(year - 1)}}
                    className="left_year caret_left"></span>
                <span>
                    {year}
                </span>
                <span
                    onClick={() => {SetYear(year + 1)}}
                    className="right_year caret_right"></span>

            </div>
        </>
    )
}

function numberOfDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}
function generateDays(year, month) {
    let month_start_at = (new Date(year, month, 1).getDay() - 1) % 7;
    let number_of_days = numberOfDaysInMonth(year, month + 1)
    let month_end_at = 7 - (number_of_days + month_start_at) % 7

    let days = Array.from({length: number_of_days + month_start_at + month_end_at}, (v, i) => {
        if (i < month_start_at){
            return -1
        }
        if ((i - month_start_at) >= number_of_days) {
            return -1
        }
        return (i - month_start_at + 1)
    });
    return days
}
function renderCalendarMonth(year, month, day, SetRentDate, from_date,  SetFromDate, to_date, SetToDate) {
    const days_names = ['P', 'U', 'S', 'Č', 'P', 'S', 'N']
    const days = generateDays(year, month)
    // console.log(days);
    let new_date = new Date(year, month, day)
    return (
        <>
            <div className="days_names">
                {days_names.map((item, index) => {
                    return(
                        <span>{item}</span>
                    )
                })}
            </div>

            <div className="days">
                {days.map((item, index) => {
                    if (item == -1) {
                        return (
                            <span></span>
                        )
                    }
                    let today = (day == item) ? " today" : ""

                    new_date.setDate(item)
                    let active = "";

                    if (from_date && to_date) {
                        let left_compare = compareDate(from_date, new_date)
                        let right_compare = compareDate(to_date, new_date)

                        if (left_compare <= 0 && right_compare >= 0) {
                            active = " active"
                        }
                    }

                    if (from_date) {
                        let left_compare = compareDate(from_date, new_date)
                        if (left_compare == 0 ) {
                            active = " active"
                        }
                    }


                    return (
                        <span
                            onClick={(e) => SetRentDate(
                                e, year, month, item,
                                from_date, SetFromDate,
                                to_date, SetToDate)}
                            className={today + active}>
                            {item}
                        </span>
                    )
                })}
            </div>
        </>
    )
}
function renderCalendarFooter() {

}
function renderCalendarInfo(today, from, to, dateSpanHeader) {
    let today_string = today.getDate() + "." + month_names[today.getMonth()] + "." + today.getFullYear()
    let from_string = ""
    if (from) {
        from_string = from.getDate() + "." + month_names[from.getMonth()] + "." + from.getFullYear()
    }
    let to_string = ""
    if (to) {
        to_string = to.getDate() + "." + month_names[to.getMonth()] + "." + to.getFullYear()
    }


    return (
        <>
            <div>
                <div>Dnes:</div>
                <span>{today_string}</span>
            </div>
            <div>
                <span className={"date_span_header"}>{dateSpanHeader}</span>
                <div className="rent_from_date">
                    <div> Od:</div>
                    <div>{from_string}</div>
                </div>
                <div className="rent_to_date">
                    <div>Do:</div>
                    <div>{to_string}</div>
                </div>
            </div>

        </>
    )
}

function compareDate(old_date, new_date) {
    // -1 0 1 -> old < new; old == new; old > new

    if (old_date.getFullYear() < new_date.getFullYear()) {
        return -1
    }

    if (old_date.getFullYear() > new_date.getFullYear()) {
        return 1
    }

    // old_date.getFullYear() === old_date.getFullYear()
    if (old_date.getMonth() < new_date.getMonth()) {
        return -1
    }
    if (old_date.getMonth() > new_date.getMonth()) {
        return 1
    }

    // old_date.getMonth() == new_date.getMonth()
    if (old_date.getDate() < new_date.getDate()) {
        return -1
    }
    if (old_date.getDate() > new_date.getDate()) {
        return 1
    }

    // old_date.getDate() == new_date.getDate()
    return 0;

}


function setRentDate(event, year, month, day, from_date, SetFromDate, to_date, SetToDate) {
    if (!from_date && !to_date) {
        SetFromDate(new Date(year, month, day))
        return
    }
    if (!to_date) {
        let new_to_date = new Date(year, month, day)
        if (compareDate(from_date, new_to_date) > 0) {
            SetFromDate(new_to_date)
            SetToDate(from_date)
            return
        }

        SetToDate(new_to_date)
        return
    }

    // from_date and to_date are set
    SetToDate()
    SetFromDate(new Date(year, month, day))

    // console.log(year, month, day);
    // let new_date = new Date(year ,month, day)

    // let left_compare = compareDate(from_date, new_date)
    // let right_compare = compareDate(to_date, new_date)

}

function renderDate(label, from, to) {
    let from_string = ""
    let string = label

    if (from) {
        from_string = from.getDate() + "." + (from.getMonth() + 1) + "." + from.getFullYear()
        string = from_string
    }
    let to_string = ""
    if (to) {
        to_string = to.getDate() + "." + (to.getMonth() + 1) + "." + to.getFullYear()
        string  += " - " + to_string

    }


    return (
        string
    )
}
function CalendarInput(props) {
    let now_date = new Date();

    let day = now_date.getDate()
    // let month = now_date.getMonth()
    // let year = now_date.getFullYear()
    // const [day, setDay] = useState(now_date.getDate())
    const [month, SetMonth] = useState(now_date.getMonth())
    const [year, SetYear] = useState(now_date.getFullYear())

    const [from_date, SetFromDate] = useState()
    const [to_date, SetToDate] = useState()
    const [open_calendar, setOpenCalendar] = useState(false)

    useEffect(() => {
        // console.log(month);
        if (month < 0) {
            SetMonth(11)
            SetYear(year - 1)
            return
        }

        if (month > 11) {
            SetMonth(0)
            SetYear(year + 1)
            return
        }

    }, [month])

    useEffect(() => {
        if (to_date) {
            setOpenCalendar(false)
            // console.log(open_calendar);
        }
    }, [to_date])

    useEffect(() => {
        if (props.onChange) props.onChange(from_date, to_date);
    }, [from_date, to_date]);

    // console.log(props);

    return (
        <div className="calendar_input_wrapper">
            <div
                onClick={() => setOpenCalendar(!open_calendar)}
                className="calendar_input">
                <div className={"placeholder"} >
                    <span>{renderDate(props.field.label, from_date, to_date)}</span>
                </div>
            </div>

            <div className={"date_picker_wrapper " + (open_calendar ? "open_calendar" : "")}>
                <div className="date_picker">
                    <div className="current_info">
                        {renderCalendarInfo(now_date, from_date, to_date, props.dateSpanHeader)}
                    </div>
                    <div className="calendar">
                        <div className="header">
                            {renderCalendarHeader(year, SetYear, month, SetMonth)}
                        </div>
                        <div className="content">
                            {renderCalendarMonth(
                                year, month, day, setRentDate,
                                from_date, SetFromDate,
                                to_date, SetToDate)}
                        </div>
                        <div className="footer">
                            {renderCalendarFooter()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarInput
