import React, {useReducer, useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Button, Col, Form, Table} from "react-bootstrap";
import debounce from "lodash/debounce";
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom";
import Pagination from "../util/Pagination";
import ConfirmButton from "../util/ConfirmButton";

const getProductConnections = gql`
  query getProductConnections{
    listProductCategorys {
      items {
        id
        name
        children{
          items{
            id
            name
            children{
              items{
                id
                name
                children{
                  items{
                    id
                    name
                    children{
                      items{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    listManufacturers{
      items{
        id
        name
      }
    }
  }
`;

const searchProductsQuery = gql`
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
    ) {
      items {
        id
        isHidden
        manufacturerId
        returnsCategory
        apiId
        manufacturerProductCode
        description
        excerpt
        stock
        weight
        country
        warrantyTime
        name
        slug
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        featuredNews
        ean
        unit
        vat
        apiTimestamp
        createdAt
        updatedAt
        categoryId
        subCategoryId
        subSubCategoryId
        subSubSubCategoryId
        subSubSubSubCategoryId
      }
      total
    }
  }
`;

const deleteProductMutation = gql`
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateProductMutation = gql`
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

function ProductRow({product, selected, onSelectChange}){
  const [deleteProduct, deleteProductResponse] = useMutation(deleteProductMutation, {
    refetchQueries: [
      'SearchProducts'
    ]
  });
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat produkt"} />
      </td>
      <td style={{
        maxWidth: 50,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{product.id}</td>
      <td>{product.apiId}</td>
      <td>{product.name}</td>
      <td>{product.price}</td>
      <td>{product.stock} {product.unit}</td>
      <td>{product.createdAt}</td>
      <td>
        {deleteProductResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s platební metodou">
            <Link to={"/admin/products/" + product.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat produkt: " + product.name + "?"} onClick={() => deleteProduct({variables: {input: {id: product.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Products(){
  const [deleteProduct, deleteProductResponse] = useMutation(deleteProductMutation);
  const [updateProduct, updateProductResponse] = useMutation(updateProductMutation);
  const {loading: connectonsLoading, data: connectionsData, error: connectionsError} = useQuery(getProductConnections);
  const [editType, setEditType] = useState("none");
  const [editValue, setEditValue] = useState(0);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subSubCategoryId, setSubSubCategoryId] = useState(null);
  const [subSubSubCategoryId, setSubSubSubCategoryId] = useState(null);
  const [subSubSubSubCategoryId, setSubSubSubSubCategoryId] = useState(null);
  const [fulltext, changeFulltext] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortField, changeSortField] = useState("name");
  const [sortDirection, changeSortDirection] = useState("asc");
  let filter = null;
  if (fulltext){
    if (!filter) filter = {};
    filter.or = [
      {
        id: {
          matchPhrasePrefix: fulltext
        },
      },
      {
        apiId: {
          matchPhrasePrefix: fulltext
        },
      },
      {
        manufacturerProductCode: {
          matchPhrasePrefix: fulltext
        },
      },
      {
        description: {
          matchPhrasePrefix: fulltext
        },
      },
      {
        name: {
          matchPhrasePrefix: fulltext
        },
      },
      {
        ean: {
          matchPhrasePrefix: fulltext
        },
      },
    ];
  }
  if (manufacturerId && manufacturerId !== "null"){
    if (!filter) filter = {};
    filter.manufacturerId = {eq: manufacturerId};
  }
  let product = {
    categoryId,
    subCategoryId,
    subSubCategoryId,
    subSubSubCategoryId,
    subSubSubSubCategoryId,
  }
  Object.keys(product).forEach(categoryType => {
    if (product[categoryType] && product[categoryType] !== "null"){
      if (!filter) filter = {};
      filter[categoryType] = {eq: product[categoryType]};
    }
  });

  const { loading: productsLoading, error: productsError, data: productsData, refetch: refetchProducts } = useQuery(searchProductsQuery, {
    variables: {
      filter: filter,
      sort: {
        field: sortField,
        direction: sortDirection,
      },
      limit,
      from: offset * limit,
    },
    onCompleted: () => {
      dispatchSelectedProducts({type: "clear"});
    }
  });
  const [selectedProducts, dispatchSelectedProducts] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === productsData.searchProducts.items.length){
          return {}
        }
        productsData.searchProducts.items.forEach(product => newState[product.id] = product);
        return newState;
      case "toggle":
        if(newState[action.product.id]){
          delete newState[action.product.id];
        }
        else{
          newState[action.product.id] = action.product;
        }
        return newState;
      case "select":
        newState[action.product.id] = action.product;
        return newState;
      case "deselect":
        delete newState[action.product.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if (((productsLoading) && (!productsData)) || (connectonsLoading && !connectionsData)) return(
      <Preloader/>
    );

    if (productsError || productsData.errors || connectionsError || connectionsData.error) return(
      <div className="danger">Nastala chyba při načítání produktů</div>
    );

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedProducts).length === productsData.searchProducts.items.length} onChange={() => dispatchSelectedProducts({type: "selectAll"})} aria-label={"Vybrat produkt"} />
            </th>
            <th>id</th>
            <th>TopasId {renderSortDirection("apiId")}</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Cena {renderSortDirection("price")}</th>
            <th>Skladem {renderSortDirection("stock")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {productsData.searchProducts.items.map(product => (
            <ProductRow key={"product_" + product.id} onSelectChange={() => dispatchSelectedProducts({type: "toggle", product})} selected={!!selectedProducts[product.id]} product={product}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  function renderBatchEdit(){
    const selectedProductIds = Object.keys(selectedProducts);
    if (!selectedProductIds || selectedProductIds.length < 1){
      return (
        <Form>
          <Form.Row>
            <Col>
              <h5>Skupinová editace produktů</h5>
              <p>Nebyly vybrány žádné produkty</p>
            </Col>
          </Form.Row>
        </Form>
      );
    }

    async function doBatchEdit(event){
      event.preventDefault();
      let updatePromises = [];
      switch (editType){
        case "setStockTo":
          selectedProductIds.forEach(selectedProductId => {
            updatePromises.push(updateProduct({
              variables:{
                input: {
                  id: selectedProductId,
                  stock: editValue,
                },
              }
            }));
          });
          break;
        case "changeStockBy":
          selectedProductIds.forEach(selectedProductId => {
            const selectedProduct = selectedProducts[selectedProductId];
            updatePromises.push(updateProduct({
              variables:{
                input: {
                  id: selectedProductId,
                  stock: selectedProduct.stock + editValue,
                },
              }
            }));
          });
          break;
        case "percentilePriceChange":
          selectedProductIds.forEach(selectedProductId => {
            const selectedProduct = selectedProducts[selectedProductId];
            const editMultiplier = 1 + (editValue / 100);
            const newPrice = selectedProduct.price * editMultiplier;
            const newSalePrice = selectedProduct.salePrice * editMultiplier;
            updatePromises.push(updateProduct({
              variables:{
                input: {
                  id: selectedProductId,
                  price: newPrice,
                  salePrice: newSalePrice,
                },
              }
            }));
          });
          break;
        case "delete":
          selectedProductIds.forEach(selectedProductId => {
            updatePromises.push(deleteProduct({
              variables:{
                input: {
                  id: selectedProductId,
                },
              }
            }));
          });
          break;
      }
      await Promise.all(updatePromises);
      setTimeout(() => {
        refetchProducts();
        dispatchSelectedProducts({type: "clear"});
      }, 2000);
    }

    return(
      <Form onSubmit={doBatchEdit}>
        <Form.Row>
          <Col>
            <h5>Skupinová editace produktů</h5>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId={"editType"}>
              <Form.Label>Typ změny</Form.Label>
              <Form.Control
                as={"select"}
                value={editType}
                onChange={({target}) => {
                  setEditType(target.value);
                }}
              >
                <option value={"none"}>Žádná</option>
                <option value={"percentilePriceChange"}>Procentuelní změna ceny</option>
                <option value={"delete"}>Smazat</option>
                <option value={"setStockTo"}>Změnit skladové zásoby na</option>
                <option value={"changeStockBy"}>Změnit skladové zásoby o</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className={(editType === "none") ? "d-none" : ""} controlId={"editValue"}>
              <Form.Label>Změna</Form.Label>
              <Form.Control type={"number"} value={editValue} onChange={({target}) => setEditValue(parseFloat(target.value))}/>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row className={((editType === "none")) ? "d-none" : ""}>
          <Col>
            <Button disabled={updateProductResponse.loading} variant={"primary"} type={"submit"}>Provést změny (počet: {selectedProductIds.length})</Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }

  if (connectonsLoading && !connectionsData) return(
    <Preloader/>
  );

  let categories = connectionsData?.listProductCategorys?.items;
  let manufacturers = connectionsData?.listManufacturers?.items;
  let selectedCategory = {
    category: null,
    subCategory: null,
    subSubCategory: null,
    subSubSubCategory: null,
    subSubSubSubCategory: null,
  };
  let levels = Object.keys(selectedCategory);
  let children = categories;
  for (let depth = 0; depth < levels.length; depth++) {
    const level = levels[depth];
    for (let i = 0; i < children.length; i++) {
      if (children[i].id === product[level + "Id"]){
        selectedCategory[level] = children[i];
        children = selectedCategory[level]?.children?.items || [];
        break;
      }
    }
  }

  return (
    <div className={"products"}>
      <h3>Produkty</h3>
      <hr/>
      <Form>
        <Form.Row>
          <Col>
            <Form.Group controlId={"categoryId"}>
              <Form.Label>Kategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={categoryId}
                onChange={({target}) => {
                  setCategoryId(target.value);
                  setSubCategoryId(null);
                  setSubSubCategoryId(null);
                  setSubSubSubCategoryId(null);
                  setSubSubSubSubCategoryId(null);
                }}
              >
                <option value={"null"}>Žádná</option>
                {categories.map(category => <option value={category.id}>{category.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subCategoryId"}>
              <Form.Label>1. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subCategoryId}
                onChange={({target}) => {
                  setSubCategoryId(target.value);
                  setSubSubCategoryId(null);
                  setSubSubSubCategoryId(null);
                  setSubSubSubSubCategoryId(null);
                }}
              >
                <option value={"null"}>Žádná</option>
                {selectedCategory.category ? selectedCategory.category.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subSubCategoryId"}>
              <Form.Label>2. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subSubCategoryId}
                onChange={({target}) => {
                  setSubSubCategoryId(target.value);
                  setSubSubSubCategoryId(null);
                  setSubSubSubSubCategoryId(null);
                }}
              >
                <option value={"null"}>Žádná</option>
                {selectedCategory.subCategory ? selectedCategory.subCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subSubSubCategoryId"}>
              <Form.Label>3. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subSubSubCategoryId}
                onChange={({target}) => {
                  setSubSubSubCategoryId(target.value);
                  setSubSubSubSubCategoryId(null);
                }}
              >
                <option value={"null"}>Žádná</option>
                {selectedCategory.subSubCategory ? selectedCategory.subSubCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"subSubSubSubCategoryId"}>
              <Form.Label>4. podkategorie</Form.Label>
              <Form.Control
                as={"select"}
                value={subSubSubSubCategoryId}
                onChange={({target}) => setSubSubSubSubCategoryId(target.value)}
              >
                <option value={"null"}>Žádná</option>
                {selectedCategory.subSubSubCategory ? selectedCategory.subSubSubCategory.children.items.map(category => <option value={category.id}>{category.name}</option>) : null}
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId={"manufacturerId"}>
              <Form.Label>Výrobce</Form.Label>
              <Form.Control
                as={"select"}
                value={manufacturerId}
                onChange={({target}) => setManufacturerId(target.value)}
              >
                <option value={"null"}>Žádný</option>
                {manufacturers.map(manufacturer => <option value={manufacturer.id}>{manufacturer.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={"fulltext"}>
              <Form.Label>Fulltext</Form.Label>
              <Form.Control
                className="mb-2"
                placeholder="Vyhledávání"
                onChange={({target}) => debouncedChangeFulltext(target.value)}
              />
            </Form.Group>
          </Col>
        </Form.Row>

      </Form>
      <hr/>
      {renderBatchEdit()}
      <hr/>
      <div>
        <span><strong>Nalezeno: </strong>{productsData?.searchProducts?.total} produktů</span>
        <span> | </span>
        <span><strong>Vybráno: </strong>{Object.keys(selectedProducts).length} produktů</span>
      </div>
      <hr/>
      {renderTable()}
      <Pagination changePerPage={newPageLimit => setLimit(newPageLimit)} changePage={newPageNumber => {setOffset(newPageNumber); dispatchSelectedProducts({type: "clear"})}} total={productsData?.searchProducts?.total} limit={limit} currentPage={offset}/>
    </div>
  );
}

export default Products;
