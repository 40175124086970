import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from '@aws-amplify/auth';
import Banners from "../widgets/Banners";
import {fetchCurrentUserInfo} from "../app/cognitoReducer";
import {addNotification} from '../app/notificationsReducer';

import './account.scss'

class Account extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      companyName: '',
      companyId: '',
      companyTaxId: '',

      street: '',
      city: '',
      zip: '',

      error: '',
    }
  }

  autoFillFromCognito(){
    if(this.props.cognito && this.props.cognito.currentUserInfo){
      const attributes = this.props.cognito.currentUserInfo.attributes;

      this.setState(() => ({
        companyName: attributes["custom:companyName"] || "",
        companyId: attributes["custom:companyId"] || "",
        companyTaxId: attributes["custom:companyTaxId"] || "",

        street: attributes["custom:street"] || "",
        city: attributes["custom:city"] || "",
        zip: attributes["custom:zip"] || "",
      }));
    }
  }

  componentDidMount(){
    this.autoFillFromCognito();
  }

  checkInputs() {
      const notEmpty = [
        "street",
        "city",
        "zip",
      ];

      for (let i = 0; i < notEmpty.length; i++){
        if (!this.state[notEmpty[i]]){
          //TODO: Error reporting etc.
          this.props.dispatch(addNotification({
            message: `Empty ${notEmpty[i]}`,
            type: "error",
            code: `frontend.${notEmpty[i]}Empty`
          }));
          return false;
        }
      }

      return true;
  }


  doChangeAttribute() {
    if (this.state.loading) return;
    if (!this.checkInputs()) return;
    this.setState(() => ({loading: true}), () => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          Auth.updateUserAttributes(user, {
            "custom:companyName": this.state.companyName,
            "custom:companyId": this.state.companyId,
            "custom:companyTaxId": this.state.companyTaxId,
            "custom:street": this.state.street,
            "custom:city": this.state.city,
            "custom:zip": this.state.zip,
          })
          .then(() => {
            this.props.dispatch(fetchCurrentUserInfo());
          })
          .catch(error => {
            this.props.dispatch(addNotification({
              message: error.message,
              type: "error",
              code: error.code
            }));
          })
          .finally(() => {
            this.setState(() => ({
              loading: false
            }));
          });
        })
        .catch(error => {
          this.setState(() => ({
            loading: false
          }));
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code
          }));

        })

    })
  }

  render() {
    return (
      <div className={"account container mbt-25"}>
        <div className="row gutter-20px">
          <div className="col w-66p">
            <form onSubmit={event => {event.preventDefault(); this.doChangeAttribute(); }}>

              <div className="panel mb-20">
                <div className="panelHeader">
                  <h2>Firma</h2>
                </div>
                <div className="panelBody">
                  <label className={"mb-15 grid-80"}>
                    <span>Název</span>
                    <input type={"text"} name={"companyName"} value={this.state.companyName} onChange={({target}) => this.setState(() => ({companyName: target.value}))}/>
                  </label>
                  <label className={"mb-15 grid-80"}>
                    <span>IČO</span>
                    <input type={"text"} name={"companyId"} value={this.state.companyId} onChange={({target}) => this.setState(() => ({companyId: target.value}))}/>
                  </label>
                  <label className={"mb-15 grid-80"}>
                    <span>DIČ</span>
                    <input type={"text"} name={"companyTaxId"} value={this.state.companyTaxId} onChange={({target}) => this.setState(() => ({companyTaxId: target.value}))}/>
                  </label>
                </div>
              </div>

              <div className="panel mb-20">
                <div className="panelHeader">
                  <h2>Adresa</h2>
                </div>
                <div className="panelBody">
                  <label className={"mb-15 grid-80"}>
                    <span>Ulice a č.p.</span>
                    <input type={"text"} name={"street"} value={this.state.street} onChange={({target}) => this.setState(() => ({street: target.value}))}/>
                  </label>
                  <label className={"mb-15 grid-80"}>
                    <span>Město</span>
                    <input type={"text"} name={"city"} value={this.state.city} onChange={({target}) => this.setState(() => ({city: target.value}))}/>
                  </label>
                  <label className={"mb-15 grid-80"}>
                    <span>PSČ</span>
                    <input type={"text"} name={"zip"} value={this.state.zip} onChange={({target}) => this.setState(() => ({zip: target.value}))}/>
                  </label>
                </div>
              </div>

            </form>
            <div className={"row m-0"}>
              <div className={"button medium light-gray"} onClick={() => this.doChangeAttribute()}><span>Změnit údaje</span></div>
            </div>
          </div>
          <div className="col w-33p">
            <Banners position={"login"}/>
          </div>
        </div>
      </div>
    );
  }

}

export default connect(state => ({cognito: state.cognito}))(Account);
