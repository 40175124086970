import React from 'react';
import {Link, useRouteMatch} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {getFullCategoryList} from "./EshopSidebarAPI";
import Preloader from "../util/Preloader";
import "./eshopSidebar.scss";
import { connect } from 'react-redux';
import { toggleMenu } from "../app/menuReducer";


const getSidebarProductInfo = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      category{
        id
      }
      subCategory{
        id
      }
      subSubCategory{
        id
      }
      subSubSubCategory{
        id
      }
      subSubSubSubCategory{
        id
      }
    }
  }
`;

function CategoryName({name}){
  return <div className={"categoryName"}>{name}</div>;

  let result = name.toLowerCase()
  return (result.replace(/^./, result[0].toUpperCase()))

}



function SidebarCategory(props){

  // console.log(props)
  let category = props.category
  let prevLink = props.prevLink
  let productCategories = props.productCategories

  const link = prevLink + "/" + category.slug;
  const match = useRouteMatch();
  const active = (productCategories.indexOf(category.id) !== -1) || ((match.url === link) || ((match.url.indexOf(link) === 0) && (match.url.indexOf("/", link.length) !== -1)));
  const splitUrl = match.url.split("/").filter(element => {
    if (!element) return false;
    if (element === "eshop") return false;
    if (element.indexOf(":") !== -1) return false;
    return true;
  });
  const lastActive = active && (splitUrl.length && (category.slug === splitUrl[splitUrl.length - 1]));

  let children = null;
  if (category?.children?.items?.length){
    children = [...category.children.items].sort((a, b) => a.order - b.order);
  }
  return (
    <li className={(lastActive ? "current" : "") + " " +(active ? ((prevLink === "/eshop" ? "first " : "") + "active") : "inactive")}>
      <Link to={link}>
        <CategoryName name={category.name} />
        {lastActive ? (<span onClick={() => {
          props.dispatch(toggleMenu(false));
        }} className="caret-right"></span>) : null}
      </Link>
      {children ? (
        <ul className={active ? "active" : "hide"}>
          {children.map(subCategory => (
            <SidebarCategory {...props} key={"subCategory_" + subCategory.id} category={subCategory} prevLink={link} productCategories={productCategories}/>
          ))}
        </ul>
      ) : null}
    </li>
  );
}


function EshopSidebar2(props){
  // {className}
  // console.log(props);
  
  const { loading, error, data } = useQuery(getFullCategoryList);
  const match = useRouteMatch();
  const filter = match?.params?.filter;
  const splitFilter = filter ? filter.split("/") : [];
  let productId = null;
  let categorySlugs = null;
  if (splitFilter[0] === "product"){
    productId = splitFilter[1];
  }
  else{
    categorySlugs = [];
    splitFilter.forEach(filterParam => {
      if (filterParam.indexOf(":") === -1){
        categorySlugs.push(filterParam);
      }
    })
  }
  const { loading: productLoading, error: productError, data: productData } = useQuery(getSidebarProductInfo, {
    variables: {
      id: productId
    },
    skip: !productId
  });

  if (error || productError){
    console.error(error || productError);
    return(
      <div className="col w-25p eshopSidebar">
        Nastala chyba při načítání sidebaru
      </div>
    );
  }

  if ((loading || productLoading) && (!data || !productData)){
    return(
      <div className="col w-25p eshopSidebar">
        <Preloader/>
      </div>
    );
  }

  const categories = [...data.listProductCategorys.items].sort((a, b) => a.order - b.order);
  const categoryLevels = [
    "category",
    "subCategory",
    "subSubCategory",
    "subSubSubCategory",
    "subSubSubSubCategory",
  ];
  let productCategories = [];
  if (productData?.getProduct){
    categoryLevels.forEach(categoryLevel => {
      if (productData.getProduct[categoryLevel]){
        productCategories.push(productData.getProduct[categoryLevel].id)
      }
    })
  }

  return(
    <div className={"col w-25p eshopSidebar " + props.className}>
      <ul className={"sideBar"}>
        {categories.map(category => (
          <SidebarCategory {...props} key={"category_" + category.id} category={category} prevLink={"/eshop"} productCategories={productCategories}/>
        ))}
      </ul>

    </div>
  );
}

export default connect((state) => ({
  menuOpen: state.menuOpen
}))(EshopSidebar2);

// export default EshopSidebar2;
