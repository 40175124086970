import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Button, Col, Form, Table} from "react-bootstrap";
import ConfirmButton from "../util/ConfirmButton";

const getMenu = gql`
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      name
      position
      elements{
        items{
          id
          title
          type
          target
          order
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const updateMenuMutation = gql`
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
    }
  }
`;

const createElementMutation = gql`
  mutation CreateMenuElement(
    $input: CreateMenuElementInput!
    $condition: ModelMenuElementConditionInput
  ) {
    createMenuElement(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateElementMutation = gql`
  mutation UpdateMenuElement(
    $input: UpdateMenuElementInput!
    $condition: ModelMenuElementConditionInput
  ) {
    updateMenuElement(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteElementMutation = gql`
  mutation DeleteMenuElement(
    $input: DeleteMenuElementInput!
    $condition: ModelMenuElementConditionInput
  ) {
    deleteMenuElement(input: $input, condition: $condition) {
      id
    }
  }
`;

function MenuEditForm({initialMenu, saveChanges}){
  const parseInitialMenu = () => JSON.parse(JSON.stringify(initialMenu), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [menu, setMenu] = useState(parseInitialMenu());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newMenu = JSON.parse(JSON.stringify(menu));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newMenu[splitPath[0]] = value;
        break;
      case 2:
        newMenu[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newMenu[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setMenu(newMenu);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={menu.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="position">
            <Form.Label>Pozice</Form.Label>
            <Form.Control type="text" placeholder="není" value={menu.position} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setMenu(parseInitialMenu())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...menu, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function ElementRow({initialElement}){
  const parseInitialElement = () => JSON.parse(JSON.stringify(initialElement), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [element, setElement] = useState(parseInitialElement());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newElement = JSON.parse(JSON.stringify(element));
    if (path === "order"){
      value = parseInt(value);
    }
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newElement[splitPath[0]] = value;
        break;
      case 2:
        newElement[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newElement[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setElement(newElement);
  };
  const [deleteElement, deleteElementResponse] = useMutation(deleteElementMutation, {
    refetchQueries: ["GetMenu"],
  });
  const [updateElement, updateElementResponse] = useMutation(updateElementMutation, {
    refetchQueries: ["GetMenu"],
  })

  return (
    <tr>
      <td>
        <Form.Group controlId="title" className={"m-0"}>
          <Form.Control type="text" placeholder="není" value={element.title} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="target" className={"m-0"}>
          <Form.Control type="text" placeholder="není" value={element.target} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="order" className={"m-0"}>
          <Form.Control type="number" step={1} placeholder="není" value={element.order} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="type" className={"m-0"}>
          <Form.Control
            as={"select"}
            value={element.type}
            onChange={changeEventHandler}
          >
            <option value={"NULL"}>Žádný</option>
            <option value={"internal-link"}>Odkaz na vlastní stránky</option>
            <option value={"external-link"}>Externí odkaz</option>
            <option value="mobile-submenu-eshop">Odkaz s mobilním submenu kategorií</option>
          </Form.Control>
        </Form.Group>
      </td>
      <td>
        <div className="btn-group btn-group-sm" role="group" aria-label="Operace s prvkem">
          <ConfirmButton confirmMessage={"Skutečně chcete smazat toto menu?"} disabled={deleteElementResponse.loading || updateElementResponse.loading} onClick={() => deleteElement({variables: {input: {id: element.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          <button disabled={deleteElementResponse.loading || updateElementResponse.loading} onClick={() => setElement(parseInitialElement())} type="button" className="btn btn-secondary">Vrátit</button>
          <button disabled={deleteElementResponse.loading || updateElementResponse.loading} onClick={() => updateElement({
            variables: {
              input: {...element, updatedAt: undefined, createdAt: undefined,}
            }
          })} type="button" className="btn btn-primary">Uložit</button>
        </div>
      </td>
    </tr>
  );
}

function ElementsTable({elements, menuId}){
  const [createElement, createElementResponse] = useMutation(createElementMutation, {
    refetchQueries: ["GetMenu"],
  })
  let sortElements = [...elements].sort((a, b) => a.order - b.order)
  return (
    <>
      <h4>
        <span>Prvky</span>
        <Button className={"float-right"} variant={"success"} onClick={() => createElement({
          variables: {
            input: {
              menuID: menuId,
              order: 0,
              title: "Nový prvek",
              type: "",
              target: "",
            }
          }
        })}>Přidat nový</Button>
      </h4>
      <hr/>
      <Table striped bordered hover responsive={"md"}>
        <thead>
        <tr>
          <th>Text</th>
          <th>Odkaz</th>
          <th>Pořadí</th>
          <th>Typ</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        {sortElements.map(element => (
          <ElementRow key={"element_" + element.id} initialElement={element}/>
        ))}
        </tbody>
      </Table>
    </>
  );
}

function SingleMenu(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getMenu, {
    variables: {
      id: match.params.menuId,
    }
  });
  const [updateMenu, updateMenuResponse] = useMutation(updateMenuMutation, {
    refetchQueries: [
      "GetMenu"
    ],
  });
  if (error) return (
    <article className={"singleMenu"}><div className={"danger"}>Chyba při načítání menu</div></article>
  ) ;

  if (loading || updateMenuResponse.loading) return (
    <article className={"singleMenu"}><Preloader/></article>
  ) ;

  const menu = data.getMenu;

  return (
    <article className={"singleMenu"}>
      <h3>Menu: {match.params.menuId}</h3>
      <hr/>
      <MenuEditForm initialMenu={menu} saveChanges={(changedMenu) => updateMenu({
        variables:{
          input: {
            ...changedMenu,
            elements: undefined,
            createdAt: undefined,
            updatedAt: undefined,
          },
        }
      })}/>
      <hr/>
      <ElementsTable elements={menu?.elements?.items} menuId={menu?.id}/>
    </article>
  );
}

export default SingleMenu;
