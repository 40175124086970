import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listMenus = gql`
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        position
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const updateMenuMutation = gql`
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteMenuMutation = gql`
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
    }
  }
`;

const createMenuMutation = gql`
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
    }
  }
`;

function MenuRow({menu, selected, onSelectChange}){
  const [updateMenu, updateMenuResponse] = useMutation(updateMenuMutation, {
    refetchQueries: [
      'ListMenus'
    ]
  });
  const [deleteMenu, deleteMenuResponse] = useMutation(deleteMenuMutation, {
    refetchQueries: [
      'ListMenus'
    ]
  });
  const [order, updateOrder] = useState(menu.order);
  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat menu"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{menu.id}</td>
      <td>{menu.name}</td>
      <td>{menu.position}</td>
      <td>{menu.createdAt}</td>
      <td>
        {updateMenuResponse.loading || deleteMenuResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s menu">
            <Link to={"/admin/menus/" + menu.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat menu: " + menu.name + "?"} onClick={() => deleteMenu({variables: {input: {id: menu.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Menus(props){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("order");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listMenus, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createMenu, createMenuResponse] = useMutation(createMenuMutation, {
    refetchQueries: [
      'ListMenus'
    ]
  });
  const [selectedMenus, dispatchSelectedMenus] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listMenus.items.length){
          return {}
        }
        data.listMenus.items.forEach(menu => newState[menu.id] = menu);
        return newState;
      case "toggle":
        if(newState[action.menu.id]){
          delete newState[action.menu.id];
        }
        else{
          newState[action.menu.id] = action.menu;
        }
        return newState;
      case "select":
        newState[action.menu.id] = action.menu;
        return newState;
      case "deselect":
        delete newState[action.menu.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání menu</div>
    );

    const menus = [...data.listMenus.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedMenus).length === menus.length} onChange={() => dispatchSelectedMenus({type: "selectAll"})} aria-label={"Vybrat menu"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Pozice {renderSortDirection("fee")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {menus.map(menu => (
            <MenuRow key={"menu_" + menu.id} onSelectChange={() => dispatchSelectedMenus({type: "toggle", menu})} selected={!!selectedMenus[menu.id]} menu={menu}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"menus"}>
      <h3>Menu</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createMenuResponse.loading} onClick={() => createMenu({variables: {input: {
              name: "Nové menu",
              position: "none",
            }}})}>Vytvořit nové</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Menus;
