import React from "react";
import Preloader from "../util/Preloader";

import { gql, useQuery } from "@apollo/client";
import { connect } from "react-redux";

import "./orderDetail.scss";

const GetSingleOrder = gql`
  query GetSingleOrder($id: ID!) {
    getSingleOrder(id: $id) {
      id
      shippingMethodId
      paymentMethodId
      orderStatus
      note
      createdAt
      orderNumber
      fields {
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
    }
  }
`;
function OrderDetail(props) {
  const {
    data: getSingleOrderData,
    loading: getSingleOrderLoading,
  } = useQuery(GetSingleOrder, {
    variables: {
      id: props.orderId,
    },
    onError(error) {
      console.log(error);
    },
  });

  if (!props.orderId) {
    return null;
  }

  const includeVATInPrices =
    props.cognito.currentUserInfo.attributes["custom:pricesInclVat"] === "1";

  var orders = document.getElementById("orders");
  var wrapperPosition = {};
  if (orders) {
    var bounding = orders.getBoundingClientRect();
    wrapperPosition = {
      top: window.pageYOffset + 170,
      left: bounding.left || 0,
      width: bounding.width || 0,
      height: "auto",
    };
  }

  if (getSingleOrderLoading && !getSingleOrderData) {
    return (
      <div className="orderDetailWrapper" style={wrapperPosition}>
        <div className={"header"}>
          <h2>Detail objednávky</h2>
        </div>
        <div className={"container mbt-25"}>
          <Preloader />
        </div>
      </div>
    );
  }
  const paymentMethod = getPaymentMethod(
    getSingleOrderData.getSingleOrder.paymentMethodId
  );
  const shippingMethod = getShippingMethod(
    getSingleOrderData.getSingleOrder.shippingMethodId
  );

  function formatDate(createdAt) {
    let date = new Date(createdAt);
    return (("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2)) + " " + ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
  }

  function formatStatus(status){
    const statusMap = {
      new: "Nová",
      paid: "Zaplacená",
      complete: "Vyřízená",
      cancelled: "Zrušená",
    }
    return statusMap[status] || status;
  }

  return (
    <div className="orderDetailWrapper" style={wrapperPosition}>
      <div className={"header"}>
        <h2>Detail objednávky: {getSingleOrderData.getSingleOrder.id}</h2>
        <div
          className="button close"
          onClick={() => {
            props.closeDetail();
          }}
        ></div>
      </div>
      <div className={"container"}>
        <div className="row">
          <div className="col">
            <strong>Číslo objednávky</strong>
          </div>
          <div className="col">{getSingleOrderData.getSingleOrder.orderNumber}</div>
        </div>

        <div className="row">
          <div className="col">
            <strong>Stav objednávky</strong>
          </div>
          <div className="col">
            {formatStatus(getSingleOrderData.getSingleOrder.orderStatus)}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <strong>Vytvoření objednávky</strong>
          </div>
          <div className="col">
            {formatDate(getSingleOrderData.getSingleOrder.createdAt)}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <strong>Způsob platby</strong>
          </div>
          <div className="col">
            {paymentMethod.name}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <strong>Způsob dopravy</strong>
          </div>
          <div className="col">
            {shippingMethod.name}
          </div>
        </div>

        <div className="table-responsive cart mt-10">
          <div className="table-header">
            <div className="id">ID</div>
            <div className="name">Název produktu</div>
            <div className="quantity">Množství</div>
            <div className="itemprice">Cena/ks</div>
            <div className="totalprice">Cena celkem</div>
          </div>
          <div className="table-content">{renderorderFields()}</div>
        </div>
      </div>
      <div className={"footer"}></div>
    </div>
  );
  function getPaymentMethod(id) {
    let toReturn = null;
    props.paymentMethods.forEach((paymentMethod) => {
      if (paymentMethod.id === id) toReturn = paymentMethod;
    });
    return toReturn;
  }

  function getShippingMethod(id) {
    let toReturn = null;
    props.shippingMethods.forEach((shippingMethod) => {
      if (shippingMethod.id === id) toReturn = shippingMethod;
    });
    return toReturn;
  }
  function renderorderFields() {
    return getSingleOrderData.getSingleOrder.fields.map((item, index) => {
      return (
        <div
          className={
            (getSingleOrderData.getSingleOrder.fields.length - 1 === index
              ? "last-item "
              : "") + "table-content-item"
          }
          key={item.productId}
        >
          <div className="id">{item.productId}</div>
          <div className="name"> {item.name}</div>
          <div className="quantity">{item.amount}</div>
          <div className="itemprice">
            <span>
              {(includeVATInPrices
                ? item.priceIncludingTax
                : item.price
              ).toFixed(2)}
            </span>{" "}
            <span> Kč</span>
          </div>
          <div className="totalprice">
            <span>
              {(
                (includeVATInPrices ? item.priceIncludingTax : item.price) *
                item.amount
              ).toFixed(2)}
            </span>
            <span> Kč</span>
          </div>
        </div>
      );
    });
  }
}

export default connect((state) => ({
  cognito: state.cognito,
}))(OrderDetail);
