import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Col, Form} from "react-bootstrap";

const getShippingMethod = gql`
  query GetShippingMethod($id: ID!) {
    getShippingMethod(id: $id) {
      id
      name
      description
      fee
      feePercent
      order
      createdAt
      updatedAt
      requiresShippingAddress
      minAllowedCartPrice
      maxAllowedCartPrice
      slug
    }
  }
`;

const updateShippingMethodMutation = gql`
  mutation UpdateShippingMethod(
    $input: UpdateShippingMethodInput!
    $condition: ModelShippingMethodConditionInput
  ) {
    updateShippingMethod(input: $input, condition: $condition) {
      id
      name
      description
      fee
      feePercent
      order
      createdAt
      updatedAt
      requiresShippingAddress
      minAllowedCartPrice
      maxAllowedCartPrice
      slug
    }
  }
`;

function ShippingMethodEditForm({initialShippingMethod, saveChanges}){
  const parseInitialShippingMethod = () => JSON.parse(JSON.stringify(initialShippingMethod), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [shippingMethod, setShippingMethod] = useState(parseInitialShippingMethod());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newShippingMethod = JSON.parse(JSON.stringify(shippingMethod));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newShippingMethod[splitPath[0]] = value;
        break;
      case 2:
        newShippingMethod[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newShippingMethod[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setShippingMethod(newShippingMethod);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Název</Form.Label>
            <Form.Control type="text" placeholder="není" value={shippingMethod.name} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="order">
            <Form.Label>Pořadí</Form.Label>
            <Form.Control type="number" placeholder="není" value={shippingMethod.order} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="fee">
            <Form.Label>Poplatek</Form.Label>
            <Form.Control type="number" placeholder="není" step={0.01} value={shippingMethod.fee} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="feePercent">
            <Form.Label>Procentuelní poplatek</Form.Label>
            <Form.Control type="number" placeholder="není" step={0.01} value={shippingMethod.feePercent} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="requiresShippingAddress">
            <Form.Switch checked={shippingMethod.requiresShippingAddress} label={"Vyžaduje dodací adresu"} onChange={(e) => changeHandler("requiresShippingAddress", e.target.checked)}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"minAllowedCartPrice"}>
            <Form.Label>Minimální povolená cena košíku</Form.Label>
            <Form.Control type={"number"} placeholder={"není"} step={0.01} value={shippingMethod.minAllowedCartPrice} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"maxAllowedCartPrice"}>
            <Form.Label>Maximální povolená cena košíku</Form.Label>
            <Form.Control type={"number"} placeholder={"není"} step={0.01} value={shippingMethod.maxAllowedCartPrice} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"slug"}>
            <Form.Label>Označení v importu</Form.Label>
            <Form.Control type={"text"} placeholder={"není"} value={shippingMethod.slug} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId={"description"}>
            <Form.Label>Popis</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={shippingMethod.description} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setShippingMethod(parseInitialShippingMethod())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...shippingMethod, createdAt: undefined, updatedAt: undefined, minAllowedCartPrice: shippingMethod.minAllowedCartPrice || null, maxAllowedCartPrice: shippingMethod.maxAllowedCartPrice || null})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleShippingMethod(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getShippingMethod, {
    variables: {
      id: match.params.shippingMethodId,
    }
  });
  const [updateShippingMethod, updateShippingMethodResponse] = useMutation(updateShippingMethodMutation);
  if (error) return (
    <article className={"singleShippingMethod"}><div className={"danger"}>Chyba při načítání výrobce</div></article>
  ) ;

  if (loading || updateShippingMethodResponse.loading) return (
    <article className={"singleShippingMethod"}><Preloader/></article>
  ) ;

  const shippingMethod = data.getShippingMethod;

  return (
    <article className={"singleShippingMethod"}>
      <h3>Dopravní metoda: {match.params.shippingMethodId}</h3>
      <hr/>
      <ShippingMethodEditForm initialShippingMethod={shippingMethod} saveChanges={(changedShippingMethod) => updateShippingMethod({
        variables:{
          input: changedShippingMethod,
        }
      })}/>
    </article>
  );
}

export default SingleShippingMethod;
