import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";

import "./pills.scss";
import Preloader from "../util/Preloader";

const getCategoryList = gql`
  query getCategoryList {
    listProductCategorys {
      items {
        name
        id
        order
        slug
        description
      }
    }
  }
`;

const Pills = (props) => {
  const {loading, error, data} = useQuery(getCategoryList);

  if (error){
    return(
      <div className={"row gutter-20px pills " + props.className}>
        <span className="error">Nastal problém s načítáním kategorií</span>
      </div>
    )
  }

  const pills = data?.listProductCategorys?.items;

  if (loading && !pills){
    return(
      <div className={"row gutter-20px pills " + props.className}>
        <Preloader/>
      </div>
    )
  }

  return (
    <div className={"row gutter-20px pills " + props.className}>
      {pills
        .slice()
        .sort((a, b) => a.order - b.order)
        .map((pill, index) => (
          <Link
            to={"/eshop/" + pill.slug}
            key={"pill_" + index}
            className={"col pill  box-shadow " + props.pillClassName}
          >
            <span>{pill.name}</span>
          </Link>
        ))}
    </div>
  );
};

Pills.propTypes = {
  className: PropTypes.string,
  pillClassName: PropTypes.string,
};

export default Pills;
