import React, { Component } from 'react';
import './preloader.scss';
import PropTypes from 'prop-types';

class Preloader extends Component {
  static defaultProps = {
    type: "dots",
  };

  renderTypeSpecificInner(type){
    switch (type) {
      case "dots": return (
        <>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
        </>
      );
      default: return <span>Invalid preloader type chosen!</span>;
    }
  }

  render(){
    return (
      <div className={"preloaderWrapper"}>
        <div className={"preloader " + this.props.type}>
          {this.renderTypeSpecificInner(this.props.type)}
        </div>
      </div>
    )
  }
}

Preloader.propTypes = {
  type: PropTypes.string,
};

export default Preloader
