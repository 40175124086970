class Icons{
  static getIcon(icon){
    switch (icon) {
      case 'caret':
      case 'cart':
      case 'cartWhite':
      case 'addToCart':
      case 'helpdesk':
      case 'logout':
      case 'search':
      case 'tools':
      case 'userAccount':
      case 'pin':
      case 'check-box':
      case 'pdf':
      case 'eye':
      case 'bankTransfer':
      case 'truck':
      case 'user':
      case 'company':
      case 'marker':
      case 'pencil':
      case 'close':
        return '/staticImages/icons/' + icon + '.svg';
      default:
        return null;
    }
  }
}

export default Icons;
