import React, {Component} from "react";
import Auth from "@aws-amplify/auth";
import {fetchCurrentUserInfo} from "../app/cognitoReducer";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {addNotification} from '../app/notificationsReducer';
import { Helmet } from "react-helmet-async";

import './registration.scss'

class Register extends Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      login: '',
      email: '',
      password: '',
      passwordConfirm: '',

      name: '',
      telephonePrefix: '+420',
      telephone: '',

      companyName: '',
      companyId: '',
      companyTaxId: '',

      street: '',
      city: '',
      zip: '',

      error: '',

      gdpr: false,
    }
  }

  renderError(errors){

  }

  checkInputs(){
    const notEmpty = {
      "login": "Login",
      "email": "Email",
      "password": "Heslo",
      "passwordConfirm": "Heslo znovu",
      "name": "Jméno",
      "telephone": "Telefon",
      // "companyName",
      // "companyId",
      // "companyTaxId",
      "street": "Ulice a č.p.",
      "city": "Město",
      "zip": "PSČ",
    };

    const notEmptyKeys = Object.keys(notEmpty);
    for (let i = 0; i < notEmptyKeys.length; i++){
      if (!this.state[notEmptyKeys[i]]){
        const error = {
          message: `Pole ${notEmpty[notEmptyKeys[i]]} nesmí být prázdné`,
          type: "error",
          code: `frontend.${notEmptyKeys[i]}Empty`,
          field: notEmptyKeys[i],
        };
        this.props.dispatch(addNotification(error));
        this.setState(() => ({error: error}));
        return false;
      }
    }

    if (!this.state.gdpr){
      const error = {
        message: `Musíte souhlasit s GDPR`,
        type: "error",
        code: `frontend.gdpr`,
        field: "gdpr",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }

    if (this.state.password !== this.state.passwordConfirm) {
      const error = {
        message: "Heslo se neshoduje s kontrolou",
        type: "error",
        code: "frontend.missmatchPassword",
        field: "passwordConfirm",
      };
      this.props.dispatch(addNotification(error));
      this.setState(() => ({error: error}));
      return false;
    }

    return true;
  }

  doRegister(){
    if (this.state.loading) return;
    if (!this.checkInputs()) return;
    this.setState(() => ({loading: true}), () => {
      Auth.signUp({
        username: this.state.login,
        password: this.state.password,
        attributes: {
          name: this.state.name,
          email: this.state.email,
          phone_number: this.state.telephonePrefix + this.state.telephone,
          "custom:companyName": this.state.companyName,
          "custom:companyId": this.state.companyId,
          "custom:companyTaxId": this.state.companyTaxId,
          "custom:street": this.state.street,
          "custom:city": this.state.city,
          "custom:zip": this.state.zip,
        },
      })
        .then(() => {this.props.dispatch(fetchCurrentUserInfo()); this.props.history.push("/verify/" + this.state.login)})
        .catch((error) => {
          switch (error.code){
            case "UsernameExistsException":
              error.message = "Login již používá jiný uživatel";
              error.field = "login";
              break;
            case "InvalidPasswordException":
              error.message = "Heslo nevyhovuje požadavkům: Alespoň 8 znaků, z čehož musí být alespoň jedno: Číslo, velké písmeno a malé písmeno";
              error.field = "password";
              break;
          }
          console.log(error);
          this.props.dispatch(addNotification({
            message: error.message,
            type: "error",
            code: error.code
          }));
        })
        .finally(() => this.setState(() => ({loading: false})));
    });
  }

  render() {
    if (this.props.cognito.currentSession){
      return <Redirect to={"/"}/>
    }

    return (
      <div className={"registration container mbt-25"}>
        <Helmet>
          <title>Registrace</title>
        </Helmet>
        <div className={"row gutter-20px m-0"}>
          <div className="col mbt-25 w-100p">
            <div className="pageHeader">
              <h1>Registrace</h1>
            </div>

            <div className="row pageContent">
            <div className="col w-66p">
              <form onSubmit={event => {event.preventDefault(); this.doRegister(); }}>
                <div className="panel fullWidthInput mb-20">
                  <div className="panelHeader">
                    <h2>Základní informace</h2>
                  </div>
                  <div className="panelBody">
                    <label className={"mb-15 grid-105"}>
                      <span>Login</span>
                      <input type={"text"} name={"login"} className={(this?.state?.error?.field === "login") ? "error" : ""} value={this.state.login} onChange={({target}) => this.setState(() => ({login: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-105"}>
                      <span>Email</span>
                      <input type={"email"} name={"email"} className={(this?.state?.error?.field === "email") ? "error" : ""} value={this.state.email} onChange={({target}) => this.setState(() => ({email: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-105"}>
                      <span>Heslo</span>
                      <input type={"password"} name={"password"} className={(this?.state?.error?.field === "password") ? "error" : ""} value={this.state.password} onChange={({target}) => this.setState(() => ({password: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-105"}>
                      <span>Heslo znovu</span>
                      <input type={"password"} name={"passwordConfirm"} className={(this?.state?.error?.field === "passwordConfirm") ? "error" : ""} value={this.state.passwordConfirm} onChange={({target}) => this.setState(() => ({passwordConfirm: target.value}))}/>
                    </label>
                  </div>
                </div>

                <div className="panel fullWidthInput mb-20">
                  <div className="panelHeader">
                    <h2>Kontaktní osoba</h2>
                  </div>
                  <div className="panelBody">
                    <label className={"mb-15 grid-80"}>
                      <span>Jméno</span>
                      <input type={"text"} name={"name"} className={(this?.state?.error?.field === "name") ? "error" : ""} value={this.state.name} onChange={({target}) => this.setState(() => ({name: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-80-130"}>
                      <span>Telefon</span>
                      <select value={this.state.telephonePrefix} onChange={({target}) => this.setState(() => ({telephonePrefix: target.value}))}>
                        <option>+420</option>
                        <option>+421</option>
                      </select>
                      <input type={"tel"} name={"telephone"} className={(this?.state?.error?.field === "telephone") ? "error" : ""} value={this.state.telephone} onChange={({target}) => this.setState(() => ({telephone: target.value}))}/>
                    </label>
                  </div>
                </div>

                <div className="panel fullWidthInput mb-20">
                  <div className="panelHeader">
                    <h2>Firma</h2>
                  </div>
                  <div className="panelBody">
                    <label className={"mb-15 grid-80"}>
                      <span>Název</span>
                      <input type={"text"} name={"companyName"} className={(this?.state?.error?.field === "companyName") ? "error" : ""} value={this.state.companyName} onChange={({target}) => this.setState(() => ({companyName: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-80"}>
                      <span>IČO</span>
                      <input type={"text"} name={"companyId"} className={(this?.state?.error?.field === "companyId") ? "error" : ""} value={this.state.companyId} onChange={({target}) => this.setState(() => ({companyId: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-80"}>
                      <span>DIČ</span>
                      <input type={"text"} name={"companyTaxId"} className={(this?.state?.error?.field === "companyTaxId") ? "error" : ""} value={this.state.companyTaxId} onChange={({target}) => this.setState(() => ({companyTaxId: target.value}))}/>
                    </label>
                  </div>
                </div>

                <div className="panel fullWidthInput mb-20">
                  <div className="panelHeader">
                    <h2>Adresa</h2>
                  </div>
                  <div className="panelBody">
                    <label className={"mb-15 grid-80"}>
                      <span>Ulice a č.p.</span>
                      <input type={"text"} name={"street"} className={(this?.state?.error?.field === "street") ? "error" : ""} value={this.state.street} onChange={({target}) => this.setState(() => ({street: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-80"}>
                      <span>Město</span>
                      <input type={"text"} name={"city"} className={(this?.state?.error?.field === "city") ? "error" : ""} value={this.state.city} onChange={({target}) => this.setState(() => ({city: target.value}))}/>
                    </label>
                    <label className={"mb-15 grid-80"}>
                      <span>PSČ</span>
                      <input type={"text"} name={"zip"} className={(this?.state?.error?.field === "zip") ? "error" : ""} value={this.state.zip} onChange={({target}) => this.setState(() => ({zip: target.value}))}/>
                    </label>
                  </div>
                </div>

              </form>

              <div className="row">
                <div className="col w-100p">
                  <h3 className={"mb-5"}>GDPR</h3>
                  <label>
                    <input type={"checkbox"} value={true} className={(this?.state?.error?.field === "gdpr") ? "error" : ""} checked={this.state.gdpr} onChange={() => this.setState((state) => ({gdpr: !state.gdpr}))}/>
                    Souhlasím se zpracováním osobních údajů
                    <a className={"underlinedLink blueLink ml-5"} target={"_blank"} href={"/staticDocs/GDPR_TOPASPLUS_final_9_2021.docx.pdf"}>(Více informací zde)</a>
                  </label>
                </div>
              </div>

              <div className={"row mt-10 buttonWrapper"}>
                <div className={"button medium blue"} onClick={() => this.doRegister()}><span>Založit účet</span></div>
              </div>
            </div>
            <div className="col w-33p">
              <div className="row">
                <div className="col w-100p">
                  <h3 className={"mb-0"}>1. Slevy za odběr zboží</h3>
                  <p>
                    Čím více objednáte, tím větší máte slevu! Registrujte se a získejte informace o partnerském programu.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col w-100p">
                  <h3 className={"mb-0 mt-15"}>2. Sledování stavu objednávky</h3>
                  <p>
                    Ve svém uživatelském profilu můžete sledovat stav objednávky a tak víte, kdy materiál dorazí.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col w-100p">
                  <h3 className={"mb-0 mt-15"}>3. Historie objednávek</h3>
                  <p>
                    Nemusíte držet faktury u sebe, ve svém profilu si jednoduše dohledáte všechny vaše objednávky.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

     

      </div>
    );
  }
}

export default connect(state => ({cognito: state.cognito}))(Register);
