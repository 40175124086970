import React from "react";
import {gql, useQuery} from "@apollo/client";
import Preloader from "../../util/Preloader";

const sitemapQuery = gql`
  query GetSitemapData{
    listPages{
      items{
        id
        slug
        updatedAt
      }
    }
    listProductCategorys {
      items {
        id
        slug
        updatedAt
        children {
          items {
            id
            slug
            updatedAt
            children {
              items {
                id
                slug
                updatedAt
                children {
                  items {
                    id
                    slug
                    updatedAt
                    children{
                      items{
                        id
                        slug
                        updatedAt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    listNewsCategorys {
      items {
        id
        slug
        updatedAt
      }
    }
  }
`;

function Sitemap(){
  const {loading, data, error} = useQuery(sitemapQuery);
  if (error){
    return (
      <div>
        <h3>Aktuální sitemap</h3>
        <p>
          Nastala chyba: {error.message}
        </p>
      </div>
    )
  }
  if (loading){
    return (
      <div>
        <h3>Aktuální sitemap</h3>
        <Preloader/>
      </div>
    );
  }
  let currentDate = (new Date()).toISOString();
  let siteMap = `
<?xml version="1.0" encoding="UTF-8"?>
<urlset
        xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">

    <url>
        <loc>https://topasplus.cz/</loc>
        <lastmod>${currentDate}</lastmod>
    </url>  
  `;
  if (data?.listPages?.items){
    data.listPages.items.forEach(page => {
      siteMap += `
    <url>
        <loc>https://topasplus.cz/${page.slug}</loc>
        <lastmod>${page.updatedAt}</lastmod>
    </url>  
      `;
    })
  }
  siteMap += `
    <url>
        <loc>https://topasplus.cz/eshop</loc>
        <lastmod>${currentDate}</lastmod>
    </url>  
  `;
  if (data?.listProductCategorys?.items){
    function extendSitemapByCategory(category, prefix ){
      console.log({category, prefix});
      siteMap += `
        <url>
            <loc>https://topasplus.cz/${prefix}${category.slug}</loc>
            <lastmod>${category.updatedAt}</lastmod>
        </url>  
      `;
      if (category?.children?.items){
        category.children.items.forEach((child) => extendSitemapByCategory(child, prefix + category.slug + "/"));
      }
    }
    data.listProductCategorys.items.forEach((category) => extendSitemapByCategory(category, "eshop/"));
  }
  // TODO: https://topasplus.cz/eshop/product/:productId ... Problem: Limit can not exceed 1000 on searchProducts query

  siteMap += `
    <url>
        <loc>https://topasplus.cz/novinky</loc>
        <lastmod>${currentDate}</lastmod>
    </url>  
  `;

  if (data?.listNewsCategorys?.items){
    data.listNewsCategorys.items.forEach(newsCategory => {
      siteMap += `
    <url>
        <loc>https://topasplus.cz/novinky/${newsCategory.slug}</loc>
        <lastmod>${newsCategory.updatedAt}</lastmod>
    </url>  
      `;
    });
  }

  // TODO: https://topasplus.cz/novinky/:categorySlug/:postSlug

  siteMap += `
</urlset>  
  `;
  return (
    <div>
      <h3>Aktuální sitemap:</h3>
      <pre>
        {siteMap}
      </pre>
    </div>
  );
}

export default Sitemap;
