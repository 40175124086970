import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import Preloader from "../../util/Preloader";
import {Button, Col, Form, Table} from "react-bootstrap";
import {bannerPositions} from './Banners';
import ConfirmButton from "../util/ConfirmButton";

const getBanner = gql`
  query GetBanner($id: ID!) {
    getBanner(id: $id) {
      id
      position
      order
      target
      title
      text
      buttonText
      buttonClass
      bannerClass
      createdAt
      updatedAt
      specialFunction
      image
      pills {
        items {
          class
          icon
          id
          order
          target
          text
        }
      }
    }
  }
`;

const updateBannerMutation = gql`
  mutation UpdateBanner(
    $input: UpdateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    updateBanner(input: $input, condition: $condition) {
      id
    }
  }
`;

const createPillMutation = gql`
  mutation CreateBannerPill(
    $input: CreateBannerPillInput!
    $condition: ModelBannerPillConditionInput
  ) {
    createBannerPill(input: $input, condition: $condition) {
      id
    }
  }
`;

const deletePillMutation = gql`
  mutation DeleteBannerPill(
    $input: DeleteBannerPillInput!
    $condition: ModelBannerPillConditionInput
  ) {
    deleteBannerPill(input: $input, condition: $condition) {
      id
    }
  }
`;

const updatePillMutation = gql`
  mutation UpdateBannerPill(
    $input: UpdateBannerPillInput!
    $condition: ModelBannerPillConditionInput
  ) {
    updateBannerPill(input: $input, condition: $condition) {
      id
    }
  }
`;

function PillRow({initialPill}){
  const parseInitialPill = () => JSON.parse(JSON.stringify(initialPill), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [pill, setPill] = useState(parseInitialPill());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newPill = JSON.parse(JSON.stringify(pill));
    if (path === "order"){
      value = parseInt(value);
    }
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newPill[splitPath[0]] = value;
        break;
      case 2:
        newPill[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newPill[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setPill(newPill);
  };
  const [deletePill, deletePillResponse] = useMutation(deletePillMutation, {
    refetchQueries: ["GetBanner"],
  });
  const [updatePill, updatePillResponse] = useMutation(updatePillMutation, {
    refetchQueries: ["GetBanner"],
  })

  return (
    <tr>
      <td>
        <Form.Group controlId="text" className={"m-0"}>
          <Form.Control type="text" placeholder="není" value={pill.text} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="target" className={"m-0"}>
          <Form.Control type="text" placeholder="není" value={pill.target} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="order" className={"m-0"}>
          <Form.Control type="number" step={1} placeholder="není" value={pill.order} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="icon" className={"m-0"}>
          <Form.Control type="text" placeholder="není" value={pill.icon} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <Form.Group controlId="class" className={"m-0"}>
          <Form.Control type="text" placeholder="není" value={pill.class} onChange={changeEventHandler} />
        </Form.Group>
      </td>
      <td>
        <div className="btn-group btn-group-sm" role="group" aria-label="Operace s bannerem">
          <ConfirmButton confirmMessage={"Skutečně chcete smazat tento banner?"} disabled={deletePillResponse.loading || updatePillResponse.loading} onClick={() => deletePill({variables: {input: {id: pill.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          <button disabled={deletePillResponse.loading || updatePillResponse.loading} onClick={() => setPill(parseInitialPill())} type="button" className="btn btn-secondary">Vrátit</button>
          <button disabled={deletePillResponse.loading || updatePillResponse.loading} onClick={() => updatePill({
            variables: {
              input: {...pill, updatedAt: undefined, createdAt: undefined,}
            }
          })} type="button" className="btn btn-primary">Uložit</button>
        </div>
      </td>
    </tr>
  );
}

function PillsTable({pills, bannerId}){
  const [createPill, createPillResponse] = useMutation(createPillMutation, {
    refetchQueries: ["GetBanner"],
  })
  let sortPills = [...pills].sort((a, b) => a.order - b.order)
  return (
    <>
      <h4>
        <span>Podbannery</span>
        <Button className={"float-right"} variant={"success"} onClick={() => createPill({
          variables: {
            input: {
              bannerID: bannerId,
              order: 0,
              text: "Nový podbanner"
            }
          }
        })}>Přidat nový</Button>
      </h4>
      <hr/>
      <Table striped bordered hover responsive={"md"}>
        <thead>
        <tr>
          <th>Text</th>
          <th>Odkaz</th>
          <th>Pořadí</th>
          <th>Ikona</th>
          <th>Třída</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        {sortPills.map(pill => (
          <PillRow key={"pill_" + pill.id} initialPill={pill}/>
        ))}
        </tbody>
      </Table>
    </>
  );
}

function BannerEditForm({initialBanner, saveChanges}){
  const parseInitialBanner = () => JSON.parse(JSON.stringify(initialBanner), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [banner, setBanner] = useState(parseInitialBanner());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let newBanner = JSON.parse(JSON.stringify(banner));
    let splitPath = path.split(".");
    switch (splitPath.length) {
      case 1:
        newBanner[splitPath[0]] = value;
        break;
      case 2:
        newBanner[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newBanner[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    setBanner(newBanner);
  };

  return(
    <Form>
      <Form.Row className={"border-bottom border-secondary mb-2"}>
        <Col>
          <Form.Group controlId="title">
            <Form.Label>Titulek</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.title} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="position">
            <Form.Label>Pozice</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.position} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="order">
            <Form.Label>Pořadí</Form.Label>
            <Form.Control type="number" placeholder="není" value={banner.order} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="buttonText">
            <Form.Label>Text tlačítka</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.buttonText} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="target">
            <Form.Label>Odkaz</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.target} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="bannerClass">
            <Form.Label>Třída banneru</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.bannerClass} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="specialFunction">
            <Form.Label>Speciální funkce banneru</Form.Label>
            <Form.Control
              as={"select"}
              className={"mr-2 " + (banner.specialFunction ? "" : " text-secondary ")}
              value={banner.specialFunction}
              onChange={changeEventHandler}
            >
              <option value={""}>Žádná</option>
              <option value="lastPost">Zobrazení posledního příspěvku</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="buttonClass">
            <Form.Label>Třída tlačítka</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.buttonClass} onChange={changeEventHandler} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId={"image"}>
            <Form.Label>Obrázek</Form.Label>
            <Form.Control type="text" placeholder="není" value={banner.image} onChange={changeEventHandler} />
          </Form.Group>
          <img className={"img-thumbnail"} src={banner.image}/>
        </Col>
        <Col>
          <Form.Group controlId={"text"}>
            <Form.Label>Text</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={"není"} value={banner.text} onChange={changeEventHandler}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={{span: 3, offset: 9}} >
          <div className="btn-group w-100" role="group" aria-label="Operace s objednávkou">
            <button onClick={() => setBanner(parseInitialBanner())} type="button" className="btn btn-secondary">Vrátit změny</button>
            <button onClick={() => saveChanges({...banner, pills: undefined, createdAt: undefined, updatedAt: undefined})} type="button" className="btn btn-primary">Uložit změny</button>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
}

function SingleBanner(props){
  const match = useRouteMatch();
  const {loading, data, error} = useQuery(getBanner, {
    variables: {
      id: match.params.bannerId,
    }
  });
  const [updateBanner, updateBannerResponse] = useMutation(updateBannerMutation, {
    refetchQueries: ["GetBanner"]
  });
  if (error) return (
    <article className={"singleBanner"}><div className={"danger"}>Chyba při načítání banneru</div></article>
  ) ;

  if (loading || updateBannerResponse.loading) return (
    <article className={"singleBanner"}><Preloader/></article>
  ) ;

  const banner = data.getBanner;

  return (
    <article className={"singleBanner"}>
      <h3>Banner: {match.params.bannerId}</h3>
      <hr/>
      <BannerEditForm initialBanner={banner} saveChanges={(changedBanner) => updateBanner({
        variables:{
          input: {...changedBanner, specialFunction: changedBanner.specialFunction || null},
        }
      })}/>
      <hr/>
      <PillsTable pills={banner?.pills?.items} bannerId={banner?.id}/>
    </article>
  );
}

export default SingleBanner;
