import React from "react";
import {Helmet} from "react-helmet-async";

function PageNotFound(){
  return(
    <article className={"dynamicPage container mbt-25 error 404"}>
      <Helmet>
        <title>404 - Stránka nenalezena</title>
      </Helmet>
      <div className="pageHeader d-flex">
        <h1>Stránka nebyla nalezena</h1>
      </div>
    </article>
  );
}

export default PageNotFound;
