import React, { Component } from 'react';
import "./footer.scss";
import HorizontalMenu from "../menu/HorizontalMenu";
import {withRouter} from "react-router-dom";

class Footer extends Component {
  render(){
    if (this.props.location.pathname.match(/^\/admin/))
      return null;
    return (
      <footer className={"pageFooter"}>
        <div className="footerContent">
          <HorizontalMenu open={true} position={"footer-menu"}/>
          <div className="row gutter-52px widgetContainer mlr-0-mobile">
            <div className="widget text col w-20p">
              <p className={"white no-spacing manrope uppercase font-size-12"}>
                <strong>
                  TOPAS PLUS J.M. S.R.O.<br/>
                  tel. <a href="tel:602559671">602 559 671</a><br/>
                  {/*tel. 2: <a href="tel:602728519">602 728 519</a><br/>*/}
                  Lísková 112, Zlonín 250 64<br/>
                  <a href="mailto:info@topasplus.cz">info@topasplus.cz</a>
                </strong>
              </p>
            </div>
            <div className="widget buttons col w-80p hidden-mobile">
              <div className="button large transparentWhite cursor-auto">
                <span>Doprava zdarma od 10 000 Kč</span>
              </div>
              <div className="button large transparentWhite cursor-auto">
                <span>Široký sortiment</span>
              </div>
              <div className="button large transparentWhite cursor-auto">
                <span>Profíci profíkům</span>
              </div>
              <div className="button large transparentWhite cursor-auto">
                <span>Roky zkušeností v oboru</span>
              </div>
            </div>
          </div>
          <hr className={"blue m-20"}/>
          <div className="row gutter-52px copyrightWrapper mlr-0-mobile">
            <div className="col w-20p vertical-centering">
              <span>© Copyright {(new Date()).getFullYear()} TopasPlus</span>
            </div>
            <div className="col w-80p">
              <span>
                Při poskytování služeb nám pomáhají soubory cookie.<br/>
                Používáním webu vyjadřujete souhlas. Proti tomuto zpracování můžete vznést námitku. <a className={"underlinedLink"} target={"_blank"} href={"/staticDocs/cookies_TOPASPLUS_final_9_2021.docx.pdf"}>(Více informací zde)</a>.
              </span>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default withRouter(Footer)
