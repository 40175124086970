import React, {useReducer, useState} from "react";
import {Table, Form, Button} from "react-bootstrap";
import {gql, useMutation, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import debounce from "lodash/debounce";
import ConfirmButton from "../util/ConfirmButton";

const listManufacturers = gql`
  query ListManufacturers(
    $filter: ModelManufacturerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManufacturers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const updateManufacturerMutation = gql`
  mutation UpdateManufacturer(
    $input: UpdateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    updateManufacturer(input: $input, condition: $condition) {
      id
      order
      name
      description
      image
      createdAt
      updatedAt
    }
  }  
`;

const deleteManufacturerMutation = gql`
  mutation DeleteManufacturer(
    $input: DeleteManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    deleteManufacturer(input: $input, condition: $condition) {
      id
      order
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;

const createManufacturerMutation = gql`
  mutation CreateManufacturer(
    $input: CreateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    createManufacturer(input: $input, condition: $condition) {
      id
    }
  }
`;

function ManufacturerRow(props){
  const [updateManufacturer, updateManufacturerResponse] = useMutation(updateManufacturerMutation, {
    refetchQueries: [
      'ListManufacturers'
    ]
  });
  const [deleteManufacturer, deleteManufacturerResponse] = useMutation(deleteManufacturerMutation, {
    refetchQueries: [
      'ListManufacturers'
    ]
  });
  const [order, updateOrder] = useState(props.manufacturer.order);
  const manufacturer = props.manufacturer;
  return (
    <tr>
      <td>
        <Form.Check checked={props.selected} onChange={() => props.onSelectChange()} aria-label={"Vybrat výrobce"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{manufacturer.id}</td>
      <td>{manufacturer.name}</td>
      <td><Form.Control type={"number"} size={"sm"} value={order} onKeyDown={({key}) => key === "Enter" ? updateManufacturer({variables: {input: {id: manufacturer.id, order}}}) : null} onChange={({target}) => updateOrder(parseInt(target.value))}/></td>
      <td>{manufacturer.createdAt}</td>
      <td>
        {updateManufacturerResponse.loading || deleteManufacturerResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s objednávkou">
            <Link to={"/admin/manufacturers/" + manufacturer.id} type="button" className="btn btn-primary">Upravit</Link>
            <ConfirmButton confirmMessage={"Skutečně chcete smazat výrobce: " + manufacturer.name + "?"} onClick={() => deleteManufacturer({variables: {input: {id: manufacturer.id}}})} type="button" className="btn btn-danger">Smazat</ConfirmButton>
          </div>
        )}
      </td>
    </tr>
  );
}

function Manufacturers(props){
  const [fulltext, changeFulltext] = useState("");
  const [sortField, changeSortField] = useState("order");
  const [sortDirection, changeSortDirection] = useState("asc");
  const { loading, error, data } = useQuery(listManufacturers, {
    variables: {
      filter: !fulltext ? null :{
        name: {
          contains: fulltext
        }
      },
    },
  });
  const [createManufacturer, createManufacturerResponse] = useMutation(createManufacturerMutation, {
    refetchQueries: [
      'ListManufacturers'
    ]
  });
  const [selectedManufacturers, dispatchSelectedManufacturers] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.listManufacturers.items.length){
          return {}
        }
        data.listManufacturers.items.forEach(manufacturer => newState[manufacturer.id] = manufacturer);
        return newState;
      case "toggle":
        if(newState[action.manufacturer.id]){
          delete newState[action.manufacturer.id];
        }
        else{
          newState[action.manufacturer.id] = action.manufacturer;
        }
        return newState;
      case "select":
        newState[action.manufacturer.id] = action.manufacturer;
        return newState;
      case "deselect":
        delete newState[action.manufacturer.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání výrobců</div>
    );

    const manufacturers = [...data.listManufacturers.items].sort((a, b) => {
      let sortResult = 0;
      if (a[sortField] > b[sortField]) sortResult = 1;
      if (a[sortField] < b[sortField]) sortResult = -1;
      if (sortDirection === "desc") sortResult *= -1;
      return sortResult;
    });

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedManufacturers).length === manufacturers.length} onChange={() => dispatchSelectedManufacturers({type: "selectAll"})} aria-label={"Vybrat objednávku"} />
            </th>
            <th>id</th>
            <th>Název {renderSortDirection("name")}</th>
            <th>Pořadí {renderSortDirection("order")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {manufacturers.map(manufacturer => (
            <ManufacturerRow key={"manufacturer_" + manufacturer.id} onSelectChange={() => dispatchSelectedManufacturers({type: "toggle", manufacturer})} selected={!!selectedManufacturers[manufacturer.id]} manufacturer={manufacturer}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className={"manufacturers"}>
      <h3>Výrobci</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
        <Button className={"mb-2 ml-auto"} variant={"success"} type={"button"} disabled={createManufacturerResponse.loading} onClick={() => createManufacturer({variables: {input: {
              name: "Nový výrobce",
              order: 0,
            }}})}>Vytvořit nového</Button>
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Manufacturers;
