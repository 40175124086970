import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";

import { addProduct } from "../app/cartReducer";
import { addNotification } from "../app/notificationsReducer";
import { GetProduct, getProductsInCategory } from "./ProductAPI";
import { EshopProduct } from "./EshopCategoryProducts";

import Preloader from "../util/Preloader";
import Breadcrumbs from "./Breadcrumbs";
import Icons from "../util/Icons";
import {CapitalizeString, ConvertImageUriFromS3ToCloudFront} from "../helpers";
import chunk from "lodash/chunk";

import "./product.scss";
import {Link, Redirect} from "react-router-dom";
import RentFormular from "./RentFormular";
import {Helmet} from "react-helmet-async";
import parse from "html-react-parser";
import domReplacementOptions from "../dynamicPage/domReplacementOptions";

const GetSimilar = gql`
  query GetProductsRelatedTo(
    $detailId: ID
    $relatedId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProductsRelatedTo(
      detailId: $detailId
      relatedId: $relatedId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        detailId
        relatedId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const GetProductBySlug = gql`
  query ProductsBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isHidden
        notForSale
        apiId
        linksTo
        categoryId
        manufacturerId
        manufacturerProductCode
        name
        description
        excerpt
        price
        salePrice
        isRental
        isInstallation
        securityDeposit
        dailyRentalFee
        featuredSale
        ean
        unit
        vat
        createdAt
        updatedAt
        slug
        stock
        minimalAmount
        restockDays
        manufacturer {
          id
          order
          name
          description
          image
          createdAt
          updatedAt
        }
        attachments {
          items {
            file
            id
            description
            type
            typeDesctiption
          }
        }
        attributes {
          name
          value
          unit
        }
        category{
          id
          slug
          name
        }
        subCategory{
          id
          slug
          name
        }
        subSubCategory{
          id
          slug
          name
        }
        subSubSubCategory{
          id
          slug
          name
        }
        subSubSubSubCategory{
          id
          slug
          name
        }
      }
      nextToken
    }
  }
`;

const SubmitFormMutation = gql`
  mutation SubmitForm($input: SubmitFormInput!) {
    submitForm(input: $input) {
      status
      errorData
    }
  }
`;

function Product(props) {
  const dispatch = useDispatch();
  const restockDays = useSelector(state => state.pageSettings.NaskladneniDo);
  // const [similarProducts, setSimilarProducts] = useState([]);
  const [amount, setAmount] = useState(1);
  const [stockWarning, setStockWarning] = useState(false);
  const [rentFormData, setRentFormData] = useState(null);
  const [rentFormError, setRentFormError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  // const [openVariants, setOpenVariants] = useState(false);

  useEffect(() => {
    setAmount(product?.minimalAmount || 1);
  }, [product]);

  const [getProductById] = useLazyQuery(GetProduct, {
    variables: {
      id: props.match.params.productId,
    },
    // onCompleted(data) {
    //   setProduct(data.getProduct);
    // },
    onError(error) {
      console.log("Id fetch error", error);
      setError(error);
      setLoading(false);
    },
    onCompleted(data){
      setLoading(false);
      if (data?.getProduct){
        setProduct(data?.getProduct);
      }
    },
  });

  useQuery(GetProductBySlug, {
    variables: {
      slug: props.match.params.productId,
    },
    onError(error) {
      console.log("Slug fetch error", error);
      setError(error);
      setLoading(false);
    },
    onCompleted(data){
      if(data?.productsBySlug?.items?.length){
        if (data.productsBySlug.items.length > 1){
          console.error("Product slug is not unique!");
        }
        setProduct(data.productsBySlug.items[0]);
        setLoading(false);
        return;
      }
      console.log("Trying to fetch by id");
      getProductById();
    },
  });

  const {loading: loadingSimilar, error: errorSimilar, data: dataSimilar} = useQuery(GetSimilar, {
    skip: !product?.id,
    variables: {
      detailId: product?.id,
    }
  });
  const [submitForm, submitFormResponse] = useMutation(SubmitFormMutation, {
    onCompleted: (data) => {
      console.log("Submit form response: ", data);
      if (data?.submitForm?.status === "success"){
        dispatch(addNotification({
          message: "Formulář byl úspěšně odeslán!",
          type: "success",
          code: "contactFormSuccess",
        }));
      }
      else{
        dispatch(addNotification({
          message: "Nastala chyba při odesílání formuláře!",
          type: "error",
          code: "contactFormError",
        }));
      }
    }
  });
  // useQuery(getProductsInCategory, {
  //   variables: {
  //     categoryId: data?.getProduct?.categoryId,
  //   },
  //   skip: !data,
  //   onCompleted(data) {
  //     setSimilarProducts(data.productsInCategory.items);
  //   },
  //   onError(error) {
  //     console.log(error);
  //   },
  // });

  if (loading) {
    return (
      <div className={"row"}>
        <div className="col mlr-auto mbt-20">
          <Preloader />
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className={"row"}>
        <div className="col mlr-auto mbt-20">Chyba při načítání produktu</div>
      </div>
    );
  }

  if (!product){
    return <article className={"productDetail product"}>
      <h1 className="productName mb-25 mb-15-mobile">404 - Tento produkt neexistuje</h1>
    </article>;
  }

  if (product.isHidden){
    return <Redirect to={"/"}/>;
  }

  return (
    <article className={"productDetail product"}>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={getMetaDescription()}/>
        <meta property="og:image" content={getMainImageUri()} />
      </Helmet>
      <Breadcrumbs product={product} className={"mb-20"} history={props.history} />
      {renderProductDetail()}
    </article>
  );

  function handleInputAmount(e) {
    let value = parseInt(e.target.value) || product.minimalAmount || 1;

    if (!value || value === 0) {
      value = product.minimalAmount || 1;
    }

    let modulo = value % (product.minimalAmount || 1);

    if (modulo){
      value += product.minimalAmount - modulo;
    }

    setAmount(value);
  }


  function handleInputNumber(add) {
    if (amount + add < 1) return;


    setStockWarning(amount + add > product.stock);

    setAmount(amount + add);
  }
  function renderProductDetail() {
    return (
      <>
        <h1 className="productName mb-25 mb-15-mobile">{product.name}</h1>
        <div className="row gutter-20px mb-25">
          <div className="col w-66p productImages">{renderMainImage()}</div>
          <div className="col w-33p productInfo">{renderSaleBlock()}</div>
        </div>
        <div className="subtitle">Parametry</div>
        {renderParametersTable()}
        {renderParametersTable(2, "hidden-desktop")}
        <div className="subtitle">Podrobný popis</div>
        <div className="mbt-25 description">
          {/*{getDescription()*/}
          {/*  .split("<br>")*/}
          {/*  .map((item, key) => {*/}
          {/*    return <p key={key}>{item}</p>;*/}
          {/*  })}*/}
          {getMainDescription()}
        </div>
        <div className="subtitle">Ke stažení</div>
        {renderForDownload()}
        {renderCategoryProducts()}
        <hr className={"mt-30 mb-40"} />
      </>
    );
  }

  function renderCategoryProducts() {
    if (loadingSimilar && !dataSimilar){
      return (
        <>
          <h3 className="subtitle">Související produkty</h3>
          <div className={"eshopCategoryProductsWrapper eshopProductGrid"}>
            <Preloader/>
          </div>
        </>
      );
    }
    if (errorSimilar){
      return (
        <>
          <h3 className="subtitle">Související produkty</h3>
          <div className={"eshopCategoryProductsWrapper eshopProductGrid"}>
            <div>Nastala chyba při načítání podobných produktů</div>
          </div>
        </>
      );
    }
    const similarProducts = dataSimilar?.getProductsRelatedTo?.items;
    if (!similarProducts || !similarProducts.length){
      return null;
    }
    return (
      <>
        <h3 className="subtitle">Související produkty</h3>
        <div className={"eshopCategoryProductsWrapper eshopProductGrid"}>
          {similarProducts?.slice(0, 12).map((product, i) => (
            <EshopProduct product={{id: product.relatedId}} key={product.relatedId} {...props} />
          ))}
        </div>
      </>
    );
  }
  function renderPrices(product) {
    if (product.isRental){
      return(
        <div className={"prices"}>
          <div className="amount">
            <span>kauce:</span>
            <div className="value">
              {product.securityDeposit.toFixed(2)}
              <span>Kč</span>
            </div>
          </div>
          <div className="amount">
            <span>cena za den:</span>
            <div className="value">
              {product.dailyRentalFee.toFixed(2)}
              <span>Kč</span>
            </div>
          </div>
        </div>
      );
    }
    // if (product.isInstallation){
    //   return(
    //     <div className={"prices"}>
    //       <div className="amount">
    //         <span>cena za den:</span>
    //         <div className="value">
    //           {product.dailyRentalFee.toFixed(2)}
    //           <span>Kč</span>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    if (product.linksTo){
      return null;
    }
    // normal products and installations
    return (
        <div className={"prices"}>
            {product.salePrice ? (
                <div className="amount-pre-sale">
                <span>původní cena: </span>
                <div className="value">
                    <span className="amount">{product.price.toFixed(2)} </span> <span>Kč</span>
                </div>
                </div>
            ) : null}
            <div className={"amount"}>
                <span>aktuální cena: </span>
                <div className="value">
                {(product.salePrice > 1000
                    ? (Math.round(product.salePrice * 100) / 100).toFixed(2)
                    : (Math.round(product.salePrice * 100) / 100).toFixed(2)) ||
                    product.price}
                <span>Kč</span>
                </div>
            </div>
            <div className={"amountDph"}>
                <span>aktuální cena s DPH: </span>
                <div className="value">
                {(product.salePrice * (1 + product.vat / 100) > 1000
                    ? (Math.round(product.salePrice * (1 + product.vat / 100) * 100) / 100).toFixed(2)
                    : (Math.round(product.salePrice * (1 + product.vat / 100) * 100) / 100).toFixed(
                        2
                    )) || product.price}
                <span>Kč</span>
                </div>
            </div>
        </div>
    )
  }
  function renderSaleButton(product) {
    return (
      <div
            onClick={() => {
              // Check if we go over stock
              let inCart = null;
              for (let i = 0; i < props.cart.length; i++) {
                if (props.cart[i].productId === product.id){
                  inCart = props.cart[i];
                }
              }
              if (inCart){
                const futureAmount = inCart.amount + amount;
                if ((futureAmount > product.stock) && product.stock ){
                  setStockWarning(true);
                  dispatch(
                    addNotification({
                      message: `V košíku máte více ${product.unit} produktu "${product.name}", než je okamžitě dostupných. Produkt dorazí do ${product.restockDays || restockDays} dnů`,
                      type: "warning",
                      code: "frontend.addCartProduct",
                    })
                  );
                }
              }

              dispatch(addProduct({ productId: product.id, amount: amount }));
              dispatch(
                addNotification({
                  message: `Přídán produkt ${product.name}`,
                  type: "info",
                  code: "frontend.addCartProduct",
                })
              );
            }}
            className={"button large blue eshop mt-10 " + (product.notForSale ? " hidden " : "")}
          >
            <img
              src={Icons.getIcon("cartWhite")}
              className={"icon cart"}
              alt={"icon kosik"}
            />
            <span>Koupit</span>
        </div>
    )
  }

  function handleRentFormSubmit(){
    console.log(rentFormData);
    setRentFormError(null);
    const requiredFields = ["rent_name", "rent_lastname", "rent_phone", "rent_email", "rent_calendar"];
    let isValid = true;
    requiredFields.forEach(requiredField => {
      if (!rentFormData[requiredField]){
        isValid = false;
      }
    })
    if (!isValid){
      setRentFormError("Prosím vyplňte všechna povinná pole");
      dispatch(addNotification({
        message: "Nastala chyba při odesílání formuláře!",
        type: "error",
        code: "contactFormError",
      }));
      return;
    }

    submitForm({
      variables: {
        input: {
          formId: "rental",
          formData: JSON.stringify({...rentFormData, rent_product: product.apiId}),
        }
      }
    });
  }

  function renderSaleBlock() {
    if (product.isRental || product.isInstallation) {
      return (
        <div className="saleBlock">
          <div className="saleBlockTop">
            <h2>
              <span>ID produktu: {product.manufacturerProductCode}</span>
            </h2>
          </div>
          <hr className={"transparentBlue mt-10 mb-15"} />

          <RentFormular product={product} onChange={(formData) => setRentFormData(formData)} handleSubmit={(event, formData) => {
            setRentFormData(formData);
            handleRentFormSubmit();
          }} />

          {renderPrices(product)}

          {rentFormError ? (
            <div className={"error text-red"}>
              <strong>{rentFormError}</strong>
            </div>
          ) : null}

          <div onClick={handleRentFormSubmit} className={"button large blue eshop mt-10 " + (product.notForSale ? " hidden " : "")}>
            Poptat
          </div>
        </div>
      )
    }

    return (
      <div className="saleBlock">
        <div className="saleBlockTop">
          <h2>
            <span>ID produktu: {product.manufacturerProductCode}</span>
          </h2>
          {/*<img*/}
          {/*  src={Icons.getIcon("pin")}*/}
          {/*  className={"icon pin"}*/}
          {/*  alt={"icon pin"}*/}
          {/*/>*/}
        </div>
        <hr className={"transparentBlue mt-10 mb-15"} />

        {renderPrices(product)}

        <hr className={"transparentBlue mbt-20"} />
        <div className="stockInfo row mb-20">
          <div className={"col w-50p"}>
            <span className="stockInfoName">Skladem:</span>
            <span className="stockInfoAmount">
              {product.stock || ("Dodání do " + (product.restockDays || restockDays) + " dnů")} {product.stock ? product.unit : ""}
            </span>
          </div>

          <div className={"col w-50p"}>
            <div className={"input_number mr-10 " + ((product.notForSale || product.linksTo) ? " hidden " : "")}>
              <div>
                <input
                  type={"number"}
                  value={amount}
                  onChange={(e) => handleInputAmount(e)}
                  onBlur={(e) => handleInputAmount(e)}
                  min={product.minimalAmount || 1}
                  step={product.minimalAmount || 1}
                />
                <span>{product.unit}</span>
              </div>
              <div className={"spin_buttons"}>
                <span
                  className="spin_top"
                  onClick={() => handleInputNumber(product.minimalAmount || 1)}
                ></span>
                <span
                  className="spin_bottom"
                  onClick={() => handleInputNumber(-1 * (product.minimalAmount || 1))}
                ></span>
              </div>
            </div>
          </div>
        </div>
        {(stockWarning && product.stock) ? (
          <div className={"stockInfo row mb-20"}>
            <div className={"col w-100p"}>
              <span className={"stockInfoAmount warning"}>
                {`Nad ${product.stock}${product.unit} produkt dostupný do ${product.restockDays || restockDays} dnů`}
              </span>
            </div>
          </div>
        ) : null}
        {/*<div className="variantSelect row mt-10 mb-10">*/}
        {/*  /!*TODO: Variant dropdown*!/*/}

        {/*  <div className="input_dropdown">*/}
        {/*    <div*/}
        {/*      className="dropdown_choosen"*/}
        {/*      onClick={() => {*/}
        {/*        setOpenVariants(!openVariants);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <span>Zvolte variantu </span>*/}
        {/*    </div>*/}
        {/*    <div className={(openVariants ? "open " : "") + "variants"}>*/}
        {/*      <div className="variant">Koncovka 6 cm</div>*/}
        {/*      <div className="variant">Koncovka 8 cm</div>*/}
        {/*      <div className="variant">Koncovka 10 cm</div>*/}
        {/*      <div className="variant">Koncovka 12 cm</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {product.linksTo ? (
          <Link to={product.linksTo} className={"button large blue eshop mt-10 full-width"}>
            <span>Více</span>
          </Link>
        ) : (
          renderSaleButton(product)
        )}
      </div>
    );
  }
  function renderForDownload() {
    if (product.attachments.items.length > 0) {
      return (
        <div className="forDownload row mb-25">
          {product.attachments.items
            .filter((item) => item.type !== "OV")
            .map((item, index) => {
              return (
                item.description && (
                  <div className="col" key={index}>
                    <button
                      className="button medium blue download"
                      onClick={() => window.open(ConvertImageUriFromS3ToCloudFront(item.file))}
                    >
                      {CapitalizeString(item.description.toLowerCase())}
                    </button>
                  </div>
                )
              );
            })}
        </div>
      );
    }
  }

  function renderParametersTable(chunkSize = 4, className = "hidden-mobile") {
    const chunked = chunk(product.attributes, chunkSize);
    let toReturn = [];
    chunked.forEach((attributesChunk, index) => {
      let chunkShort = chunkSize - attributesChunk.length;
      for (let i = 0; i < chunkShort; i++) {
        attributesChunk.push({ name: null, value: null, unit: null });
      }
      toReturn.push(
        <div className="table-content-item">
          {attributesChunk.map((attribute) => (
            <div className="header">{attribute.name}</div>
          ))}
        </div>
      );
      toReturn.push(
        <div
          className={
            "table-content-item " +
            (index + 1 === chunked.length ? "last-item" : "")
          }
        >
          {attributesChunk.map((attribute) => (
            <div className="value">
              {attribute.value} {attribute.unit}
            </div>
          ))}
        </div>
      );
    });
    return (
      <div className={"table-responsive product-parameters mb-25 " + className}>
        {toReturn}
      </div>
    );
  }
  function getMainImageUri(){
    let toReturn = "/staticImages/placeholders/product.png";
    const acceptedMainImageTypes = ["OV"];
    for (let i = 0; i < product.attachments.items.length; i++) {
      let attachment = product.attachments.items[i];
      if (acceptedMainImageTypes.indexOf(attachment.type) !== -1) {
        toReturn = ConvertImageUriFromS3ToCloudFront(attachment.file);
      }
    }
    return toReturn;
  }
  function renderMainImage() {
    let toReturn = (
      <img src={"/staticImages/placeholders/product.png"} alt={product.name} />
    );
    let imagePath = "#";
    const acceptedMainImageTypes = ["OV"];
    for (let i = 0; i < product.attachments.items.length; i++) {
      let attachment = product.attachments.items[i];

      if (acceptedMainImageTypes.indexOf(attachment.type) !== -1) {
        imagePath = ConvertImageUriFromS3ToCloudFront(attachment.file);
        toReturn = <img src={imagePath} alt={attachment.description} />;
      }
    }

    return (
      <div className={"mainImage"}>
        <a
          href={imagePath}
          target={"_blank"}
          rel="noopener noreferrer"
          className="button medium light-gray zoom-in"
        >
          {" "}
        </a>
        {toReturn}
      </div>
    );
  }

  function getMetaDescription() {
    if (product.excerpt) {
      return product.excerpt;
    }
    if (product.description) {
      return product.description;
    }
    return "Produkt zatím nemá popis.";
  }

  function getMainDescription() {
    if (product.description) {
      return parse(product.description, domReplacementOptions);
    }
    if (product.excerpt) {
      return <p>{product.excerpt}</p>;
    }
    return "Produkt zatím nemá popis.";
  }
}

export default connect((state) => ({
  cart: state.cart,
}))(Product);
