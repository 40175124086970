import React, {useEffect, useReducer, useState} from "react";
import {Table, Form} from "react-bootstrap";
import {gql, useMutation, useQuery, useLazyQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import {Link} from "react-router-dom"
import Pagination from "../util/Pagination";
import debounce from "lodash/debounce";
import {orderStatuses} from "../util/globalMaps";
import Storage from '@aws-amplify/storage';

const searchOrdersTotal = gql`
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
    ) {
      total
    }
  }
`;

const searchOrders = gql`
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userName
        shippingMethodId
        shipmentTrackingId
        paymentMethodId
        paymentTrackingId
        orderStatus
        orderNumber  
        note
        createdAt
        updatedAt
        fields{
          productId
          name
          amount
          price
          priceIncludingTax
          unit
        }
        invoiceAddress {
          companyName
          companyId
          companyTaxId
          name
          street
          city
          zip
        }
        shippingAddress {
          companyName
          name
          street
          city
          zip
          telephone
          email
        }
      }
      nextToken
      total
    }
  }
`;

const getFixedRequisites = gql`
  query GetFixedRequisites{
    listPaymentMethods{
      items {
        id
        name
        description
        fee
        order
        createdAt
        updatedAt
      }
    }
    listShippingMethods {
      items {
        id
        name
        description
        fee
        order
        createdAt
        updatedAt
      }
    }
  }
`;

const updateOrderMutation = gql`
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      userName
      shippingMethodId
      shipmentTrackingId
      paymentMethodId
      paymentTrackingId
      orderStatus
      orderNumber
      note
      createdAt
      updatedAt
      fields{
        productId
        name
        amount
        price
        priceIncludingTax
        unit
      }
      invoiceAddress {
        companyName
        companyId
        companyTaxId
        name
        street
        city
        zip
      }
      shippingAddress {
        companyName
        name
        street
        city
        zip
        telephone
        email
      }
    }
  }
`;

const getOrderInvoiceQuery = gql`
    query GetOrderInvoice($orderId: ID!) {
        getOrderInvoice(orderId: $orderId) {
            invoice {
                bucket
                key
            }       
        }
    }
`;

const getOrderDeliveryNoteQuery = gql`
    query GetOrderDeliveryNote($orderId: ID!) {
        getOrderDeliveryNote(orderId: $orderId) {
            deliveryNote {
                bucket
                key
            }
        }
    }
`;

const deleteOrderPdfsMutation = gql`
  mutation DeleteOrderPdfs(
    $input: AdminDeleteOrderPdfsInput!
  ) {
    adminDeleteOrderPdfs(input: $input)
  }
`;

function OrderRow({order, paymentMethods, shippingMethods, selected, onSelectChange}){
  const [updateOrder, updateOrderResponse] = useMutation(updateOrderMutation);
  const [deleteOrderPdfs, deleteOrderPdfsResponse] = useMutation(deleteOrderPdfsMutation, {
    onCompleted: (data) => {
      window.alert("Smazáno souborů: " + data.adminDeleteOrderPdfs);
    }
  });
  const [getOrderInvoice, {data: orderInvoiceS3Info, loading: orderInvoiceS3InfoLoading}] = useLazyQuery(getOrderInvoiceQuery, {
    onCompleted: () => {
      openOrderInvoice();
    }
  });
  const [getOrderDeliveryNote, {data: orderDeliveryNoteS3Info, loading: orderDeliveryNoteS3InfoLoading}] = useLazyQuery(getOrderDeliveryNoteQuery, {
    onCompleted: () => {
      openOrderDeliveryNote();
    }
  });
  let price = 0;
  order.fields.forEach(field => price += field.amount * field.priceIncludingTax);
  const openOrderInvoice = async () => {
    if (orderInvoiceS3Info){
      const getOrderInvoice = await Storage.get(orderInvoiceS3Info.getOrderInvoice.invoice.key.substr(7), {
        level: "public",
        download: false,
      });
      window.open(getOrderInvoice, '_blank');
      return;
    }
    getOrderInvoice({variables:{orderId: order.id}});
  };
  const openOrderDeliveryNote = async () => {
    if (orderDeliveryNoteS3Info){
      const getOrderDeliveryNote = await Storage.get(orderDeliveryNoteS3Info.getOrderDeliveryNote.deliveryNote.key.substr(7), {
        level: "public",
        download: false,
      });
      window.open(getOrderDeliveryNote, '_blank');
      return;
    }
    getOrderDeliveryNote({variables:{orderId: order.id}});
  };

  return (
    <tr>
      <td>
        <Form.Check checked={selected} onChange={() => onSelectChange()} aria-label={"Vybrat objednávku"} />
      </td>
      <td style={{
        maxWidth: 150,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>{order.id}</td>
      <td>{order.orderNumber}</td>
      <td>{order.createdAt}</td>
      <td>{orderStatuses[order.orderStatus]}</td>
      <td>{order.userName}</td>
      <td>{(paymentMethods[order.paymentMethodId] && paymentMethods[order.paymentMethodId].name) || "Neznámá metoda"}</td>
      <td>{(shippingMethods[order.shippingMethodId] && shippingMethods[order.shippingMethodId].name) || "Neznámá metoda"}</td>
      <td className={"text-right"}>{price.toFixed(2)}</td>
      <td>
        {updateOrderResponse.loading ? <Preloader/> : (
          <div className="btn-group btn-group-sm" role="group" aria-label="Operace s objednávkou">
            <Link to={"/admin/orders/" + order.id} type="button" className="btn btn-primary">Upravit</Link>
            <button onClick={() => updateOrder({variables: {input: {id: order.id, orderStatus: "cancelled"}}})} type="button" className="btn btn-danger">Zrušit</button>
            <button onClick={() => updateOrder({variables: {input: {id: order.id, orderStatus: "complete"}}})} type="button" className="btn btn-success">Vyřízená</button>
            <button disabled={orderInvoiceS3InfoLoading} className="btn btn-info" type={"button"} title={"Faktura"} onClick={openOrderInvoice}><i className="bi bi-receipt"/></button>
            <button disabled={orderDeliveryNoteS3InfoLoading} className="btn btn-info" type={"button"} title={"Dodací list"} onClick={openOrderDeliveryNote}><i className="bi bi-minecart"/></button>
            <button disabled={deleteOrderPdfsResponse.loading} className="btn btn-danger" type={"button"} title={"Smazat generované PDF"} onClick={() => deleteOrderPdfs({variables: {input: {id: order.id, orderNumber: order.orderNumber}}})}><i className="bi bi-trash"/></button>
          </div>
        )}
      </td>
    </tr>
  );
}

function Orders(props){
  const [fulltext, changeFulltext] = useState("");
  const [pageLimit, changePageLimit] = useState(20);
  const [pageNumber, changePageNumber] = useState(0);
  const [sortField, changeSortField] = useState("createdAt");
  const [sortDirection, changeSortDirection] = useState("desc");

  const { loading: loadingFixed, error: errorFixed, data: dataFixed } = useQuery(getFixedRequisites);

  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(searchOrders, {
    variables: {
      limit: pageLimit,
      from: pageLimit * pageNumber,
      sort: {field: sortField, direction: sortDirection},
      filter: !fulltext ? null :{
        or: [
          {
            id: {
              matchPhrasePrefix: fulltext
            }
          },
          {
            userName: {
              matchPhrasePrefix: fulltext
            }
          },
        ]
      },
    },
    nextFetchPolicy: "cache-first",
  });
  useEffect(() => {
    startPolling(10000);
    return function cleanup() {
      stopPolling();
    }
  });
  const {loading: loadingTotal, error: errorTotal, data: dataTotal} = useQuery(searchOrdersTotal, {
    variables: {
      sort: {field: sortField, direction: sortDirection},
      filter: !fulltext ? null :{
        or: [
          {
            id: {
              matchPhrasePrefix: fulltext
            }
          },
          {
            userName: {
              matchPhrasePrefix: fulltext
            }
          },
        ]
      },
    }
  });

  const [selectedOrders, dispatchSelectedOrders] = useReducer((state, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case "clear":
        return {};
      case "selectAll":
        if(Object.keys(state).length === data.searchOrders.items.length){
          return {}
        }
        data.searchOrders.items.forEach(order => newState[order.id] = order);
        return newState;
      case "toggle":
        if(newState[action.order.id]){
          delete newState[action.order.id];
        }
        else{
          newState[action.order.id] = action.order;
        }
        return newState;
      case "select":
        newState[action.order.id] = action.order;
        return newState;
      case "deselect":
        delete newState[action.order.id];
        return newState;
      default:
        throw new Error("Invalid action");
    }
  }, {});

  const debouncedChangeFulltext = debounce(value => changeFulltext(value), 500);

  function renderTable(){
    function renderSortDirection(fieldName){
      function clickHandler() {
        changeSortField(fieldName);
        if (fieldName === sortField)
          changeSortDirection(sortDirection === "desc" ? "asc" : "desc");
        refetch();
      }
      let className = "cursor-pointer bi";
      if (sortDirection === "desc") className += " bi-caret-down-fill";
      else className += " bi-caret-up-fill";
      if (fieldName !== sortField) className += " text-muted";
      return <i onClick={clickHandler} className={className}/>;
    }

    if ((loading || loadingTotal || loadingFixed) && (!data || !dataFixed || !dataTotal)) return(
      <Preloader/>
    );

    if (error || errorFixed || errorTotal || data.errors) return(
      <div className="danger">Nastala chyba při načítání objednávek</div>
    );

    const {searchOrders: orders} = data;

    const {listPaymentMethods, listShippingMethods} = dataFixed;
    let paymentMethods = {};
    listPaymentMethods.items.forEach(paymentMethod => paymentMethods[paymentMethod.id] = paymentMethod);
    let shippingMethods = {};
    listShippingMethods.items.forEach(shippingMethod => shippingMethods[shippingMethod.id] = shippingMethod);

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>
              <Form.Check checked={Object.keys(selectedOrders).length === orders.items.length} onChange={() => dispatchSelectedOrders({type: "selectAll"})} aria-label={"Vybrat objednávku"} />
            </th>
            <th>id</th>
            <th>Číslo {renderSortDirection("orderNumber")}</th>
            <th>Datum {renderSortDirection("createdAt")}</th>
            <th>Stav {renderSortDirection("orderStatus")}</th>
            <th>Uživatel {renderSortDirection("userName")}</th>
            <th>Platební metoda {renderSortDirection("paymentMethodId")}</th>
            <th>Dopravní metoda {renderSortDirection("shippingMethodId")}</th>
            <th>Cena</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {orders.items.map(order => (
            <OrderRow key={"order_" + order.id} onSelectChange={() => dispatchSelectedOrders({type: "toggle", order: order})} selected={!!selectedOrders[order.id]} order={order} paymentMethods={paymentMethods} shippingMethods={shippingMethods}/>
          ))}
          </tbody>
        </Table>
        <Pagination changePerPage={newPageLimit => changePageLimit(newPageLimit)} changePage={newPageNumber => {changePageNumber(newPageNumber); dispatchSelectedOrders({type: "clear"})}} total={dataTotal.searchOrders.total} limit={pageLimit} currentPage={pageNumber}/>
      </>
    );
  }

  return (
    <div className={"orders"}>
      <h3>Objednávky</h3>
      <hr/>
      <Form inline>
        <Form.Label htmlFor={"fulltext"} srOnly>Fulltextové vyhledávání</Form.Label>
        <Form.Control
          className="mb-2"
          id="fulltext"
          placeholder="Fulltextové vyhledávání"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
      </Form>
      <hr/>
      {renderTable()}
    </div>
  );
}

export default Orders;
