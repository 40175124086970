import { createSlice } from "@reduxjs/toolkit";

const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState: {
    eshop: {
      productDisplay: "col",
    },
    "cookiesAccepted": false,
    "cart.shippingMethod": null,
    "cart.paymentMethod": null,
    agreedToTerms: false,
    "eshop.filter": {
      sort: { field: "createdAt", direction: "asc" },
      filter: {
        featuredSale: { eq: false },
        salePrice: { gte: 0, lte: 100000 },
        categoryId: null,
        subCategoryId: null,
        subSubCategoryId: null,
        subSubSubCategoryId: null,
        subSubSubSubCategoryId: null,
        or: [],
        aggregate: true,
      },
      view: { isGridView: true },
      uniqManufacturer: [],
      isHeaderSearch:false
    },
    "eshop.filterResultAmount": null,
  },
  reducers: {
    setEshopDisplay: (state, action) => {
      state.eshop.productDisplay = action.payload;
      return state;
    },
    setPreference: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
      return state;
    },
    setFilterAmount: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
      return state;
    },
    emptyPreferences: (state, action) => {
      const { name } = action.payload;
      state[name] = {
        sort: { field: "createdAt", direction: "asc" },
        filter: {
          featuredSale: { eq: false },
          salePrice: { gte: 0, lte: 100000 },
          categoryId: null,
          subCategoryId: null,
          subSubCategoryId: null,
          subSubSubCategoryId: null,
          or: [],
          aggregate: true,
        },
        view: { isGridView: true },
        uniqManufacturer: [],
        isHeaderSearch:false
      };
    },
    emptyFilterAmount: (state) => (state["eshop.filterResultAmount"] = null),
  },
});

export const {
  setPreference,
  setFilterAmount,
  emptyPreferences,
  emptyFilterAmount,
  setEshopDisplay,
} = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
