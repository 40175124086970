import React, {Component} from "react";
import { connect } from "react-redux";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import Orders from "./Orders";
import Account from "./Account";
import Settings from "./Settings";
import "./user.scss";
import Preloader from "../util/Preloader";
import { Helmet } from "react-helmet-async";

class User extends Component{
  render(){
    if (this.props.cognito.currentUserInfo === null){
      return <div className={"profile container mbt-25"}><Preloader/></div>;
    }

    if (this.props.cognito.currentUserInfo === false){
      return <Redirect to={"/login"}/>
    }

    let { path } = this.props.match;
    let { pathname } = this.props.location;

    return(
      <div className={"profile container mbt-25"}>
        <Helmet>
          <title>Uživatelský profil</title>
        </Helmet>
        <div className={"row gutter-20px m-0"}>
          <div className="col mbt-25 w-100p">
            <div className="pageHeader">
              <h1>Uživatelský profil</h1>
            </div>

            <nav className={"row navigation"}>
              <Link to={`${path}/account`} className={"button medium " + (pathname === (`${path}/account`) ? "blue " : "gray ")}>
                <span>Osobní údaje</span>
              </Link>
              <Link to={`${path}/orders`} className={"button medium " + (pathname === (`${path}/orders`) ? "blue " : "gray ")}>
                <span>Objednávky</span>
              </Link>
              <Link to={`${path}/settings`} className={"button medium " + (pathname === (`${path}/settings`) ? "blue " : "gray ")}>
                <span>Nastavení</span>
              </Link>
            </nav>
            <Switch>
              <Route path={`${path}/account`}>
                <Account/>
              </Route>
              <Route path={`${path}/orders`}>
                <Orders userName={this.props.cognito.currentUserInfo && this.props.cognito.currentUserInfo.username}/>
              </Route>
              <Route path={`${path}/settings`}>
                <Settings/>
              </Route>
              <Route path={''}>
                <Redirect to={"/user/account"}/>
              </Route>
            </Switch>

            <div className="row gdpr">
              <div className="col w-100p">
                <h3>GDPR</h3>
                <p>
                  Tento web používá k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory cookie. Používáním tohoto webu s tím souhlasíte.
                  <br/>
                  <a className={"blueLink"} target={"_blank"} href={"/staticDocs/cookies_TOPASPLUS_final_9_2021.docx.pdf"}> (Více informací zde)</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      
        

      </div>
    );
  }
}

export default connect(state => ({cognito: state.cognito}))(User);
