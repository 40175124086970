import React from 'react';
import "./newsCategory.scss";
import { gql, useQuery } from '@apollo/client';
import Preloader from "../util/Preloader";
import PostExcerpt from "./PostExcerpt";

const GetCategory = gql`
  query NewsCategoriesBySlug(
    $slug: String
    $limit: Int
  ) {
    newsCategoriesBySlug(
      slug: $slug
      limit: $limit
    ) {
      items {
        id
        slug
        name
        order
        description
        createdAt
        updatedAt
        posts {
          items {
            id
          }
        }
      }
    }
  }
`;

function NewsCategory(props){
  const { loading, error, data } = useQuery(GetCategory, {
    variables:{
      slug: props.match.params.categorySlug,
      limit: 1,
    }
  });

  if(loading && !data){
    return (
      <section className={"newsCategory"}>
        <Preloader/>
      </section>
    )
  }

  if(error || !data.newsCategoriesBySlug || !data.newsCategoriesBySlug.items || !data.newsCategoriesBySlug.items.length){
    return (
      <section className={"newsCategory"}>
        <strong className={"error"}>Nastala chyba při načítání kategorie novinek</strong>
      </section>
    )
  }

  //TODO: !data.newsCategoriesBySlug.items.length should throw 404 not just loading error

  const category = data.newsCategoriesBySlug.items[0];
  const postIds = category.posts.items.map(item => item.id);
  return (
    <section className={"newsCategory"}>
      <div className="pageHeader">
        <h1>{category.name}</h1>
      </div>
      <div>{category.description}</div>
      {postIds.map(newsPostId => <PostExcerpt key={"excerpt_" + newsPostId} newsPostId={newsPostId}/>)}
    </section>
  )
}

export default NewsCategory;
