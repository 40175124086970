import React, { useState } from "react";
import {useMutation, gql} from "@apollo/client";
import {useDispatch} from "react-redux";
import { addNotification } from "../app/notificationsReducer";
import Icons from "../util/Icons";

import "./contactForm.scss";


const contactFields = [
    {
      label: "Jméno",
      name: "process_name",
      required: true,
      type: "text",
    },
    {
      label: "Příjmení",
      name: "process_lastname",
      required: true,
      type: "text",
    },
    {
      label: "Telefon",
      name: "process_phone",
      required: true,
      type: "tel",
    },
    {
      label: "e-mail",
      name: "process_email",
      required: true,
      type: "email",
    },
    {
      label: "Zpráva",
      name: "process_textarea",
      required: false,
      type: "textarea",
    }
]
const SubmitFormMutation = gql`
    mutation SubmitForm($input: SubmitFormInput!) {
        submitForm(input: $input) {
            status
            errorData
        }
    }
`;

function ContactFormField({field, setFormData}) {
    // console.log(field);
  
    const handleOnCHangeEvent = (event) => {
      let value = event.target.value
      setFormData(prevState => ({
        ...prevState,
        [field.name]: value
      }));
    }
  
    switch(field.type) {
      case "textarea": {
        return (
          <textarea
            name={field.name}
            required={field.required}
            onChange={(event) => handleOnCHangeEvent(event)}
          ></textarea>
        )
      }
      default: {
        return (
          <input 
            type={field.type} 
            name={field.name}
            required={field.required}
            onChange={(event) => handleOnCHangeEvent(event)}
          ></input>)
      }
    }
  
}

function ContactFormFields({handleSubmit}) {
  
    const [formData, setFormData] = useState()
    return (
      <form onSubmit={event => handleSubmit(event, formData)}>
        {contactFields.map((item, index) => {
          // console.log(item);
          return (
            <label className={item.name}>
              <span>
                {item.label} 
                {(item.required) ? "*" : null}
              </span>
              <ContactFormField setFormData={setFormData} field={item}/>
            </label>)
        })}
        <div className="buttonWrapper">
          <button type="submit">
            Odeslat
            <img className={"icon"} src="staticImages/arrowRightDarkerBlue.svg" alt="odeslat" />
          </button>
        </div>
        
      </form>
    )
}

function TitleWithIcon({title, icon}){
    return (
        <h3>
          <img className={"icon"} src={Icons.getIcon(icon)} alt={icon} />
          <span>{title}</span>
        </h3>
      )
  }

const ContactForm = ({title}) => {
    // console.log(title);
    const dispatch = useDispatch();
    const [submitForm, submitFormResponse] = useMutation(SubmitFormMutation, {
      onCompleted: (data) => {
        console.log("Submit form response: ", data);
        if (data?.submitForm?.status === "success"){
          dispatch(addNotification({
            message: "Formulář byl úspěšně odeslán!",
            type: "success",
            code: "contactFormSuccess",
          }));
        }
        else{
          dispatch(addNotification({
            message: "Nastala chyba při odesílání formuláře!",
            type: "error",
            code: "contactFormError",
          }));
        }
      }
    });
    const handleSubmit = (event, formData) => {
        // Ignore if we are awaiting response
        event.preventDefault();
    
        if (submitFormResponse.loading) return;
    
        console.log(formData);
        submitForm({
          variables: {
            input: {
              formId: "contacts",
              formData: JSON.stringify(formData),
            }
          }
        });
    };
    return (
        <div className="formWrapper">
            <div className="panel">
                {(title) ? 
                    <div className="panelHeader">
                        <TitleWithIcon title={title} icon="pencil"/>
                    </div>
                : null}
                
                
                <div className="panelBody">
                    <ContactFormFields handleSubmit={handleSubmit}/>
                </div>
            </div>
        </div>
    )           
}

export default ContactForm;