import React, {useEffect, useState} from "react";
import {Table, Form, Button, Row, Col, ButtonGroup} from "react-bootstrap";
import {gql, useQuery} from '@apollo/client';
import Preloader from "../../util/Preloader";
import debounce from "lodash/debounce";
import {Link} from "react-router-dom";
import {userStatuses} from "../util/globalMaps";

const adminListUsers = gql`
  query AdminListUsers(
    $Limit: Int
    $PaginationToken: String
    $Filter: CognitoListUsersFilter
  ) {
    adminListUsers(
      Limit: $Limit
      PaginationToken: $PaginationToken
      Filter: $Filter
    ) {
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes{
          Name
          Value
        }
      }
      PaginationToken
    }
  }
`;

function Users(props){
  const [searchText, changeSearchText] = useState("");
  const [searchType, changeSearchType] = useState("");
  const [pageLimit, changePageLimit] = useState(20);
  const [paginationToken, changePaginationToken] = useState("");

  const { loading, error, data, startPolling, stopPolling } = useQuery(adminListUsers, {
    variables: {
      Limit: pageLimit,
      PaginationToken: paginationToken,
      Filter: (searchType && searchText) ? {field: searchType, value: searchText} : null,
    },
  });
  useEffect(() => {
    startPolling(30000);
    return function cleanup() {
      stopPolling();
    }
  });


  const debouncedChangeFulltext = debounce(value => changeSearchText(value), 500);

  function renderTable(){
    if ((loading) && (!data)) return(
      <Preloader/>
    );

    if (error || data.errors) return(
      <div className="danger">Nastala chyba při načítání uživatelů</div>
    );

    const {adminListUsers} = data;

    function UserRow({user}){
      function getAttribute(name){
        for (let i = 0; i < user.Attributes.length; i++){
          if (user.Attributes[i].Name === name) return user.Attributes[i].Value;
        }
        return "Nejsou data";
      }
      return (
        <tr>
          <td>{user.Username}</td>
          <td>{getAttribute("name")}</td>
          <td>{getAttribute("phone_number")}</td>
          <td>{getAttribute("email")}</td>
          <td>{user.UserCreateDate}</td>
          <td>{user.Enabled ? userStatuses[user.UserStatus] : "Zabanovaný"}</td>
          <td>
            <div className="btn-group btn-group-sm" role="group" aria-label="Operace s objednávkou">
              <Link to={"/admin/users/" + user.Username} type="button" className="btn btn-primary">Upravit</Link>
            </div>
          </td>
        </tr>
      );
    }

    return (
      <>
        <Table striped bordered hover responsive={"md"}>
          <thead>
          <tr>
            <th>Uživatel</th>
            <th>Jméno</th>
            <th>Telefon</th>
            <th>Email</th>
            <th>Datum registrace</th>
            <th>Stav</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {adminListUsers.Users.map(user => (
            <UserRow key={"order_" + user.Username} user={user}/>
          ))}
          </tbody>
        </Table>
      </>
    );
  }

  return(
    <div className={"users"}>
      <h3>Uživatelé</h3>
      <hr/>
      <Form inline onSubmit={event => event.preventDefault()}>
        <Form.Label htmlFor={"searchType"} srOnly>Hledaná položka</Form.Label>
        <Form.Control
          as={"select"}
          className={"mr-2 " + (searchType ? "" : "text-secondary")}
          id="searchType"
          value={searchType}
          onChange={({target}) => changeSearchType(target.value)}
        >
          <option className={"text-secondary"} value="" disabled>Hledaná položka</option>
          <option className={"text-dark"} value="username">Uživatel</option>
          <option className={"text-dark"} value="email">Email</option>
          <option className={"text-dark"} value="phone_number">Telefon</option>
          <option className={"text-dark"} value="name">Jméno</option>
        </Form.Control>
        <Form.Label htmlFor={"fulltext"} srOnly>Vyhledat</Form.Label>
        <Form.Control
          id="fulltext"
          placeholder="Vyhledat"
          onChange={({target}) => debouncedChangeFulltext(target.value)}
        />
      </Form>
      <hr/>
      {renderTable()}
      <hr/>
      <Row>
        <Col>
          <ButtonGroup className={"ml-auto"}>
            <Button variant={"secondary"} disabled={!paginationToken} onClick={() => changePaginationToken("")}>Od začátku</Button>
            <Button disabled={!(data && data.adminListUsers && data.adminListUsers.PaginationToken)} onClick={() => changePaginationToken(data && data.adminListUsers && data.adminListUsers.PaginationToken)}>Další</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}

export default Users;
