import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorker from './serviceWorker';
import { ApolloClient, ApolloProvider, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import {createAuthLink} from 'aws-appsync-auth-link';
import DebounceLink from 'apollo-link-debounce';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsConfig from "./aws-exports";
import LoadingSplash from "./util/LoadingSplash";
import {HelmetProvider} from "react-helmet-async";
import * as Validator from "validatorjs";
import {validate as uuidValidate} from "uuid";

Amplify.configure(awsConfig);
//TODO: Think about using: apollo-link-batch-http
// import { BatchHttpLink } from "apollo-link-batch-http";
// const batchLink = new BatchHttpLink({ uri: "/graphql" });

Validator.register("uuid", (value, requirement, attribute) => {
  return uuidValidate(value);
}, 'The :attribute is not a valid uuid.');
Validator.register('telephone', function(value, requirement, attribute) { // requirement parameter defaults to null
  return !!value.match(/^\+[0-9]{3} ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/);
}, 'The :attribute phone number is not in the format +xxx xxx xxx xxx.');

/*
 * currentAuthenticatedUser is way too big
 * To get username and attributes it is enough to use currentUserInfo
 * To get user pool info we can then use just the currentUserPoolUser (ALSO BIG THO, so look below!!!)
 * To get tokens it is enough to use currentSession
 * To get identity IDs currentUserCredentials are enough
*/

const url = awsConfig.aws_appsync_graphqlEndpoint;
const region = awsConfig.aws_appsync_region;
const iamAuth = {
  type: awsConfig.aws_appsync_authenticationType,
  apiKey: awsConfig.aws_appsync_apiKey,
  credentials: () => Auth.currentCredentials(),
};
const userPoolAuth = {
  type: "AMAZON_COGNITO_USER_POOLS",
  apiKey: awsConfig.aws_appsync_apiKey,
  jwtToken: async () => {
    let currentSession = await Auth.currentSession();
    return currentSession && currentSession.accessToken && currentSession.accessToken.jwtToken;
  },
};


const authLink = ApolloLink.split(
  () => {
    return !store.getState().cognito.currentSession;
  },
  createAuthLink({ url, region, auth: iamAuth }),
  createAuthLink({ url, region, auth: userPoolAuth })
);
const httpLink = createHttpLink({ uri: url });
const DEFAULT_DEBOUNCE_TIMEOUT = 100;
const link = ApolloLink.from([
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  authLink,
  createSubscriptionHandshakeLink(url, httpLink)
]);
const cache = new InMemoryCache();
const client = new ApolloClient({
  link: link,
  cache: cache,
  name: 'eshop-frontend-client',
  version: '0.1',
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
}).then(() => {
  const persistor = persistStore(store);
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<LoadingSplash/>} persistor={persistor}>
          <ApolloProvider client={client}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
