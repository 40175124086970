import React from 'react';
import './CookiesBar.scss';
import {useDispatch, useSelector} from "react-redux";
import {setPreference} from "../app/userPreferencesReducer";

function CookiesBar(){
  const dispatch = useDispatch();
  const cookiesAccepted = useSelector(
    (state) => state.userPreferences.cookiesAccepted
  );

  function hideCookiesBar(){
    dispatch(setPreference({
      name: "cookiesAccepted",
      value: true,
    }));
  }

  if (cookiesAccepted){
    return null;
  }

  return(
    <div className={"cookiesBar"}>
      <div className="cookiesText">
        Tento web používá k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory cookie. Používáním tohoto webu s tím souhlasíte.
        <a target={"_blank"} href={"/staticDocs/cookies_TOPASPLUS_final_9_2021.docx.pdf"}>(Více informací zde)</a>
      </div>
      <div onClick={hideCookiesBar} className="button small yellow cookiesButton">
        Rozumím
      </div>
    </div>
  );
}

export default CookiesBar;
