import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {useRouteMatch} from "react-router-dom";
import Preloader from "../../util/Preloader";
import {Col, Form, Row} from "react-bootstrap";
import ConfirmButton from "../util/ConfirmButton";

const getProductQuery = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      apiId
      name
      attachments {
        items {
          id
          file
          description
          type
          typeDesctiption
        }
      }
    }
  }
`;

const updateAttachmentMutation = gql`
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteAttachmentMutation = gql`
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
    }
  }
`;

const attachmentDescriptions = {
  OV: 'Hlavní obrázek',
  NP: 'Návod k použití',
  NP1: 'Návod k použití',
  ENERG: 'Energetický štítek',
  TN: 'Technický nákres',
  TL: 'Technický list',
  PS: 'PROHLÁŠENÍ O SHODĚ',
  CR: 'CERTIFIKÁT',
  CR1: 'CERTIFIKÁT',
  PV: 'PROHLÁŠENÍ O VLASTNOSTECH',
};

function AttachmentRow({initialAttachment}){
  const parseInitialAttachment = () => JSON.parse(JSON.stringify(initialAttachment), (key, value) => (key === "__typename") ? undefined : (value == null || value === "___xamznone____") ? "" : value);
  const [attachment, setAttachment] = useState(parseInitialAttachment());
  const changeEventHandler = ({target}) => changeHandler(target.id, target.value);
  const changeHandler = (path, value) => {
    let floatValue = parseFloat(value);
    if (value == floatValue) value = floatValue;
    let newAttachment = JSON.parse(JSON.stringify(attachment));
    let splitPath = path.split(".");
    for (let i = 0; i < splitPath.length; i++) {
      let intSplit = parseInt(splitPath[i]);
      if (intSplit == splitPath[i]) splitPath[i] = intSplit;
    }
    switch (splitPath.length) {
      case 1:
        newAttachment[splitPath[0]] = value;
        break;
      case 2:
        newAttachment[splitPath[0]][splitPath[1]] = value;
        break;
      case 3:
        newAttachment[splitPath[0]][splitPath[1]][splitPath[2]] = value;
        break;
      default:
        throw new Error("Path length not handled");
    }
    if (path === "type"){
      newAttachment.typeDesctiption = attachmentDescriptions[value];
    }
    setAttachment(newAttachment);
  };
  const [deleted, setDeleted] = useState(false);
  const [updateAttachment, updateAttachmentResponse] = useMutation(updateAttachmentMutation);
  const [deleteAttachment, deleteAttachmentResponse] = useMutation(deleteAttachmentMutation, {
    onCompleted: () => setDeleted(true),
  });

  if (deleted) return null;
  if (updateAttachmentResponse.loading || deleteAttachmentResponse.loading){
    return (
      <Row className={"attachmentRow"}>
        <Col><Preloader/></Col>
      </Row>
    );
  }

  return (
    <Row className={"attachmentRow"}>
      <Col>
        <Form className={"pb-2 mb-2 border-bottom"}>
          <Form.Row>
            <Col>
              <Form.Group className={"flex-grow-1 d-flex flex-column"} controlId={"type"}>
                <Form.Label className={"mb-auto"}>Typ:</Form.Label>
                <Form.Control
                  as={"select"}
                  value={attachment.type}
                  onChange={changeEventHandler}
                >
                  <option value={"NULL"}>Žádný</option>
                  {Object.keys(attachmentDescriptions).map(attachmentKey => <option value={attachmentKey}>{attachmentDescriptions[attachmentKey]}</option>)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={"file"}>
                <Form.Label className={"w-100"}>Soubor: <a className={"float-right btn btn-outline-primary btn-sm"} target={"_blank"} href={attachment.file}>Zobrazit</a></Form.Label>
                <Form.Control type={"text"} placeholder="není" value={attachment.file} onChange={changeEventHandler}/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className={"flex-grow-1 d-flex flex-column"} controlId={"description"}>
                <Form.Label className={"mb-auto"}>Popisek:</Form.Label>
                <Form.Control type={"text"} placeholder="není" value={attachment.description} onChange={changeEventHandler}/>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={{size: 3, offset: 9}}>
              <div className="btn-group w-100" role="group" aria-label="Operace s přílohou">
                <button onClick={() => setAttachment(parseInitialAttachment())} type="button" className="btn btn-secondary">Vrátit</button>
                <button onClick={() => updateAttachment({
                  variables: {
                    input: {...attachment, createdAt: undefined, updatedAt: undefined}
                  }
                })} type="button" className="btn btn-primary">Uložit</button>
                <ConfirmButton confirmMessage={"Skutečně chcete smazat přílohu: " + attachment.file + "?"} onClick={() => deleteAttachment({
                  variables: {
                    input: {id: attachment.id}
                  }
                })} type="button" className="btn btn-danger">Smazat</ConfirmButton>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  );
}

function SingleProductAttachments(){
  const match = useRouteMatch();
  const {loading: productLoading, data: productData, error: productError} = useQuery(getProductQuery, {
    variables: {
      id: match.params.productId,
    }
  });

  if (productError) return (
    <article className={"singleProductAttachments"}><div className={"danger"}>Chyba při načítání produktu</div></article>
  );

  if (productLoading) return (
    <article className={"singleProductAttachments"}><Preloader/></article>
  );

  return (
    <article className={"singleProductAttachments"}>
      <h3>
        Přílohy produktu: <a href={"/admin/products/" + productData?.getProduct?.id}>{productData?.getProduct?.apiId}</a>
      </h3>
      <p>{productData?.getProduct?.name}</p>
      <hr/>
      {productData?.getProduct?.attachments?.items?.map(attachment => (
        <AttachmentRow key={attachment.id} initialAttachment={attachment}/>
      ))}
    </article>
  );

}

export default SingleProductAttachments;
