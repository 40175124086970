import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';

const links = [
  {
    name: "Dashboard",
    target: "/admin",
  },
  {
    name: "Objednávky",
    target: "/admin/orders",
    className: "",
  },
  {
    name: "Faktury",
    target: "/admin/invoices",
  },
  {
    name: "Uživatelé",
    target: "/admin/users",
    className: "",
  },
  {
    name: "Produkty",
    children: [
      {
        name: "Produkty",
        target: "/admin/products",
      },
      {
        name: "Import produktů",
        target: "/admin/productImport",
      },
      {
        name: "Export produktů",
        target: "/admin/productExport",
      },
      {
        name: "Kategorie",
        target: "/admin/categories",
      },
      {
        name: "Výrobci",
        target: "/admin/manufacturers",
      },
    ]
  },
  {
    name: "Obsah stránek",
    children: [
      {
        name: "Kategorie novinek",
        target: "/admin/newsCategories",
      },
      {
        name: "Novinky",
        target: "/admin/newsPosts",
      },
      {
        name: "Stránky",
        target: "/admin/pages",
      },
      {
        name: "Bannery",
        target: "/admin/banners",
      },
      {
        name: "Popupy",
        target: "/admin/popups",
      },
      {
        name: "Menu",
        target: "/admin/menus"
      },
    ],
  },
  {
    name: "Platební metody",
    target: "/admin/paymentMethods",
  },
  {
    name: "Dopravní metody",
    target: "/admin/shippingMethods",
  },
  {
    name: "Cenové úrovně",
    target: "/admin/priceLevels",
  },
  {
    name: "Nastavení",
    children: [
      {
        name: "FTP server",
        target: "/admin/ftp",
      },
      {
        name: "Proměnné",
        target: "/admin/settings",
      },
    ]
  },
  {
    name: "WIP",
    children: [
      {
        name: "Test editoru 3",
        target: "/admin/editor3",
      },
      {
        name: "Test spojení s ČSOB",
        target: "/admin/csobEchoTest",
      },
      {
        name: "Sitemap",
        target: "/admin/sitemap",
      }
    ]
  }
];

function ParentLink({link, path}){
  let [open, setOpen] = useState(false);
  let childMatch = false;
  link.children.forEach(child => {
    if (path.indexOf(child.target) === 0){
      childMatch = true;
    }
  })

  return (
    <div key={"link_" + link.name} className={"linkParent mb-1 w-100 " + link.className + (open || childMatch ? " open " : "")}>
      <div className={"parentName mb-1 " + (childMatch ? " font-weight-bold " : "")} onClick={() => setOpen(!open)}>
        <span>{link.name}</span>
        <i className={"bi-chevron-down"}/>
      </div>
      <div className={"childrenContainer"}>
        {link.children.map(child => renderLink(child, path))}
      </div>
    </div>
  );
}

function renderLink(link, path){
  if (!link.children || !link.children.length)
    return <Link key={"link_" + link.name} className={"btn btn-success mb-1 w-100 " + link.className + ((path.indexOf(link.target) === 0) ? " font-weight-bold " : "")} to={link.target}>{link.name}</Link>;

  return <ParentLink link={link} path={path}/>
}

function Sidebar(props){
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  return(
    <div className="sideNav py-2 col-xl-2 col-md-3 col-12 position-sticky bg-primary sidebar d-flex flex-column">
      <Navbar expanded={expanded} className={"flex-column flex-nowrap"} expand={"md"} collapseOnSelect={true}>
        <Navbar.Toggle className={"mb-2"} aria-controls="sidebar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse className={"flex-column w-100"} id ="sidebar-nav">
          {links.map(link => renderLink(link, location.pathname))}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Sidebar;
