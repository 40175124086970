import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import propTypes from 'prop-types';
import './breadcrumbs.scss';

class Breadcrumbs extends Component{
  render(){
    const categoryLevels = [
      "category",
      "subCategory",
      "subSubCategory",
      "subSubSubCategory",
      "subSubSubSubCategory",
    ];
    if (!this.props.product) return null;
    let path = "/eshop";
    let path_buttons = "/eshop"


    return (
      <>
        <nav className={"breadcrumbs " + this.props.className}>
          {/*TODO: Create the category tree once we get categories*/}
          <div onClick={() => this.props.history.goBack()} className={"button medium light-gray arrow-left"}>
            <span>Zpět na výpis</span>
          </div>
          
          <div className={"categoryTree"}>
            <Link to={path}>
              eShop
            </Link>
            {categoryLevels.map(categoryLevel => {
              const category = this.props.product[categoryLevel];
              if (!category) return null;
              path += "/" + category.slug;
              return (<>
                <div className={"separator"}>
                  /
                </div>
                <Link to={path}>
                  {category.name}
                </Link>
              </>);
            })}
          </div>
        </nav>

        <nav className={"breadcrumbs hiddenDesktop " + this.props.className}>
          {/* category tree for mobile devices */}
          {/*TODO: link to produkt class or to upper cattegory */}
          <a onClick={() => this.props.history.goBack()} className={"button medium light-gray caret-left"}>
            <span>Zpět</span>
          </a>
          {categoryLevels.map(categoryLevel => {
            const category = this.props.product[categoryLevel];
            if (!category) return null;
            path_buttons += "/" + category.slug;

            return (
              <Link to={path_buttons} className="button medium light-gray">
                <span>{category.name}</span>
              </Link>              
            )
          })}

        </nav>
      </>
    );
  }
}

Breadcrumbs.propTypes = {
  product: propTypes.object,
  history: propTypes.object.isRequired,
  className: propTypes.string,
};

export default Breadcrumbs;
